import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { gql, useMutation, useQuery } from "@apollo/client";

import { showToast } from "../../Helpers/HelperFns";

import MainModal from "../MainModal";
import { BSelect } from "form-builder";

const formNameValidation = "setLeadsAgentClientValidation";

const ASSIGN = gql`
  mutation assign($userId: Int, $requestId: Int) {
    assign_subscription_request(userId: $userId, requestId: $requestId)
  }
`;
const GET_OPTIONS = gql`
  query getOptions {
    users: saas_names {
      id
      name
    }
  }
`;

const SetAccountManagerModal = ({ data, onClose, refetchList }) => {
  console.log(data);
  const { t } = useTranslation();

  // Local State
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formData, setFormData] = React.useState({ user: data?.user || null });

  // Server State
  const [assign, { loading: assignLoading }] = useMutation(ASSIGN);
  const { data: options, loading: isOptionsLoading } = useQuery(GET_OPTIONS, {
    onError: (error) => {
      showToast(
        "error",
        error?.graphQLErrors?.[0]?.extensions?.reason ||
          error?.graphQLErrors?.[0]?.message ||
          error?.message
      );
    },
  });

  // Reducer State
  const assignLeadsClientValidation = useSelector(
    (state) => state.crm[formNameValidation]
  );

  /* ↓ State Effects ↓ */

  /* ↓ Helpers ↓ */

  const handleSelectChange = (val, { action, name, ...rest }) => {
    if (action === "clear") {
      setFormData((prev) => ({ ...prev, [name]: [] }));
    } else {
      const isRemove = action.includes("remove");
      setFormData((prev) => ({
        ...prev,
        [name]: isRemove
          ? prev[name].filter((p) => p.id !== rest.removedValue.id)
          : val,
      }));
    }
  };

  const handleUpsert = () => {
    setFormSubmitting(true);
    if (assignLeadsClientValidation.length) return;

    assign({
      variables: {
        requestId: +data?.id,
        userId: +formData?.user?.id,
      },
      onCompleted: () => {
        refetchList();
        onClose();
        showToast("success");
      },
      onError: (error) => {
        showToast(
          "error",
          error?.graphQLErrors?.[0]?.extensions?.reason ||
            error?.graphQLErrors?.[0]?.message ||
            error?.message
        );
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={onClose}
      btnOnClick={handleUpsert}
      btnSubmitLoading={assignLoading}
      modalTitle={t("set account manager")}
    >
      <BSelect
        name="user"
        icon="employee"
        label="user"
        isClearable
        value={formData.user}
        onChange={handleSelectChange}
        options={options?.users || []}
        isLoading={isOptionsLoading}
        rootStyle="mb-4"
        formSubmitting={formSubmitting}
        formNameValidation={formNameValidation}
      />
    </MainModal>
  );
};

export default SetAccountManagerModal;
