import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery, gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";

import {
  FACIAL_RECOGNITION_OPTIONS,
  facialReportListQuery,
} from "../../Graphql/query";
import {
  onFormResetAction,
  showErrorToast,
  showViewCheckInsOutsLocationModalAction,
  updateValueAction,
} from "../../Store/Actions";

import {
  BSelect,
  DateTimePickerForm,
  CheckboxBooleanForm,
  InputForm,
} from "../../Builder/Form";
import List from "./List";
import { Grid } from "@mui/material";
import { Apps, FormatListBulleted } from "@mui/icons-material";
import { OutlineIconButton } from "../../Components/IconButtonWithTooltip";
import ViewCheckInsOutsLocationMap from "../../Components/HistoryEmployeeCard/ViewCheckInsOutsLocationMap";
import { ExportButton } from "../../Components/Buttons";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import FiltersDrawer, { FilterCheckBox } from "../../Components/FiltersDrawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import ClearIcon from "@mui/icons-material/Clear";
import FilterBadge from "../../Components/FilterBadge";
import moment from "moment";

// Constants
const dataTableRef = "facialRecognitionReport";
const filtersNames = ["employees", "departments", "positions", "offices"];
const tablePaginatorInitState = {
  lastItem: 20,
  total: 20,
  perPage: 30,
  currentPage: 1,
  hasMorePages: false,
  firstItem: 1,
  lastPage: 1,
};
const filterInitState = {
  from: null,
  to: null,
  employees: null,
  positions: null,
  departments: null,
  offices: null,
  employee_search: null,
};

const FacialRecognitionReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [filterState, setFilterState] = useState(filterInitState);

  // Local State
  const [tableData, setTableData] = React.useState([]);
  const [isListView, setIsListView] = React.useState(false);
  const [spoof, setSpoof] = React.useState({ img: false, loc: false });
  const [tableFilters, setTableFilters] = React.useState({
    [filtersNames[0]]: [],
    [filtersNames[1]]: [],
    [filtersNames[2]]: [],
    [filtersNames[3]]: [],
  });
  const [tablePaginator, setTablePaginator] = React.useState(
    tablePaginatorInitState
  );
  // Server State
  const [getAttendanceLogs, { called, loading, variables }] = useLazyQuery(
    facialReportListQuery,
    {
      variables: {
        isListView,
        spoofed_img: spoof?.img,
        spoofed_loc: spoof?.loc,
        perPage: tablePaginatorInitState.perPage,
      },
      onCompleted: (data) => {
        const list = isListView ? "listView" : "gridView";
        setTableData(data?.[list]?.data);
        setTablePaginator(data?.[list]?.paginatorInfo);
      },
      onError: () => {
        setTableData([]);
        setTablePaginator(tablePaginatorInitState);
      },
    }
  );

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    fetchFacialRecognitionReport();
  }, []);

  /* ↓ Helpers ↓ */

  const handleViewLocation = (data) => {
    dispatch(
      showViewCheckInsOutsLocationModalAction({
        data,
        ref: "viewCheckInOutLocationModalActions",
        formName: "viewCheckInOutLocationModal",
      })
    );
  };

  const fetchFacialRecognitionReport = (page = 1) => {
    const filtersVariables = {
      to: filterState.to || "",
      from: filterState.from || "",
      emp_id: filterState.employees ?? [],
      office_id: filterState.offices ?? [],
      department_id: filterState.departments ?? [],
      ...(filterState?.employee_search
        ? {
            employee_search: filterState?.employee_search,
          }
        : {}),
      position_id: filterState.positions ?? [],
      spoofed_img: spoof?.img,
      spoofed_loc: spoof?.loc,
    };
    getAttendanceLogs({
      variables: {
        page,
        ...filtersVariables,
        perPage: tablePaginatorInitState.perPage,
      },
      onCompleted: (data) => {
        const list = isListView ? "listView" : "gridView";
        setTableData(data?.[list]?.data);
        setTablePaginator(data?.[list]?.paginatorInfo);
      },
      onError: () => {
        setTableData([]);
        setTablePaginator(tablePaginatorInitState);
      },
    });
  };

  const handlePaginate = (page) => {
    
    fetchFacialRecognitionReport(page);
  };

  const handleToggleView = () => {
    setIsListView((prev) => !prev);
  };

  // handle employee search input on change function
  const handleEmployeeSearchInputOnChange = (e) => {
    setFilterState((prev) => ({
      ...prev,
      [e?.target?.name]: e?.target?.value,
    }));
    return true;
  };

  const handleSpoof = (name) => {
    setSpoof((prev) => ({ ...prev, [name]: !prev[name] }));
    return true;
  };

  return (
    <>
      {/* Filters */}
      <Filters
        spoof={spoof}
        isListView={isListView}
        tableFilters={tableFilters}
        handleSpoof={handleSpoof}
        handleEmployeeSearchInputOnChange={handleEmployeeSearchInputOnChange}
        handleToggleView={handleToggleView}
        variables={variables}
        handleFilter={fetchFacialRecognitionReport}
        setSpoof={setSpoof}
        setFilterState={setFilterState}
        filterState={filterState}
      />

      {/* List */}
      <List
        tableData={tableData}
        isListView={isListView}
        isLoading={!called || loading}
        tablePaginator={tablePaginator}
        handlePaginate={handlePaginate}
        onViewLocation={handleViewLocation}
      />

      {/* Modals */}
      <ViewCheckInsOutsLocationMap />
    </>
  );
};

const Filters = ({
  spoof,
  isListView,
  tableFilters,
  handleSpoof,
  handleEmployeeSearchInputOnChange,
  handleToggleView,
  variables,
  handleFilter,
  setSpoof,
  filterState,
  setFilterState,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [clearOrCancel, setClearOrCancel] = useState(true);
  const [filterOptions, setFilerOptions] = useState(null);

  useEffect(() => {
    if (clearOrCancel) {
      handleFilter();
      setClearOrCancel(false);
    }
  }, [
    filterState?.employees,
    filterState?.departments,
    filterState?.positions,
    filterState?.offices,
    filterState?.from,
    filterState?.to,
    filterState?.employee_search,
    spoof,
  ]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (open && !filterOptions) {
      getOptions({
        onCompleted: (res) => {
          setFilerOptions(res);
        },
        onError: (err) => {
          showToast(
            "error",
            err?.graphQLErrors?.[0]?.extensions?.reason ?? err?.message
          );
        },
      });
    }
    setIsFilterDrawerOpen(open);
  };

  const onFilterReset = () => {
    setFilterState(filterInitState);
    setSpoof({ img: false, loc: false });
  };

  const onFilterSubmit = () => {
    const { isListView, page, perPage, ...filterVariables } = variables;
    let selectedFilters = {
      spoofed_img: spoof?.img,
      spoofed_loc: spoof?.loc,
      to: filterState?.to ?? "",
      employee_search: filterState?.employee_search ?? null,
      from: filterState?.from ?? "",
      emp_id: filterState?.employees ?? [],
      office_id: filterState?.offices ?? [],
      department_id: filterState?.departments ?? [],
      position_id: filterState?.positions ?? [],
    };

    const isDifferent =
      JSON.stringify({ ...filterVariables }) !==
      JSON.stringify({ ...selectedFilters });

    if (isDifferent) handleFilter();
    setIsFilterDrawerOpen(false);
  };

  const EXPORT_FACIAL_REPORT = gql`
    query exportFR($input: exportFacialRecognitionInput) {
      exportFacialRecognitionReport(input: $input) {
        file
        status
        message
      }
    }
  `;
  const [exportFR, { loading, data, error }] = useLazyQuery(
    EXPORT_FACIAL_REPORT,
    {
      onCompleted(res) {
        // do someting
        if (res?.exportFacialRecognitionReport?.status == "success") {
          HelperFns.downloadFile(res?.exportFacialRecognitionReport?.file);
        } else {
          dispatch(showErrorToast(res?.exportFacialRecognitionReport?.message));
        }
      },
      onError(error) {
        dispatch(showErrorToast(error?.message));
      },
    }
  );

  const [getOptions, { loading: optionsLoading }] = useLazyQuery(
    FACIAL_RECOGNITION_OPTIONS
  );

  const handleExport = () => {
    const filtersVariables = {
      filter_data: {
        emp_id: filterState.employees ?? [],
        office_id: filterState.offices ?? [],
        department_id: filterState.departments ?? [],
        position_id: filterState.positions ?? [],
        date_range: {
          from: filterState.from || "",
          to: filterState.to || "",
        },
        spoofed: spoof?.img,
        mocked_location: spoof?.loc,
        page: "history_logs",
      },
    };
    exportFR({
      variables: {
        input: filtersVariables,
      },
    });
  };

  const renderFilters = () => {
    const selectedEmployees = filterOptions?.employees?.data?.filter((emp) =>
      variables?.emp_id?.includes(emp?.id)
    );
    const selectedDepartments = filterOptions?.departments?.data?.filter(
      (dep) => variables?.department_id?.includes(dep?.id)
    );
    const selectedOffices = filterOptions?.offices?.data?.filter((off) =>
      variables?.office_id?.includes(off?.id)
    );
    const selectedPositions = filterOptions?.positions?.data?.filter((pos) =>
      variables?.position_id?.includes(pos?.id)
    );

    const employeeSearch = variables?.employee_search
      ? [{ name: variables?.employee_search }]
      : [];

    const onCancelFilter = (name, emptyVal = null) => {
      setFilterState((prev) => ({ ...prev, [name]: emptyVal }));
      setClearOrCancel(true);
    };

    const cancelSpoof = (name) => {
      setSpoof((prev) => ({ ...prev, [name]: false }));
      setClearOrCancel(true);
    };

    return (
      <div className="d-flex flex-wrap gap-1">
        <FilterBadge
          label={t("employee search")}
          selectedItems={employeeSearch}
          onCancelFilter={() => onCancelFilter("employee_search", null)}
        />
        <FilterBadge
          label={t("employee")}
          selectedItems={selectedEmployees}
          onCancelFilter={() => onCancelFilter("employees", [])}
        />
        <FilterBadge
          label={t("departments")}
          selectedItems={selectedDepartments}
          onCancelFilter={() => onCancelFilter("departments", [])}
        />
        <FilterBadge
          label={t("offices")}
          selectedItems={selectedOffices}
          onCancelFilter={() => onCancelFilter("offices", [])}
        />
        <FilterBadge
          label={t("positions")}
          selectedItems={selectedPositions}
          onCancelFilter={() => onCancelFilter("positions", [])}
        />
        <FilterBadge
          label={t("from")}
          selectedItems={
            variables?.from
              ? [
                  {
                    name: moment(variables?.from, "YYYY/MM/DD").format(
                      "DD/MM/YYYY"
                    ),
                  },
                ]
              : []
          }
          onCancelFilter={() => onCancelFilter("from", "")}
        />
        <FilterBadge
          label={t("to")}
          selectedItems={
            variables?.to
              ? [
                  {
                    name: moment(variables?.to, "YYYY/MM/DD").format(
                      "DD/MM/YYYY"
                    ),
                  },
                ]
              : []
          }
          onCancelFilter={() => onCancelFilter("to", "")}
        />
        <FilterBadge
          label={t("spoofed images")}
          selectedItems={variables?.spoofed_img ? [{}] : []}
          onCancelFilter={() => cancelSpoof("img")}
        />
        <FilterBadge
          label={t("mocked locations")}
          selectedItems={variables?.spoofed_loc ? [{}] : []}
          onCancelFilter={() => cancelSpoof("loc")}
        />
      </div>
    );
  };

  const appliedFiltersLength =
    (variables?.emp_id?.length ?? 0) +
    (variables?.department_id?.length ?? 0) +
    (variables?.position_id?.length ?? 0) +
    (variables?.office_id?.length ?? 0) +
    (variables?.from ? 1 : 0) +
    (variables?.to ? 1 : 0) +
    (variables?.spoofed_img ? 1 : 0) +
    (variables?.spoofed_loc ? 1 : 0) +
    (variables?.employee_search ? 1 : 0);

  const handelClearFilters = () => {
    onFilterReset();
    setClearOrCancel(true);
  };

  return (
    <>
      <div
        className={`d-flex align-items-center mt-2 gap-1 flex-wrap-reverse ${
          appliedFiltersLength
            ? " justify-content-between"
            : " justify-content-end"
        }`}
      >
        {renderFilters()}

        <div className="d-flex flex-grow-1 gap-1 align-items-center justify-content-end">
          {appliedFiltersLength ? (
            <div onClick={handelClearFilters} className="logs-btn clear-btn">
              <ClearIcon color="inherit" fontSize="small" />
              {t("clear")}
            </div>
          ) : null}

          <div
            onClick={toggleDrawer(true)}
            className={`logs-btn ${
              appliedFiltersLength ? "filter-btn-active" : "filter-btn-inactive"
            }`}
          >
            <FontAwesomeIcon icon={faSlidersH} />
            {t("filters")}
            {appliedFiltersLength ? (
              <div className="filter-count-container">
                {t(appliedFiltersLength)}
              </div>
            ) : null}
          </div>
          {/* <div className="d-flex gap-20">
          <CheckboxBooleanForm
            name="img"
            options={["show only spoofed images"]}
            checked={spoof?.img}
            onChange={handleSpoof}
            setWithValue
          />
          <CheckboxBooleanForm
            name="loc"
            options={["show only mocked locations"]}
            checked={spoof?.loc}
            onChange={handleSpoof}
            setWithValue
          />
        </div> */}

          <div className="d-flex gap-20">
            <HasPrivileges
              allowBP
              reqireMain={[Privilages.EXPORT_FACIAL_RECOGNITION_REPORT]}
            >
              <ExportButton
                onClick={handleExport}
                loadingButton
                loading={loading}
                disabled={!filterState?.from || !filterState?.to}
                disabledLabel={t("Please select a date range to export")}
              />
            </HasPrivileges>
            {isListView ? (
              <OutlineIconButton
                label="Show grid view"
                onClick={handleToggleView}
                icon={<Apps fontSize="small" />}
              />
            ) : (
              <OutlineIconButton
                label="Show list view"
                onClick={handleToggleView}
                icon={<FormatListBulleted fontSize="small" />}
              />
            )}
          </div>
        </div>
      </div>

      <FiltersDrawer
        isFilterDrawerOpen={isFilterDrawerOpen}
        toggleDrawer={toggleDrawer}
        onFilterReset={onFilterReset}
        onFilterSubmit={onFilterSubmit}
        dataTableRef={dataTableRef}
        isLoading={optionsLoading}
        formData={filterState}
        isLocal
        setLocalState={setFilterState}
        employeesOptions={filterOptions?.employees?.data ?? []}
        officesOptions={filterOptions?.offices?.data ?? []}
        positionsOptions={filterOptions?.positions?.data ?? []}
        departmentsOptions={filterOptions?.departments?.data ?? []}
      >
        <InputForm
          labelStyle="mb-2"
          label={t("employee search")}
          name="employee_search"
          icon="search"
          type="search"
          onChange={(e) => handleEmployeeSearchInputOnChange(e)}
          placeholder={t("employee search")}
          value={filterState?.employee_search?.trimStart()}
          inputContainerStyle="w-100"
        />

        <div className="col">
          <FilterCheckBox
            label={t("show only spoofed images")}
            name="img"
            value={spoof?.img}
            onIntercept={handleSpoof}
          />
          <FilterCheckBox
            label={t("show only mocked locations")}
            name="loc"
            value={spoof?.loc}
            onIntercept={handleSpoof}
          />
        </div>
      </FiltersDrawer>
    </>
  );
};

export default FacialRecognitionReport;
