import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import {
  BSelect,
  InputForm,
  DateTimePickerForm,
  CheckboxBooleanForm,
} from "form-builder";
import { connect } from "react-redux";
import {
  dismissHolidaysModalAction,
  createHolidayAction,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";

const formName = "holidaysModal";
const formNameValidation = "holidaysModalValidation";
const formServerValidation = "holidaysModalServerValidation";

const HolidaysModal = (props) => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const FormProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
  };
  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    if (props.modalValidation.length) {
      return;
    }

    props.createHolidayAction({
      data: props.holidaysModal,
      ref: "holidaysModalActions",
    });
  };

  const isModalVissible = props.modalData.isVissible;
  const modalMessage = props.modalData.modalMessage;
  const isLoading = props.modalData.isLoading;
  const toggleModal = () => {
    props.dismissHolidaysModalAction({ ref: "holidaysModalActions", formName });
  };

  useEffect(() => {
    setIsSubmitting(false);
  }, [isModalVissible]);

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={toggleModal}
      modalTitle={t(`${props.holidaysModal?.type} holidays`)}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
    >
      <>
        <div className="row">
          <div className="col-12 mb-3">
            <InputForm
              {...FormProps}
              validateBy="textRequired"
              name="name"
              validationName={`input.name`}
              placeholder={t("name")}
              label="name"
              labelStyle="mb-2"
              containerStyle="mt-0"
              inputContainerStyle=" "
              icon="holiday"
            />
          </div>
          <div className="col-6 mb-2">
            <DateTimePickerForm
              {...FormProps}
              containerStyle="custom_datepicker_wrapper_style"
              name="start_date"
              validateBy="textRequired"
              validationName={`input.start_date`}
              label="start date"
              labelStyle="mb-2"
              hasIcon
              minDate={new Date()}
              requestFormat="YYYY-MM-DD"
            />
          </div>
          <div className="col-6 mb-2">
            <DateTimePickerForm
              {...FormProps}
              containerStyle="custom_datepicker_wrapper_style"
              name="end_date"
              label="end date"
              labelStyle="mb-2"
              validationName={`input.end_date`}
              validateBy="end_date"
              dependTime="start_date"
              hasIcon
              minDate={new Date()}
              requestFormat="YYYY-MM-DD"
            />
          </div>
          <div className="col-12 mb-2">
            <CheckboxBooleanForm
              {...FormProps}
              options={[`${t("Apply Holiday on All Offices")}`]}
              name="all_offices"
              validationName={`input.all_offices`}
              type="checkbox"
            />
            <BSelect
              {...FormProps}
              reducer="super"
              name="office_ids"
              validationName={`input.office_ids`}
              keepDefaultStyle
              optionLabel="name"
              optionValue="id"
              options={props?.holidayOffices}
              isMulti={true}
              inputContainerStyle="mt-2"
              placeholder={t("Select Office")}
              dependOn="all_offices"
              dependancyType="equal"
              dependancyValue={[0]}
              skipLocalization
              icon="office"
            />
          </div>

          <div className="col-12 mb-2">
            <CheckboxBooleanForm
              {...FormProps}
              options={[`${t("Apply Holiday on All Attendance Profiles")}`]}
              name="all_att_profiles"
              validationName={`input.all_att_profiles`}
              type="checkbox"
            />
            <BSelect
              {...FormProps}
              reducer="super"
              name="att_profile_ids"
              validationName={`input.att_profile_ids`}
              keepDefaultStyle
              optionLabel="name"
              optionValue="id"
              options={props?.holidayAttProfile}
              isMulti={true}
              inputContainerStyle="mt-2"
              placeholder={t("Select Attendance Profile")}
              optDependKey="default_office_holiday_profile"
              optDependValue={[0]}
              optDependType="equal"
              dependOn="all_att_profiles"
              dependancyType="equal"
              dependancyValue={[0]}
              skipLocalization
              icon="person"
            />
          </div>
        </div>

        {/* (Start) Error Message */}
        {modalMessage && isSubmitting && (
          <div className="warnig-msg-style">{modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.super.holidaysModalActions,
  modalValidation: state.super[formNameValidation],
  holidaysModal: state.super[formName],
  serverVaildation: state.super[formServerValidation],
  holidayOffices: state.super.holidayOffices,
  holidayAttProfile: state.super.holidayAttProfile,
});

export default connect(mapStateToProps, {
  dismissHolidaysModalAction,
  createHolidayAction,
})(HolidaysModal);
