import React from "react";
import { useTranslation } from "react-i18next";

const FieldError = ({
  name,
  prefixValidationName,
  submitted,
  clientValidations,
  serverValidations,
}) => {
  const { t } = useTranslation();
  let inputValidationName = prefixValidationName + "." + name;
  let isInValid =
    (!!clientValidations[inputValidationName] && submitted) ||
    !!serverValidations[inputValidationName];
  if (isInValid) {
    return (
      <div className="invalid-container-style">
        <div className="validity-msg-style text-left">
          {!serverValidations[inputValidationName]
            ? t(clientValidations[inputValidationName])
            : t(serverValidations[inputValidationName])}
        </div>
      </div>
    );
  } else return null;
};

export default FieldError;
