import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";

import {
  periodToDuration,
  serializeFetchPlan,
} from "../../Helpers/HelperFns/PaymentTracking";
import {
  plansListQuery,
  plansListOptionsQuery,
} from "../../Graphql/query/PaymentTracking";
import {
  removePlanMutation,
  setPlanStatusMutation,
} from "../../Graphql/mutation/PaymentTracking";
import Privileges from "../../Constants/Privilages";
import { onFormResetAction } from "../../Store/Actions";
import { billingPeriodOptions } from "../../Constants/PaymentTracking";
import HelperFns, { openSwalConfirm, showToast } from "../../Helpers/HelperFns";

import {
  UpsertPlanModal,
  AttachTiersModal,
  UpdatePlanCompanies,
  PreviewPlanTiersModal,
} from "../../Components/PaymentTrackingModals";
import IconButtonWithTooltip, {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import Loader from "../../Components/Loader";
import AntSwitch from "../../Components/AntSwitch";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import { AddButton } from "../../Components/Buttons";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { InputForm, BSelect, RadioboxForm } from "form-builder";
import { AddCircle, FileCopyOutlined } from "@mui/icons-material";

const reducer = "paymentTracking";
const filtersFormName = "plansListFilters";
const FiltersFormProps = { reducer, formName: filtersFormName };
const modalInitState = { isOpen: false, data: null };
const modals = [
  { name: "upsert", Modal: UpsertPlanModal },
  { name: "attachTiers", Modal: AttachTiersModal },
  { name: "previewTiers", Modal: PreviewPlanTiersModal },
  { name: "updatePlanCompanies", Modal: UpdatePlanCompanies },
];
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};
const sendFilters = (filters) => {
  return {
    page: 1,
    status: filters?.type,
    name: filters?.search || undefined,
    package_id: +filters?.package || undefined,
    period: filters?.billingPeriod || undefined,
    currency_id: +filters?.currency || undefined,
    duration: +filters?.contractDuration || undefined,
  };
};

const PlansList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [data, setData] = React.useState([]);
  const [pagination, setPagination] = React.useState(paginationInitState);
  const [modalsState, setModalsState] = React.useState(
    modals.map(({ name }) => ({ name, ...modalInitState }))
  );

  // Reducer State
  const filters = useSelector((state) => state?.[reducer]?.[filtersFormName]);
  const options = useSelector(
    (state) => state?.[reducer]?.paymentTrackingOptions
  );

  // Server State
  const [removePlan, { loading: removePlanLoading }] =
    useMutation(removePlanMutation);
  const [setStatus, { loading: setStatusLoading }] = useMutation(
    setPlanStatusMutation
  );
  const { loading: isOptionsLoading } = useQuery(plansListOptionsQuery, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ companies, currencies, packages }) => {
      dispatch(
        onFormResetAction("paymentTrackingOptions", {
          ...options,
          companies: companies || [],
          currencies: currencies || [],
          packages: packages?.data || [],
        })
      );
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });
  const { loading, refetch } = useQuery(plansListQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      first: pagination?.perPage,
      page: pagination?.currentPage,
      ...sendFilters(filters),
    },
    onCompleted: ({ plans }) => {
      const formattedPlans = plans?.data?.length
        ? plans?.data?.map((d) => {
            const plan = serializeFetchPlan(d);
            return {
              ...plan,
              package: plan?.package?.name,
              currency: plan?.currency?.name,
            };
          })
        : [];

      setData(formattedPlans);
      setPagination(plans?.pagination || paginationInitState);
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  // Constants
  const columns = React.useMemo(
    () => [
      { name: t("name"), selector: "name", grow: 2 },
      { name: t("Package"), selector: "package", grow: 2 },
      { name: t("Currency"), selector: "currency" },
      { name: t("Billing Period"), selector: "billingPeriod" },
      {
        name: t("Contract Duration"),
        cell: ({ contractDuration, billingPeriod }) =>
          contractDuration + " " + periodToDuration(billingPeriod),
      },
      { name: t("suspend after days"), selector: "suspend_after" },
      { name: t("Payment Pricing"), selector: "pricing" },
      { name: t("Payment Type"), selector: "paymentType" },
      {
        width: "fit-content",
        name: t("No. Of Tiers"),
        cell: ({
          id,
          name,
          tiers,
          paymentType,
          billingPeriod,
          contractDuration,
          package: Package,
        }) => (
          <div className="d-flex gap-10">
            <HasPrivileges
              altChildren={<>{tiers.length} Tiers</>}
              reqireMain={[
                Privileges.VIEW_PLAN_TIERS,
                Privileges.SUPER_PRIVILEGE,
              ]}
            >
              <button
                className="link-btn"
                onClick={() =>
                  handleOpenModal("previewTiers", {
                    name,
                    tiers,
                    paymentType,
                    billingPeriod,
                    contractDuration,
                    package: Package,
                  })
                }
              >
                {tiers.length} Tiers
              </button>
            </HasPrivileges>
            <HasPrivileges
              reqireMain={[
                Privileges.ADD_EDIT_PAYMENT_PLANS,
                Privileges.SUPER_PRIVILEGE,
              ]}
            >
              <IconButtonWithTooltip
                label="attach tiers"
                icon={<AddCircle fontSize="small" />}
                onClick={() => handleOpenModal("attachTiers", { id, tiers })}
              />
            </HasPrivileges>
          </div>
        ),
      },
      { name: t("Active Subscribers"), selector: "activeSubscribers" },
      {
        name: "companies",
        cell: (row) => (
          <EditIconButton onClick={() => handleUpdatePlanCompanies(row)} />
        ),
        omit: !HelperFns.checkPrivileges({
          privileges: [
            Privileges.ADD_EDIT_PAYMENT_PLANS,
            Privileges.SUPER_PRIVILEGE,
          ],
        }),
      },
      {
        name: t("Active"),
        omit: !HelperFns.checkPrivileges({
          privileges: [
            Privileges.ACTIVATE_DEACTIVATE_PAYMENT_PLANS,
            Privileges.SUPER_PRIVILEGE,
          ],
        }),
        cell: ({ id, status }) => (
          <AntSwitch
            checked={status === "Active"}
            onChange={() => handleChangeStatus(+id, status)}
          />
        ),
      },
      {
        cell: (row) => (
          <>
            <HasPrivileges
              reqireMain={[
                Privileges.SUPER_PRIVILEGE,
                Privileges.ADD_EDIT_PAYMENT_PLANS,
              ]}
            >
              <IconButtonWithTooltip
                label="clone"
                onClick={() => handleCLonePlan(row?.id)}
                icon={
                  <FileCopyOutlined sx={{ fontSize: 18, color: "#009efb" }} />
                }
              />
            </HasPrivileges>
            <HasPrivileges
              reqireMain={[
                Privileges.SUPER_PRIVILEGE,
                Privileges.DELETE_PAYMENT_PLANS,
              ]}
            >
              <RemoveIconButton onClick={() => handleRemovePlan(row?.id)} />
            </HasPrivileges>
          </>
        ),
      },
    ],
    []
  );

  React.useEffect(() => {
    return () => {
      dispatch(onFormResetAction(filtersFormName));
    };
  }, []);

  /* ↓ Helpers ↓ */

  const handleOpenModal = (name, data = null) => {
    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? { name, data, isOpen: true } : p))
    );
  };

  const handleCloseModal = (name) => {
    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? { name, ...modalInitState } : p))
    );
  };

  const handleCLonePlan = (id) => {
    handleOpenModal("upsert", { id, isClone: true });
  };

  const handleRemovePlan = (id) => {
    openSwalConfirm(
      (confirmed) =>
        confirmed &&
        removePlan({
          variables: { id: +id },
          onCompleted: () => {
            refetch();
            showToast("success");
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors?.[0]?.extensions?.reason ||
                err?.graphQLErrors?.[0]?.message ||
                err?.message
            );
          },
        })
    );
  };

  const handleUpdatePlanCompanies = (plan) => {
    handleOpenModal("updatePlanCompanies", {
      id: +plan?.id,
      companiesIds: plan.companyIds,
    });
  };

  const handleChangeStatus = (id, status) => {
    setStatus({
      variables: { id, status: status === "Active" ? "Inactive" : "Active" },
      onCompleted: () => {
        refetch();
        showToast("success");
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });
  };

  const handlePaginate = (page = pagination.currentPage) => {
    refetch({ page });
  };

  return (
    <>
      {removePlanLoading || setStatusLoading ? <Loader fixed /> : null}

      {/* Filters */}
      <div className="d-flex gap-20 flex-wrap pt-2">
        <BSelect
          {...FiltersFormProps}
          label="currency"
          name="currency"
          icon="money"
          rootStyle="flex-1"
          options={options.currencies}
          isLoading={isOptionsLoading}
          isClearable
        />
        <BSelect
          {...FiltersFormProps}
          label="package"
          name="package"
          icon="document"
          rootStyle="flex-1"
          options={options.packages}
          isLoading={isOptionsLoading}
          isClearable
        />
        <BSelect
          {...FiltersFormProps}
          label="Billing period"
          name="billingPeriod"
          icon="calendar"
          rootStyle="flex-1"
          options={billingPeriodOptions}
          isClearable
        />
        <InputForm
          {...FiltersFormProps}
          name="contractDuration"
          label="Contract Duration"
          icon="time"
          labelStyle="mb-2 pt-1"
          containerStyle="w-100"
          rootStyle="flex-1"
          placeholder="No. Of Billing Cycles"
        />
      </div>
      <div className="d-flex justify-content-between align-items-center my-3 gap-20">
        <RadioboxForm
          {...FiltersFormProps}
          name="type"
          options={[
            { label: "Active", value: "Active" },
            { label: "Inactive", value: "Inactive" },
            { label: "All", value: "All" },
          ]}
          optionItemStyle=" "
          optionInputStyle=" "
          containerStyle="mb-0"
          optionsContainerStyle="d-flex gap-10"
        />

        <InputForm
          {...FiltersFormProps}
          name="search"
          placeholder="search"
          type="search"
          rootStyle="flex-1"
          containerStyle="w-100"
          inputStyle="search_input"
          inputContainerStyle="search_container"
          icon="search"
        />

        <HasPrivileges
          reqireMain={[
            Privileges.ADD_EDIT_PAYMENT_PLANS,
            Privileges.SUPER_PRIVILEGE,
          ]}
        >
          <AddButton onClick={() => handleOpenModal("upsert")} />
        </HasPrivileges>
      </div>

      {/* Table */}
      <DataTable
        noHeader
        data={data}
        columns={columns}
        className="cards_table"
        progressPending={loading}
        progressComponent={<Loader inner />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {/* Modals */}
      {modals.map(({ name, Modal }, i) =>
        modalsState[i].isOpen ? (
          <Modal
            key={name}
            refetchList={refetch}
            data={modalsState[i]?.data}
            onClose={() => handleCloseModal(name)}
          />
        ) : null
      )}
    </>
  );
};

export default PlansList;
