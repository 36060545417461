import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MainModal from "../../../Components/MainModal";
import {
  toggleTransferAssetsModal,
  toggleWarehouseDetailsModal,
} from "../../../Store/Actions";
import { useMutation, useQuery } from "@apollo/client";
import { GET_WAREHOUSE_DETAILS } from "../../../Graphql/query";
import DataTable from "react-data-table-component";
import { RemoveIconButton } from "../../../Components/IconButtonWithTooltip";
import { DELETE_ITEM } from "../../../Graphql/mutation";
import { showToast } from "../../../Helpers/HelperFns";
import Swal from "sweetalert2";
import { IconButton } from "@mui/material";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import Privilages from "../../../Constants/Privilages";

const ExpandedItems = ({ data }) => {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(data?.items ?? []);
  }, [data?.items]);

  const [attemptDeleteItem] = useMutation(DELETE_ITEM, {
    onCompleted: (data) => {
      if (data?.deleteItem?.__typename == "GeneralException") {
        showToast("error", data?.deleteItem?.message);
        return;
      }
      setItems((prev) =>
        prev?.filter((item) => item?.id != data?.deleteItem?.id)
      );
    },
    onError(err) {
      showToast(
        "error",
        err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
      );
    },
  });

  const handleDelete = (item) => {
    Swal.fire({
      icon: "warning",
      title: t("are you sure"),
      showConfirmButton: true,
      confirmButtonText: t("Yes"),
      confirmButtonColor: "#7cd1f9",
      showDenyButton: true,
      denyButtonText: t("No"),
      showCancelButton: false,
      customClass: {
        popup: "swal-warning-style",
        icon: "swal-icon",
        denyButton: "order-2",
        confirmButton: "order-3",
        htmlContainer: "attendance-type-alert-text",
      },
    }).then(async (values) => {
      if (values.isConfirmed) {
        attemptDeleteItem({
          variables: {
            id: item?.id,
          },
        });
      }
    });
  };
  return (
    <div className="d-flex align-items-center flex-wrap shadow-sm p-3">
      {items?.map((item) => {
        return (
          <div
            className="w-50 my-1 d-flex align-items-center justify-content-between"
            key={item?.id}
          >
            <p className="m-0">{item?.serial_number}</p>
            <HasPrivileges
              allowBP
              reqireMain={[Privilages.DELETE_ASSET_UNITES]}
            >
              <RemoveIconButton onClick={() => handleDelete(item)} />
            </HasPrivileges>
          </div>
        );
      })}
    </div>
  );
};

const groupItemsByAssets = (items) => {
  const assets = [];

  items?.forEach((item) => {
    const assetIndex = assets?.findIndex(
      (asset) => asset?.id == item?.asset?.id
    );

    if (assetIndex > -1) {
      assets[assetIndex] = {
        ...assets[assetIndex],
        items: [
          ...(assets[assetIndex]?.items ?? []),
          { id: item?.id, serial_number: item?.serial_number },
        ],
      };
    } else {
      assets?.push({
        id: item?.asset?.id,
        name: item?.asset?.name,
        model: item?.asset?.model,
        productType: item?.asset?.product_type,
        type: item?.asset?.assetType?.name,
        items: [{ id: item?.id, serial_number: item?.serial_number }],
      });
    }
  });

  return assets;
};

const WarehouseDetailsModal = ({ handleRefetch }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [expandedAssets, setExpandedAssets] = useState([]);

  const isOpen = useSelector(
    (state) => state?.assets?.warehouseDetailsModal?.isOpen
  );

  const warehouseId = useSelector(
    (state) => state?.assets?.warehouseDetailsModal?.warehouseId
  );

  const { data, loading } = useQuery(GET_WAREHOUSE_DETAILS, {
    variables: {
      id: warehouseId,
    },
  });

  const warehouse = data?.fetchWarehouse ?? {};

  const dismiss = () => {
    dispatch(toggleWarehouseDetailsModal({ isOpen: false, warehouseId: null }));
    handleRefetch();
  };

  const handleViewSerials = (assetId) => {
    setExpandedAssets((assetIds) => {
      if (assetIds?.includes(assetId)) {
        return assetIds?.filter((id) => id != assetId);
      } else {
        return [...assetIds, assetId];
      }
    });
  };

  const handleTransferAsset = (asset) => {
    dispatch(
      toggleWarehouseDetailsModal({
        isOpen: false,
        warehouseId: null,
      })
    );

    dispatch(
      toggleTransferAssetsModal({
        isOpen: true,
        asset,
        warehouseId: warehouse?.id,
      })
    );
  };

  const columns = [
    {
      name: t("asset"),
      cell: (asset) => `${asset?.name} - ${asset?.model}`,
    },
    {
      name: t("type"),
      cell: (asset) => asset?.type,
    },
    {
      name: t("units"),
      cell: (asset) => asset?.items?.length,
    },
    {
      cell: (asset) => {
        
        return (
          <div className="d-flex align-items-center gap-10">
            {asset?.productType?.toLowerCase() == "serialized" ? (
              <IconButton
                style={{ fontSize: 14, borderRadius: 4 }}
                onClick={() => handleViewSerials(asset?.id)}
              >
                {t("view serials")}
              </IconButton>
            ) : null}

            <HasPrivileges
              allowBP
              reqireMain={[
                Privilages.TRANSFER_ASSETS_UNITS_TO_ANOTHER_STORAGE_LOCATION,
              ]}
            >
              <IconButton onClick={() => handleTransferAsset(asset)}>
                <SyncAltIcon color="primary" fontSize="small" />
              </IconButton>
            </HasPrivileges>
          </div>
        );
      },
    },
  ];

  return (
    <MainModal
      isOpen={isOpen}
      toggle={dismiss}
      modalTitle={warehouse?.name}
      btnLabel={t("save")}
      size="lg"
    >
      <h5>{warehouse?.address}</h5>
      <div className="d-flex align-items-center justify-content-between border-top border-bottom py-2">
        <p className="m-0 align-self-start w-100">
          <b className="mr-2">{t("total assets")}</b> {warehouse?.assets_count}
        </p>
        <p className="m-0 align-self-start w-100">
          <b className="mr-2">{t("total units")}</b> {warehouse?.items_count}
        </p>
      </div>

      <DataTable
        noHeader
        data={groupItemsByAssets(warehouse?.items) ?? []}
        columns={columns}
        className="cards_table my-3"
        progressPending={loading}
        progressComponent={<></>}
        pagination={false}
        paginationServer={false}
        expandableRows
        expandableRowsComponent={<ExpandedItems />}
        expandableRowDisabled={() => true}
        expandableRowExpanded={(asset) => expandedAssets?.includes(asset?.id)}
        expandableRowsHideExpander
      />
    </MainModal>
  );
};

export default WarehouseDetailsModal;
