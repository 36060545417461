import React from "react";
import { makeStyles } from "tss-react/mui";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";
import HelperFns from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";
import { useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import i18n from "i18next";
import { useSelector } from "react-redux";
import Constants from "../../Constants";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import useAllowCostCenter from "../../Helpers/Hooks/useAllowCostCenter";

const verticalNavbarStyles = makeStyles()(() => Constants.verticalNavbarStyles);
const horizontalNavbarStyles = makeStyles()(
  () => Constants.horizontalNavbarStyles
);

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const FinancialsTabs = (props) => {
  const { allowCostCenter } = useAllowCostCenter();

  const handleChange = (event, newValue) => {
    history.push(`/financials?tab=${newValue}`);
  };
  const history = useHistory();
  let query = useQuery();
  const activeValue = query.get("tab");
  const { classes } = props?.isRouteSidebar
    ? verticalNavbarStyles()
    : horizontalNavbarStyles();
  const { t } = useTranslation();
  const isRtl =
    i18n.dir(useSelector((state) => state.user.userProfile.lng)) === "rtl"
      ? true
      : false;

  const possibleValues = [
    "payroll",
    "bonus",
    "deductions",
    "deductions_policy",
    "loans",
    "expenses",
    "cost-centers",
    // "commission",
  ];

  return (
    <>
      <Tabs
        variant="scrollable"
        value={possibleValues.includes(activeValue) ? activeValue : false}
        onChange={handleChange}
        orientation={props?.isRouteSidebar ? "vertical" : "horizontal"}
        scrollButtons="auto"
        classes={{
          root: classes.root,
          indicator: classes.indicator,
          flexContainer: classes.flexContainer,
          scrollButtons: classes.scrollButtons,
        }}
      >
        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_PAYROLL_LIST],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            value={"payroll"}
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faHandHoldingUsd"]}
                  className="mx-2 routeBar-icon"
                />
                {t("payroll")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_EMPLOYEE_BONUSES_DEDUCTIONS],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            value={"bonus"}
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faHandHoldingUsd"]}
                  className="mx-2 routeBar-icon"
                />
                {t("bonus")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_EMPLOYEE_BONUSES_DEDUCTIONS],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            value={"deductions"}
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faCut"]}
                  className="mx-2 routeBar-icon"
                />
                {t("deductions")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_EMPLOYEE_BONUSES_DEDUCTIONS],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            value={"deductions_policy"}
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faCut"]}
                  className="mx-2 routeBar-icon"
                />
                {t("deductions policy")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_LOANS_LIST],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            value={"loans"}
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faMoneyCheckAlt"]}
                  className="mx-2 routeBar-icon"
                />
                {t("loans")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [
            Privilages.VIEW_PETTY_CASH_BALANCE_ADJUSTMENT_LIST,
            Privilages.VIEW_THE_EXPENSES_OF_MY_OFFICE,
          ],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            value={"expenses"}
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faMoneyBillAlt"]}
                  className="mx-2 routeBar-icon"
                />
                {t("expenses")}
              </div>
            }
          />
        )}

        {allowCostCenter ? (
          <Tab
            classes={{
              root: `${
                HelperFns.checkPrivileges({
                  allowBP: true,
                  privileges: [
                    Privilages.VIEW_COST_CENTERS,
                    Privilages.VIEW_COST_CENTERS_REPORT,
                  ],
                })
                  ? classes.tabRoot
                  : "d-none"
              }`,
              wrapper: "top-bar-label",
              selected: classes.selected,
            }}
            value="cost-centers"
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={faFileAlt}
                  className="mx-2 routeBar-icon"
                />
                {t("cost centers")}
              </div>
            }
          />
        ) : null}

        {/* {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_EMPLOYEE_BONUSES_DEDUCTIONS],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            value={"commission"}
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faHandHoldingUsd"]}
                  className="mx-2 routeBar-icon"
                />
                {t("commission")}
              </div>
            }
          />
        )} */}
      </Tabs>
    </>
  );
};

export default FinancialsTabs;
