import gql from "graphql-tag";

export const UPSERT_DEDUCTION_POLICY_MUTATION = gql`
  mutation upsertDeductionPolicy(
    $name: String = ""
    $violations: [ViolationInput]
    $id: ID
    $isEdit: Boolean!
  ) {
    store_deduction_policy(name: $name, violations: $violations)
      @skip(if: $isEdit) {
      id
      name
      violations {
        amount
        type
      }
    }
    update_deduction_policy(id: $id, name: $name, violations: $violations)
      @include(if: $isEdit) {
      id
      name
    }
  }
`;

export const DELETE_DEDUCTION_POLICY_MUTATION = gql`
  mutation deleteDeductionPolicy($id: ID) {
    delete_deduction_policy(id: $id)
  }
`;
