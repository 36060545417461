import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { components as selectComponent } from "react-select";
import { useFormValidation } from "../../Helpers/Hooks/useFormValidation";
import { withDependency } from "../../Helpers/HOC/withDependency";
import { ErrorOutline } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import { onInputChangeAction } from "../../Store/Actions";
import CreatableSelect from "react-select/creatable";
import { renderIcon } from "./formIcons";

// import { userApiClient } from "../../Services/UserService";

const InputTooltip = ({ title = "", tooltipStyle }) => {
  return (
    <span className={`${tooltipStyle ? tooltipStyle : "pl-2"}`}>
      <Tooltip placement="top" title={title}>
        <ErrorOutline fontSize="inherit" color="inherit" />
      </Tooltip>
    </span>
  );
};

const styless = new Proxy(
  {},
  {
    get: (target, propKey) => () => {},
  }
);

const defStyle = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "red" : "blue",
    padding: 20,
  }),
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    width: 400,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const CreateSelect = ({
  isDisabled,
  isClearable,
  isRtl,
  isSearchable,
  isMulti,
  name,
  dependOn,
  label,
  labelStyle,
  keepDefaultLabel,
  formName,
  validateBy,
  formSubmitting,
  formNameValidation,
  containerStyle,
  keepDefaultContainer,
  inputContainerStyle,
  keepDefaultInputContainer,
  hasTooltip,
  tooltipTitle,
  tooltipStyle,
  options,
  SSR,
  endUrl,
  queryKey,
  minQuery = 3,
  optionLabel = "name",
  optionValue = "id",
  keepDefaultStyle,
  classNamePrefix,
  defaultStyle,
  errorStyle,
  keepDefaultError,
  optDependType,
  optDependKey,
  optDependValue,
  data,
  icon,
  iconStyle,
  validateContainerStyle,
  validateMessage,
  rootStyle = "",
  formServerValidation,
  validationName = undefined,
  ...props
}) => {
  const inputval = props?.value ?? data[formName][name];
  const validate = useFormValidation(
    inputval,
    validationName ?? name,
    formNameValidation,
    formSubmitting,
    validateBy,
    isDisabled
  );
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedOptions, setFetchedOptions] = useState([]);
  const isServerValid = !Boolean(data[formServerValidation])
    ? false
    : Boolean(data[formServerValidation][validationName ?? name])
      ? true
      : false;
  const handleChange = (value, event) => {
    if (isMulti) {
      dispatch(
        onInputChangeAction(
          formName,
          event,
          "select",
          value ? value.map((val) => val[optionValue]) : []
        )
      );
      // onInputChangeAction(
      //   formName,
      //   event,
      //   "select",
      //   value ? value.map(val => val[optionValue]) : []
      // );
      setSelected(value);
    } else {
      dispatch(
        onInputChangeAction(
          formName,
          event,
          "select",
          value ? value[optionValue] : ""
        )
      );
      // onInputChangeAction(
      //   formName,
      //   event,
      //   "select",
      //   value ? value[optionValue] : ""
      // );
      setSelected(value ? [value] : "");
    }
  };

  const filterOptions = (option, inputValue) => {
    return true;
  };

  const dependOptions = () => {
    switch (optDependType) {
      case "equal":
        return options?.filter((opt) =>
          optDependValue.includes(opt[optDependKey])
        );

      case "notEqual":
        return options?.filter(
          (opt) => !optDependValue.includes(opt[optDependKey])
        );

      case "greater":
        return options?.filter((opt) => opt[optDependKey] > optDependValue);
      case "less":
        return options?.filter((opt) => opt[optDependKey] < optDependValue);

      default:
        return options;
    }
  };

  const vaildContainer = () => {
    if (!validate.validity || isServerValid) {
      return validateContainerStyle ?? "invalid-container-style";
    }
    return " ";
  };

  const Control = ({ children, ...props }) => (
    <selectComponent.Control {...props}>
      {icon ? (
        <div className={`bselect_icon ${iconStyle ?? ""}`}>
          {renderIcon(icon)}
        </div>
      ) : null}
      {children}
    </selectComponent.Control>
  );

  return (
    <div className={`${rootStyle} ${vaildContainer()}`}>
      <div
        className={`${keepDefaultContainer ? "select-def-container" : ""} ${
          containerStyle ? containerStyle : "my-1"
        }`}
      >
        {label && (
          <label
            className={`${keepDefaultLabel ? "select-def-label" : ""} ${
              labelStyle ? labelStyle : "mb-2"
            } validity-label-style`}
          >
            {label}
            {hasTooltip && (
              <InputTooltip title={tooltipTitle} tooltipStyle={tooltipStyle} />
            )}
          </label>
        )}

        <CreatableSelect
          className={`${
            keepDefaultInputContainer ? "select-def-input-container" : ""
          } ${inputContainerStyle ? inputContainerStyle : "b-select-style"}`}
          classNamePrefix={classNamePrefix ? classNamePrefix : "b-select-style"}
          getOptionLabel={(option) => option[optionLabel]}
          getOptionValue={(option) => option[optionValue]}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isClearable={isClearable}
          isRtl={isRtl}
          components={{ Control }}
          {...(SSR
            ? {
                // onInputChange: loadOptions,
                filterOption: filterOptions,
                options:
                  selected && isMulti
                    ? [
                        ...fetchedOptions.filter(
                          (opt) => !inputval?.includes(opt[optionValue])
                        ),
                        ...selected,
                      ]
                    : fetchedOptions,
                value:
                  isMulti && SSR
                    ? selected
                    : fetchedOptions?.filter(
                        (opt) => inputval == opt[optionValue]
                      ),
              }
            : {
                options: dependOptions(),
                value: isMulti
                  ? options?.filter((opt) =>
                      inputval?.includes(opt[optionValue])
                    )
                  : options?.filter((opt) => inputval == opt[optionValue]),
              })}
          isMulti={isMulti}
          styles={keepDefaultStyle ? "" : styless}
          name={name}
          onChange={handleChange}
          {...props}
        />
      </div>
      <div className="validity-msg-style text-left">
        {validateMessage ? validateMessage : validate.validityMessage}
        {validate.validityMessage && <br />}
        {Boolean(data[formServerValidation])
          ? (data[formServerValidation][validationName ?? name] ?? "")
          : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state, { reducer = "super" }) => {
  return {
    data: state[reducer],
  };
};

export default connect(mapStateToProps)(withDependency(CreateSelect));
