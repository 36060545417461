import React from "react";
import { makeStyles } from "tss-react/mui";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";
import HelperFns from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";
import Constants, { EmployeeTypes } from "../../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faAddressBook,
  faFileAlt,
  faHandHoldingUsd,
  faMoneyCheckAlt,
  faCalendarDay,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

const horizontalNavbarStyles = makeStyles()(
  () => Constants.horizontalNavbarStyles
);

const possibleValues = [1, 2, 3, 4, 5, 6, 7, 8];

const MyProfileTabs = (props) => {
  const { classes } = horizontalNavbarStyles();
  const { t } = useTranslation();

  const isFlexUser =
    useSelector((state) => state.auth.userProfile?.employee?.__typename) ===
    EmployeeTypes.FLEX ;

  return (
    <div className={classes.root}>
      <Tabs
        variant="scrollable"
        value={
          possibleValues.includes(props?.activeValue)
            ? props?.activeValue
            : false
        }
        onChange={props.handleChangeTab}
        scrollButtons="auto"
        classes={{
          root: classes.root,
          indicator: classes.indicator,
          flexContainer: classes.flexContainer,
          scrollButtons: classes.scrollButtons,
        }}
      >
        <Tab
          classes={{
            root: classes.tabRoot,
            wrapper: "profile-top-bar-label",
            selected: classes.selected,
          }}
          value={1}
          label={
            <div className="mx-2 routeBar-text">
              <FontAwesomeIcon
                icon={faEllipsisH}
                className="mx-2 routeBar-icon"
              />
              {t("general")}
            </div>
          }
        />

        {HelperFns.checkPrivileges({
          privileges: [Privilages.VIEW_PROFILE_CALENDAR],
        }) ? (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: classes.selected,
            }}
            value={7}
            label={
              <div className="mx-2 routeBar-text">
                <FontAwesomeIcon
                  icon={faCalendarDay}
                  className="mx-2 routeBar-icon"
                />
                {t("work calendar")}
              </div>
            }
          />
        ) : (
          ""
        )}

        {/* {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_EMPLOYEE_DOCUMENTS],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
                selected: classes.selected,
              }}
            value={2}
            label={t("payroll")}
          />
        )} */}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_ATTENDANCE_HISTORY],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "profile-top-bar-label",
              selected: classes.selected,
            }}
            value={3}
            label={
              <div className="mx-2 routeBar-text">
                <FontAwesomeIcon
                  icon={faAddressBook}
                  className="mx-2 routeBar-icon"
                />
                {isFlexUser ? t("time sheet") : t("history")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_PAYROLL],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "profile-top-bar-label",
              selected: classes.selected,
            }}
            value={4}
            label={
              <div className="mx-2 routeBar-text">
                <FontAwesomeIcon
                  icon={faHandHoldingUsd}
                  className="mx-2 routeBar-icon"
                />
                {t("payroll")}
              </div>
            }
          />
        )}

        {props?.userPettyCash?.length > 0 &&
        HelperFns.checkPrivileges({
          allowBP: false,
          privileges: [Privilages.VIEW_PETTY_CASH_BALANCE_LIST],
        }) ? (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "profile-top-bar-label",
              selected: classes.selected,
            }}
            value={5}
            label={
              <div className="mx-2 routeBar-text">
                <FontAwesomeIcon
                  icon={faHandHoldingUsd}
                  className="mx-2 routeBar-icon"
                />
                {t("Petty Cash")}
              </div>
            }
          />
        ) : (
          ""
        )}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_MY_LOANS],
        }) ? (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "profile-top-bar-label",
              selected: classes.selected,
            }}
            value={6}
            label={
              <div className="mx-2 routeBar-text">
                <FontAwesomeIcon
                  icon={faMoneyCheckAlt}
                  className="mx-2 routeBar-icon"
                />
                {t("loans")}
              </div>
            }
          />
        ) : (
          ""
        )}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [
            Privilages.VIEW_MY_ASSETS,
            Privilages.VIEW_MY_ASSET_REQUEST,
            Privilages.SUBMIT_ASSET_REQUEST,
          ],
        }) ? (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "profile-top-bar-label",
              selected: classes.selected,
            }}
            value={8}
            label={
              <div className="mx-2 routeBar-text">
                <FontAwesomeIcon
                  icon={faWarehouse}
                  className="mx-2 routeBar-icon"
                />
                {t("assets")}
              </div>
            }
          />
        ) : null}
      </Tabs>
    </div>
  );
};

export default MyProfileTabs;
