import React from "react";
import { useSelector } from "react-redux";

const useCompanyAttType = () => {
  const attendance_type = useSelector(
    (state) => state?.auth?.userProfile?.company?.attendance_type
  );

  return {
    hasFlex: ["flex", "fixed_and_flex"]?.includes(attendance_type),
    hasFixed: ["fixed", "fixed_and_flex"]?.includes(attendance_type),
  };
};

export default useCompanyAttType;
