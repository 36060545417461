import React from "react";
import { useTranslation } from "react-i18next";

import { AddButton } from "../Buttons";
import { InputForm } from "form-builder";
import { TimesIconButton } from "../IconButtonWithTooltip";

const contactUnit = { name: "", email: "", phone: "" };

const PaymentContacts = ({ contacts, setContacts }) => {
  const { t } = useTranslation();

  /* ↓ Helpers ↓ */

  const handleAddContact = () => {
    setContacts((prev) => [...prev, contactUnit]);
  };

  const handleRemoveContact = (i) => {
    setContacts((prev) => prev.filter((_, idx) => idx !== i));
  };

  const handleTextChange = (e, i) => {
    setContacts((prev) =>
      prev.map((p, idx) =>
        idx === i ? { ...p, [e.target.name]: e.target.value } : p
      )
    );
  };

  return (
    <>
      <div className="w-100 d-flex align-items-center gap-20 my-2">
        <strong className="blue-color">{t("contacts")}</strong>
        <AddButton isIconOnly onClick={handleAddContact} />
      </div>

      {contacts.map((contact, i) => (
        <div key={i} className="d-flex align-items-end gap-20">
          <InputForm
            name="name"
            icon="employee"
            label="name"
            rootStyle="flex-1"
            placeholder={t("name")}
            onChange={(e) => handleTextChange(e, i)}
            inputContainerStyle="w-100"
            value={contact?.name}
          />
          <InputForm
            name="phone"
            icon="phone"
            label="phone"
            rootStyle="flex-1"
            placeholder={t("phone")}
            onChange={(e) => handleTextChange(e, i)}
            inputContainerStyle="w-100"
            value={contact?.phone}
          />
          <InputForm
            name="email"
            icon="mail"
            label="email"
            rootStyle="flex-1"
            placeholder={t("email")}
            onChange={(e) => handleTextChange(e, i)}
            inputContainerStyle="w-100"
            value={contact?.email}
          />
          <TimesIconButton onClick={() => handleRemoveContact(i)} />
        </div>
      ))}
    </>
  );
};

export default PaymentContacts;
