import React, { useEffect, useState } from "react";
import { InputForm, RadioboxForm } from "form-builder";
import { useTranslation } from "react-i18next";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import AddIcon from "@mui/icons-material/Add";
import { AddButton } from "../../../Components/Buttons";
import UpsertCompanyAssetModal from "./UpsertCompanyAssetModal";
import { useDispatch, useSelector } from "react-redux";
import {
  onFormResetAction,
  toggleCompanyAssetModal,
  toggleAssetUnitsModal,
  toggleAddNewUnitsModal,
  toggleAssignRetriveAssetModal,
} from "../../../Store/Actions";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  fetchCompanyAssetsQuery,
  fetchAssetItemsQuery,
} from "../../../Graphql/query";
import DataTable from "react-data-table-component";
import Loader from "../../../Components/Loader";
import Pagination from "../../../Components/Pagination";
import IconButtonWithTooltip, {
  EditIconButton,
  RemoveIconButton,
} from "../../../Components/IconButtonWithTooltip";
import swal from "sweetalert";
import { deleteCompanyAssetMutation } from "../../../Graphql/mutation";
import HelperFns, { showToast } from "../../../Helpers/HelperFns";
import ReactShowMoreText from "react-show-more-text";
import AssetUnitsModal from "./AssetUnitsModal";
import AddNewUnitsModal from "./AddNewUnitsModal";
import UnscrappItemModal from "../ScrappedAssets/UnscrappItemModal";
import ExchangeItemModal from "../EmployeeAssets/ExchangeItemModal";
import RetriveAssetModal from "../EmployeeAssets/RetriveAssetModal";
import Privilages from "../../../Constants/Privilages";
import ScrapNonSerializedAssetsModal from "./ScrapNonSerializedAssetsModal";
import ScrappItemModal from "../ScrappedAssets/ScrappItemModal";

// list paginator init state constants
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const CompanyAssets = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [pagination, setPagination] = useState(paginationInitState);
  const [filters, setFilters] = useState({
    name: "",
    itemsScope: "all",
  });

  // Redux States
  const isUpsertCompanyAssetModalOpen = useSelector(
    (state) => state?.assets?.companyAssetModal?.isOpen
  );
  const isAssetUnitsModalOpen = useSelector(
    (state) => state?.assets?.assetUnitsModal?.isOpen
  );
  const isAddNewUnitsModalOpen = useSelector(
    (state) => state?.assets?.addNewUnitsModal?.isOpen
  );
  const isScrapItemModalOpen = useSelector(
    (state) => state?.assets?.scrappItemModal?.isOpen
  );
  const isUnScrapItemModalOpen = useSelector(
    (state) => state?.assets?.unscrappItemModal?.isOpen
  );
  const isExchangeItemModalOpen = useSelector(
    (state) => state?.assets?.exchangeItemModal?.isOpen
  );
  const isRetriveModalOpen = useSelector(
    (state) => state?.assets?.retriveAssetModal?.isOpen
  );
  const isScrapNonSerializedAssetsModalOpen = useSelector(
    (state) => state?.assets?.scrapNonSerializedAssetModal?.isOpen
  );

  const companyId = useSelector(
    (state) => state.auth?.userProfile?.company?.id
  );

  // handle fetch company assets
  const handleFetchCompanyAssetsList = (page) => {
    fetchCompanyAssetsList({
      variables: {
        input: {
          company_id: companyId,
          filter: {
            ...(!!filters?.name
              ? {
                  name: filters?.name,
                  itemsScope: filters?.itemsScope ?? "",
                }
              : { itemsScope: filters?.itemsScope ?? "" }),
          },
        },
        first: 20,
        page: page ?? (pagination?.currentPage || 1),
      },
    });
  };

  // handle fetch asset items function
  const handleFetchAssetItems = (asset_Id) => {
    fetchAssetItems({
      variables: {
        assetId: asset_Id,
      },
    });
  };

  // Start of handle fetch company assets query
  const [
    fetchCompanyAssetsList,
    { data: companyAssetsData, loading: listLoading },
  ] = useLazyQuery(fetchCompanyAssetsQuery, {
    onError(err) {
      // error toaster
      showToast(
        "error",
        err?.graphQLErrors[0]?.extensions?.reason ||
          err?.graphQLErrors[0]?.message ||
          err?.message
      );
    },
    onCompleted: (data) => {
      // set data table pagination
      setPagination(data?.companyAssets?.paginatorInfo);
    },
  });
  // End of handle fetch company assets query

  // Start of handle fetch asset items query
  const [fetchAssetItems, { loading: assetItemsIsLoading }] = useLazyQuery(
    fetchAssetItemsQuery,
    {
      onError(err) {
        // error toaster
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message ||
            err?.message
        );
      },
      onCompleted: (data) => {
        // store asset items data to modal
        handleViewAssetUnitsModal(data?.fetchAsset);
      },
    }
  );
  // End of handle fetch asset items query

  // Start of handle delete company asset mutation
  const [deleteCompanyAsset, { loading: deleteCompanyAssetLoading }] =
    useMutation(deleteCompanyAssetMutation, {
      onCompleted: (data) => {
        if (data?.deleteAsset?.__typename == "GeneralException") {
          // error toaster
          showToast("error", data?.deleteAsset?.message);

          return;
        }

        // success toaster
        showToast("success");

        // handle refetch data list
        handleFetchCompanyAssetsList();
      },
      onError(err) {
        // error toaster
        showToast(
          "error",
          err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
        );
      },
    });
  // End of handle delete company asset mutation

  // Server State
  useEffect(() => {
    handleFetchCompanyAssetsList();
  }, []);

  // handle filter useEffect
  useEffect(() => {
    handleFetchCompanyAssetsList();
  }, [filters?.name, filters?.itemsScope]);

  /* ↓ Helpers ↓ */

  // handle filters on change function
  const handleFiltersChange = (e, val) => {
    setFilters((prev) => ({
      ...prev,
      [e.target.name]: val ? val : e.target.value,
    }));
  };

  // handle paginate function
  const handlePaginate = (page = pagination.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
    handleFetchCompanyAssetsList(page);
  };

  // handle add new company assets function
  const handleAddNew = () => {
    dispatch(toggleCompanyAssetModal({ isOpen: true }));
  };

  // handle edit company asset function
  const handleEdit = (companyAsset) => {
    dispatch(toggleCompanyAssetModal({ isOpen: true }));
    dispatch(onFormResetAction("upsertCompanyAssetForm", { ...companyAsset }));
  };

  // handle add new units function
  const handleAddNewUnit = (addUnit) => {
    let modifiedAddUnitData = {
      ...addUnit,
      items: [
        {
          serial_number: "",
        },
      ],
    };

    dispatch(toggleAddNewUnitsModal({ isOpen: true }));
    dispatch(onFormResetAction("addNewUnitsForm", { ...modifiedAddUnitData }));
  };

  // handle assign / retrieve assets modal function
  const handleAssignRetireveModal = () => {
    dispatch(
      toggleAssignRetriveAssetModal({
        isOpen: true,
        handleRefetch: handleFetchCompanyAssetsList,
      })
    );
  };

  // handle view asset units function
  const handleViewAssetUnitsModal = (assetUnits) => {
    dispatch(toggleAssetUnitsModal({ isOpen: true, assetUnits }));
  };

  // handle delete company asset function
  const handleDelete = (companyAsset) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        deleteCompanyAsset({
          variables: {
            id: companyAsset?.id,
          },
        });
      }
    });
  };

  // data table columns
  const columns = [
    {
      name: t("name"),
      wrap: true,
      grow: 1,
      cell: (row) => (
        <button
          className="clickable_asset_name_style"
          onClick={() => handleFetchAssetItems(row?.id)}
          {...(!HelperFns.checkPrivileges({
            allowBP: true,
            privileges: [
              Privilages.VIEW_ASSIGNED_ASSETS,
              Privilages.VIEW_STORED_ASSET_UNITE,
              Privilages.VIEW_SCRAPPED_ASSETS,
            ],
          })
            ? { disabled: true }
            : {})}
        >
          {row?.name}
        </button>
      ),
    },
    {
      name: t("type"),
      wrap: true,
      grow: 1,
      cell: (row) => <div>{row?.assetType?.name}</div>,
    },
    {
      name: t("brand"),
      wrap: true,
      grow: 1,
      cell: (row) => <div>{row?.brand}</div>,
    },
    {
      name: t("model"),
      wrap: true,
      grow: 1,
      cell: (row) => <div>{row?.model}</div>,
    },
    {
      name: t("In Storage"),
      wrap: true,
      grow: 1,
      cell: (row) => <div>{row?.in_storage_items_count}</div>,
    },
    {
      name: t("Assigned"),
      wrap: true,
      grow: 1,
      cell: (row) => <div>{row?.assigned_items_count}</div>,
    },
    {
      name: t("Scrapped"),
      wrap: true,
      grow: 1,
      cell: (row) => <div>{row?.scrapped_items_count}</div>,
    },
    {
      name: t("description"),
      wrap: true,
      grow: 1.8,
      cell: (row) => (
        <ReactShowMoreText lines={1}>{row?.description}</ReactShowMoreText>
      ),
    },
    {
      grow: 0.8,
      cell: (row) => (
        <div className="d-flex align-items-center gap-5">
          <HasPrivileges allowBP reqireMain={[Privilages.ADD_ADDITIONAL_UNITS]}>
            <IconButtonWithTooltip
              label="add new units"
              icon={
                <AddIcon
                  className="add_btn_icon cursor-pointer"
                  style={{ color: "#009efb" }}
                />
              }
              onClick={() => handleAddNewUnit(row)}
            />
          </HasPrivileges>

          <HasPrivileges
            allowBP
            reqireMain={[Privilages.ADD_EDIT_DELETE_ASSETS]}
          >
            <EditIconButton onClick={() => handleEdit(row)} />
            <RemoveIconButton onClick={() => handleDelete(row)} />
          </HasPrivileges>
        </div>
      ),
    },
  ];

  return (
    <div className="py-3 company_asset_wrapper_style">
      {listLoading || deleteCompanyAssetLoading || assetItemsIsLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="d-flex flex-column flex-md-row gap-10 justify-content-between align-items-center pb-3">
        <RadioboxForm
          value={filters?.itemsScope}
          options={[
            { label: "All", value: "all" },
            { label: "In Stock", value: "in_storage" },
            { label: "Out of Stock", value: "out_storage" },
          ]}
          name="itemsScope"
          type="radio"
          interceptChange={handleFiltersChange}
          containerStyle="my-2 my-lg-0"
          labelStyle="label-style"
          optionsContainerStyle=""
          optionItemStyle="optionItemStyle"
          optionInputStyle=" "
          rootStyle="w-25"
          optionLabelStyle="optionLabelStyle"
        />

        <InputForm
          placeholder={t("search")}
          containerStyle="w-100"
          icon="search"
          name="name"
          value={filters?.name}
          onChange={handleFiltersChange}
          rootStyle="w-50"
        />

        <div className="d-flex flex-column flex-md-row align-items-center gap-10 mt-3 justify-content-end w-25">
          <HasPrivileges
            reqireMain={[Privilages.ASSIGN_RETRIEVE_ASSET_UNITS]}
            allowBP
          >
            {companyAssetsData?.companyAssets?.data?.length > 0 && (
              <AddButton
                prefix="Assign"
                label="/ Retrieve Assets"
                variant="outlined"
                onClick={handleAssignRetireveModal}
              />
            )}
          </HasPrivileges>

          <HasPrivileges
            reqireMain={[Privilages.ADD_EDIT_DELETE_ASSETS]}
            allowBP
          >
            <AddButton onClick={handleAddNew} />
          </HasPrivileges>
        </div>
      </div>

      <DataTable
        noHeader
        data={companyAssetsData?.companyAssets?.data ?? []}
        columns={columns}
        className="cards_table my-3"
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {/* Start of upsert company assets modal */}
      {isUpsertCompanyAssetModalOpen ? (
        <UpsertCompanyAssetModal
          companyId={companyId}
          handleRefetch={handleFetchCompanyAssetsList}
          assetTypesOptions={companyAssetsData?.assetTypes?.data ?? []}
          storageLocationOptions={
            companyAssetsData?.companyWarehouses?.data ?? []
          }
        />
      ) : null}
      {/* End of upsert company assets modal */}

      {/* Start of asset units modal */}
      {isAssetUnitsModalOpen ? (
        <AssetUnitsModal handleRefetch={handleFetchCompanyAssetsList} />
      ) : null}
      {/* End of asset units modal */}

      {/* Start of add new units modal */}
      {isAddNewUnitsModalOpen ? (
        <AddNewUnitsModal
          companyId={companyId}
          handleRefetch={handleFetchCompanyAssetsList}
          storageLocationOptions={
            companyAssetsData?.companyWarehouses?.data ?? []
          }
        />
      ) : null}
      {/* End of add new units modal */}

      {/* Start of assign / retreive assets modal */}

      {/* End of assign / retreive assets modal */}

      {/* Start of scrap item modal */}
      {isScrapItemModalOpen ? (
        <ScrappItemModal handleRefetch={handleFetchCompanyAssetsList} />
      ) : null}
      {/* End of scrap item modal */}

      {/* Start of unscrap item modal */}
      {isUnScrapItemModalOpen ? (
        <UnscrappItemModal handleRefetch={handleFetchCompanyAssetsList} />
      ) : null}
      {/* End of unscrap item modal */}

      {/* Start of exchange item modal */}
      {isExchangeItemModalOpen ? (
        <ExchangeItemModal handleRefetch={handleFetchCompanyAssetsList} />
      ) : null}
      {/* End of exchange item modal */}

      {/* Start of retrieve asset modal */}
      {isRetriveModalOpen ? (
        <RetriveAssetModal handleRefetch={handleFetchCompanyAssetsList} />
      ) : null}
      {/* End of retrieve asset modal */}

      {isScrapNonSerializedAssetsModalOpen ? (
        <ScrapNonSerializedAssetsModal
          handleRefetch={handleFetchCompanyAssetsList}
        />
      ) : null}
    </div>
  );
};

export default CompanyAssets;
