import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import {
  childInputChange,
  onFormResetAction,
  dismissCompanyModalAction,
  onInputResetWithValueAction,
  addBusinessPartnersInputAction,
  deleteCompanyBussPartnersAction,
  removeBusinessPartnersInputAction,
} from "../../Store/Actions";
import { serializeUpsertSubscription } from "../../Helpers/HelperFns/PaymentTracking";
import HelperFns, {
  showToast,
  handleFilterOptionByEmpCodeOrName,
} from "../../Helpers/HelperFns";
import moment from "moment";
import gql from "graphql-tag";
import { swal } from "sweetalert";
import Constants from "../../Constants";
import Privileges from "../../Constants/Privilages";
import validateAddingBusinessPartner from "../../Containers/Companies/ValidateAddingBusinessPartnersFunction";

import {
  BSelect,
  InputForm,
  RadioboxForm,
  DateTimePickerForm,
  CheckboxBooleanForm,
} from "form-builder";
import {
  EditIconButton,
  TimesIconButton,
  RemoveIconButton,
} from "../IconButtonWithTooltip";
import {
  PaymentInfo,
  PaymentContacts,
  SubscriptionForm,
} from "../PaymentTracking";
import Loader from "../Loader";
import MainModal from "../MainModal";
import { AddButton } from "../Buttons";
import IntlTelInput from "react-intl-tel-input";
import DataTable from "react-data-table-component";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";

const formName = "companiesModal";
const paymentTrackingFormName = "subscriptionForm";
const formNameValidation = "companiesModalValidation";
const formServerValidation = "companiesModalServerValidation";
const paymentTrackingClientValidation = "paymentTrackingClientValidation";

/**
 *
 * Queries & Mutations & Helpers
 *
 */

const GET_MODAL_DATA = gql`
  query getCompanyModalData($id: ID, $isEdit: Boolean!) {
    company(id: $id) @include(if: $isEdit) {
      id
      name
      tax_id
      methodId: payment_method_id
      paymentContacts {
        name
        phone
        email
      }
      primarySAAS {
        user {
          id
          name
          emp_code
        }
      }
      secondarySAAS {
        user {
          id
          name
          emp_code
        }
      }
      canEditMonthStartDay
      allow_am_access
      report_status
      ratio
      week_day
      month_start_day
      year_start_month
      allow_multiple_offices_start
      businessPartners {
        user {
          id
          company {
            id
          }
          name
          email
          phone
          phone_country_code: country_short_name
        }
      }
      activeSubscription: currentSubscription {
        employees_limit
      }
      status
      numberOfEmployees
      week_start_day
      logs {
        type
        from
        created_at
        old_value
        new_value
        user {
          name
        }
      }
      allow_cost_center
      attendance_type
      country_id
    }

    # Options
    active_saas_users(first: 999) {
      data {
        id
        name
        emp_code
      }
    }
    currencies @skip(if: $isEdit) {
      id
      name
      symbol
    }
    packages: plans(first: 999, input: { page_flag: "menu", type: "active" })
      @skip(if: $isEdit) {
      data {
        id
        name
        trial_value
        features {
          id
          pivot {
            limits
          }
        }
      }
    }
    countries {
      id
      name
    }
  }
`;

const UPSERT_COMPANY = gql`
  mutation upsertCompany($input: UpsertCompany) {
    upsert_company(input: $input) {
      id
    }
  }
`;

const formatFetchingData = (data) => {
  return {
    id: data?.id,
    company_name: data?.name,
    employees_limit: data?.activeSubscription?.employees_limit,
    year_start_month: data?.year_start_month,
    week_start_day: data?.week_start_day,
    week_day: data?.week_day,
    logs: data?.logs,
    canEditMonthStartDay: data?.canEditMonthStartDay,
    month_start_day: String(data?.month_start_day),
    ratio: data?.ratio,
    report_status: data?.report_status,
    numberOfEmployees: data?.numberOfEmployees,
    allow_am_access: data?.allow_am_access,
    primarySAAS: data?.primarySAAS?.user?.id,
    secondarySAAS: data?.secondarySAAS?.map((sass) => sass?.user?.id),
    allow_multiple_offices_start: data?.allow_multiple_offices_start,
    partners_input: data?.businessPartners?.map((bp) => ({
      id: bp?.user?.id,
      company_id: bp?.user?.company?.id,
      name: bp?.user?.name,
      phone: bp?.user?.phone,
      email: bp?.user?.email,
      phone_country_code: bp?.user?.phone_country_code,
    })),
    methodId: data?.methodId,
    allow_cost_center: Boolean(data?.allow_cost_center) ? 1 : 0,
    attendance_type: data?.attendance_type,
    country_id: data?.country_id,
    tax_id: data?.tax_id,
  };
};
const formatAddCompanyData = (data) => {
  return {
    // Company Info
    company_input: {
      id: null,
      name: data?.company_name,
      week_start_day: data?.week_start_day,
      allow_am_access: data?.allow_am_access,
      week_day: data?.week_day,
      month_start_day: +data?.month_start_day,
      year_start_month: +data?.year_start_month,
      allow_multiple_offices_start: data?.allow_multiple_offices_start,
      report_status: data?.report_status,
      ratio: data?.ratio,
      payment_method_id: +data?.methodId,
      ...(data.company_status === "client" && {
        client_at: data?.client_at,
        contract_amount: +data?.contract_amount,
        contract_duration: +data?.contract_duration,
      }),
      allow_cost_center: +data?.allow_cost_center,
      payment_contacts: data?.contacts || [],
      tax_id: data?.tax_id,
      attendance_type: data?.attendance_type,
      country_id: data?.country_id,
    },
    primaryId: data?.primarySAAS,
    secondaryIds: data?.secondarySAAS || [],

    // Business Partners
    partners_input:
      data?.partners_input?.map((bp) => ({
        ...bp,
        id: null,
        force_save: data?.isForceSave,
        country_short_name: bp?.phone_country_code,
      })) || [],

    // Subscription
    ...(data.company_status === "trial" && {
      subscription_input: {
        id: null,
        plan_id: data?.plan_id,
        payment_amount: data?.payment_amount,
        currency_id: data?.currency_id,
        start_date: data?.start_date,
        end_date: data?.end_date,
        employees_limit: parseInt(data?.employees_limit),
        company_status: data?.company_status,
      },
    }),
    ...(data.company_status === "client" && {
      payment_tracking_input: serializeUpsertSubscription(data),
    }),
  };
};
const formatEditCompanyData = (data) => {
  return {
    // Company Info
    company_input: {
      id: data?.id,
      name: data?.company_name,
      week_start_day: data?.week_start_day,
      allow_am_access: data?.allow_am_access,
      week_day: data?.week_day,
      month_start_day: +data?.month_start_day,
      year_start_month: +data?.year_start_month,
      allow_multiple_offices_start: data?.allow_multiple_offices_start,
      report_status: data?.report_status,
      ratio: data?.ratio,
      payment_method_id: +data?.methodId,
      new_start_year: data?.new_start_year,
      new_start_of_month: data?.new_start_of_month,
      new_start_of_week: data?.new_start_of_week,
      change_action: data?.change_action,
      ...(data?.change_action && {
        prorate: [
          ...(data?.Leaves ? ["Leaves"] : []),
          ...(data?.Salary ? ["Salary"] : []),
          ...(data?.Permissions ? ["Permissions"] : []),
          ...(data?.Grace ? ["Grace"] : []),
          ...(data?.Overtime ? ["Overtime"] : []),
        ],
      }),
      ...(data.company_status === "client" && {
        client_at: data?.client_at,
        contract_amount: +data?.contract_amount,
        contract_duration: +data?.contract_duration,
      }),
      allow_cost_center: +data?.allow_cost_center,
      payment_contacts: data?.contacts || [],
      tax_id: data?.tax_id,
      attendance_type: data?.attendance_type,
      country_id: data?.country_id
    },
    primaryId: data?.primarySAAS,
    secondaryIds: data?.secondarySAAS || [],

    // Business Partners
    partners_input:
      data?.partners_input?.map((bp) => ({
        ...bp,
        force_save: data?.isForceSave,
        country_short_name: bp?.phone_country_code,
      })) || [],
  };
};

/**
 *
 * Start of Company Modal
 *
 */

const CompanyUpsertModal = ({ onClose, refetchList, data }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isEdit = Boolean(data?.id);

  // Local State
  const [step, setStep] = React.useState(1);
  const [contacts, setContacts] = React.useState([]);
  const [modalMsg, setModalMsg] = React.useState("");
  const [isLogsOpen, setIsLogsOpen] = React.useState(false);
  const [isForceSave, setIsForceSave] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isPayrollCalcSecEdit, setIsPayrollCalcSecEdit] = React.useState(false);
  const [options, setOptions] = React.useState({
    packages: [],
    currencies: [],
    accountMangers: [],
    countries: [],
  });

  // Reducer State
  const companiesModal = useSelector((state) => state.super?.[formName]);
  const paymentSubscriptionData = useSelector(
    (state) => state.paymentTracking[paymentTrackingFormName]
  );
  const modalValidation = useSelector(
    (state) => state?.super?.[formNameValidation]
  );
  const paymentSubscriptionClientValidation = useSelector(
    (state) => state?.paymentTracking?.[paymentTrackingClientValidation]
  );
  const serverValidation = useSelector(
    (state) => state.super?.[formServerValidation]
  );

  // Server State
  const [upsertCompany, { loading: isUpsertLoading }] =
    useMutation(UPSERT_COMPANY);
  const { loading: isDataLoading } = useQuery(GET_MODAL_DATA, {
    variables: { isEdit, id: data?.id },
    onCompleted: ({
      company,
      packages,
      currencies,
      active_saas_users,
      countries,
    }) => {
      // Data
      if (isEdit) {
        setContacts(company?.paymentContacts || []);
        dispatch(onFormResetAction(formName, formatFetchingData(company)));
      }

      // Options
      setOptions((prev) => ({
        ...prev,
        currencies: currencies || [],
        packages: packages?.data || [],
        accountMangers: active_saas_users?.data || [],
        countries: countries || [],
      }));
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  // Constants
  const isAllowedToAddBP =
    !isEdit ||
    validateAddingBusinessPartner(
      isEdit ? "Edit" : "Add",
      companiesModal.partners_input.length,
      companiesModal.numberOfEmployees,
      companiesModal?.employees_limit
    );
  const FormProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
  };

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    Object.keys(serverValidation).forEach((key) => {
      if (serverValidation?.[key]?.[0] === "phone already exists") {
        setIsForceSave(true);
      }
    });
  }, [JSON.stringify(serverValidation)]);

  /* ↓ Helpers ↓ */

  const btnColor = (() => {
    if (!isEdit && step === 1) {
      return "primary";
    }
    return isForceSave ? "danger" : "primary";
  })();
  const btnLabel = (() => {
    if (!isEdit && step === 1) {
      return "Next";
    }
    return isForceSave ? "Force Save" : "save";
  })();

  const handleCLoseModal = () => {
    onClose();
    dispatch(onFormResetAction(formServerValidation));
    dispatch(onFormResetAction(paymentTrackingFormName));
    dispatch(onFormResetAction(paymentTrackingClientValidation));
    dispatch(
      dismissCompanyModalAction({ ref: "companiesModalActions", formName })
    );
  };

  const handleAddBusinessPartnersInputs = () => {
    isAllowedToAddBP &&
      dispatch(addBusinessPartnersInputAction(companiesModal?.id));
  };

  const handleChildInputChange = (event, childName, index) => {
    dispatch(
      childInputChange(
        formName,
        childName,
        event.target.name,
        index,
        event.target.value
      )
    );
  };

  const handlePhoneChange = (value, selectedCountryData, childName, index) => {
    let country_code = selectedCountryData.iso2;
    dispatch(
      childInputChange(
        formName,
        childName,
        "phone_country_code",
        index,
        country_code
      )
    );
    dispatch(childInputChange(formName, childName, "phone", index, value));
  };

  const handleUpdateCountryCode = (selectedCountryData, childName, index) => {
    let country_code = selectedCountryData.iso2;
    dispatch(
      childInputChange(
        formName,
        childName,
        "phone_country_code",
        index,
        country_code
      )
    );
  };

  const handleDeleteBusinessPartners = (id) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteCompanyBussPartnersAction(id));
      }
    });
  };

  const handleRemoveBusinessPartnersInputs = (index) => {
    dispatch(removeBusinessPartnersInputAction(index));
  };

  const handleInterceptChangeOfPayrollMonth = (value) => {
    dispatch(
      onInputResetWithValueAction(
        formName,
        "new_start_of_month",
        `${moment(value).subtract(1, "month").format("YYYY/MM")}/20`
      )
    );
  };

  const checkStepOneServerValidations = (validation) => {
    if (validation) {
      return Object.keys(validation).some(
        (key) =>
          key.includes("input.company_input") ||
          key.includes("input.partners_input") ||
          key.includes("input.primaryId") ||
          key.includes("input.secondaryIds")
      );
    }

    return false;
  };

  const upsertCompanyForm = () => {
    const data = {
      isForceSave,
      contacts,
      ...companiesModal,
      ...paymentSubscriptionData,
    };

    upsertCompany({
      variables: {
        input: isEdit
          ? formatEditCompanyData(data)
          : formatAddCompanyData(data),
      },
      onCompleted: () => {
        refetchList();
        handleCLoseModal();
        showToast("success");
      },
      onError: (err) => {
        const validation = err?.graphQLErrors?.[0]?.extensions?.validation;
        if (validation) {
          dispatch(onFormResetAction(formServerValidation, validation));
          if (checkStepOneServerValidations(validation)) setStep(1);
        } else {
          setModalMsg(
            err?.graphQLErrors?.[0]?.extensions?.reason ||
              err?.graphQLErrors?.[0]?.message ||
              err?.message ||
              ""
          );
        }
      },
    });
  };

  const checkClientValidations = () => {
    if (
      modalValidation?.length ||
      paymentSubscriptionClientValidation?.length
    ) {
      return true;
    }

    if (step === 1) {
    }

    if (step === 2) {
      if (
        companiesModal.company_status === "client" &&
        paymentSubscriptionData.applyPromotion
      ) {
        if (
          paymentSubscriptionData?.applyPromotionAll &&
          (!paymentSubscriptionData?.promotions?.[0]?.type ||
            !paymentSubscriptionData?.promotions?.[0]?.amount)
        ) {
          setModalMsg("all promotions fields are required");
          return true;
        }

        if (
          !paymentSubscriptionData?.applyPromotionAll &&
          paymentSubscriptionData?.promotions?.some(
            (prom) => !prom?.type || !prom?.amount
          )
        ) {
          setModalMsg("all promotions fields are required");
          return true;
        }
      }
    }

    return false;
  };

  const handleUpsert = () => {
    setIsSubmitting(true);
    if (checkClientValidations()) return;

    if (isEdit) {
      HelperFns.checkPassword(
        "defaut_warning_messsage",
        "",
        "Confirm",
        "",
        upsertCompanyForm
      );
    } else {
      if (step === 1) {
        setStep(2);
        setIsSubmitting(false);
      } else {
        upsertCompanyForm();
      }
    }
  };

  return (
    <MainModal
      isOpen
      size="lg"
      toggle={handleCLoseModal}
      btnOnClick={handleUpsert}
      btnSubmitLoading={isUpsertLoading}
      btnColor={btnColor}
      btnLabel={btnLabel}
      modalTitle={`${isEdit ? "Edit" : "Add"} Company`}
    >
      {isEdit && isDataLoading ? <Loader fixed /> : null}

      {step === 1 ? (
        <>
          {/* Company Info */}
          <strong className="sub-title-style d-block mb-2">
            {t("Company Info")}
          </strong>
          <InputForm
            {...FormProps}
            validateBy="textRequired"
            name="company_name"
            validationName={`input.company_input.name`}
            placeholder={t("name")}
            label="Company Name"
            labelStyle="mb-2"
            containerStyle="mt-0"
            inputContainerStyle=" "
            icon="person"
          />
          <BSelect
            {...FormProps}
            name="primarySAAS"
            validationName={`input.primarySAAS`}
            label="Assign Primary Account Manager"
            labelStyle="mb-2"
            keepDefaultStyle
            isLoading={isDataLoading}
            options={options.accountMangers?.filter(
              (accManager) =>
                !companiesModal?.secondarySAAS?.includes(accManager?.id)
            )}
            filterOption={handleFilterOptionByEmpCodeOrName}
            inputContainerStyle=""
            placeholder={t("Select Primary Account Manager")}
            icon="person"
            rootStyle="flex-1"
            validateBy="textRequired"
          />
          <BSelect
            {...FormProps}
            isMulti
            name="secondarySAAS"
            validationName={`input.secondarySAAS`}
            label="Assign Secondary Account Managers"
            labelStyle="mb-2"
            keepDefaultStyle
            isLoading={isDataLoading}
            options={options.accountMangers?.filter(
              (accManager) => accManager?.id !== companiesModal?.primarySAAS
            )}
            filterOption={handleFilterOptionByEmpCodeOrName}
            inputContainerStyle=""
            placeholder={t("Select Secondary Account Managers")}
            icon="person"
            rootStyle="flex-1"
          />
          <div className="d-flex gap-10 align-items-end mb-2">
            <BSelect
              {...FormProps}
              name="week_start_day"
              validationName={`input.week_start_day`}
              label="calendar week start day"
              labelStyle="mb-2"
              keepDefaultStyle
              optionLabel="label"
              optionValue="value"
              options={Constants.CompanyModalWeekDays}
              inputContainerStyle=""
              rootStyle="flex-1"
              placeholder={t("select day")}
              icon="person"
            />
            <HasPrivileges reqireMain={[Privileges.EDIT_COMPANY_SETTINGS]}>
              <CheckboxBooleanForm
                {...FormProps}
                setWithValue
                name="allow_am_access"
                options={["Allow account managers access to company"]}
                validationName={`input.allow_am_access`}
              />
            </HasPrivileges>
          </div>
          <div className="d-flex gap-10">
            <BSelect
              {...FormProps}
              name="year_start_month"
              validateBy="textRequired"
              validationName={`input.year_start_month`}
              label="first month of the year"
              labelStyle="mb-2"
              keepDefaultStyle
              optionLabel="value"
              optionValue="key"
              options={Constants.MonthsData}
              inputContainerStyle=""
              rootStyle="flex-1"
              placeholder={t("select month")}
              icon="calendar"
            />

            <BSelect
              {...FormProps}
              name="month_start_day"
              validateBy="textRequired"
              validationName={`input.month_start_day`}
              label="Month Start Day"
              labelStyle="mb-2"
              keepDefaultStyle
              options={Constants.payrollStartOptions}
              inputContainerStyle=""
              rootStyle="flex-1"
              placeholder={t("select day")}
              icon="calendar"
            />

            <BSelect
              {...FormProps}
              name="week_day"
              validateBy="textRequired"
              validationName={`input.week_day`}
              label="payroll week start day"
              labelStyle="mb-2"
              keepDefaultStyle
              optionLabel="label"
              optionValue="value"
              options={Constants.CompanyModalWeekDays}
              inputContainerStyle=""
              rootStyle="flex-1"
              placeholder={t("select day")}
              icon="calendar"
            />
          </div>
          {/* <CheckboxBooleanForm
          {...FormProps}
          setWithValue
          name="allow_multiple_offices_start"
          rootStyle="col-6 d-flex align-items-end"
          options={["Allow offices to override month start day"]}
          validationName={`input.allow_multiple_offices_start`}
        /> */}
          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.EDIT_COMPANT_ACTIVITY_REPORT_DATA,
            ]}
          >
            <div className="d-flex gap-20 align-items-end mt-2">
              <RadioboxForm
                {...FormProps}
                options={[
                  { label: "Default", value: "default" },
                  { label: "Exclude", value: "exclude" },
                  { label: "Custom", value: "custom" },
                ]}
                labelStyle="d-block font-weight-bold gray-color"
                optionsContainerStyle="penalty-days-style fit-width flex-md-row flex-column"
                optionItemStyle=" "
                optionLabelStyle="mb-0 mr-3"
                optionInputStyle=" "
                containerStyle="d-flex flex-wrap align-items-center gap-20"
                label="Activity Report status"
                name="report_status"
                validationName="input.report_status"
              />

              <InputForm
                {...FormProps}
                type="number"
                name="ratio"
                validateBy="textRequired"
                validationName={`input.subscription_input.ratio`}
                labelStyle="mb-2"
                containerStyle="mt-0"
                rootStyle="flex-1"
                inputContainerStyle=" "
                placeholder={t("enter ratio")}
                icon="percent"
                dependOn="report_status"
                dependancyType="equal"
                dependancyValue={["custom"]}
              />
            </div>
          </HasPrivileges>

          <BSelect
            {...FormProps}
            label="country"
            name="country_id"
            icon="globe"
            validateBy="textRequired"
            rootStyle="flex-1"
            options={options.countries}
            isLoading={!options.countries.length}
            validationName="country_id"
          />
          <CheckboxBooleanForm
            {...FormProps}
            name="allow_cost_center"
            options={["Allow cost center"]}
            validationName={`input.company_input.allow_cost_center`}
            rootStyle="mt-2"
          />

          <RadioboxForm
            {...FormProps}
            options={[
              { label: "fixed", value: "fixed" },
              { label: "fixed_and_flex", value: "fixed_and_flex" },
              // { label: "flex", value: "flex" },
            ]}
            labelStyle="d-block font-weight-bold gray-color"
            optionsContainerStyle="penalty-days-style fit-width flex-md-row flex-column"
            optionItemStyle=" "
            optionLabelStyle="mb-0 mr-3"
            optionInputStyle=" "
            containerStyle="d-flex flex-wrap align-items-center gap-20 mt-3"
            label="attendance type"
            name="attendance_type"
            validationName="input.attendance_type"
          />
          <hr className="hr_custom_style my-3" />

          {/* Business Partners */}
          <div className="d-flex gap-20 align-items-center mb-2">
            <strong className="sub-title-style">
              {t("business partners")}
            </strong>
            <AddButton
              isIconOnly
              disabled={!isAllowedToAddBP}
              onClick={handleAddBusinessPartnersInputs}
              disabledLabel="you have reached the maximum number of employees"
            />
          </div>
          {companiesModal?.partners_input?.map((businessPartner, i) => (
            <div key={businessPartner.id} className="d-flex gap-20 mb-2">
              <InputForm
                {...FormProps}
                name="name"
                validateBy="textRequired"
                placeholder={t("name")}
                value={businessPartner.name}
                onChange={(e) => handleChildInputChange(e, "partners_input", i)}
                validationName={`input.partners_input.${i}.name`}
                label="name"
                labelStyle="mb-2"
                containerStyle="mt-0"
                inputContainerStyle=" "
                rootStyle="flex-1"
                icon="person"
              />

              <div className="flex-1">
                <div
                  className={
                    isSubmitting &&
                    serverValidation &&
                    `input.partners_input.${i}.phone` in serverValidation
                      ? "invalid-container-style"
                      : " "
                  }
                >
                  <label className="mb-2 validity-label-style">
                    {t("Phone Number")}
                  </label>
                  <div dir="ltr">
                    <IntlTelInput
                      inputClassName="input-style-default"
                      style={{ display: "flex" }}
                      fieldName={"phone"}
                      telInputProps={{
                        dir: "ltr",
                      }}
                      preferredCountries={["eg", "ae", "sa"]}
                      onPhoneNumberChange={(
                        isValid,
                        value,
                        seletedCountryData,
                        fullNumber,
                        extension,
                        event
                      ) => {
                        handlePhoneChange(
                          value,
                          seletedCountryData,
                          "partners_input",
                          i
                        );
                      }}
                      onSelectFlag={(
                        currentNumber,
                        seletedCountryData,
                        fullNumber,
                        isValid
                      ) => {
                        handleUpdateCountryCode(
                          seletedCountryData,
                          "partners_input",
                          i
                        );
                      }}
                      defaultCountry={businessPartner?.phone_country_code?.toLowerCase()}
                      separateDialCode
                      format={false}
                      formatOnInit={false}
                      value={businessPartner?.phone}
                    />
                  </div>
                  {serverValidation &&
                  `input.partners_input.${i}.phone` in serverValidation ? (
                    <p role="alert" className="validity-msg-style">
                      {serverValidation[`input.partners_input.${i}.phone`]}{" "}
                    </p>
                  ) : null}
                </div>
              </div>

              <InputForm
                {...FormProps}
                name="email"
                placeholder={t("email")}
                value={businessPartner.email}
                onChange={(e) => handleChildInputChange(e, "partners_input", i)}
                validationName={`input.partners_input.${i}.email`}
                label="email"
                labelStyle="mb-2"
                containerStyle="mt-0"
                inputContainerStyle=" "
                icon="mail"
                rootStyle="flex-1"
              />

              {businessPartner.id != null ? (
                <HasPrivileges
                  reqireMain={[
                    Privileges.SUPER_PRIVILEGE,
                    Privileges.DELETE_BUSINESS_PARTNERS,
                  ]}
                >
                  <RemoveIconButton
                    className="mt-4"
                    onClick={() =>
                      handleDeleteBusinessPartners(businessPartner?.id)
                    }
                  />
                </HasPrivileges>
              ) : (
                <TimesIconButton
                  label="clear"
                  className="mt-4"
                  onClick={() => handleRemoveBusinessPartnersInputs(i)}
                />
              )}
            </div>
          ))}
          <p role="alert" className="warnig-msg-style">
            {serverValidation?.[`input.partners_input`] ?? " "}
          </p>

          {/* Payment Info */}
          <strong className="sub-title-style d-block mt-3 mb-2">
            {t("payment info")}
          </strong>
          <InputForm
            {...FormProps}
            name="tax_id"
            placeholder={t("Enter Tax ID")}
            label="Tax ID"
            labelStyle="mb-2"
            containerStyle="mt-0"
            inputContainerStyle=" "
          />
          <PaymentInfo FormProps={FormProps} />
          <PaymentContacts contacts={contacts} setContacts={setContacts} />

          {/* Payroll Calculation */}
          {companiesModal?.canEditMonthStartDay && isEdit ? (
            <>
              <div className="d-flex gap-20 align-items-center mb-3 mt-3">
                <strong className="sub-title-style d-block">
                  {t("Payroll Calculation")}
                </strong>

                {isPayrollCalcSecEdit ? null : (
                  <EditIconButton
                    onClick={() => setIsPayrollCalcSecEdit(true)}
                  />
                )}
              </div>

              {isPayrollCalcSecEdit ? (
                <EditableInputs
                  FormProps={FormProps}
                  first_day_of_month_selected_month={
                    companiesModal?.first_day_of_month_selected_month
                  }
                  handleInterceptChangeOfPayrollMonth={
                    handleInterceptChangeOfPayrollMonth
                  }
                />
              ) : (
                <div className="d-flex gap-20 justify-content-between">
                  <div>
                    <b className="d-block mb-2">
                      {t("first month of the year")}
                    </b>
                    <span>{companiesModal?.year_start_month}</span>
                  </div>

                  <div>
                    <b className="d-block mb-2">{t("Month Start Day")}</b>
                    <span>{companiesModal?.month_start_day}</span>
                  </div>

                  <div>
                    <b className="d-block mb-2">
                      {t("payroll week start day")}
                    </b>
                    <span>{companiesModal?.week_day}</span>
                  </div>
                </div>
              )}

              <button
                className="view-change-logs-button-style"
                onClick={() => setIsLogsOpen((prev) => !prev)}
              >
                {t("view change logs")}
              </button>
              {isLogsOpen ? <Logs logs={companiesModal?.logs} /> : null}
            </>
          ) : null}
        </>
      ) : null}

      {!isEdit && step === 2 ? (
        <>
          {/* Subscription */}
          <strong className="sub-title-style d-block">
            {t("Choose your Subscription")}
          </strong>

          <RadioboxForm
            {...FormProps}
            validateBy="textRequired"
            options={[
              { label: "Trial", value: "trial" },
              { label: "Client", value: "client" },
            ]}
            labelStyle="d-block font-weight-bold gray-color"
            optionsContainerStyle="penalty-days-style fit-width flex-md-row flex-column"
            optionItemStyle=" "
            optionLabelStyle="mb-0 mr-3"
            optionInputStyle=" "
            containerStyle="d-flex flex-wrap align-items-center gap-20 my-2"
            label="subscription status"
            name="company_status"
            validationName="input.subscription_input.company_status"
          />

          {/* Trial Subscription */}
          {companiesModal.company_status === "trial" ? (
            <>
              <div className="d-flex gap-20">
                <BSelect
                  {...FormProps}
                  name="plan_id"
                  validateBy="textRequired"
                  validationName={`input.subscription_input.plan_id`}
                  label="package"
                  labelStyle="mb-2"
                  keepDefaultStyle
                  optionLabel="name"
                  optionValue="id"
                  options={options.packages}
                  inputContainerStyle=""
                  placeholder={t("Select package")}
                  getOptionLabel={(option) =>
                    `${option?.name} - ${
                      option?.trial_value == null ? t("free") : t("paid")
                    }`
                  }
                  icon="type"
                  rootStyle="flex-1"
                />
                <InputForm
                  {...FormProps}
                  type="number"
                  validateBy="textRequired"
                  name="employees_limit"
                  validationName={`input.subscription_input.employees_limit`}
                  placeholder={t("Enter limit")}
                  label="Employees limit"
                  labelStyle="mb-2"
                  containerStyle="mt-0"
                  inputContainerStyle=" "
                  rootStyle="flex-1"
                />
              </div>
              <div className="d-flex gap-20">
                <BSelect
                  {...FormProps}
                  name="currency_id"
                  validateBy="textRequired"
                  validationName={`input.subscription_input.currency_id`}
                  label="Currency"
                  labelStyle="mb-2"
                  keepDefaultStyle
                  optionLabel="name"
                  optionValue="id"
                  options={options.currencies}
                  inputContainerStyle=""
                  placeholder={t("select currency")}
                  icon="currency"
                  rootStyle="flex-1"
                />
                <InputForm
                  {...FormProps}
                  type="number"
                  validateBy="textRequired"
                  name="payment_amount"
                  validationName={`input.subscription_input.payment_amount`}
                  placeholder={t("Enter Amount")}
                  label="Payable Amount"
                  labelStyle="mb-2"
                  containerStyle="mt-0"
                  inputContainerStyle=" "
                  icon="money"
                  rootStyle="flex-1"
                />
              </div>
              <div className="d-flex gap-20">
                <DateTimePickerForm
                  {...FormProps}
                  containerStyle="custom_datepicker_wrapper_style"
                  name="start_date"
                  validateBy="textRequired"
                  validationName={`input.subscription_input.start_date`}
                  label="start date"
                  labelStyle="mb-2"
                  hasIcon
                  minDate={new Date()}
                  requestFormat="yyyy-MM-DD"
                  rootStyle="flex-1"
                />
                <DateTimePickerForm
                  {...FormProps}
                  containerStyle="custom_datepicker_wrapper_style"
                  name="end_date"
                  label="end date"
                  labelStyle="mb-2"
                  validationName={`input.subscription_input.end_date`}
                  validateBy="end_date"
                  dependTime="start_date"
                  hasIcon
                  minDate={new Date()}
                  requestFormat="yyyy-MM-DD"
                  rootStyle="flex-1"
                />
              </div>
            </>
          ) : null}

          {/* Client Subscription */}
          {companiesModal.company_status === "client" ? (
            <>
              <div className="d-flex gap-10 mb-2">
                <DateTimePickerForm
                  {...FormProps}
                  rootStyle="flex-1"
                  containerStyle=" "
                  name="client_at"
                  label="client at"
                  labelStyle="mb-2"
                  validateBy="textRequired"
                  validationName={`input.subscription_input.client_at`}
                  hasIcon
                  requestFormat="yyyy-MM-DD"
                  dependOn="company_status"
                  dependancyType="equal"
                  dependancyValue={["client"]}
                />
                <InputForm
                  {...FormProps}
                  name="contract_duration"
                  validateBy="textRequired"
                  validationName={`input.subscription_input.contract_duration`}
                  label="contract months duration"
                  labelStyle="mb-2"
                  rootStyle="flex-1"
                  containerStyle="mt-0"
                  inputContainerStyle=" "
                  placeholder={t("enter contract months duration")}
                  icon="time"
                  dependOn="company_status"
                  dependancyType="equal"
                  dependancyValue={["client"]}
                />
                <InputForm
                  {...FormProps}
                  name="contract_amount"
                  validateBy="textRequired"
                  validationName={`input.subscription_input.contract_amount`}
                  label="contract amount"
                  labelStyle="mb-2"
                  containerStyle="mt-0"
                  rootStyle="flex-1"
                  inputContainerStyle=" "
                  placeholder={t("enter contract amount")}
                  icon="money"
                  dependOn="company_status"
                  dependancyType="equal"
                  dependancyValue={["client"]}
                />
              </div>
              <SubscriptionForm
                data={{
                  ...data,
                  isCompanyForm: true,
                  formSubmitting: isSubmitting,
                }}
              />
            </>
          ) : null}
        </>
      ) : null}

      {/* Modal Messages */}
      {modalMsg && isSubmitting ? (
        <p role="alert" className="warnig-msg-style">
          {modalMsg}
        </p>
      ) : null}
    </MainModal>
  );
};

export default CompanyUpsertModal;

/**
 *
 * End of Company Modal
 *
 */

const EditableInputs = ({
  FormProps,
  first_day_of_month_selected_month,
  handleInterceptChangeOfPayrollMonth,
}) => {
  const { t } = useTranslation();

  const monthBefore = moment(first_day_of_month_selected_month, "MM-YYYY")
    .subtract(1, "month")
    .format("MM-YYYY");

  const currentMonth = moment(
    first_day_of_month_selected_month,
    "MM-YYYY"
  ).format("MM-YYYY");

  return (
    <div className="mt-2">
      <div className="d-flex gap-20 justify-content-between mb-2">
        <DateTimePickerForm
          {...FormProps}
          placeholder="Choose month"
          containerStyle="custom_datepicker_wrapper_style"
          name="new_start_year"
          validationName={`input.subscription_input.new_start_year`}
          label="first month of the year"
          labelStyle="mb-2"
          hasIcon
          picker={"month"}
          format="yyyy-MM"
          requestFormat="yyyy-MM"
          rootStyle="flex-1"
        />
        <DateTimePickerForm
          {...FormProps}
          containerStyle="custom_datepicker_wrapper_style"
          name="new_start_of_week"
          validationName={`input.subscription_input.new_start_of_week`}
          label="payroll week start day"
          labelStyle="mb-2"
          hasIcon
          requestFormat="yyyy-MM-DD"
          rootStyle="flex-1"
        />
      </div>
      <div className="d-flex gap-10 flex-1">
        <DateTimePickerForm
          {...FormProps}
          label="payroll month"
          containerStyle="custom_datepicker_wrapper_style mr-3"
          name="first_day_of_month_selected_month"
          hasIcon
          mode={"month"}
          picker={"month"}
          format={"MM-YYYY"}
          requestFormat={"MM-YYYY"}
          rootStyle="flex-1"
          onIntercept={handleInterceptChangeOfPayrollMonth}
        />
        <DateTimePickerForm
          {...FormProps}
          containerStyle="custom_datepicker_wrapper_style"
          name="new_start_of_month"
          validationName={`input.subscription_input.new_start_of_month`}
          label="Month Start Day"
          labelStyle="mb-2"
          hasIcon
          requestFormat="yyyy-MM-DD"
          rootStyle="flex-1"
          disabled={!first_day_of_month_selected_month}
          disabledDate={(date) => {
            const shouldDisable =
              moment(date).isBefore(
                moment(`20-${monthBefore}`, "DD-MM-YYYY"),
                "days"
              ) ||
              moment(date).isAfter(
                moment(`10-${currentMonth + 1}`, "DD-MM-YYYY"),
                "days"
              ) ||
              moment(date).format("DD") === "29" ||
              moment(date).format("DD") === "30" ||
              moment(date).format("DD") === "31" ||
              !first_day_of_month_selected_month;
            return shouldDisable;
          }}
        />
      </div>

      <b className="d-block mb-2">{t("Proration")}</b>
      <CheckboxBooleanForm
        {...FormProps}
        name="change_action"
        options={[
          "Prorate Current week/month/year according to the changes made",
        ]}
        validationName={`input.change_action`}
      />

      <div className="d-flex gap-20">
        <CheckboxBooleanForm
          {...FormProps}
          setWithValue
          name="Leaves"
          options={["Leaves"]}
          dependOn="change_action"
          dependancyType="equal"
          dependancyValue={[1]}
        />
        <CheckboxBooleanForm
          {...FormProps}
          setWithValue
          name="Permissions"
          options={["Permissions"]}
          dependOn="change_action"
          dependancyType="equal"
          dependancyValue={[1]}
        />
        <CheckboxBooleanForm
          {...FormProps}
          setWithValue
          name="Salary"
          options={["Salary"]}
          dependOn="change_action"
          dependancyType="equal"
          dependancyValue={[1]}
        />
        <CheckboxBooleanForm
          {...FormProps}
          setWithValue
          name="Grace"
          options={["Grace"]}
          dependOn="change_action"
          dependancyType="equal"
          dependancyValue={[1]}
        />
        <CheckboxBooleanForm
          {...FormProps}
          setWithValue
          name="Overtime"
          options={["Overtime"]}
          dependOn="change_action"
          dependancyType="equal"
          dependancyValue={[1]}
        />
      </div>
    </div>
  );
};

const Logs = ({ logs = [] }) => {
  const { t } = useTranslation();

  const columns = [
    { name: t("On"), selector: "created_at" },
    { name: t("Field"), selector: "type", grow: 1.5 },
    { name: t("old Value"), selector: "old_value" },
    { name: t("new Value"), selector: "new_value" },
    { name: t("start from"), selector: "from" },
    { name: t("Changed By"), selector: "user.name", grow: 1.5 },
  ];

  return <DataTable noHeader data={logs} columns={columns} />;
};
