import React, { useEffect, useState } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import {
  onInputChangeAction,
  onInputResetAction,
  setInputInValidateAction,
  setInputValidateAction,
} from "../../Store/Actions";
import { withDependency } from "../../Helpers/HOC/withDependency";
import Validation from "../../Helpers/Validations";
import { useFormValidation } from "../../Helpers/Hooks/useFormValidation";

const MultiSelectForm = ({
  dependOn,
  formName,
  name,
  formSubmitting,
  validateBy,
  formNameValidation,
  ...props
}) => {
  const inputval = props.super[formName][name];
  const isDisabled = props.disabled;
  const validate = useFormValidation(
    inputval,
    name,
    formNameValidation,
    formSubmitting,
    validateBy,
    isDisabled
  );

  // useEffect(() => {
  //     // if (validateBy && formSubmitting) {
  //     //     helperValidate[validateBy](value)
  //     
  //     // }
  //     if(formSubmitting){
  //     employeeFormSchema.validateAt(name,props.super[formName])
  //     .then((valid)=>{
  //         setIsValid(true)
  //         setvalidationError('')
  //     })
  //     .catch((err)=>{
  //         setIsValid(false)
  //         setvalidationError(err.message)
  
  //     }
  //     return () => {

  //     }
  // }, [props.super[formName][name],formSubmitting])

  // useEffect(() => {
  //   if (validateBy && !props.disabled) {
  //     if (formSubmitting) {
  //       Validation.validationSchema
  //         .validateAt(validateBy, { [validateBy]: props.super[formName][name] })
  //         .then((valid) => {
  //           setIsValid(true);
  //           setvalidationError("");
  //           props.setInputValidateAction(props.formNameValidation, name);
  //         })
  //         .catch((err) => {
  //           setIsValid(false);
  //           setvalidationError(err.message);
  //           props.setInputInValidateAction(props.formNameValidation, name);
  
  //         });
  //     } else {
  //       Validation.validationSchema
  //         .validateAt(validateBy, { [validateBy]: props.super[formName][name] })
  //         .then((valid) =>
  //           props.setInputValidateAction(props.formNameValidation, name)
  //         )
  //         .catch((err) =>
  //           props.setInputInValidateAction(props.formNameValidation, name)
  //         );
  //     }
  //   }
  //   return () => {
  //     if (validateBy && !props.disabled) {
  //       props.setInputValidateAction(props.formNameValidation, name);
  //     }
  //   };
  // }, [props.super[formName][name], formSubmitting]);

  const handleChange = (values, event) => {
    let e;
    switch (event.action) {
      case "clear":
        e = {
          target: {
            name: event.name,
            value: [],
          },
        };
        return props.onInputChangeAction(formName, e);

      default:
        e = {
          target: {
            name: event.name,
            value: values ? values.map((value) => value.value) : [],
          },
        };
        return props.onInputChangeAction(formName, e);
    }
  };

  const handleRenderValue = (selectedValues) => {
    return props.options?.filter((opt) => selectedValues?.includes(opt.value));
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="form-group">
          <label>{props.label}</label>
          <Select
            closeMenuOnSelect={props.closeMenuOnSelect}
            // value={handleRenderValue(props.super[formName][name])}
            value={props.options?.filter((opt) =>
              props.super[formName][name]?.includes(opt.value)
            )}
            onChange={handleChange}
            name={name}
            isMulti={props.isMulti}
            options={props.options}
          />
        </div>
        {!validate.validity && (
          <div className="error_message_style alert alert-danger">
            {validate.validityMessage}
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    super: state.super,
  };
};

export default connect(mapStateToProps, {
  onInputChangeAction,
  onInputResetAction,
  setInputInValidateAction,
  setInputValidateAction,
})(withDependency(MultiSelectForm));
