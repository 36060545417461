export const TOGGLE_ASSET_TYPE_MODAL = "TOGGLE_ASSET_TYPE_MODAL";
export const TOGGLE_WAREHOUSE_MODAL = "TOGGLE_WAREHOUSE_MODAL";

// Start of comapny assets Types
export const TOGGLE_COMPANY_ASSET_MODAL = "TOGGLE_COMPANY_ASSET_MODAL";
export const ADD_ASSET_ITEMS_INPUT = "ADD_ASSET_ITEMS_INPUT";
export const REMOVE_ASSET_ITEMS_INPUT = "REMOVE_ASSET_ITEMS_INPUT";
export const ON_ASSET_CHILD_INPUT_CHANGE = "ON_ASSET_CHILD_INPUT_CHANGE";
export const TOGGLE_ASSET_UNITS_MODAL = "TOGGLE_ASSET_UNITS_MODAL";
export const TOGGLE_ADD_NEW_UNITS_MODAL = "TOGGLE_ADD_NEW_UNITS_MODAL";
export const TOGGLE_ASSIGN_RETRIEVE_ASSET_MODAL =
  "TOGGLE_ASSIGN_RETRIEVE_ASSET_MODAL";
export const ADD_ASSET_INPUTS = "ADD_ASSET_INPUTS";
export const RESET_ASSIGN_RETRIEVE_ASSET_MODAL_VALUES =
  "RESET_ASSIGN_RETRIEVE_ASSET_MODAL_VALUES";
// End of comapny assets Types

export const TOGGLE_SCRAPP_ITEM_MODAL = "TOGGLE_SCRAPP_ITEM_MODAL";
export const TOGGLE_UNSCRAPP_ITEM_MODAL = "TOGGLE_UNSCRAPP_ITEM_MODAL";
export const TOGGLE_WAREHOUSE_DETAILS_MODAL = "TOGGLE_WAREHOUSE_DETAILS_MODAL";
export const TOGGLE_TRANSFER_ASSETS_MODAL = "TOGGLE_TRANSFER_ASSETS_MODAL";

export const TOGGLE_EMPLOYEE_ASSET_REQUEST_MODAL =
  "TOGGLE_EMPLOYEE_ASSET_REQUEST_MODAL";

export const TOGGLE_ACCEPT_ASSET_REQUEST_MODAL =
  "TOGGLE_ACCEPT_ASSET_REQUEST_MODAL";

export const TOGGLE_RETRIVE_ASSET_MODAL = "TOGGLE_RETRIVE_ASSET_MODAL";

export const TOGGLE_EXCHANGE_ITEM_MODAL = "TOGGLE_EXCHANGE_ITEM_MODAL";

export const TOGGLE_SCRAP_NON_SERIALIZED_ASSETS =
  "TOGGLE_SCRAP_NON_SERIALIZED_ASSETS";

export const ADJUST_NUMBER_OF_UNITS = "ADJUST_NUMBER_OF_UNITS";
