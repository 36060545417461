import React, { useState } from "react";
import { useFormValidation } from "../../Helpers/Hooks/useFormValidation";
import { connect } from "react-redux";
import {
  onInputChangeAction,
  onInputResetAction,
  setInputInValidateAction,
} from "../../Store/Actions";
import { withDependency } from "../../Helpers/HOC/withDependency";
import { useTranslation } from "react-i18next";

const RadioboxForm = ({
  name,
  dependOn,
  label,
  formName,
  onChange,
  validateBy,
  formSubmitting,
  handleInValidForm,
  FormValid = [],
  formNameValidation,
  containerStyle,
  labelStyle,
  inputStyle,
  optionsContainerStyle,
  optionItemStyle,
  optionInputStyle,
  optionLabelStyle,
  optionLabelActiveStyle,
  validateContainerStyle,
  validateMessage,
  formServerValidation,
  validationName = undefined,
  interceptChange = () => undefined,
  ...props
}) => {
  const [isValid, setIsValid] = useState(true);
  const [validationError, setvalidationError] = useState("");
  const inputval = props.value ?? props.super?.[formName]?.[name];
  const isDisabled = props.disabled;
  const isServerValid = !Boolean(props.super[formServerValidation])
    ? false
    : props.super[formServerValidation][validationName ?? name]
      ? true
      : false;
  const validate = useFormValidation(
    inputval,
    validationName ?? name,
    formNameValidation,
    formSubmitting,
    validateBy,
    isDisabled
  );

  const { t } = useTranslation();

  const handleInputChange = (event, val) => {
    event.persist();
    if (!interceptChange(event, val)) {
      props.onInputChangeAction(formName, event, "radio", val);
    }
  };

  const vaildContainer = () => {
    if (!validate.validity || isServerValid) {
      return validateContainerStyle ?? "invalid-container-style";
    }
    return " ";
  };

  return (
    <div className={`${props?.rootStyle} ${vaildContainer()}`}>
      <div className={`${containerStyle ? containerStyle : "form-group"}`}>
        {label && (
          <label
            className={`${labelStyle ? labelStyle : ""} validity-label-style`}
          >
            {t(label)}
          </label>
        )}

        <form
          className={`${
            optionsContainerStyle ? optionsContainerStyle : "d-flex ml-auto"
          }`}
        >
          {props.options.map((opt) => (
            <div
              className={`${
                optionItemStyle ? optionItemStyle : "form-check col"
              }`}
            >
              <label
                className={`${
                  optionInputStyle
                    ? `radio ${optionInputStyle}`
                    : "form-check-input"
                }`}
              >
                <input
                  type="radio"
                  name={name}
                  value={opt.value}
                  onChange={(e) =>
                    onChange ? onChange(e) : handleInputChange(e, opt.value)
                  }
                  checked={inputval == opt.value}
                  {...opt?.optProps}
                />
                <span></span>

                <div
                  className={`${
                    optionLabelStyle
                      ? `${optionLabelStyle} ${
                          inputval == opt.value
                            ? optionLabelActiveStyle
                              ? optionLabelActiveStyle
                              : "optionLabelActiveStyle"
                            : ""
                        }`
                      : "form-check-label"
                  }`}
                >
                  {t(opt?.label)}
                </div>
              </label>
            </div>
          ))}
        </form>
      </div>
      <div className="validity-msg-style">
        {validateMessage ? validateMessage : validate.validityMessage}
        {validate.validityMessage && <br />}
        {Boolean(props.super[formServerValidation])
          ? (props.super[formServerValidation][validationName ?? name] ?? "")
          : null}{" "}
      </div>
    </div>
  );
};

const mapStateToProps = (state, { reducer = "super" }) => {
  return {
    super: state[reducer],
  };
};
export default connect(mapStateToProps, {
  onInputChangeAction,
  onInputResetAction,
  setInputInValidateAction,
})(withDependency(RadioboxForm));
