import React, { useEffect, useState } from "react";
import MainModal from "../MainModal";
import { InputForm, DateTimePickerForm } from "form-builder";
import { useTranslation } from "react-i18next";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { upsertTaxInfo } from "../../Graphql/mutation";
import { GET_TAX_INFOS } from "../../Graphql/query";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import {
  onFormResetAction,
  setFormServerValidationAction,
} from "../../Store/Actions";
import { Snackbar } from "@mui/material";
import { WarningAmber } from "@mui/icons-material";
import HelperFns from "../../Helpers/HelperFns";

const formNameValidation = "taxFormClientValidations";
const formServerValidation = "taxFormServeValidations";

const numbersRegex = /[+-]?([0-9]*[.])?[0-9]+/;

const TaxForm = ({
  isModalOpen,
  handleCloseModal,
  formData,
  setFormData,
  handleResetForm,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);

  const [formSubmitting, setFormSubmitting] = useState(false);

  const [generalMessage, setGeneralMessage] = useState("");

  const [bracketsValidations, setBracketsValidations] = useState([]);

  useEffect(() => {
    if (!!!formData?.id) {
      setIsSnackBarOpen(true);
    }
  }, []);

  useEffect(() => {
    formData?.brackets?.forEach((bracket, index) => {
      if (index === formData?.brackets?.length - 1) return;

      let validationName = `to-${index}`;
      if (+bracket?.to <= +formData?.brackets?.[index - 1]?.to) {
        setBracketsValidations((prev) => [
          ...new Set([...prev, validationName]),
        ]);
      } else {
        setBracketsValidations((prev) =>
          prev?.filter((el) => el != validationName)
        );
      }
    });

    return () => {
      setGeneralMessage("");
      dispatch(onFormResetAction(formNameValidation));
      setBracketsValidations([]);
    };
  }, [formData?.brackets]);

  const clientValidations = useSelector(
    (state) => state.admin?.[formNameValidation]
  );

  const sharedProps = { formNameValidation, formSubmitting };

  const handleAddNewBracket = () => {
    setFormData((prev) => ({
      ...prev,
      brackets: [...prev?.brackets, { from: "", to: "", percentage: "" }],
    }));
  };

  const handleRemoveBracket = (index) => {
    setFormData((prev) => ({
      ...prev,
      brackets: prev?.brackets?.filter((_, i) => i != index),
    }));
  };

  const handleBracketChange = (index, e) => {
    let { name, value } = e?.target ?? {};

    if (numbersRegex.test(value) || value == "") {
      setFormData((prev) => ({
        ...prev,
        brackets: prev?.brackets?.map((bracket, i) => {
          if (i === index) {
            return { ...bracket, [name]: value };
          } else {
            return bracket;
          }
        }),
      }));
    }
  };

  const handleInputChange = (e, isNumber = true) => {
    let { name, value } = e?.target ?? {};

    if (isNumber && !numbersRegex.test(value) && value !== "") return;

    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const [
    attemptUpsertTaxInfo,
    { loading: upsertTaxInfoLoading, error: upsertTaxInfoError },
  ] = useMutation(upsertTaxInfo, {
    variables: {
      input: {
        id: formData?.id,
        name: formData?.name,
        from: moment(formData?.fiscalYear).format("YYYY-MM"),
        insurance_salary_percentage: parseFloat(
          formData?.insuranceSalaryPercentage
        ),
        temporary_employment_percentage: parseFloat(
          formData?.temporaryEmploymentPercentage
        ),
        min_insurance_salary: parseFloat(formData?.minInsuredSalary),
        max_insurance_salary: parseFloat(formData?.maxInsuredSalary),
        employee_insurance_percentage: parseFloat(
          formData?.employeeInsurancePercentage
        ),
        employer_insurance_percentage: parseFloat(
          formData?.businessOwnerInsurancePercentage
        ),
        personal_exemption: parseFloat(formData?.personalTaxExemption),
        brackets: formData?.brackets?.map((bracket, index) => ({
          id: bracket?.id,
          start:
            index === 0 ? +bracket?.from : +formData?.brackets?.[index - 1]?.to,
          end: bracket?.to ? +bracket?.to : null,
          percentage: parseFloat(bracket?.percentage),
        })),
      },
    },
    onCompleted: (data) => {
      if (!!data?.upsert_tax_info) {
        handleCloseModal();
      } else {
        dispatch(
          setFormServerValidationAction({
            serverValidationName: formServerValidation,
            validations:
              upsertTaxInfoError?.graphQLErrors?.[0]?.extensions?.validation,
          })
        );

        setGeneralMessage(
          upsertTaxInfoError?.graphQLErrors?.[0]?.extensions?.reason ??
            t("something went wrong")
        );
      }
    },
    onError: (error) => {
      Swal.fire({
        title: t("error"),
        text: error.message ? error.message : t("something went wrong"),
        icon: "error",
        className: "swal-error-style",
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      });
    },
    refetchQueries: [{ query: GET_TAX_INFOS }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = () => {
    setFormSubmitting(true);
    setGeneralMessage("");

    if (clientValidations?.length || bracketsValidations?.length) return;

    attemptUpsertTaxInfo();
  };

  const handleUndoButton = () => {
    handleResetForm();
    setIsSnackBarOpen(false);
  };

  return (
    <MainModal
      isOpen={isModalOpen}
      toggle={handleCloseModal}
      modalTitle={t("new tax law")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={upsertTaxInfoLoading}
    >
      <div>
        <div className="d-flex align-items-center">
          <InputForm
            validateBy="textRequired"
            name="name"
            type="text"
            placeholder={t("name")}
            label="name"
            labelStyle="mb-2"
            containerStyle="mt-0"
            inputContainerStyle=" "
            rootStyle="w-100 mr-3"
            onChange={(e) => handleInputChange(e, false)}
            value={formData?.name}
            {...sharedProps}
          />

          <DateTimePickerForm
            name="fiscalYear"
            placeholder={t("choose month")}
            rootStyle="w-100"
            containerStyle="w-100"
            validateBy="textRequired"
            label="year"
            labelStyle="mb-2"
            hasIcon
            format="YYYY-MM"
            requestFormat="YYYY-MM"
            mode="month"
            picker="month"
            onChange={(value) =>
              handleInputChange(
                { target: { name: "fiscalYear", value } },
                false
              )
            }
            value={formData?.fiscalYear}
            {...sharedProps}
          />
        </div>

        <h4 className="assignment-form-header">{t("social insurance")}</h4>

        <h4 className="assignment-form-header text-dark mb-3">
          {t("insured salary")}
        </h4>

        <InputForm
          validateBy="textRequired"
          name="insuranceSalaryPercentage"
          type="text"
          label="insurance salary percentage"
          onChange={handleInputChange}
          value={formData?.insuranceSalaryPercentage}
          placeholder={t("Percentage")}
          containerStyle="mt-0 mb-2 d-flex align-items-center justify-content-between"
          rootStyle="w-100 mr-3"
          inputStyle="input-style-default w-100"
          hasSuffix
          suffixTitle={"%"}
          {...sharedProps}
        />

        <InputForm
          validateBy="textRequired"
          name="temporaryEmploymentPercentage"
          type="text"
          label="temporary employment percentage"
          inputContainerStyle=" "
          labelStyle="font-weight-bold"
          onChange={handleInputChange}
          value={formData?.temporaryEmploymentPercentage}
          placeholder={t("Percentage")}
          rootStyle="w-100 mr-3"
          containerStyle="mt-0 mb-2 d-flex align-items-center justify-content-between"
          inputStyle="input-style-default w-100"
          hasSuffix
          suffixTitle={"%"}
          {...sharedProps}
        />

        <div className="d-flex align-items-center mb-4">
          <InputForm
            validateBy="textRequired"
            name="minInsuredSalary"
            type="text"
            placeholder={t("minimum")}
            label="minimum"
            containerStyle="mt-0"
            inputContainerStyle=" "
            rootStyle="w-100 mr-3"
            onChange={handleInputChange}
            value={formData?.minInsuredSalary}
            {...sharedProps}
          />

          <InputForm
            validateBy="textRequired"
            name="maxInsuredSalary"
            type="text"
            placeholder={t("maximum")}
            label="maximum"
            containerStyle="mt-0"
            inputContainerStyle=" "
            rootStyle="w-100"
            onChange={handleInputChange}
            value={formData?.maxInsuredSalary}
            {...sharedProps}
          />
        </div>

        <h4 className="assignment-form-header text-dark mb-3">
          {t("percent")}
        </h4>

        <div className="d-flex align-items-center mb-4">
          <InputForm
            validateBy="textRequired"
            name="employeeInsurancePercentage"
            type="text"
            label="employee"
            labelStyle="mb-1"
            onChange={handleInputChange}
            value={formData?.employeeInsurancePercentage}
            placeholder={t("Percentage")}
            rootStyle="w-100 mr-3"
            containerStyle="mt-0 mb-2"
            inputStyle="input-style-default w-100"
            hasSuffix
            suffixTitle={"%"}
            {...sharedProps}
          />

          <InputForm
            validateBy="textRequired"
            name="businessOwnerInsurancePercentage"
            type="text"
            label="business owner"
            labelStyle="mb-1"
            onChange={handleInputChange}
            value={formData?.businessOwnerInsurancePercentage}
            placeholder={t("Percentage")}
            rootStyle="w-100 mr-3"
            containerStyle="mt-0 mb-2"
            inputStyle="input-style-default w-100"
            hasSuffix
            suffixTitle={"%"}
            {...sharedProps}
          />
        </div>

        <InputForm
          validateBy="textRequired"
          name="personalTaxExemption"
          type="text"
          placeholder={t("amount")}
          label="personal tax exemption"
          labelStyle="font-weight-bold"
          containerStyle="mt-0"
          inputContainerStyle=" "
          rootStyle="w-100 mr-3"
          onChange={handleInputChange}
          value={formData?.personalTaxExemption}
          {...sharedProps}
        />

        <h4 className="assignment-form-header d-flex align-items-center">
          <p className="mb-0 mr-2">{t("brackets")}</p>
          <button
            onClick={handleAddNewBracket}
            className="brackets-add-new-button"
          >
            <FontAwesomeIcon className="bracket-add-button" icon={faPlus} />
          </button>
        </h4>

        {/* header */}
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10 d-flex align-items-center">
            <p className="font-weight-bold w-100">from</p>
            <p className="font-weight-bold w-100">to</p>
            <p className="font-weight-bold w-100">percentage</p>
          </div>
        </div>
        {/* header */}

        {formData.brackets?.map((bracket, index) => (
          <div className="row mb-2">
            <p className="col-1 font-weight-bold mb-0 align-self-start">
              {t("bracket-order", {
                index: index + 1,
                suffix:
                  index + 1 == 1
                    ? "st"
                    : index + 1 == 2
                      ? "nd"
                      : index + 1 == 3
                        ? "rd"
                        : "th",
              })}
            </p>

            <div className="col-10 d-flex align-items-start">
              <InputForm
                validateBy="textRequired"
                name="from"
                validationName={`input.bracket${index + 1}.start`}
                type="text"
                placeholder={t("from")}
                labelStyle="font-weight-bold"
                containerStyle=" "
                inputContainerStyle=" "
                rootStyle="w-100 mr-3"
                onChange={(e) => handleBracketChange(index, e)}
                value={
                  index === 0
                    ? formData?.brackets?.[index]?.from
                    : +formData?.brackets?.[index - 1]?.to
                      ? +formData?.brackets?.[index - 1]?.to
                      : ""
                }
                disabled
                formServerValidation={formServerValidation}
                {...sharedProps}
              />

              <div className="w-100 mr-3">
                <InputForm
                  // validateBy="textRequired"
                  name="to"
                  validationName={`input.bracket${index + 1}.end`}
                  type="text"
                  placeholder={t("to")}
                  labelStyle="font-weight-bold"
                  containerStyle={
                    formSubmitting &&
                    bracketsValidations?.includes(`to-${index}`)
                      ? "invalid-container-style"
                      : " "
                  }
                  inputContainerStyle=" "
                  rootStyle=""
                  onChange={(e) => handleBracketChange(index, e)}
                  value={formData?.brackets?.[index]?.to}
                  formServerValidation={formServerValidation}
                  {...sharedProps}
                />

                {formSubmitting &&
                bracketsValidations?.includes(`to-${index}`) ? (
                  <div className="invalid-container-style">
                    <p className="mb-0 validity-msg-style">
                      must be greater than{" "}
                      {+formData?.brackets?.[index - 1]?.to + 1}
                    </p>
                  </div>
                ) : null}
              </div>

              <InputForm
                // validateBy="textRequired"
                name="percentage"
                validationName={`input.bracket${index + 1}.percentage`}
                type="text"
                labelStyle="font-weight-bold"
                containerStyle=" "
                onChange={(e) => handleBracketChange(index, e)}
                value={formData?.brackets?.[index]?.percentage}
                placeholder={t("Percentage")}
                rootStyle="w-100 mr-3"
                inputStyle="input-style-default w-100"
                hasSuffix
                suffixTitle={"%"}
                formServerValidation={formServerValidation}
                {...sharedProps}
              />
            </div>
            {index !== 0 ? (
              <button
                onClick={() => handleRemoveBracket(index)}
                className="border-0 outline-0 bg-transparent align-self-center"
              >
                <FontAwesomeIcon icon={faTimes} color="red" />
              </button>
            ) : null}
          </div>
        ))}

        <div className="invalid-container-style">
          <p className="mb-0 mt-4 validity-msg-style">{generalMessage}</p>
        </div>
      </div>

      <Snackbar
        open={isSnackBarOpen}
        onClose={() => setIsSnackBarOpen(false)}
        message={
          <div className="d-flex align-items-center">
            <WarningAmber sx={{ mr: 2, mb: 0.5 }} />
            <p className="m-0 text-lowercase">
              {t(
                "form was filled with the latest regulation created, undo to reset the form"
              )}
            </p>
          </div>
        }
        action={
          <div>
            <button
              className="tax-regulation-undo-button mr-2"
              onClick={handleUndoButton}
            >
              {t("undo")}
            </button>

            <button
              className="tax-regulation-undo-button"
              onClick={() => setIsSnackBarOpen(false)}
            >
              {t("close")}
            </button>
          </div>
        }
        ContentProps={{
          style: { backgroundColor: "#ed6c02", fontWeight: "bold" },
        }}
      />
    </MainModal>
  );
};

export default TaxForm;
