import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { DateTimePickerForm, BSelect } from "form-builder";
import { fetchHistoryListAttempt, showErrorToast } from "../../Store/Actions";
import HistoryList from "../../Containers/HistoryList";
import Pagination from "../../Components/Pagination";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import Const from "../../Constants";
import Loader from "../../Components/Loader";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import { exportLogsQuery } from "../../Graphql/query";
import { useLazyQuery } from "@apollo/client";
import swal from "sweetalert";
import { Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import HelperFns, { getLateKeys } from "../../Helpers/HelperFns";

const MyHistoryTab = (props) => {
  const dataTableRef = `historyList`;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    props.fetchHistoryListAttempt({
      ref: dataTableRef,
      rows: props.data.pagination.perPage,
      status: props.data.status,
      from: props.data.from,
      to: props.data.to,
      page: props.data.pagination.currentPage,
    });
  }, []);

  const [exportLogs, { data, loading, error }] = useLazyQuery(exportLogsQuery, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      if (
        response?.exportAttendanceHistory &&
        response?.exportAttendanceHistory?.status === "success"
      ) {
        HelperFns.downloadFile(response?.exportAttendanceHistory?.file);
      } else {
        dispatch(showErrorToast(response?.exportAttendanceHistory?.message));
      }
    },
    onError: (error) => {
      dispatch(showErrorToast(error?.message));
    },
  });

  const authUser = useSelector((state) => state?.auth?.userProfile);

  let updatedStatus = props?.data?.status?.includes("Late")
    ? [...props?.data?.status, ...getLateKeys()]
    : [...props?.data?.status];

  const handleExportAttendanceLogs = () => {
    if (loading || !(props?.data?.from && props?.data?.to)) {
      return;
    }
    exportLogs({
      variables: {
        input: {
          filter_data: {
            emp_id: [authUser?.id],
            // status: props?.data?.status,
            status: props?.data?.missing_sign_out
              ? [...updatedStatus, "Missing sign out"]
              : updatedStatus,
            date_range: {
              from: props?.data?.from,
              to: props?.data?.to,
            },
            page: "history_logs",
          },
          auth_records_history: true,
        },
      },
    });
  };

  const handleFilter = () => {
    props.fetchHistoryListAttempt({
      ref: dataTableRef,
      rows: props.data.pagination.perPage,
      status: props.data.status,
      from: props.data.from,
      to: props.data.to,
      page: (props.data.pagination.currentPage = 1),
    });
  };
  const didUpdtate = useDidUpdateEffect(handleFilter, [
    props.data.status,
    props.data.from,
    props.data.to,
  ]);

  const formNameValidation = `employeeHistoryValidation`;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlePaginate = (page = props.data.pagination.currentPage) => {
    
    props.fetchHistoryListAttempt({
      ref: dataTableRef,
      page: page,
      rows: props.data.pagination.perPage,
      status: props.data.status,
      from: props.data.from,
      to: props.data.to,
    });
  };

  return (
    <div className="content">
      {/* (Start) History Employee Header */}
      <BSelect
        reducer="user"
        name="status"
        formName={dataTableRef}
        formSubmitting={isSubmitting}
        options={Const.signInStatusOptions}
        keepDefaultStyle
        getOptionLabel={(option) => t(option.value.toLowerCase())}
        optionValue="value"
        isMulti={true}
      />

      <div className="row mx-0 lign-items-center justify-content-between my-2 att-history-header">
        <div className="d-flex flex-wrap">
          <DateTimePickerForm
            name="from"
            formName={dataTableRef}
            formSubmitting={isSubmitting}
            label={t("from")}
            hasIcon
            reducer="user"
            labelStyle="label-style mb-0"
            containerStyle="container-style-default mr-sm-4 flex-nowrap"
            rootStyle="att-datepicker-style"
          />
          <DateTimePickerForm
            name="to"
            formName={dataTableRef}
            formSubmitting={isSubmitting}
            label={t("to")}
            hasIcon
            reducer="user"
            labelStyle="label-style mb-0"
            containerStyle="container-style-default flex-nowrap"
            rootStyle="att-datepicker-style"
          />
        </div>
        <div className="mx-4">
          <Tooltip
            arrow
            placement="bottom"
            classes={{
              popper: props?.data?.from && props?.data?.to ? "d-none" : "",
            }}
            title={t("Please select a date range to export")}
          >
            <button
              className={`py-0 export-btn text-nowrap
       ${loading || !(props?.data?.from && props?.data?.to) ? "disabled" : ""}`}
              type="button"
              onClick={handleExportAttendanceLogs}
            >
              {loading ? (
                <Spinner
                  style={{ width: "1rem", height: "1rem", color: "#fff" }}
                />
              ) : (
                <>
                  <FontAwesomeIcon className="mr-2" icon={faFileExport} />
                  {t("export")}
                </>
              )}
            </button>
          </Tooltip>
        </div>
      </div>
      {/* (End) History Employee Header */}

      {props.data.isLoading ? (
        <Loader />
      ) : (
        <>
          <HistoryList isMyProfileHistory={true} />
          {props.data.pagination.lastPage > 1 && (
            <Pagination
              tableRef={dataTableRef}
              styleWraper="mt-5"
              onPaginate={handlePaginate}
              reducer="user"
            />
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.user.historyList,
});

export default connect(mapStateToProps, { fetchHistoryListAttempt })(
  MyHistoryTab
);
