import React from "react";
import { gql, useQuery } from "@apollo/client";

import HelperFns, { showToast } from "../../Helpers/HelperFns";

import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import { Box, Card, Avatar, Divider, Tooltip, Typography } from "@mui/material";

const GET_ACCOUNT_MANAGERS = gql`
  query accountManagersList {
    accountManagers: company_saas {
      name
      user_id
      user_type
      position_name
      department_name
      face_file_path
      profile_picture_path
      usersPrimaryCount
      usersSecondaryCount: usersSecondryCount
      primary {
        company_id
        company_name
      }
      secondary: secondry {
        company_id
        company_name
      }
    }
  }
`;

const AccountManagersList = () => {
  // Local State
  const [accountManagers, setAccountManagers] = React.useState([]);

  // Server State
  const { loading } = useQuery(GET_ACCOUNT_MANAGERS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ accountManagers }) => {
      setAccountManagers(accountManagers || []);
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  return loading ? (
    <div style={{ width: "90vw" }}>
      <Loader inner />
    </div>
  ) : (
    <>
      <Box
        sx={{
          mt: 5,
          mb: 2,
          gap: 1,
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr))",
        }}
      >
        {accountManagers?.map((accountManager) => (
          <AccountManagerCard
            key={accountManager?.user_id}
            {...accountManager}
          />
        ))}
      </Box>
    </>
  );
};

export default AccountManagersList;

const AccountManagerCard = (props) => {
  return (
    <Card
      component="article"
      variant="outlined"
      sx={{
        width: 1,
        height: 140,
        display: "flex",
        border: "1px solid #eaeef0",
        justifyContent: "space-between",
        boxShadow: "0 3px 15px 0 rgba(0,0,0,.09)",
        bgcolor: props?.isDimmed ? "#f3f3f3" : "#fff",
      }}
    >
      {/* Image Side */}
      <Box
        className="employee-img"
        sx={{
          py: 0.5,
          minWidth: 80,
          display: "grid",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <Avatar
          src={props?.profile_picture_path ?? props?.face_file_path}
          className={
            +props?.on_break
              ? "avatar blackish-avatar on_break_status_style"
              : "avatar blackish-avatar"
          }
        >
          {HelperFns.getIntials(props?.name)}
        </Avatar>
      </Box>

      <Divider light flexItem orientation="vertical" sx={{ my: 1, mr: 1 }} />

      {/* Text Side */}
      <Box
        sx={{
          pb: 1,
          pt: 0.75,
          width: "80%",
          display: "grid",
          marginInlineStart: 0.5,
        }}
      >
        <Box sx={{ width: "95%" }}>
          {/* Name */}
          <Tooltip
            classes={{
              tooltip: "custom_tooltip_style",
              arrow: "custom_arrow_tooltip_style",
              popper: "custom_employee_name_tooltip_style",
            }}
            arrow
            title={
              props?.name?.length > 20 ? (
                <h4 className="employee_name_tooltip_style">
                  {props?.name?.toLowerCase()}
                </h4>
              ) : (
                ""
              )
            }
          >
            <Typography
              noWrap
              component="h2"
              sx={{ textAlign: "left" }}
              dir={HelperFns.isStringRTL(props?.name) ? "rtl" : "ltr"}
            >
              <Link
                className="employee_name_style"
                onContextMenu={(e) => e.preventDefault()}
                to={{
                  pathname: `/admin-panel/account-manager/${props.user_id}`,
                  state: { name: props.name },
                }}
              >
                {props?.name?.toLowerCase()}
              </Link>
            </Typography>
          </Tooltip>

          {/* Position */}
          <Tooltip
            classes={{
              tooltip: "custom_tooltip_style",
              arrow: "custom_arrow_tooltip_style",
              popper: "custom_employee_name_tooltip_style",
            }}
            arrow
            title={
              props?.position_name.length > 20 ? (
                <Typography variant="body2" color="text.secondary">
                  {props?.user_type === "Owner"
                    ? props?.user_type
                    : props?.position_name}
                </Typography>
              ) : null
            }
          >
            <Typography component="div" variant="body2" color="text.secondary">
              {props?.user_type === "Owner"
                ? props?.user_type
                : props?.position_name}

              {/* Department */}
              <Tooltip
                classes={{
                  tooltip: "custom_tooltip_style",
                  arrow: "custom_arrow_tooltip_style",
                  popper: "custom_employee_name_tooltip_style",
                }}
                arrow
                title={
                  props?.department_name.length > 25 ? (
                    <div className="employee_title_style">
                      {props?.department_name}
                    </div>
                  ) : null
                }
              >
                <div className="employee_title_style">
                  {props?.department_name}
                </div>
              </Tooltip>
            </Typography>
          </Tooltip>
        </Box>

        {/* Lower Part */}
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            columnGap: 1,
          }}
        >
          <tr>
            <th className="font-weight-400">Primary Clients:</th>
            <td className="font-weight-500">{props?.primary?.length}</td>
          </tr>
          <tr>
            <th className="font-weight-400">Secondary Clients:</th>
            <td className="font-weight-500">{props?.secondary?.length}</td>
          </tr>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            columnGap: 1,
          }}
        >
          <tr>
            <th className="font-weight-400">Primary Users:</th>
            <td className="font-weight-500">{props?.usersPrimaryCount}</td>
          </tr>
          <tr>
            <th className="font-weight-400">Secondary Users:</th>
            <td className="font-weight-500">{props?.usersSecondaryCount}</td>
          </tr>
        </Box>
      </Box>
    </Card>
  );
};
