import React from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  SubscriptionRequestsListQuery,
  SubscriptionRequestsListOptionsQuery,
} from "../../Graphql/query";
import { showToast } from "../../Helpers/HelperFns";
import Privileges from "../../Constants/Privilages";
import { onFormResetAction } from "../../Store/Actions";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";

import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import { PersonAdd } from "@mui/icons-material";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import { AddButton } from "../../Components/Buttons";
import { BSelect, DateTimePickerForm } from "form-builder";
import { SubscriptionRequestModal } from "../../Components/CRMFormModals";
import IconButtonWithTooltip from "../../Components/IconButtonWithTooltip";
import { SetAccountManagerModal } from "../../Components/AdminPanelModals";
import { UpsertSubscriptionModal } from "../../Components/PaymentTrackingModals";

const reducer = "paymentTracking";
const filtersFormName = "subscriptionsListFilters";
const FiltersFormProps = { reducer, formName: filtersFormName };
const modalInitState = { isOpen: false, data: null };
const modals = [
  { name: "addSub", Modal: UpsertSubscriptionModal },
  { name: "upsert", Modal: SubscriptionRequestModal },
  { name: "setAccManager", Modal: SetAccountManagerModal },
];
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};
const sendFilters = (filters) => {
  return {
    page: 1,
    from: filters?.from || undefined,
    company_id: +filters.company || undefined,
    package_id: +filters.package || undefined,
  };
};

const SubscriptionRequestsList = ({ isFollowUp }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [data, setData] = React.useState([]);
  const [pagination, setPagination] = React.useState(paginationInitState);
  const [modalsState, setModalsState] = React.useState(
    modals.map(({ name }) => ({ name, ...modalInitState }))
  );

  // Reducer State
  const filters = useSelector((state) => state?.[reducer]?.[filtersFormName]);

  // Server State
  const { data: options, loading: isOptionsLoading } = useQuery(
    SubscriptionRequestsListOptionsQuery,
    {
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    }
  );
  const { loading, refetch } = useQuery(SubscriptionRequestsListQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      first: pagination?.perPage,
      page: pagination.currentPage,
      isFollowUp: +isFollowUp || 0,
      ...sendFilters(filters),
    },
    onCompleted: ({ subscription_requests }) => {
      setData(subscription_requests?.data || []);
      setPagination(subscription_requests?.pagination || paginationInitState);
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  // Constants
  const columns = React.useMemo(
    () => [
      {
        name: t("company"),
        cell: (row) => (
          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.VIEW_COMPANY_PROFILE,
            ]}
            altChildren={row?.company?.name}
          >
            <Link to={`/admin-panel/row?.company-profile/${row?.company?.id}`}>
              {row?.company?.name}
            </Link>
          </HasPrivileges>
        ),
      },
      {
        name: t("Primary Company Account Manager"),
        cell: (row) => row?.company?.primarySAAS?.user?.name,
      },
      {
        grow: 1.5,
        name: "agent",
        cell: (row) => row?.user?.name,
      },
      { name: t("package"), cell: (row) => <div>{row?.package?.name}</div> },
      // { name: t("Payment Type"), selector: "paymentType" },
      { name: t("start date"), selector: "from" },
      { name: t("paid"), selector: "paid" },
      { name: t("currency"), cell: (row) => row?.currency?.name },
      // { name: t("Billing Period"), selector: "billingPeriod" },
      {
        name: "assigned to",
        width: "200px",
        omit: !isFollowUp,
        cell: (row) => (
          <div className="d-flex gap-10 align-items-center">
            {row?.assignedTo?.name}
            {!row?.firstPayment ? null : (
              <HasPrivileges
                reqireMain={[
                  Privileges.SUPER_PRIVILEGE,
                  Privileges.CAN_ASSIGN_FOLLOW_UP_SUBSCRIPTION,
                ]}
              >
                <IconButtonWithTooltip
                  label="assign"
                  icon={<PersonAdd fontSize="small" className="blue-color" />}
                  onClick={() =>
                    handleOpenModal("setAccManager", {
                      id: row?.id,
                      user: row?.assignedTo,
                    })
                  }
                />
              </HasPrivileges>
            )}
          </div>
        ),
      },
      {
        omit: !isFollowUp,
        cell: (row) =>
          row?.firstPayment?.status || (
            <AddButton
              isIconOnly
              onClick={() => handleOpenModal("addSub", { ...row, isFollowUp })}
            >
              Create Subscription
            </AddButton>
          ),
      },
    ],
    []
  );

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    return () => {
      dispatch(onFormResetAction(filtersFormName));
    };
  }, []);

  /* ↓ Helpers ↓ */

  const handleOpenModal = (name, data = null) => {
    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? { name, data, isOpen: true } : p))
    );
  };

  const handleCloseModal = (name) => {
    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? { name, ...modalInitState } : p))
    );
  };

  const handlePaginate = (page = pagination.currentPage) => {
    refetch({ page });
  };

  return (
    <>
      {/* Filters */}
      <div className="d-flex align-items-end gap-20 flex-wrap mb-3">
        <BSelect
          {...FiltersFormProps}
          label="company"
          name="company"
          icon="department"
          rootStyle="flex-1"
          options={options?.companies || []}
          isLoading={isOptionsLoading}
          isClearable
        />
        <BSelect
          {...FiltersFormProps}
          label="package"
          name="package"
          icon="document"
          rootStyle="flex-1"
          options={options?.packages?.data || []}
          isLoading={isOptionsLoading}
          isClearable
        />
        <DateTimePickerForm
          {...FiltersFormProps}
          label="from"
          name="from"
          hasIcon
          isClearable
          rootStyle="flex-1"
          labelStyle="mb-2 pb-1"
          datePickerContainer="w-100"
          requestFormat="YYYY-MM-DD"
        />
        {isFollowUp ? null : (
          <HasPrivileges
            reqireMain={[
              Privileges.ADD_NEW_PAYMENT_SUBSCRIPTION,
              Privileges.SUPER_PRIVILEGE,
            ]}
          >
            <AddButton onClick={() => handleOpenModal("upsert")} />
          </HasPrivileges>
        )}
      </div>

      {/* Table */}
      <DataTable
        noHeader
        data={data}
        columns={columns}
        className="cards_table expandable"
        progressPending={loading}
        progressComponent={<Loader inner />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {/* Modals */}
      {modals.map(({ name, Modal }, i) =>
        modalsState[i].isOpen ? (
          <Modal
            key={name}
            refetchList={refetch}
            data={modalsState[i]?.data}
            onClose={() => handleCloseModal(name)}
          />
        ) : null
      )}
    </>
  );
};

export default SubscriptionRequestsList;
