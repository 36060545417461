import gql from "graphql-tag";

export const DEDUCTION_POLICY_QUERY = gql`
  query getDeductioPolices($first: Int = 30, $page: Int = 1) {
    deduction_policies(first: $first, page: $page) {
      data {
        id
        name
        violations {
          amount
          type
        }
        logs {
          id
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

// handle fetch bonus logs query
export const fetchBonusLogsQuery = gql`
  query fetchBonusLogs($id: ID) {
    bonus(id: $id) {
      user {
        id
        name
      }
      payroll_at
      logs {
        id
        event
        description
        created_at
        converted_created_at
      }
    }
  }
`;

// handle fetch deductions logs query
export const fetchDeductionsLogsQuery = gql`
  query fetchDeductionsLogs($id: ID) {
    deduction(id: $id) {
      user {
        id
        name
      }
      payroll_at
      logs {
        id
        event
        description
        created_at
        converted_created_at
      }
    }
  }
`;

// handle fetch deduction policy logs query
export const fetchDeductionsPolicyLogsQuery = gql`
  query fetchDeductionPolicyLogs($id: ID) {
    deduction_policy(id: $id) {
      id
      name
      logs {
        id
        event
        description
        created_at
        converted_created_at
      }
    }
  }
`;
