import React from "react";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import {
  showToast,
  getToDate,
  quarterToMonth,
} from "../../../Helpers/HelperFns";
import { clsx } from "clsx";
import moment from "moment";
import { onFormResetAction } from "../../../Store/Actions";
import Constants, { quartersOptions } from "../../../Constants";
import { overviewReportQuery } from "../../../Graphql/query/PaymentTracking";

import {
  CRMReportBarChart,
  CRMReportLinearChart,
} from "../../../Components/CRM";
import {
  ViewRelevantPayments,
  ViewCancelledCompanies,
} from "../../../Components/PaymentTrackingModals";
import BSelect from "form-builder/BSelect";
import { PieChart } from "../../../Components/Charts";
import CheckboxBooleanForm from "form-builder/CheckboxBooleanForm";
import { Skeleton, ToggleButton, ToggleButtonGroup } from "@mui/material";

const reducer = "paymentTracking";
const filtersFormName = "overviewReportsListFilters";
const modalInitState = { isOpen: false, data: null };
const FiltersFormProps = { reducer, formName: filtersFormName };
const getFiltersVariables = (filters) => {
  let input = {
    to_date: null,
    from_date: null,
    display_currency_id: +filters?.display_currency_id || 0,
    payments_currency_id: +filters?.payments_currency_id || 0,
    country_id: filters?.country_id ? filters?.country_id?.map((c) => +c) : [],
  };

  switch (filters.view) {
    case "monthly":
      input.from_date = `${filters?.fromYear}-${filters?.fromMonth}-01`;
      input.to_date = getToDate(`${filters?.toYear}-${filters?.toMonth}`);
      break;

    case "quarterly":
      (input.from_date = `${filters?.fromYear}-${quarterToMonth(
        filters?.fromQuarter,
        "from"
      )}-01`),
        (input.to_date = getToDate(
          `${filters?.toYear}-${quarterToMonth(filters?.toQuarter, "to")}`
        ));
      break;

    case "annually":
      input.from_date = moment()
        .year(filters?.fromYear)
        .startOf("year")
        .format("YYYY-MM-DD");
      input.to_date = moment()
        .year(filters?.toYear)
        .endOf("year")
        .format("YYYY-MM-DD");
      break;

    default:
      break;
  }

  return { input };
};

const Overview = () => {
  const dispatch = useDispatch();

  // Local State
  const [options, setOptions] = React.useState({
    currencies: [],
    countries: [],
  });

  // Reducer State
  const filters = useSelector((state) => state?.[reducer]?.[filtersFormName]);

  // Server State
  const { loading, data } = useQuery(overviewReportQuery, {
    notifyOnNetworkStatusChange: true,
    variables: getFiltersVariables(filters),
    onCompleted: (res) => {
      setOptions({
        countries: res?.countries || [],
        currencies: res?.currencies || [],
      });
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  /* ↓ Helpers ↓ */

  const handleViewChange = (_, view) => {
    dispatch(onFormResetAction(filtersFormName, { ...filters, view }));
  };

  const renderDateFilters = () => {
    switch (filters.view) {
      case "monthly":
        return (
          <>
            <div className="d-flex align-items-center gap-10 mr-2">
              <b>from</b>
              <BSelect
                {...FiltersFormProps}
                name="fromMonth"
                inputContainerStyle="w-100"
                options={Constants.MonthsData}
                rootStyle="min-w-150"
                optionLabel="key"
                optionValue="key"
              />
              <BSelect
                {...FiltersFormProps}
                name="fromYear"
                inputContainerStyle="w-100"
                options={Constants.YearData}
                rootStyle="min-w-150"
                optionLabel="value"
                optionValue="value"
              />
            </div>
            <div className="d-flex align-items-center gap-10">
              <b>to</b>
              <BSelect
                {...FiltersFormProps}
                name="toMonth"
                inputContainerStyle="w-100"
                options={Constants.MonthsData}
                rootStyle="min-w-150"
                optionLabel="key"
                optionValue="key"
              />
              <BSelect
                {...FiltersFormProps}
                name="toYear"
                inputContainerStyle="w-100"
                options={Constants.YearData}
                rootStyle="min-w-150"
                optionLabel="value"
                optionValue="value"
              />
            </div>
          </>
        );

      case "quarterly":
        return (
          <>
            <div className="d-flex align-items-center gap-10 mr-2">
              <b>from</b>
              <BSelect
                {...FiltersFormProps}
                name="fromQuarter"
                inputContainerStyle="w-100"
                options={quartersOptions}
                rootStyle="min-w-150"
              />
              <BSelect
                {...FiltersFormProps}
                name="fromYear"
                inputContainerStyle="w-100"
                options={Constants.YearData}
                rootStyle="min-w-150"
                optionLabel="value"
                optionValue="value"
              />
            </div>
            <div className="d-flex align-items-center gap-10">
              <b>to</b>
              <BSelect
                {...FiltersFormProps}
                name="toQuarter"
                inputContainerStyle="w-100"
                options={quartersOptions}
                rootStyle="min-w-150"
              />
              <BSelect
                {...FiltersFormProps}
                name="toYear"
                inputContainerStyle="w-100"
                options={Constants.YearData}
                rootStyle="min-w-150"
                optionLabel="value"
                optionValue="value"
              />
            </div>
          </>
        );

      case "annually":
        return (
          <div className="d-flex gap-10">
            <BSelect
              {...FiltersFormProps}
              label="from"
              name="fromYear"
              labelStyle="font-weight-bold"
              containerStyle="d-flex gap-10 align-items-center"
              placeholder="year"
              inputContainerStyle="w-100"
              options={Constants.YearData}
              rootStyle="min-w-150"
              optionLabel="value"
              optionValue="value"
            />
            <BSelect
              {...FiltersFormProps}
              label="to"
              name="toYear"
              labelStyle="font-weight-bold"
              containerStyle="d-flex gap-10 align-items-center"
              placeholder="year"
              inputContainerStyle="w-100"
              options={Constants.YearData}
              rootStyle="min-w-150"
              optionLabel="value"
              optionValue="value"
            />
          </div>
        );

      // case "custom":
      //   return (
      //     <>
      //       <DateTimePickerForm
      //         {...FiltersFormProps}
      //         hasIcon
      //         name="from"
      //         label="from"
      //         placeholder="from"
      //         requestFormat="YYYY-MM-DD"
      //         rootStyle="min-width-200"
      //         datePickerContainer="w-100"
      //         labelStyle=""
      //         containerStyle="py-0 d-flex gap-10 align-items-center"
      //       />
      //       <DateTimePickerForm
      //         {...FiltersFormProps}
      //         hasIcon
      //         name="to"
      //         label="to"
      //         placeholder="to"
      //         requestFormat="YYYY-MM-DD"
      //         rootStyle="min-width-200"
      //         datePickerContainer="w-100"
      //         labelStyle="font-weight-bold"
      //         containerStyle="py-0 d-flex gap-10 align-items-center"
      //       />
      //     </>
      //   );

      default:
        break;
    }
  };

  return (
    <>
      {/* <GeneralInfo loading={loading} data={data?.overViewCharts?.generalInfo} /> */}

      {/* Filters */}
      <div className="d-flex align-items-center gap-20 mb-2">
        <ToggleButtonGroup
          exclusive
          size="small"
          color="primary"
          value={filters.view}
          onChange={handleViewChange}
          aria-label="Select View Mode"
        >
          <ToggleButton value="monthly">monthly</ToggleButton>
          <ToggleButton value="quarterly">quarterly</ToggleButton>
          <ToggleButton value="annually">annually</ToggleButton>
          {/* <ToggleButton value="custom">custom</ToggleButton> */}
        </ToggleButtonGroup>
        {renderDateFilters()}
        <div className="d-flex gap-10 flex-1">
          <BSelect
            isClearable
            {...FiltersFormProps}
            placeholder="Select Display Currency"
            name="display_currency_id"
            icon="money"
            rootStyle="flex-1"
            options={options?.currencies || []}
          />
          <BSelect
            isClearable
            {...FiltersFormProps}
            placeholder="Select Payment Currency"
            name="payments_currency_id"
            icon="money"
            rootStyle="flex-1"
            options={options?.currencies || []}
          />
          <BSelect
            isMulti
            isClearable
            {...FiltersFormProps}
            placeholder="Select Countries"
            name="country_id"
            rootStyle="flex-1"
            options={options?.countries || []}
          />
        </div>
        <CheckboxBooleanForm
          {...FiltersFormProps}
          setWithValue
          name="forecasted"
          containerStyle="mt-2"
          options={["show forecasted data"]}
          dependOn="view"
          dependancyType="equal"
          dependancyValue={["quarterly", "annually"]}
        />
      </div>

      {/* Reports */}
      <div className="d-flex gap-10">
        <TotalActiveSubscribersOverTime
          loading={loading}
          view={filters.view}
          forecasted={filters.forecasted}
          data={data?.overViewCharts?.totalActiveSubscriptionsOverTime}
        />
        <ClientChurnRate
          loading={loading}
          view={filters.view}
          forecasted={filters.forecasted}
          data={data?.overViewCharts?.churnRate}
        />
      </div>
      <div className="d-flex gap-10">
        <TotalRevenueOvertime
          loading={loading}
          view={filters.view}
          forecasted={filters.forecasted}
          data={data?.overViewCharts?.totalRevenueOverTime}
        />
        <TopTenClients
          loading={loading}
          forecasted={filters.forecasted}
          data={{
            users: data?.overViewCharts?.topTenClientsByUserLimit,
            revenues: data?.overViewCharts?.topTenClientsByRevenue,
          }}
        />
      </div>
      <div className="d-flex gap-10">
        <ClientsByPackage
          loading={loading}
          view={filters.view}
          forecasted={filters.forecasted}
          data={data?.overViewCharts?.totalClientsPerPackage}
        />
        <OutstandingPayments
          loading={loading}
          forecasted={filters.forecasted}
          data={data?.overViewCharts?.outStandingPayments}
          num={
            data?.overViewCharts?.generalInfo?.totalOutstandingPaymentsAmount
          }
        />
      </div>
    </>
  );
};

export default Overview;

/*
  General Info
*/

const GeneralInfo = ({ loading, data }) => {
  return (
    <div className="d-flex gap-10 mt-4">
      <Widget
        loading={loading}
        classes="flex-1 text-center"
        style={{ minHeight: 100 }}
      >
        <b className="d-block text-16 mt-2">Total Active Subscribers</b>
        {data?.activeCount}
      </Widget>
      <Widget
        loading={loading}
        classes="flex-1 text-center"
        style={{ minHeight: 100 }}
      >
        <b className="d-block text-16 mt-2">Total Canceled Subscribers</b>
        {data?.canceledCount}
      </Widget>
      <Widget
        loading={loading}
        classes="flex-1 text-center"
        style={{ minHeight: 100 }}
      >
        <b className="d-block text-16 mt-2">Total Outstanding Payments</b>
        {data?.totalOutstandingPaymentsAmount}
      </Widget>
    </div>
  );
};

/*
  Total active subscribers over time
*/

const formatTotalActiveSubscribersOverTimeChart = ({ isUsers, ...args }) => {
  if (!args?.data) return { labels: [], datasets: [] };
  const d = getData(args);

  const formattedData = d?.reduce(
    (acc, curr) => {
      // Labels
      curr?.year && acc.years.push(curr.year);
      curr.month_year && acc.months.push(curr.month_year);
      curr?.quarter &&
        acc.quarters.push("Q" + curr.quarter + " - " + curr.year);

      // Data
      acc.users.push((curr.users_count || 0)?.toString());
      acc.subscriptions.push((curr.subscriptions_count || 0)?.toString());
      return acc;
    },
    {
      users: [],
      years: [],
      months: [],
      quarters: [],
      subscriptions: [],
    }
  );

  let labels = formattedData?.months;
  if (args?.view === "annually") labels = formattedData.years;
  if (args?.view === "quarterly") labels = formattedData?.quarters;
  return {
    labels,
    datasets: isUsers
      ? [{ data: formattedData.users }]
      : [{ data: formattedData.subscriptions }],
  };
};

const TotalActiveSubscribersOverTime = ({ loading, ...props }) => {
  const [isUsers, setIsUsers] = React.useState(false);

  return (
    <Widget loading={loading}>
      <div className="d-flex align-items-center justify-content-between">
        <b className="d-block text-16">Total active subscribers over time</b>
        <ToggleClientsUsers isUsers={isUsers} setIsUsers={setIsUsers} />
      </div>
      <CRMReportLinearChart
        noLegends
        height="auto"
        chartData={formatTotalActiveSubscribersOverTimeChart({
          ...props,
          isUsers,
        })}
        options={{
          scales: {
            yAxes: [{ ticks: { callback: (val) => val?.toLocaleString() } }],
          },
          tooltips: {
            callbacks: {
              label: (context) => (+context.value).toLocaleString(),
            },
          },
        }}
      />
    </Widget>
  );
};

/*
  Clients by Package
*/

const formatClientsByPackageChart = ({ isUsers, ...args }) => {
  if (!args?.data) return { labels: [], datasets: [] };
  const d = getData(args);

  const formattedData = d?.reduce(
    (acc, curr) => {
      // Labels
      curr?.year && acc.years.push(curr.year);
      curr.month_year && acc.months.push(curr.month_year);
      curr?.quarter &&
        acc.quarters.push("Q" + curr.quarter + " - " + curr.year);

      // Data
      if (!acc.plans.length) acc.plans = curr?.plans?.map((p) => p?.plan_name);
      curr?.plans?.forEach((p, i) => {
        if (!acc.users[i]) acc.users[i] = [];
        if (!acc.subscriptions[i]) acc.subscriptions[i] = [];
        acc.users[i].push((p.users_count || 0)?.toString());
        acc.subscriptions[i].push((p.subscriptions_count || 0)?.toString());
      });
      return acc;
    },
    {
      plans: [],
      users: [],
      years: [],
      months: [],
      quarters: [],
      subscriptions: [],
    }
  );

  let labels = formattedData.months;
  if (args?.view === "annually") labels = formattedData.years;
  if (args?.view === "quarterly") labels = formattedData?.quarters;
  return {
    labels,
    datasets: formattedData.plans.map((p, i) => ({
      label: p,
      data: isUsers
        ? formattedData.users?.[i]
        : formattedData.subscriptions?.[i],
    })),
  };
};

const ClientsByPackage = ({ loading, ...props }) => {
  const [isUsers, setIsUsers] = React.useState(false);

  return (
    <Widget loading={loading}>
      <div className="d-flex align-items-center justify-content-between">
        <b className="d-block text-16">Clients by Package</b>
        <ToggleClientsUsers isUsers={isUsers} setIsUsers={setIsUsers} />
      </div>
      <CRMReportLinearChart
        chartData={formatClientsByPackageChart({ ...props, isUsers })}
        options={{
          scales: {
            yAxes: [{ ticks: { callback: (val) => val?.toLocaleString() } }],
          },
          tooltips: {
            callbacks: {
              label: (context) => (+context.value).toLocaleString(),
            },
          },
        }}
      />
    </Widget>
  );
};

/*
  Client Churn Rate
*/

const formatClientChurnRateChart = ({ isUsers, ...args }) => {
  if (!args?.data) return { labels: [], datasets: [] };
  const d = getData(args);

  const formattedData = d?.reduce(
    (acc, curr) => {
      // Labels
      curr?.year && acc.years.push(curr.year);
      curr.month_year && acc.months.push(curr.month_year);
      curr?.quarter &&
        acc.quarters.push("Q" + curr.quarter + " - " + curr.year);

      // Data
      if (isUsers) {
        acc.rate.push((curr.users_churn_rate || 0)?.toString());
        acc.active.push(
          (curr.active_subscriptions_users_count || 0)?.toString()
        );
        acc.cancelled.push(
          (curr.cancelled_subscriptions_users_count || 0)?.toString()
        );
      } else {
        acc.rate.push((curr.clients_churn_rate || 0)?.toString());
        acc.active.push((curr.active_subscriptions_count || 0)?.toString());
        acc.cancelled.push(
          (curr.cancelled_subscriptions_count || 0)?.toString()
        );
      }

      // IDs
      acc.ids.push(curr.cancelled_companies_ids?.split(","));

      return acc;
    },
    {
      ids: [],
      rate: [],
      years: [],
      active: [],
      months: [],
      quarters: [],
      cancelled: [],
    }
  );

  let labels = formattedData?.months;
  if (args?.view === "annually") labels = formattedData.years;
  if (args?.view === "quarterly") labels = formattedData?.quarters;
  return {
    labels,
    ids: formattedData.ids,
    datasets: [{ data: formattedData.rate }],
  };
};

const ClientChurnRate = ({ loading, ...props }) => {
  // Local State
  const [isUsers, setIsUsers] = React.useState(false);
  const [showRelevantCompaniesState, setShowRelevantCompaniesState] =
    React.useState(modalInitState);

  // Constants
  const chartData = formatClientChurnRateChart({ ...props, isUsers });

  /* Helpers */

  const handleShowRelevantCompaniesState = (_, ele) => {
    const _index = ele[0]?._index;

    const companiesIDs = chartData?.ids?.[_index];
    if (companiesIDs) {
      setShowRelevantCompaniesState({
        isOpen: true,
        data: { companiesIDs: chartData?.ids?.[_index] },
      });
    }
  };

  return (
    <Widget loading={loading}>
      <div className="d-flex align-items-center justify-content-between">
        <b className="d-block text-16">Client Churn Rate (%)</b>
        <ToggleClientsUsers isUsers={isUsers} setIsUsers={setIsUsers} />
      </div>
      <CRMReportLinearChart
        noLegends
        chartData={chartData}
        options={{
          onClick: handleShowRelevantCompaniesState,
          tooltips: {
            callbacks: { footer: () => "Click to see Cancelled Companies" },
          },
          scales: {
            yAxes: [{ ticks: { callback: (val) => val + "%" } }],
          },
        }}
      />
      {showRelevantCompaniesState?.isOpen ? (
        <ViewCancelledCompanies
          data={showRelevantCompaniesState?.data}
          onClose={() => setShowRelevantCompaniesState(modalInitState)}
        />
      ) : null}
    </Widget>
  );
};

/*  
  Total Revenue Overtime
*/

const formatTotalRevenueOvertimeChart = (args) => {
  if (!args?.data) return { labels: [], datasets: [] };
  const d = getData(args);

  const formattedData = d?.reduce(
    (acc, curr) => {
      // Labels
      curr?.year && acc.years.push(curr.year);
      curr.month_year && acc.months.push(curr.month_year);
      curr?.quarter &&
        acc.quarters.push("Q" + curr.quarter + " - " + curr.year);

      // Data
      acc.amounts.push((curr.amount || 0)?.toString());

      return acc;
    },
    {
      years: [],
      months: [],
      amounts: [],
      quarters: [],
    }
  );

  let labels = formattedData?.months;
  if (args?.view === "annually") labels = formattedData.years;
  if (args?.view === "quarterly") labels = formattedData?.quarters;
  return {
    labels,
    datasets: [{ data: formattedData.amounts }],
  };
};

const TotalRevenueOvertime = ({ loading, ...props }) => {
  return (
    <Widget loading={loading}>
      <b className="d-block text-16">Total Revenue Overtime</b>
      <CRMReportBarChart
        noLegends
        chartData={formatTotalRevenueOvertimeChart(props)}
        options={{
          scales: {
            yAxes: [{ ticks: { callback: (val) => val?.toLocaleString() } }],
          },
          tooltips: {
            callbacks: {
              label: (context) => (+context.value).toLocaleString(),
            },
          },
        }}
      />
    </Widget>
  );
};

/*  
  Top Ten Revenue Clients
*/

const formatTopTenClientsChart = (data, isUsers) => {
  if (!data) return { labels: [], datasets: [] };
  let d = data?.users || [];
  if (!isUsers) d = data?.revenues || [];

  const formattedData = d?.reduce(
    (acc, curr) => {
      acc.names.push(curr.company_name || "");
      acc.packages.push(curr.package_name || "");
      if (isUsers) {
        acc.amounts.push((curr.user_limit || 0)?.toString());
      } else {
        acc.amounts.push((curr.total_amount || 0)?.toString());
      }

      return acc;
    },
    {
      names: [],
      packages: [],
      amounts: [],
      percentages: [],
    }
  );

  const amountsSum = formattedData.amounts.reduce(
    (acc, curr) => acc + +curr,
    0
  );
  const percentages = formattedData.amounts.map(
    (amount) => ((+amount / amountsSum) * 100)?.toFixed(2) + "%"
  );
  return {
    labels: formattedData.names.map(
      (name, i) =>
        `${name} - ${formattedData.packages[i]} - ${(+formattedData.amounts[i]).toLocaleString()} (${percentages[i]})`
    ),
    datasets: [{ data: formattedData.amounts }],
  };
};

const TopTenClients = ({ data, loading }) => {
  const [isUsers, setIsUsers] = React.useState(false);

  const handleViewChange = (_, view) => {
    setIsUsers(view);
  };

  return (
    <Widget loading={loading}>
      <div className="d-flex justify-content-between">
        <b className="d-block text-16">Top Ten Clients</b>
        <ToggleButtonGroup
          exclusive
          size="small"
          color="primary"
          value={isUsers}
          onChange={handleViewChange}
        >
          <ToggleButton value={false}>Revenue</ToggleButton>
          <ToggleButton value={true}>Users</ToggleButton>
        </ToggleButtonGroup>
      </div>
      <PieChart
        chartData={formatTopTenClientsChart(data, isUsers)}
        options={{
          tooltips: { enabled: false },
          legend: { position: "right" },
        }}
      />
    </Widget>
  );
};

/*  
  Outstanding Payments
*/

const formatOutstandingPaymentsChart = (data) => {
  if (!data) return { labels: [], datasets: [] };

  const formattedData = data?.reduce(
    (acc, curr) => {
      acc.amounts.push((curr.amount || 0)?.toString());
      acc.ids.push((curr.payments_ids || "").split(","));
      acc.percentages.push((curr.percentage || 0) + "%");
      acc.periods.push((curr.period || "")?.replaceAll("_", " "));
      return acc;
    },
    {
      ids: [],
      periods: [],
      amounts: [],
      percentages: [],
    }
  );

  return {
    ids: formattedData.ids,
    labels: formattedData.periods.map(
      (p, i) =>
        `${p} - ${(+formattedData.amounts[i]).toLocaleString()} (${formattedData.percentages[i]})`
    ),
    datasets: [{ data: formattedData.amounts }],
  };
};

const OutstandingPayments = ({ num, data, loading }) => {
  // Local State
  const [showRelevantPaymentsState, setShowRelevantPaymentsState] =
    React.useState(modalInitState);

  // Constants
  const chartData = formatOutstandingPaymentsChart(data);

  return (
    <Widget loading={loading}>
      <b className="d-block text-16">
        Outstanding Payments ({num?.toLocaleString()})
      </b>
      <PieChart
        chartData={chartData}
        options={{
          legend: { position: "right" },
          onClick: (_, ele) => {
            if (ele?.[0]) {
              setShowRelevantPaymentsState({
                isOpen: true,
                data: { paymentsIDs: chartData?.ids?.[ele[0]?._index] },
              });
            }
          },
          tooltips: {
            callbacks: {
              label: () => "",
              footer: () => "Click to see Payments",
            },
          },
        }}
      />
      {showRelevantPaymentsState?.isOpen ? (
        <ViewRelevantPayments
          data={showRelevantPaymentsState?.data}
          onClose={() => setShowRelevantPaymentsState(modalInitState)}
        />
      ) : null}
    </Widget>
  );
};

/*
  Utils
*/

const getData = ({ data, view, forecasted }) => {
  let d = data?.monthly || [];

  // Annually
  if (view === "annually" && !forecasted) d = data?.yearly?.current;
  if (view === "annually" && forecasted) d = data?.yearly?.forecasted;

  // Quarterly
  if (view === "quarterly" && !forecasted) d = data?.quarterly?.current;
  if (view === "quarterly" && forecasted) d = data?.quarterly?.forecasted;

  return d;
};

const Widget = ({ loading, children, classes, style }) => (
  <div className={clsx("card overflow-hidden", classes)} style={style}>
    {loading ? (
      <Skeleton width="50vw" height={200} variant="rectangular" />
    ) : (
      <div className="card-body">{children}</div>
    )}
  </div>
);

const ToggleClientsUsers = ({ isUsers, setIsUsers }) => {
  const handleViewChange = (_, view) => {
    setIsUsers(view);
  };

  return (
    <ToggleButtonGroup
      exclusive
      size="small"
      color="primary"
      value={isUsers}
      onChange={handleViewChange}
    >
      <ToggleButton value={false}>Clients</ToggleButton>
      <ToggleButton value={true}>Users</ToggleButton>
    </ToggleButtonGroup>
  );
};
