import axios from "axios";
import { Config } from "../Config";

// Store.subscribe(listener)
// function select(state) {
//   return state.auth.token
// }

// export const  listener=()=> {
//   let token = select(Store.getState())
//   userApiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
// }
function createAxiosAuthMiddleware() {
  return ({ getState }) =>
    (next) =>
    (action) => {
      const { token } = getState().auth;
      userApiClient.defaults.headers.common["Authorization"] = token
        ? `Bearer ${token}`
        : Config.token;

      return next(action);
    };
}

const axiosAuth = createAxiosAuthMiddleware();

export default axiosAuth;

export const userApiClient = axios.create({
  /**
   * Import the config from the App/Config/index.js file
   */
  baseURL: Config.API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    // Authorization: Config.token,
    // "Access-Control-Allow-Origin": "*",
    // 'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
  // timeout: 5000,
});

// userApiClient.interceptors.response.use(function (response) {
//   return response;
// }, function (error) {
//   if (401 === error.response.status) {
//     Store.dispatch(logoutAction())
//   } else {
//       return Promise.reject(error);
//   }
// });

// Attendance profile requests starts here
export const initProfile = () => {
  return userApiClient.get("/api/att_profiles/init");
};
export const initMultiAttProfile = () => {
  return axios.all([
    userApiClient.get("/api/att_profiles/init"),
    userApiClient.get("/api/att_profiles/init"),
    userApiClient.get("/api/att_profiles/init"),
  ]);
};

export const editAttProfile = (id) => {
  return userApiClient.get("/api/att_profiles/edit", {
    params: {
      id,
    },
  });
};

export const saveAttProfile = (att_profiles) => {
  return userApiClient.post("/api/att_profiles/save", {
    ...att_profiles,
    update: "Save",
  });
};
// Attendance profile requests ends here

// Employee form requests starts here
export const initEmployees = () => {
  return userApiClient.get("/api/employees/init");
};

export const editEmployees = (id) => {
  return userApiClient.get("/api/employees/edit", {
    params: {
      id,
    },
  });
};

export const saveEmployees = (employees) => {
  return userApiClient.post("/api/employees/save", {
    ...employees,
    update: "Save",
  });
};
// Employee form requests ends here

export const authUser = ({ user }) => {
  
  return userApiClient.post("/oauth/token", {
    grant_type: "password",
    client_id: Config.Client_Id,
    client_secret: Config.Client_Secret,
    username: user.email,
    password: user.password,
    device_token: "asddddddddddd",
    device_type: "web",
  });
};

export const fetchProfile = () => {
  return userApiClient.get("/api/profile");
};

export const fetchAttendanceRecords = ({ month, year }) => {
  return userApiClient.get("/api/sign_in_out/history", {
    params: {
      month,
      year,
    },
  });
};

export const createSignIn = () => {
  return userApiClient.post("/api/sign_in_out/sign_in", {}, { timeout: 15000 });
};

export const dismissHomeSignIn = () => {
  return userApiClient.post("/api/sign_in_out/dismiss_work_from_home");
};

export const createSignOut = (force_confirm) => {
  return userApiClient.post("/api/sign_in_out/sign_out", {
    force_confirm,
  });
};

export const fetchUserStatus = () => {
  return userApiClient.post("/api/sign_in_out/check_sign_in_out_status");
};

// employee fetch Own Subimitted Requests

export const fetchAllRequests = ({ month, year }) => {
  
  return userApiClient.get("/api/requests", {
    params: {
      month,
      year,
    },
  });
};

// common employees requests API

export const cancelUserRequest = (req_id) => {
  return userApiClient.post("/api/requests/cancel", {
    req_id,
  });
};

export const createPermission = (permission) => {
  
  return userApiClient.post("/api/requests/permission", {
    permission_from: permission.from,
    permission_to: permission.to,
  });
};

export const createNormalLeave = (normal) => {
  
  return userApiClient.post("/api/requests/normal", {
    normal_from: normal.from,
    normal_to: normal.to,
  });
};

export const createEmergencyLeave = (emergency) => {
  
  return userApiClient.post("/api/requests/emergency", {
    emergency_from: emergency.from,
    emergency_to: emergency.to,
  });
};

export const createHalfDayLeave = (halfDay) => {
  return userApiClient.post("/api/requests/half_day", {
    half_day: halfDay.from,
    half: halfDay.to,
    leave_type: halfDay.type,
  });
};

export const createFlexHome = (homeReq) => {
  return userApiClient.post("/api/requests/flex_home", {
    home_day: homeReq.from,
  });
};

export const createExchangeHome = (homeReq) => {
  return userApiClient.post("/api/requests/exchange_home", {
    home_day: homeReq.from,
    instead: homeReq.to,
  });
};

// employee fetch Own Penalties

export const fetchUserPenalties = (filter) => {
  return userApiClient.get("/api/penalties", {
    params: {
      month: filter.monthKey,
      year: filter.year,
    },
  });
};

// employee Update Profile Image API

export const updateProfileImage = (image) => {
  return userApiClient.post("/api/upload_profile_img", {
    img_base64: image.data,
    img_extension: image.ext,
  });
};

export const removeProfileImage = () => {
  return userApiClient.post("/api/remove_profile_img");
};

// Fetch Office Time

export const fetchOfficeTime = () => {
  return userApiClient.get("/api/time");
};

// NON EMPLOYEE API SERVICES

export const fetchEmployees = () => {
  return userApiClient.get("/api/employees");
};

export const fetchInboundRequests = ({ monthKey, year }) => {
  return userApiClient.get("/api/requests/inbound", {
    params: {
      month: monthKey,
      year: year,
    },
  });
};

export const fetchEmployeesNames = () => {
  return userApiClient.get("/api/employees_names");
};

export const fetchEmployeesPenalties = ({ filter, user_id }) => {
  
  if (typeof user_id !== undefined) {
    return userApiClient.get("/api/penalties/children_penalties", {
      params: {
        month: filter.monthKey,
        year: filter.year,
        user_id,
      },
    });
  }
  return userApiClient.get("/api/penalties/children_penalties", {
    params: {
      month: filter.monthKey,
      year: filter.year,
    },
  });
};

export const fetchEmployeeProfile = (user_id) => {
  return userApiClient.get("/api/profile", {
    params: {
      user_id,
    },
  });
};

export const fetchEmployeeAttendanceRecords = ({ filter, user_id }) => {
  return userApiClient.get("/api/sign_in_out/history", {
    params: {
      month: filter.monthKey,
      year: filter.year,
      user_id,
    },
  });
};

export const acceptInboundRequest = (req_id) => {
  return userApiClient.post("/api/requests/accept", {
    req_id,
  });
};

export const rejectInboundRequest = (req_id) => {
  return userApiClient.post("/api/requests/reject", {
    req_id,
  });
};
