import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// utils
import { normalizeSumbittedLeavesBreaks } from "../../../Helpers/HelperFns/normalizeLeavesBreaks";
import {
  EditSetupWizardEssentialData,
  setLeavesBreaksServerValidation,
  showErrorToast,
  showSuccessToast,
} from "../../../Store/Actions";

// apollo
import { useMutation } from "@apollo/client";
import { upsertLeavesBreaksSettingMutation } from "../../../Graphql/mutation";

// ui
import { Spinner } from "reactstrap";
import swal from "sweetalert";

const FooterSection = ({
  currentStep,
  setCurrentStep,
  setIsSubmitting,
  setIsModalOpen,
  fetchLeavesBreaks,
  isSetupWizard,
}) => {
  const { t } = useTranslation();

  const [upsertLeavesBreaksSetting, { loading }] = useMutation(
    upsertLeavesBreaksSettingMutation
  );

  // redux hooks
  const dispatch = useDispatch();
  const leavesBreaksForm = useSelector((state) => state.super.leavesBreaksForm);
  const leavesBreaksFormValidation = useSelector(
    (state) => state.super.leavesBreaksFormValidation
  );

  const firstStepFields = [
    "input.name",
    "input.annual_leave_setting.allowed_annual_leaves",
    "input.annual_leave_setting.annual_leave_cuttoff",
    "input.annual_leave_setting.allowed_emergency_leaves",
    "input.annual_leave_setting.include_weekends_and_holidays_in_annual",
    "input.annual_leave_setting.leave_basis",
    "input.annual_leave_setting.deduct_leaves_against_unpaid_leaves",
    "input.annual_leave_setting.deduct_leaves_against_paid_leaves",
    "input.annual_leave_setting.deduct_leaves_against_absent_days",
    "input.probation_period.request_leaves",
    "input.settle_leaves.remaining_balance_option_id",
    "input.settle_leaves.rollover_balance_automatic",
    "input.settle_leaves.max_number_of_leaves_to_be_rolled",
    "input.settle_leaves.next_year",
    "input.settle_leaves.payable_at",
    "input.sick_leave_setting.sick_leaves",
    "input.sick_leave_setting.allowed_sick_leaves",
    "input.sick_leave_setting.include_weekends_and_holidays_in_sick",
    "input.unpaid_leave_setting.unpaid_leaves",
    "input.unpaid_leave_setting.include_weekends_and_holidays_in_unpaid",
    "input.sick_leave_setting.deduction_amount",
    "input.sick_leave_setting.deduction_type",
    "input.sick_leave_setting.negative_balance_action",
    "input.day_off_hours_count",
    "input.holiday_hours_count",
    "input.annual_leave_setting.deserved_against",
    "input.annual_leave_setting.total_weighted_hours",
    "input.sick_leave_setting",
    "input.annual_leave_setting",
    "input.unpaid_leave_setting",
    "input.settle_leaves",
    "input.probation_period",
  ];

  const redirectToErrorStep = (errors) => {
    const isFirstStepError = Object.keys(errors).some((key) =>
      firstStepFields.includes(key)
    );

    if (isFirstStepError) {
      return setCurrentStep(1);
    }

    // stay on step 2
    return false;
  };

  const submitLeavesBreaksData = async () => {
    setIsSubmitting(true);

    if (leavesBreaksFormValidation.length === 0) {
      dispatch(setLeavesBreaksServerValidation({}));
      let next_year = false;
      if (leavesBreaksForm?.id) {
        next_year = await swal({
          title: t("are you sure"),
          text: t("Adjust employee balance starting from"),
          icon: "warning",
          className: "swal-warning-style",
          dangerMode: true,
          buttons: {
            next: { text: t("Next Year"), value: true, className: "btn-info" },
            year: {
              text: t("This Year"),
              value: false,
              className: "btn-danger",
            },
            cancel: { text: t("cancel"), closeModal: true, value: "cancel" },
          },
          closeOnClickOutside: true,
        });
      }
      if (next_year === "cancel") {
        return;
      }

      const response = await upsertLeavesBreaksSetting({
        variables: {
          input: normalizeSumbittedLeavesBreaks({
            ...leavesBreaksForm,
            recalc_next_year: next_year,
          }),
        },
      });

      if (!loading && !response?.errors) {
        if (isSetupWizard) {
          dispatch(EditSetupWizardEssentialData("company_leaves_breaks"));
        } else {
          setIsModalOpen(false);

          fetchLeavesBreaks();
          dispatch(showSuccessToast());
        }
      }

      if (response?.errors?.[0]?.extensions?.category === "validation") {
        dispatch(
          setLeavesBreaksServerValidation(
            response?.errors?.[0]?.extensions?.validation
          )
        );

        redirectToErrorStep(response?.errors?.[0]?.extensions?.validation);
      }

      if (response?.errors?.[0].extensions?.category === "graphql") {
        dispatch(showErrorToast(response?.errors?.[0]?.message));
      }
      if (response?.errors?.[0]?.extensions?.category === "GraphQLException") {
        dispatch(showErrorToast(response?.errors?.[0]?.extensions?.reason));
      }
    }
  };

  const firstBtn = {
    text: currentStep === 2 ? t("previous") : null,
    isDisabled: loading,
    onClick: () => {
      setCurrentStep(1);
    },
  };

  const secondBtn = {
    text:
      loading && currentStep === 2 ? (
        <Spinner style={{ width: "1rem", height: "1rem", color: "#fff" }} />
      ) : currentStep === 1 ? (
        t("next")
      ) : (
        t("save")
      ),
    isDisabled: loading,
    onClick: () => {
      if (currentStep === 1) {
        setIsSubmitting(true);

        if (leavesBreaksFormValidation.length === 0) {
          setIsSubmitting(false);
          setCurrentStep(2);
        }
      } else {
        submitLeavesBreaksData();
      }
    },
  };

  return (
    <div className="footer_component">
      {firstBtn.text ? (
        <button
          className="footer_component_btn-primary"
          onClick={firstBtn.onClick}
          disabled={firstBtn.isDisabled}
        >
          {firstBtn.text}
        </button>
      ) : null}
      <button
        className="footer_component_btn-secondary"
        onClick={secondBtn.onClick}
        disabled={secondBtn.isDisabled}
      >
        {secondBtn.text}
      </button>
    </div>
  );
};

export default FooterSection;
