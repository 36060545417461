import React from "react";

import Privileges from "../../../Constants/Privilages";

import {
  Dashboard,
  TaxesList,
  CompaniesList,
  AdminUsersList,
  AnnouncementsList,
  AdminHolidaysList,
  DeletedSignInsList,
  FailedSignInsList,
  AccountManagersList,
  ManualVerificationsList,
} from "../../../Containers/AdminPanelLists";
import {
  faUser,
  faBuilding,
  faPlaneDeparture,
} from "@fortawesome/free-solid-svg-icons";
import {
  Payments,
  Dangerous,
  SupportAgent,
  DeleteForever,
  LocalActivity,
  Notifications,
  Dashboard as DashboardIcon,
} from "@mui/icons-material";
import {
  CompanyRemindersList,
  CompanyActivitiesList,
} from "../../../Components/AdminPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import { SubscriptionRequestsList } from "../../../Containers/CRMLists";
import { PaymentsList } from "../../../Containers/PaymentTrackingLists";

const adminTabs = [
  {
    label: "Companies",
    icon: <FontAwesomeIcon icon={faBuilding} />,
    value: "companies",
    privileges: [Privileges.VIEW_COMPANIES, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <CompaniesList />,
  },
  {
    label: "Admin Users",
    icon: <FontAwesomeIcon icon={faUser} />,
    value: "admin-users",
    privileges: [Privileges.VIEW_ADMIN_USERS, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <AdminUsersList />,
  },
  {
    label: "Holidays",
    icon: <FontAwesomeIcon icon={faPlaneDeparture} />,
    value: "holidays",
    privileges: [Privileges.VIEW_ADMIN_HOLIDAYS, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <AdminHolidaysList />,
  },
  {
    label: "Taxes",
    icon: <FontAwesomeIcon icon={faFileLines} />,
    value: "taxes",
    privileges: [Privileges.VIEW_TAX_INFO, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <TaxesList />,
  },
  {
    label: "Announcements",
    icon: <FontAwesomeIcon icon={faFileLines} />,
    value: "announcements",
    privileges: [
      Privileges.VIEW_ADMIN_ANNOUNCEMENT,
      Privileges.SUPER_PRIVILEGE,
    ],
    allowBP: false,
    panel: <AnnouncementsList isAdminPanel />,
  },
  {
    label: "Manual Verification",
    icon: <FontAwesomeIcon icon={faUser} />,
    value: "manual-verification",
    privileges: [
      Privileges.ADMIN_MANUAL_VERIFY_EMPLOYEE,
      Privileges.SUPER_PRIVILEGE,
    ],
    allowBP: false,
    panel: <ManualVerificationsList />,
  },
  {
    label: "deleted sign ins",
    icon: <DeleteForever fontSize="small" />,
    value: "deleted-sign-ins",
    privileges: [Privileges.VIEW_COMPANY_LOGS, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <DeletedSignInsList />,
  },
  {
    label: "failed sign ins",
    icon: <Dangerous fontSize="small" />,
    value: "failed-sign-ins",
    privileges: [
      Privileges.VIEW_FAILED_SIGN_IN_OUT_ATTEMPTS,
      Privileges.SUPER_PRIVILEGE,
    ],
    allowBP: false,
    panel: <FailedSignInsList />,
  },
  {
    label: "Dashboard",
    icon: <DashboardIcon fontSize="small" />,
    value: "dashboard",
    privileges: [
      Privileges.VIEW_COMPANY_ACTIVITIES,
      Privileges.VIEW_COMPANY_REMINDERS,
      Privileges.VIEW_COMPANY_MEETING,
      Privileges.SUPER_PRIVILEGE,
    ],
    allowBP: false,
    panel: <Dashboard />,
  },
  {
    label: "Activities",
    icon: <LocalActivity fontSize="small" />,
    value: "activities",
    privileges: [
      Privileges.VIEW_COMPANY_ACTIVITIES,
      Privileges.SUPER_PRIVILEGE,
    ],
    allowBP: false,
    panel: <CompanyActivitiesList list />,
  },
  {
    label: "Reminders",
    icon: <Notifications fontSize="small" />,
    value: "reminders",
    privileges: [Privileges.VIEW_COMPANY_REMINDERS, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <CompanyRemindersList list />,
  },
  {
    label: "Account Managers",
    icon: <SupportAgent fontSize="small" />,
    value: "agents",
    privileges: [Privileges.VIEW_COMPANY_SAAS, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <AccountManagersList />,
  },
  {
    label: "Follow-Up Subscriptions",
    icon: <Payments fontSize="small" />,
    value: "subscriptions",
    privileges: [
      Privileges.VIEW_FOLLOW_UP_SUBSCRIPTION,
      Privileges.SUPER_PRIVILEGE,
    ],
    allowBP: false,
    panel: <SubscriptionRequestsList isFollowUp />,
  },
  {
    label: "Follow-Up Payments",
    icon: <Payments fontSize="small" />,
    value: "payments",
    privileges: [Privileges.VIEW_FOLLOW_UP_PAYMENT, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <PaymentsList isFollowUp />,
  },
];

export default adminTabs;
