import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import Privileges from "../../Constants/Privilages";
import { onFormResetAction, showToast } from "../../Store/Actions";
import HelperFns, { openSwalConfirm } from "../../Helpers/HelperFns";
import { paymentMethodsListQuery } from "../../Graphql/query/PaymentTracking";
import { removePaymentMethodMutation } from "../../Graphql/mutation/PaymentTracking";
import { serializeFetchPaymentMethod } from "../../Helpers/HelperFns/PaymentTracking";

import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import { AddButton } from "../../Components/Buttons";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { UpsertPaymentMethodModal } from "../../Components/PaymentTrackingModals";

const reducer = "paymentTracking";
const filtersFormName = "paymentMethodsListFilters";
const FiltersFormProps = { reducer, formName: filtersFormName };
const modalInitState = { isOpen: false, data: null };
const modals = [{ name: "upsert", Modal: UpsertPaymentMethodModal }];
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const PaymentMethodsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [data, setData] = React.useState([]);
  const [pagination, setPagination] = React.useState(paginationInitState);
  const [modalsState, setModalsState] = React.useState(
    modals.map(({ name }) => ({ name, ...modalInitState }))
  );

  // Reducer State
  const filters = useSelector((state) => state?.[reducer]?.[filtersFormName]);
  const options = useSelector(
    (state) => state?.[reducer]?.paymentTrackingOptions
  );

  // Server State
  const [removePaymentMethod, { loading: removePaymentMethodLoading }] =
    useMutation(removePaymentMethodMutation);
  const { loading, refetch } = useQuery(paymentMethodsListQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      first: pagination?.perPage,
      page: pagination?.currentPage,
    },
    onCompleted: ({ paymentMethods }) => {
      setData(paymentMethods?.data?.map(serializeFetchPaymentMethod));
      setPagination(paymentMethods?.pagination || paginationInitState);
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  // Constants
  const columns = React.useMemo(
    () => [
      { name: t("name"), selector: "name", grow: 2 },
      {
        grow: 5,
        name: t("details"),
        cell: (row) => (
          <div>
            {row?.paymentMethod === "Bank" ? (
              <>
                <div className="">Bank Name: {row?.bankName}</div>
                <div className="">Account Number: {row?.accountNumber}</div>
                <div className="">IBAN: {row?.IBAN}</div>
              </>
            ) : null}
            {row?.paymentMethod === "Wallet" ? (
              <>
                <div className="">Wallet Name: {row?.wallet}</div>
                <div className="">Phone Number: {row?.phoneNumber}</div>
              </>
            ) : null}
            {row?.paymentMethod === "Prepaid" ? (
              <>
                <div className="">Card Name: {row?.card}</div>
                <div className="">Card Number: {row?.cardNumber}</div>
              </>
            ) : null}
          </div>
        ),
      },
      {
        cell: (row) => (
          <>
            <HasPrivileges
              reqireMain={[
                Privileges.SUPER_PRIVILEGE,
                Privileges.ADD_EDIT_PAYMENT_PLANS,
              ]}
            >
              <EditIconButton onClick={() => handleEdit(row)} />
            </HasPrivileges>

            <HasPrivileges
              reqireMain={[
                Privileges.SUPER_PRIVILEGE,
                Privileges.DELETE_PAYMENT_PLANS,
              ]}
            >
              <RemoveIconButton onClick={() => handleRemove(row?.id)} />
            </HasPrivileges>
          </>
        ),
      },
    ],
    []
  );

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    return () => {
      dispatch(onFormResetAction(filtersFormName));
    };
  }, []);

  /* ↓ Helpers ↓ */

  const handleOpenModal = (name, data = null) => {
    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? { name, data, isOpen: true } : p))
    );
  };

  const handleCloseModal = (name) => {
    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? { name, ...modalInitState } : p))
    );
  };

  const handleEdit = (data) => {
    handleOpenModal("upsert", data);
  };

  const handleRemove = (id) => {
    const remove = (confirm) => {
      removePaymentMethod({
        variables: { id: +id, confirm },
        onCompleted: () => {
          refetch();
          showToast("success");
        },
        onError: (err) => {
          if (err?.graphQLErrors?.[0]?.extensions?.reason) {
            const msg =
              "This payment method is taken. that will delete it from all companies. Do you want to proceed?";
            return openSwalConfirm((isConfirmed) => isConfirmed && remove(1), {
              text: msg,
            });
          }
          showToast(
            "error",
            err?.graphQLErrors?.[0]?.extensions?.reason ||
              err?.graphQLErrors?.[0]?.message ||
              err?.message
          );
        },
      });
    };

    openSwalConfirm((confirmed) => confirmed && remove(0));
  };

  const handlePaginate = (page = pagination.currentPage) => {
    refetch({ page });
  };

  return (
    <>
      {removePaymentMethodLoading ? <Loader fixed /> : null}

      {/* Actions */}
      <div className="d-flex justify-content-end mt-2 mb-3">
        <HasPrivileges
          reqireMain={[
            Privileges.ADD_EDIT_PAYMENT_PLANS,
            Privileges.SUPER_PRIVILEGE,
          ]}
        >
          <AddButton onClick={() => handleOpenModal("upsert")} />
        </HasPrivileges>
      </div>

      {/* Table */}
      <DataTable
        noHeader
        data={data}
        columns={columns}
        className="cards_table"
        progressPending={loading}
        progressComponent={<Loader inner />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {/* Modals */}
      {modals.map(({ name, Modal }, i) =>
        modalsState[i].isOpen ? (
          <Modal
            key={name}
            refetchList={refetch}
            data={modalsState[i]?.data}
            onClose={() => handleCloseModal(name)}
          />
        ) : null
      )}
    </>
  );
};

export default PaymentMethodsList;
