import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { Section, Unit } from "./SectionBase";

const SignInSection = (props) => {
  const { t } = useTranslation();

  return (
    <Section
      title="sign in"
      units={[
        <Unit
          label="Min. Sign In Time"
          val={moment(props?.sign_in_start_time, "HH:mm").format("hh:mm A")}
        />,
        <Unit
          label="Max. Sign In Time"
          val={moment(props?.sign_in_end_time, "HH:mm").format("hh:mm A")}
        />,
        <Unit label="work hours" val={props?.work_hours + " " + t("Hours")} />,
        <Unit
          label="can sign in as early as"
          val={moment(props?.time_allowed_before_sign_in, "HH:mm").format(
            "hh:mm A"
          )}
        />,
        <Unit
          label="cannot sign out before"
          isHidden={!Boolean(props?.can_not_sign_out_before)}
          val={moment(props?.can_not_sign_out_before, "HH:mm").format(
            "hh:mm A"
          )}
        />,
        <Unit
          label="cannot sign in after"
          isHidden={!Boolean(props?.can_not_sign_in_after)}
          val={moment(props?.can_not_sign_in_after, "HH:mm").format("hh:mm A")}
        />,
        <Unit
          label="cannot sign out after"
          val={moment(props?.end_of_day, "HH:mm").format("hh:mm A")}
        />,
      ]}
    />
  );
};

export default SignInSection;
