import React from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { showToast } from "../../Helpers/HelperFns";
import { onFormResetAction } from "../../Store/Actions";
import { serializeEditInvoice } from "../../Helpers/HelperFns/PaymentTracking";

import MainModal from "../MainModal";
import { AddButton } from "../Buttons";
import PaymentInfo from "../PaymentTracking/PaymentInfo";
import { RemoveIconButton } from "../IconButtonWithTooltip";
import { InputForm, DateTimePickerForm } from "form-builder";
import { editInvoiceMutation } from "../../Graphql/mutation/PaymentTracking";

const reducer = "paymentTracking";
const formName = "editInvoiceModal";
const formNameValidation = "paymentTrackingClientValidation";
const formServerValidation = "paymentTrackingServerValidation";
const invoiceEntryInitState = { name: "", amount: 0, userId: true };
// const TAX_PERCENTAGE = 14;

const EditInvoiceModal = ({ data, onClose, refetchList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [errMsg, setErrMsg] = React.useState("");
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [invoiceEntries, setInvoiceEntries] = React.useState([
    invoiceEntryInitState,
  ]);

  // Reducer State
  const formData = useSelector((state) => state?.[reducer]?.[formName]);
  // const options = useSelector(
  //   (state) => state?.[reducer]?.paymentTrackingOptions
  // );

  // Server State
  const [editInvoice, { loading }] = useMutation(editInvoiceMutation);

  // Constants
  const FormProps = {
    reducer,
    formName,
    formSubmitting,
    formNameValidation,
    formServerValidation,
  };
  // const totalItems = invoiceEntries.reduce(
  //   (acc, curr) => acc + parseFloat(curr?.amount),
  //   0
  // );
  // const taxItems = (totalItems * TAX_PERCENTAGE) / 100;

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    dispatch(
      onFormResetAction(formName, {
        ...formData,
        id: data?.id,
        dueDate: data?.invoiceInfo?.dueDate,
        methodId: data?.invoiceInfo?.methodId,
        paymentTerms: data?.invoiceInfo?.terms,
      })
    );

    setInvoiceEntries(
      data?.invoiceInfo?.items?.filter(
        (item) => !["tax (14%)", "total"].includes(item?.name.toLowerCase())
      )
    );
  }, []);

  /* ↓ Helpers ↓ */

  const handleInvoiceEntriesChange = (e, idx) => {
    if (e.target.name === "amount" && isNaN(e.target.value)) return;
    setInvoiceEntries((prev) =>
      prev.map((p, i) =>
        idx === i ? { ...p, [e.target.name]: e.target.value } : p
      )
    );
  };

  const handleAddInvoiceEntry = () => {
    setInvoiceEntries((prev) => [...prev, invoiceEntryInitState]);
  };

  const handleRemoveInvoiceEntry = (idx) => {
    setInvoiceEntries((prev) => prev.filter((_, i) => idx !== i));
  };

  const handleCloseModal = () => {
    onClose();
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formNameValidation));
    dispatch(onFormResetAction(formServerValidation));
  };

  const handleSubmit = () => {
    setFormSubmitting(true);

    editInvoice({
      variables: serializeEditInvoice({ ...formData, items: invoiceEntries }),
      onCompleted: () => {
        refetchList();
        handleCloseModal();
        showToast("success");
      },
      onError: (err) => {
        const validation = err?.graphQLErrors?.[0]?.extensions?.validation;
        dispatch(onFormResetAction(formServerValidation, validation || {}));

        const msg =
          validation?.json?.[0] ||
          err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message;
        setErrMsg(msg || "");
      },
    });
  };

  return (
    <MainModal
      isOpen
      size="lg"
      modalTitle="Edit Invoice"
      toggle={handleCloseModal}
      btnOnClick={handleSubmit}
      btnSubmitLoading={loading}
    >
      <div
        className="d-flex justify-content-between mb-3 mb-2 border p-2"
        style={{ borderRadius: 4 }}
      >
        <div className="flex-1">
          <b className="dark-color d-block mb-2">{t("Billed For")}</b>
          {data?.invoiceInfo?.for}
        </div>
        <div className="flex-1">
          <b className="dark-color d-block mb-2">{t("Billed To")}</b>
          {data?.invoiceInfo?.to}
        </div>
      </div>

      <PaymentInfo FormProps={FormProps} />

      <DateTimePickerForm
        {...FormProps}
        name="dueDate"
        label="Due Date"
        hasIcon
        containerStyle="w-100"
        rootStyle="d-inline-block w-50 pr-2 mt-2"
        validateBy="textRequired"
        validationName="due_date"
      />

      {/* Invoice Entries */}
      <div className="d-flex gap-10 align-items-center mt-2">
        <b>{t("Invoice Entries")}</b>
        <AddButton isIconOnly onClick={handleAddInvoiceEntry} />
      </div>
      {invoiceEntries.map((entry, idx) =>
        !entry?.userId ? (
          <div
            key={idx}
            style={{ borderRadius: 4 }}
            className="d-flex align-items-center justify-content-between border p-2 mt-2"
          >
            {entry?.name?.replaceAll("\\n", ", ")}
            <b>{entry?.amount}</b>
          </div>
        ) : (
          <div
            key={idx}
            style={{ borderRadius: 4 }}
            className="d-flex gap-20 justify-content-between border p-2 mt-2"
          >
            <InputForm
              name="name"
              placeholder="New Entry"
              value={entry.name}
              rootStyle="flex-1"
              containerStyle="w-100"
              validateBy="textRequired"
              onChange={(e) => handleInvoiceEntriesChange(e, idx)}
            />
            <div className="d-flex gap-5">
              <InputForm
                {...FormProps}
                name="amount"
                type="number"
                placeholder="amount"
                value={entry.amount}
                hasSuffix
                suffixTitle="EGP"
                rootStyle="flex-1"
                containerStyle="w-100"
                validateBy="textRequired"
                onChange={(e) => handleInvoiceEntriesChange(e, idx)}
              />
              <RemoveIconButton
                disabled={!idx}
                sx={{ opacity: idx ? undefined : "0 !important" }}
                onClick={() => handleRemoveInvoiceEntry(idx)}
              />
            </div>
          </div>
        )
      )}

      {errMsg ? (
        <p role="alert" className="red-color mt-2 mb-0">
          {t(errMsg)}
        </p>
      ) : null}
    </MainModal>
  );
};

export default EditInvoiceModal;
