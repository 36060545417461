import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { InputForm, DateTimePickerForm } from "form-builder";
import { connect } from "react-redux";
import {
  dismissClaimRequestModalAction,
  upsertEmployeeClaimsRequestAction,
  onInputChangeAction,
} from "../../Store/Actions";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

const formName = "employeeClaimRequestModal";
const formNameValidation = "employeeClaimRequestModalValidation";
const formServerValidation = "employeeClaimRequestFormServerValidation";

const EditClaimRequestModal = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [textAreaValidation, setTextAreaValidation] = useState("");

  const dangerTextArea = isSubmitting && textAreaValidation;

  const isModalVissible = props.modalData.isVissible;
  const modalMessage = props.modalData.modalMessage;
  const isLoading = props.modalData.isLoading;

  const toggleModal = () => {
    props.dismissClaimRequestModalAction({
      ref: "employeeClaimRequestModalActions",
      formName,
    });
  };

  const handleSubmitBtn = () => {
    setIsSubmitting(true);

    // Return And Validate On TextArea
    if (!Boolean(props?.employeeClaimRequestModal?.reason)) {
      return setTextAreaValidation("This Field Is Required");
    }

    // to send file and extenstion only without id key in the array
    let attachments = claimRequestFiles.map(({ file, extension }) => ({
      file,
      extension,
    }));

    if (!props.modalValidation.length) {
      const { name, ...requestData } = props.employeeClaimRequestModal;
      // Your Request
      props.upsertEmployeeClaimsRequestAction({
        ref: "employeeClaimRequestModalActions",
        formName,
        data: { ...requestData, attachments },
      });
    }
  };

  // initail vlaue for claimRequestFiles set to empty array
  const [claimRequestFiles, setClaimRequestFiles] = useState([]);

  // function to add object to claimRequestFiles array
  const addClaimRequestFileObject = (claimFile) => {
    setClaimRequestFiles((claimRequestFiles) => [
      ...claimRequestFiles,
      claimFile,
    ]);
  };

  // function to remove an object from an claimRequestFiles array
  const removeClaimRequestFileObject = (id) => {
    setClaimRequestFiles((claimRequestFiles) =>
      claimRequestFiles.filter((claimFile) => claimFile?.id !== id)
    );
  };

  // handle request image change
  const handleImageChange = ({ file, meta }, status) => {
    

    // to convert image to base64 string
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      let base64 = reader.result.split(",");
      let fileType = file?.type.split("/");

      if (status == "done") {
        let target = {
          file: base64[1],
          extension: fileType[1],
          id: meta?.id,
        };
        addClaimRequestFileObject(target);
      }
    };

    if (status == "removed") {
      removeClaimRequestFileObject(meta?.id);
    }
    return;
  };

  // handle remove image
  const handleRemoveImage = ({ meta }) => {
    removeClaimRequestFileObject(meta?.id);
  };

  // handel text area change
  const handleTextArea = (e) => {
    // To Handle Auto Error Message If Text Is Empty
    if (!Boolean(e.target.value) && isSubmitting) {
      setTextAreaValidation("This Field Is Required");
    } else {
      setTextAreaValidation("");
    }
    props.onInputChangeAction(formName, e);
  };

  useEffect(() => {
    setIsSubmitting(false);
  }, [isModalVissible]);

  return (
    <MainModal
      isOpen={isModalVissible}
      btnLabelCancel="Cancel"
      btnOnCancelClick={toggleModal}
      modalTitle={`Edit claim request for ${props?.employeeClaimRequestModal?.name}`}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
      className="add_claim_request_modal_wrapper_style"
    >
      <div>
        <div className="row">
          <div className="col-12">
            <DateTimePickerForm
              name="incurred_at"
              labelStyle="mb-2"
              formName={formName}
              formNameValidation={formNameValidation}
              validationName={`input.incurred_at`}
              formServerValidation={formServerValidation}
              formSubmitting={isSubmitting}
              containerStyle="mb-2"
              datePickerContainer="w-100"
              validateBy="textRequired"
              hasIcon
              requestFormat="yyyy-MM-D"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <InputForm
              name="amount"
              placeholder="Amount"
              formName={formName}
              formNameValidation={formNameValidation}
              validationName={`input.amount`}
              formServerValidation={formServerValidation}
              formSubmitting={isSubmitting}
              labelStyle="mb-2"
              containerStyle=""
              inputContainerStyle="w-100"
              type="number"
              validateBy="textRequired"
            />
          </div>
        </div>

        <div className="row my-2">
          <div
            className={`col-12 ${
              dangerTextArea ? "invalid-container-style" : ""
            }`}
          >
            <textarea
              rows="4"
              formName={formName}
              formSubmitting={isSubmitting}
              validationName={`input.reason`}
              formServerValidation={formServerValidation}
              name="reason"
              placeholder="State Reason"
              onChange={handleTextArea}
              value={props?.employeeClaimRequestModal?.reason}
            />
            {
              <span className="validity-msg-style">
                {dangerTextArea && textAreaValidation}
              </span>
            }
          </div>
        </div>

        {/* <div className="row">
          <div className="col-12">
            {Boolean(props?.attachments) ? (
              <div className="align-items-center justify-content-center">
                <p>{props?.attachments}</p>
                <button onClick={handleRemoveImage} className="mt-2 btn-danger">
                  remove
                </button>
              </div>
            ) : (
              <Dropzone
                onChangeStatus={handleImageChange}
                canRemove
                accept="image/*"
                name="attachments"
                multiple={true}
              />
            )}
          </div>
        </div> */}

        {/* (Start) Form server validation message */}
        <span className="warnig-msg-style">
          {props.serverVaildation[`input.description`] ?? " "}
        </span>
        {/* (End) Form server validation message */}

        {/* (Start) Error Message */}
        {modalMessage && isSubmitting && (
          <div className="warnig-msg-style">{modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.super.employeeClaimRequestModalActions,
  modalValidation: state.super[formNameValidation],
  employeeClaimRequestModal: state.super[formName],
  serverVaildation: state.super[formServerValidation],
});

export default connect(mapStateToProps, {
  dismissClaimRequestModalAction,
  upsertEmployeeClaimsRequestAction,
  onInputChangeAction,
})(EditClaimRequestModal);
