import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BSelect, CheckboxBooleanForm, InputForm } from "form-builder";

const RequestLimitConfig = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <strong className="d-block mt-4 mb-2 blue-color">
        {t("Request Limits Configuration")}
      </strong>
      <CheckboxBooleanForm
        {...props?.FormProps}
        name="has_request_dayoff_limit"
        options={[t("Day off request limit")]}
        optionLabelStyle={""}
        type="checkbox"
      />
      <div className="align-items-end px-0 row">
        <InputForm
          rootStyle="col-md-6"
          {...props?.FormProps}
          label={t("Max. Number of requests")}
          hasMinMax
          minValue={0}
          maxValue={Infinity}
          name="dayoff_request_limits"
          placeholder="Max. Number of requests"
          dependOn="has_request_dayoff_limit"
          dependancyType="equal"
          dependancyValue={[1]}
          stepInput
          labelStyle="mb-2"
          inputContainerStyle="input-container-style-default max-days-style"
          validationName="input.request_configuration_settings.request_limits"
          type="number"
          validateBy="number_req_non_zero_or_negative"
        />

        <BSelect
          rootStyle="col-md-6"
          {...props?.FormProps}
          label={t("reset every")}
          name="dayoff_period_type"
          keepDefaultStyle
          dependOn="has_request_dayoff_limit"
          dependancyType="equal"
          dependancyValue={[1]}
          placeholder={t("select option")}
          optionLabel="label"
          optionValue="label"
          options={[{ label: "week" }, { label: "month" }, { label: "year" }]}
          getOptionLabel={(option) => t(option.label)}
          containerStyle="row justify-content-between align-items-center"
          labelStyle="col-6"
          inputContainerStyle="col-6"
          validationName="input.user_input.home_days"
        />
      </div>

      <div className="d-flex align-items-center justify-content-between my-3">
        <CheckboxBooleanForm
          {...props?.FormProps}
          name="submit_dayoff_cutoff_time"
          options={[
            t(
              "Employee must submit dayoff request before the start of work day by"
            ),
          ]}
          optionLabelStyle={""}
          type="checkbox"
        />

        <InputForm
          {...props?.FormProps}
          name="dayoff_cutoff_time"
          inputContainerStyle="input-container-style-default max-days-style"
          type="number"
          containerStyle="d-flex align-items-center justify-content-between"
          hasSuffix
          suffixTitle="hours"
          dependOn="submit_dayoff_cutoff_time"
          dependancyType="equal"
          dependancyValue={[1]}
        />
      </div>

      <CheckboxBooleanForm
        {...props?.FormProps}
        name="has_request_change_shift_limit"
        options={[t("Change shift request limit")]}
        optionLabelStyle={""}
        type="checkbox"
      />
      <div className="align-items-end px-0 row">
        <InputForm
          rootStyle="col-md-6"
          {...props?.FormProps}
          label={t("Max. Number of requests")}
          hasMinMax
          minValue={0}
          maxValue={Infinity}
          name="change_shift_request_limits"
          placeholder="Max. Number of requests"
          dependOn="has_request_change_shift_limit"
          dependancyType="equal"
          dependancyValue={[1]}
          stepInput
          labelStyle="mb-2"
          inputContainerStyle="input-container-style-default max-days-style"
          validationName="input.request_configuration_settings.request_limits"
          type="number"
          validateBy="number_req_non_zero_or_negative"
        />

        <BSelect
          rootStyle="col-md-6"
          {...props?.FormProps}
          label={t("reset every")}
          name="change_shift_period_type"
          keepDefaultStyle
          dependOn="has_request_change_shift_limit"
          dependancyType="equal"
          dependancyValue={[1]}
          placeholder={t("select option")}
          optionLabel="label"
          optionValue="label"
          options={[{ label: "week" }, { label: "month" }, { label: "year" }]}
          getOptionLabel={(option) => t(option.label)}
          containerStyle="row justify-content-between align-items-center"
          labelStyle="col-6"
          inputContainerStyle="col-6"
          validationName="input.user_input.home_days"
        />
      </div>

      <div className="d-flex align-items-center justify-content-between my-3">
        <CheckboxBooleanForm
          {...props?.FormProps}
          name="submit_change_shift_cutoff_time"
          options={[
            t(
              "Employee must submit change shift request before the start of work day by"
            ),
          ]}
          optionLabelStyle={""}
          type="checkbox"
        />

        <InputForm
          {...props?.FormProps}
          name="change_shift_cutoff_time"
          inputContainerStyle="input-container-style-default max-days-style"
          type="number"
          containerStyle="d-flex align-items-center justify-content-between"
          hasSuffix
          suffixTitle="hours"
          dependOn="submit_change_shift_cutoff_time"
          dependancyType="equal"
          dependancyValue={[1]}
        />
      </div>
      <CheckboxBooleanForm
        {...props?.FormProps}
        name="has_request_additional_shift_limit"
        options={[t("Additional shift request limit")]}
        optionLabelStyle={""}
        type="checkbox"
      />
      <div className="align-items-end px-0 row">
        <InputForm
          rootStyle="col-md-6"
          {...props?.FormProps}
          label={t("Max. Number of requests")}
          hasMinMax
          minValue={0}
          maxValue={Infinity}
          name="additional_shift_request_limits"
          placeholder="Max. Number of requests"
          dependOn="has_request_additional_shift_limit"
          dependancyType="equal"
          dependancyValue={[1]}
          stepInput
          labelStyle="mb-2"
          inputContainerStyle="input-container-style-default max-days-style"
          validationName="input.request_configuration_settings.request_limits"
          type="number"
          validateBy="number_req_non_zero_or_negative"
        />

        <BSelect
          rootStyle="col-md-6"
          {...props?.FormProps}
          label={t("reset every")}
          name="additional_shift_period_type"
          keepDefaultStyle
          dependOn="has_request_additional_shift_limit"
          dependancyType="equal"
          dependancyValue={[1]}
          placeholder={t("select option")}
          optionLabel="label"
          optionValue="label"
          options={[{ label: "week" }, { label: "month" }, { label: "year" }]}
          getOptionLabel={(option) => t(option.label)}
          containerStyle="row justify-content-between align-items-center"
          labelStyle="col-6"
          inputContainerStyle="col-6"
          validationName="input.user_input.home_days"
        />
      </div>

      <div className="d-flex align-items-center justify-content-between my-3">
        <CheckboxBooleanForm
          {...props?.FormProps}
          name="submit_additional_shift_cutoff_time"
          options={[
            t(
              "Employee must submit additional shift request before the start of work day by"
            ),
          ]}
          optionLabelStyle={""}
          type="checkbox"
        />

        <InputForm
          {...props?.FormProps}
          name="additional_shift_cutoff_time"
          inputContainerStyle="input-container-style-default max-days-style"
          type="number"
          containerStyle="d-flex align-items-center justify-content-between"
          hasSuffix
          suffixTitle="hours"
          dependOn="submit_additional_shift_cutoff_time"
          dependancyType="equal"
          dependancyValue={[1]}
        />
      </div>
    </div>
  );
};

export default RequestLimitConfig;
