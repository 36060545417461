import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onInputChangeAction,
  updateRadioScopeAction,
  updateValueAction,
} from "../Store/Actions";
import Masonry from "react-masonry-component";
import { useTranslation } from "react-i18next";
import CheckboxForm from "../Builder/Form/CheckboxForm";
import HelperFns from "../Helpers/HelperFns";

const employeesOptions = [
  { label: "all employees", value: "all" },
  { label: "managed employees", value: "managed" },
];

const getScopedDescendants = (parent) => {
  let values = [];
  const rfn = (child) => {
    if (child.flag) values.push(child.id);
    if (child.children.length) {
      for (let i = 0; i < child.children.length; i++) {
        rfn(child.children[i]);
      }
    }
  };
  rfn(parent);

  return values;
};

const RolePrivilegesTreeView = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <h5 className="sub-tilte-style mt-4 mb-0">{t("Role Privileges")}</h5>
      <Masonry>
        <OptionsList
          companyLevel={props.companyLevel}
          options={props.parents}
          isFirst={true}
        />
      </Masonry>
    </div>
  );
};

// Recursive component
const OptionsList = ({ options, isFirst, companyLevel }) => {
  let reducer = companyLevel ? "super" : "admin";
  let formName = companyLevel ? "managePrivilagesModal" : "roleForm";
  const privileges = useSelector((state) => state?.[reducer]?.[formName]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleSelectAll = (e, option) => {
    if (e.target.checked) {
      let updatedPrivlegesWithAll = [
        ...new Set([
          ...privileges?.main,
          ...option?.descendants?.map(String),
          option?.id?.toString(),
        ]),
      ];

      let scopedPrivileges = getScopedDescendants(option);

      let scopedEvent = [
        ...new Set([...privileges?.managed, ...scopedPrivileges]),
      ];

      let updatedAllScope = privileges?.all?.filter((priv) =>
        scopedPrivileges?.includes(+priv)
      );

      // remove all desendant from all Scoped Privilages first
      dispatch(updateValueAction(formName, "all", updatedAllScope));
      // add main privileges to main privileges
      dispatch(updateValueAction(formName, "main", updatedPrivlegesWithAll));
      // add scoped privilegs to managed scope
      dispatch(updateValueAction(formName, "managed", scopedEvent));
    } else {
      let updatedPrivlegesWithAll;
      let optionBasicPrivilegesIds = HelperFns.getBasicPrivileges(option);
      updatedPrivlegesWithAll = [
        ...privileges?.main?.filter(
          (item) => item != option?.id && !option?.descendants?.includes(+item)
        ),
        ...optionBasicPrivilegesIds,
      ];

      let event = {
        target: {
          name: "main",
          value: updatedPrivlegesWithAll,
        },
      };

      // remove all desendant from main Privilages
      dispatch(onInputChangeAction(formName, event));
      dispatch(
        updateRadioScopeAction(formName, "main", [
          ...option.descendants,
          +option.id,
        ])
      );
    }
  };

  return (
    <>
      {options?.map((option) => (
        <div key={option.id} className={`${isFirst ? "col-12 col-xl-6" : ""}`}>
          <div
            className={`${isFirst ? "boxContainer py-2 my-2 pl-3 pr-2" : ""}`}
          >
            <ul className={`${isFirst ? "pl-0 mb-0" : "p-l-22"}`}>
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <Checkbox companyLevel={companyLevel} {...option} />

                {option?.flag && privileges?.main?.includes(option?.id) ? (
                  <div className="d-flex flex-wrap align-items-center justify-content-between py-1">
                    <form className="d-flex justify-content-end">
                      {employeesOptions?.map((opt) => (
                        <Radiobox
                          companyLevel={companyLevel}
                          {...opt}
                          {...option}
                        />
                      ))}
                    </form>
                  </div>
                ) : null}
                {isFirst ? (
                  <div
                    style={{
                      backgroundColor: "#23aaeb5e",
                      borderRadius: "3px",
                      border: "solid 1px #23aaeb5e",
                    }}
                    className="p-1 row fit-width align-items-center mx-0"
                  >
                    <input
                      type="checkbox"
                      className="align-items-center"
                      onChange={(e) => handleSelectAll(e, option)}
                      checked={[...option.descendants, option.id].every(
                        (child) => privileges?.main.includes(`${child}`)
                      )}
                    />
                    <label style={{ fontSize: "10px" }} className="ml-1">
                      {t("select all")}
                    </label>
                  </div>
                ) : null}
              </div>

              {/* Base Case */}
              {option?.children?.length > 0 &&
                privileges?.main?.includes(option?.id) && (
                  <OptionsList
                    companyLevel={companyLevel}
                    options={option.children}
                  />
                )}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

// checkbox component, completly controlled by parent
const Checkbox = ({
  name,
  id,
  descendants,
  flag,
  privilege_type_id,
  companyLevel,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  let reducer = companyLevel ? "super" : "admin";
  let formName = companyLevel ? "managePrivilagesModal" : "roleForm";
  const privileges = useSelector((state) => state[reducer][formName]);
  const handleCheckboxChange = (e) => {
    //handle if un Checked and has descendants
    if (!e.target.checked) {
      let event = {
        target: {
          name: "main",
          value: privileges?.main?.filter(
            (item) => !descendants?.includes(+item) && item != id
          ),
        },
      };
      // remove all desendant from scope Privilages
      dispatch(
        updateRadioScopeAction(formName, e.target.name, [...descendants, +id])
      );
      // remove all desendant from main Privilages
      dispatch(onInputChangeAction(formName, event));
    } else {
      dispatch(onInputChangeAction(formName, e, "checkbox", id));
      if (e.target.checked && flag)
        dispatch(updateRadioScopeAction(formName, "managed", id));
    }
  };
  return (
    <CheckboxForm
      options={[{ value: id, label: t(name) }]}
      name="main"
      formName={companyLevel ? "managePrivilagesModal" : formName}
      reducer={companyLevel ? "super" : "admin"}
      type="checkbox"
      containerStyle=" "
      labelStyle="label-style"
      optionsContainerStyle="my-1"
      optionItemStyle=" "
      optionInputStyle=" "
      optionLabelStyle=" "
      optionLabelActiveStyle="optionLabelActiveStyle"
      onChange={handleCheckboxChange}
      disabled={privilege_type_id}
    />
  );
};
// Radiobox component, completly controlled by parent
const Radiobox = (props) => {
  let formName = props.companyLevel ? "managePrivilagesModal" : "roleForm";
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleRadioChange = (e) => {
    dispatch(updateRadioScopeAction(formName, e.target.name, e.target.value));
  };
  let reducer = props.companyLevel ? "super" : "admin";
  const privileges = useSelector((state) => state[reducer][formName]);
  // const privileges = useSelector((state) => state.admin[formName]);

  return (
    <div className="option-item">
      <label className="radio">
        <input
          type="radio"
          name={props.value}
          value={props.id}
          onChange={handleRadioChange}
          checked={privileges[props.value]?.includes(props.id)}
        />
        <span></span>

        <div className="optionLabelStyle">{t(props.label)}</div>
      </label>
    </div>
  );
};

export default RolePrivilegesTreeView;
