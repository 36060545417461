import gql from "graphql-tag";

export const UPSERT_FLEX_WORK_GROUP = gql`
  mutation upsertFlexWorkGroup($input: UpdateOrCreateFlexWorkGroupInput) {
    updateOrCreateFlexWorkGroup(input: $input) {
      ... on FlexWorkGroup {
        __typename
        id
        name
      }

      ... on GeneralException {
        __typename
        message
      }
    }
  }
`;

export const DELETE_FLEX_WORK_GROUP = gql`
  mutation deletefLEXWorkGroup($id: ID!) {
    deleteFlexWorkGroup(id: $id) {
      id
    }
  }
`;

export const UPSERT_FLEX_WORK_TEAM = gql`
  mutation upsertFlexWorkTeam($input: UpdateOrCreateFlexWorkTeamInput) {
    updateOrCreateFlexWorkTeam(input: $input) {
      ... on FlexWorkTeam {
        __typename
        id
        name
      }

      ... on GeneralException {
        __typename
        message
      }
    }
  }
`;

export const DELETE_FLEX_WORK_TEAM = gql`
  mutation deleteFlexWorkTeam($id: ID!) {
    deleteFlexWorkTeam(id: $id) {
      id
    }
  }
`;

export const UPSERT_MONTHLY_PROFILE = gql`
  mutation upsertMonthlyProfile($input: FlexMonthlyProfileInput) {
    upsertFlexMonthlyProfile(input: $input) {
      __typename
      id
      name
    }
  }
`;

export const CHANGE_MONTHLY_PROFILE_STATUS = gql`
  mutation changeMonthlyProfileStatus($id: ID!) {
    changeFlexMonthlyProfileStatus(id: $id) {
      status
      message
    }
  }
`;

export const DELETE_MONTHLY_PROFILE = gql`
  mutation deleteMonthlyProfile($id: ID!) {
    deleteFlexMonthlyProfile(id: $id) {
      status
      message
    }
  }
`;

export const UPSERT_FLEX_WORK_DAY_TEMPLATE = gql`
  mutation upsertFlexWorkDayTemplate($input: FlexWorkDayTemplateInput) {
    upsertFlexWorkDayTemplate(input: $input) {
      id
      __typename
      name
    }
  }
`;

export const CHANGE_WORK_DAY_TEMPLATE_STATUS = gql`
  mutation changeWorkDayTemplate($id: ID!) {
    changeFlexWorkDayTemplateStatus(id: $id) {
      status
      message
    }
  }
`;

export const DELETE_WORK_DAY_TEMPLATE = gql`
  mutation deleteWorkDayTemplate($id: ID!) {
    deleteFlexWorkDayTemplate(id: $id) {
      status
      message
    }
  }
`;

export const UPDATE_CLOCKIN = gql`
  mutation updateClockin($input: AddSimpleClockInInput) {
    updateOrCreateClockIn(input: $input) {
      __typename
      ... on Clockin {
        id
      }

      ... on StatusResponse {
        status
        message
      }
    }
  }
`;

export const DELETE_CLOCKIN = gql`
  mutation upsertClockin($id: Int) {
    deleteClockIn(id: $id) {
      __typename
      ... on Clockin {
        id
      }

      ... on StatusResponse {
        status
        message
      }
    }
  }
`;

export const UPSERT_FLEX_ATTENDANCE_TYPE_CONFIG = gql`
  mutation upsertFlexAttendanceTypeConfig(
    $input: UpsertAttendanceTypeConfigurationForFlex
  ) {
    upsertAttendanceTypeConfigurationForFlex(input: $input) {
      ... on FlexAttendanceTypeConfiguration {
        __typename
        id
        type
      }

      ... on GeneralException {
        message
        __typename
      }
    }
  }
`;

export const UPSERT_FLEX_ASSIGNMNET = gql`
  mutation upsertFlexAssignment($input: UpdateOrCreateFlexAssignmentInput) {
    updateOrCreateFlexAssignment(input: $input) {
      ... on FlexAssignment {
        id
        name
        from
        to
        __typename
      }
      ... on GeneralException {
        message
        __typename
      }
    }
  }
`;

export const SUBMIT_FLEX_ANNUAL_LEAVE_REQUEST = gql`
  mutation submitFlexAnnualLeaveRequest($input: FlexAnnualFullDayRequestInput) {
    flexAnnualFullDayRequest(input: $input) {
      __typename
      ... on GeneralException {
        message
      }
      ... on FlexAnnualLeave {
        flex_request {
          id
        }
      }
      ... on FlexEmergencyLeave {
        flex_request {
          id
        }
      }
      ... on PaidPayrollException {
        message
      }
    }
  }
`;

export const SUBMIT_FLEX_UNPAID_LEAVE_REQUEST = gql`
  mutation submitFlexUnpaidLeaveRequest($input: FlexUnpaidRequestInput) {
    FlexUnpaidLeaveRequest(input: $input) {
      __typename
      ... on GeneralException {
        message
      }
      ... on FlexUnpaidLeave {
        flex_request {
          id
        }
      }
      ... on PaidPayrollException {
        message
      }
    }
  }
`;

export const SUBMIT_FLEX_CUSTOM_LEAVE_REQUEST = gql`
  mutation submitFlexCustomLeaveRequest($input: FlexCustomRequestInput) {
    FlexCustomRequest(input: $input) {
      __typename
      ... on GeneralException {
        message
      }
      ... on FlexCustomRequest {
        flex_request {
          id
        }
      }
      ... on PaidPayrollException {
        message
      }
    }
  }
`;

export const SUBMIT_FLEX_SICK_LEAVE_REQUEST = gql`
  mutation submitFlexSickLeaveRequest($input: FlexSickLeaveRequestInput) {
    flexSickLeaveRequest(input: $input) {
      __typename
      ... on GeneralException {
        message
      }
      ... on FlexSickLeave {
        flex_request {
          id
        }
      }
      ... on PaidPayrollException {
        message
      }
    }
  }
`;

export const SUBMIT_FLEX_WORK_REMOTELY_REQUEST = gql`
  mutation submitFlexWorkRemotelyRequest($input: FlexWorkRemotelyRequestInput) {
    FlexWorkRemotelyRequest(input: $input) {
      __typename
      ... on GeneralException {
        message
      }
      ... on FlexWorkRemotely {
        flex_request {
          id
        }
      }
      ... on PaidPayrollException {
        message
      }
    }
  }
`;

export const SUBMIT_FLEX_CHANGE_SHIFT_REQUEST = gql`
  mutation submitFlexChangeShiftRequest($input: FlexChangeShiftRequestInput) {
    flex_change_shift_request(input: $input) {
      __typename
      ... on GeneralException {
        message
      }
      ... on FlexChangeShift {
        flex_request {
          id
        }
      }
      ... on PaidPayrollException {
        message
      }
    }
  }
`;

export const SUBMIT_FLEX_IGNORE_ASSIGNMENT_REQUEST = gql`
  mutation submitFlexIgnoreAssignmentRequest($input: IgnoreExceptionInput!) {
    ignoreFlexException(input: $input) {
      __typename
      ... on GeneralException {
        message
      }
      ... on FlexRequest {
        id
      }

      ... on FlexEditAttendance {
        flex_request {
          id
        }
      }
      ... on PaidPayrollException {
        message
      }
    }
  }
`;

export const ACCEPT_FLEX_REQUEST = gql`
  mutation acceptFlexRequest($id: ID) {
    accept_flex_request(id: $id) {
      __typename
      ... on GeneralException {
        message
      }
      ... on FlexRequest {
        id
      }

      ... on PaidPayrollException {
        message
      }
    }
  }
`;

export const REJECT_FLEX_REQUEST = gql`
  mutation acceptFlexRequest($id: ID) {
    reject_flex_request(id: $id) {
      __typename
      ... on GeneralException {
        message
      }
      ... on FlexRequest {
        id
      }

      ... on PaidPayrollException {
        message
      }
    }
  }
`;

export const CANCEL_FLEX_REQUEST = gql`
  mutation cancelFlexRequest($input: CancelFlexRequestInput) {
    cancel_flex_request(input: $input) {
      __typename
      ... on GeneralException {
        message
      }
      ... on FlexRequest {
        id
      }

      ... on PaidPayrollException {
        message
      }
    }
  }
`;

export const ACCEPT_FLEX_CHANGE_SHIFT_REQUEST = gql`
  mutation acceptFlexChangeShiftRequest(
    $input: AcceptFlexRequestChangeShiftInput
  ) {
    accept_flex_change_shift_request(input: $input) {
      __typename
      ... on GeneralException {
        message
      }
      ... on FlexRequest {
        id
      }

      ... on PaidPayrollException {
        message
      }
    }
  }
`;

export const ACCEPT_OR_REJECT_TIMESHEET_EMPLOYEES = gql`
  mutation acceptMutlipleEmployees($input: AcceptOrRejectSummaryInput) {
    acceptSummaryByDatesPerOneOrManyEmployees(input: $input) {
      status
      message
      details {
        employee_name
        container_date
        work_day_template_name
      }
    }
  }
`;

export const ACCEPT_OR_REJECT_TIMESHEET_PER_ONE_EMPLOYEE = gql`
  mutation acceptOrRejectTimesheetPerOneEmployee(
    $input: AcceptOrRejectSummaryInput
  ) {
    acceptSummaryByDatesPerOneEmployee(input: $input) {
      status
      message
      details {
        container_date
        work_day_template_name
      }
    }
  }
`;

export const ACCEPT_FLEX_OVERTIME_REQUEST = gql`
  mutation acceptFlexOvertimeRequest(
    $id: ID
    $interval_in_time: String
    $payable_at: String
    $force_confirm: Boolean
  ) {
    accept_overtime_flex(
      id: $id
      interval_in_time: $interval_in_time
      payable_at: $payable_at
      force_confirm: $force_confirm
    ) {
      id
    }
  }
`;

export const ADD_NEW_FLEX_MONTHLY_PROFLE_CONFIG = gql`
  mutation addNewFlexMonthlyProfileConfig(
    $input: EmployeeTimeRangeProfileSettingInput
  ) {
    upsertEmployeeTimeRangeProfileSetting(input: $input) {
      id
    }
  }
`;

export const EDIT_FLEX_WORK_TEAM_NAME = gql`
  mutation editFlexWorkTeamName($input: UpdateOrCreateFlexWorkTeamInput) {
    updateOrCreateFlexWorkTeam(input: $input) {
      __typename
      ... on FlexWorkTeam {
        id
      }
      ... on GeneralException {
        message
      }
    }
  }
`;

export const flexRequestCommentMutation = gql`
  mutation upsertRequestComments($input: FlexRequestCommentInput) {
    upsert_comment: upsert_flex_request_comment(input: $input) {
      id
      request_id: flex_request_id
      request: flex_request {
        __typename
        employee {
          user {
            id
          }
        }
      }
    }
  }
`;

export const SUBMIT_FLEX_DAY_OFF_REQUEST = gql`
  mutation submitFlexDayOffRequest($input: FlexDayOffRequestInput) {
    flex_day_off_request(input: $input) {
      __typename
      ... on GeneralException {
        message
      }
      ... on FlexDayOffRequest {
        flex_request {
          id
        }
      }
      ... on PaidPayrollException {
        message
      }
    }
  }
`;

export const ACCEPT_FLEX_DAY_OFF_REQUEST = gql`
  mutation acceptFlexDayoffRequest($input: AcceptFlexDayOffRequestInput) {
    accept_flex_day_off_request(input: $input) {
      __typename
      ... on GeneralException {
        message
      }
      ... on FlexRequest {
        id
      }
      ... on PaidPayrollException {
        message
      }
    }
  }
`;

export const changeFlexEmployeesDayOffSettingsMutation = gql`
  mutation changeFlexEmployeesDayOffSettings(
    $input: DayOffExceptionConfigMultipleInput
  ) {
    addDayOffExceptionConfigForEmployees: addDayOffExceptionConfigForFlexEmployees(
      input: $input
    ) {
      status
      message
    }
  }
`;

export const changeFlexEmployeesHolidaySettingsMutation = gql`
  mutation changeFlexEmployeesHolidaySettings(
    $input: HolidayExceptionConfigMultipleInput
  ) {
    addHolidayExceptionConfigForEmployees: addHolidayExceptionConfigForFlexEmployees(
      input: $input
    ) {
      status
      message
    }
  }
`;

export const EDIT_CLOCKINS_CONTAINER = gql`
  mutation editClockinsContainer($input: ChangeStatusClockInContainer) {
    editClockInContainer(input: $input) {
      __typename
      ... on ClockinContainer {
        id
      }
      ... on GeneralException {
        message
      }
    }
  }
`;

export const SUBMIT_FLEX_OPEN_REQUEST = gql`
  mutation submitFlexOpenRequest($input: FlexOpenRequestInput) {
    flex_open_request(input: $input) {
      ... on GeneralException {
        message
      }

      ... on FlexOpenRequest {
        flex_request {
          id
          type
        }
      }
    }
  }
`;
