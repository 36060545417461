import React from "react";
import MainModal from "../../Components/MainModal";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toggleLogsModal } from "../../Store/Actions";
import moment from "moment";
import DataTable from "react-data-table-component";
import Loader from "../../Components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";

const LogsModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const logsModalData = useSelector((state) => state.super?.logsModalData);

  const handleCloseModal = () => {
    dispatch(
      toggleLogsModal({ isOpen: false, data: [], name: null, date: null })
    );
  };

  const columns = [
    {
      name: t("on"),
      wrap: false,
      sortable: false,
      grow: 1.2,
      cell: (row) => (
        <div>
          <p className="m-0">{moment(row?.created_at).format("DD-MM-YYYY")}</p>
          <p className="m-0">{moment(row?.created_at).format("hh:mm:ss A")}</p>
        </div>
      ),
    },
    {
      name: t("operation"),
      wrap: false,
      sortable: false,
      grow: 1.2,
      cell: (row) => t(row?.event?.toLowerCase()),
    },
    {
      name: t("details"),
      wrap: false,
      sortable: false,
      grow: 7,
      cell: (row) => (
        <div className="py-1">
          <p>{JSON.parse(row?.description)?.msg}</p>

          <>
            {Object.keys(JSON.parse(row?.description)?.attributes || {})
              ?.filter((el) => el?.toLowerCase() !== "violations")
              ?.map((attributeName) => (
                <div
                  key={attributeName}
                  className="d-flex align-items-baseline gap-10"
                >
                  <div className="px-0">
                    <p className="mb-0 attribute-name">
                      {t(attributeName?.replace(/_/g, " "))}
                    </p>
                  </div>

                  {row?.event?.toLowerCase() === "created" ? (
                    <p className="mb-0 attribute-value">
                      {t(
                        JSON.parse(row?.description)?.attributes?.[
                          attributeName
                        ]
                      )}
                    </p>
                  ) : (
                    <div className="mb-2 d-flex gap-10">
                      <p className="mb-0 attribute-property">{t("from")}</p>

                      <p className="mb-0 attribute-value">
                        {attributeName?.toLowerCase() === "sign in time" ||
                        attributeName?.toLowerCase() === "sign out time"
                          ? JSON.parse(
                              JSON.parse(row?.description)?.attributes?.[
                                attributeName
                              ]
                            )?.from === ""
                            ? JSON.parse(
                                JSON.parse(row?.description)?.attributes?.[
                                  attributeName
                                ]
                              )?.from?.replace("", "------")
                            : moment(
                                JSON.parse(
                                  JSON.parse(row?.description)?.attributes?.[
                                    attributeName
                                  ]
                                )?.from
                              ).format("hh:mm:ss A")
                          : t(
                              JSON.parse(
                                JSON.parse(row?.description)?.attributes?.[
                                  attributeName
                                ]
                              )?.from === "" ? (
                                JSON.parse(
                                  JSON.parse(row?.description)?.attributes?.[
                                    attributeName
                                  ]
                                )?.from?.replace("", "------")
                              ) : JSON.parse(
                                  JSON.parse(row?.description)?.attributes?.[
                                    attributeName
                                  ]
                                )?.from?.toLowerCase() === "true" ? (
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  color="#27b40c"
                                />
                              ) : JSON.parse(
                                  JSON.parse(row?.description)?.attributes?.[
                                    attributeName
                                  ]
                                )?.from?.toLowerCase() === "false" ? (
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  color="#ff6a6a"
                                />
                              ) : (
                                JSON.parse(
                                  JSON.parse(row?.description)?.attributes?.[
                                    attributeName
                                  ]
                                )?.from
                              )
                            )}
                      </p>

                      <p className="mb-0 attribute-property">{t("to")}</p>

                      <p className="mb-0 attribute-value">
                        {attributeName?.toLowerCase() === "sign in time" ||
                        attributeName?.toLowerCase() === "sign out time"
                          ? JSON.parse(
                              JSON.parse(row?.description)?.attributes?.[
                                attributeName
                              ]
                            )?.to === ""
                            ? JSON.parse(
                                JSON.parse(row?.description)?.attributes?.[
                                  attributeName
                                ]
                              )?.to?.replace("", "------")
                            : moment(
                                JSON.parse(
                                  JSON.parse(row?.description)?.attributes?.[
                                    attributeName
                                  ]
                                )?.to
                              ).format("hh:mm:ss A")
                          : t(
                              JSON.parse(
                                JSON.parse(row?.description)?.attributes?.[
                                  attributeName
                                ]
                              )?.to === "" ? (
                                JSON.parse(
                                  JSON.parse(row?.description)?.attributes?.[
                                    attributeName
                                  ]
                                )?.to?.replace("", "------")
                              ) : JSON.parse(
                                  JSON.parse(row?.description)?.attributes?.[
                                    attributeName
                                  ]
                                )?.to?.toLowerCase() === "true" ? (
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  color="#27b40c"
                                />
                              ) : JSON.parse(
                                  JSON.parse(row?.description)?.attributes?.[
                                    attributeName
                                  ]
                                )?.to?.toLowerCase() === "false" ? (
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  color="#ff6a6a"
                                />
                              ) : (
                                JSON.parse(
                                  JSON.parse(row?.description)?.attributes?.[
                                    attributeName
                                  ]
                                )?.to
                              )
                            )}
                      </p>
                    </div>
                  )}
                </div>
              ))}

            {/* in violations case as it is array inside array in json */}
            {Object.keys(JSON.parse(row?.description)?.attributes || {})
              ?.filter((el) => el?.toLowerCase() === "violations")
              ?.map((attributeName) => (
                <div
                  key={attributeName}
                  className="d-flex align-items-baseline gap-10"
                >
                  <div className="px-0">
                    <p className="mb-0 attribute-name">
                      {t(attributeName?.replace(/_/g, " "))}
                    </p>
                  </div>

                  {row?.event?.toLowerCase() === "created" ? (
                    <div className="d-flex flex-wrap">
                      {JSON.parse(
                        JSON.parse(row?.description)?.attributes?.["violations"]
                      )
                        .map((obj) => JSON.parse(obj))
                        .map((el, i) => (
                          <div key={i} className="mb-2">
                            <p className="mb-0 attribute-property">
                              {t("amount")}
                            </p>

                            <p className="mb-0 attribute-value">{el?.amount}</p>

                            <p className="mb-0 attribute-property">
                              {t("type")}
                            </p>

                            <p className="mb-0 attribute-value">
                              {t(el?.type)}
                            </p>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div className="mb-2">
                      <p className="mb-0 attribute-property my-2">
                        {t("from")}:
                      </p>

                      <div className="d-flex flex-wrap">
                        {JSON.parse(
                          JSON.parse(
                            JSON.parse(row?.description)?.attributes?.[
                              "violations"
                            ]
                          )?.from
                        )
                          .map((obj) => JSON.parse(obj))
                          .map((el, i) => (
                            <div key={i} className="mb-2">
                              <p className="mb-0 attribute-property">
                                {t("amount")}
                              </p>

                              <p className="mb-0 attribute-value">
                                {el?.amount}
                              </p>

                              <p className="mb-0 attribute-property">
                                {t("type")}
                              </p>

                              <p className="mb-0 attribute-value">
                                {t(el?.type)}
                              </p>
                            </div>
                          ))}
                      </div>

                      <div className="my-2">
                        <p className="mb-0 attribute-property">{t("to")}:</p>
                      </div>

                      <div className="d-flex flex-wrap">
                        {JSON.parse(
                          JSON.parse(
                            JSON.parse(row?.description)?.attributes?.[
                              "violations"
                            ]
                          )?.to
                        )
                          .map((obj) => JSON.parse(obj))
                          .map((el, i) => (
                            <div key={i} className="mb-2">
                              <p className="mb-0 attribute-property">
                                {t("amount")}
                              </p>

                              <p className="mb-0 attribute-value">
                                {el?.amount}
                              </p>

                              <p className="mb-0 attribute-property">
                                {t("type")}
                              </p>

                              <p className="mb-0 attribute-value">
                                {t(el?.type)}
                              </p>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </>
        </div>
      ),
    },
  ];

  const getDate = () => {
    return moment(logsModalData?.date ?? undefined).format("dddd DD-MM-YYYY");
  };

  return (
    <MainModal
      isOpen={logsModalData?.isOpen}
      toggle={handleCloseModal}
      hasModalFooter={false}
      modalTitle={t("change logs")}
      className="sign-in-logs-modal-container"
    >
      <div className="d-flex align-items-center gap-10 my-2">
        <h4 className="change-logs-user-name">{logsModalData?.name}</h4>
        <h5>{getDate()}</h5>
      </div>

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="cards_table"
        columns={columns}
        data={
          logsModalData?.fromWhere === "employeeAttTypeConfigTab" ||
          logsModalData?.fromWhere === "bonusAndDeductionsTabs"
            ? logsModalData?.data
            : logsModalData?.data?.signInOut?.logs
        }
        noHeader
        persistTableHead
        pagination={false}
        paginationServer={false}
        progressPending={false}
        progressComponent={<Loader />}
      />
    </MainModal>
  );
};

export default LogsModal;
