import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import HelperFns from "../../Helpers/HelperFns";
import FlexibleWorkScheduleTabs from "./FlexibleWorkScheduleTabs";
import TimesheetSummary from "../../Containers/FlexibleLists/TimesheetSummary";
import {
  TimeSheetList,
  WorkDayTemplates,
} from "../../Containers/FlexibleLists";
import useCompanyAttType from "../../Helpers/Hooks/useCompanyAttType";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const FlexibleWorkSchedule = (props) => {
  const history = useHistory();
  let query = useQuery();
  const activeValue = query.get("tab");

  useEffect(() => {
    if (!activeValue) {
      renderfirstTab();
    }
  }, [activeValue]);

  const renderfirstTab = () => {
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_TIMESHEET_RECORDS],
        allowBP: true,
      })
    ) {
      return history.push(`/flexible-work-schedule?tab=timesheet-summary`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_TIMESHEET_RECORDS],
        allowBP: true,
      })
    ) {
      return history.push(`/flexible-work-schedule?tab=timesheet`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_WORK_DAY_TEMPLATES],
        allowBP: true,
      })
    ) {
      return history.push(`/flexible-work-schedule?tab=workday-template`);
    }

    return null;
  };

  const handleChange = (event, newValue) => {
    history.push(`/flexible-work-schedule?tab=${newValue}`);
  };

  const { hasFlex, hasFixed } = useCompanyAttType();

  const renderSelectedTab = () => {
    switch (activeValue?.toLowerCase()) {
      case "timesheet-summary":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_TIMESHEET_RECORDS]}
            allowBP
          >
            <TimesheetSummary />
          </HasPrivileges>
        );
      case "timesheet":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_TIMESHEET_RECORDS]}
            allowBP
          >
            <TimeSheetList />
          </HasPrivileges>
        );
      case "workday-template":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_WORK_DAY_TEMPLATES]}
            allowBP
          >
            <WorkDayTemplates />
          </HasPrivileges>
        );
      default:
        break;
    }
  };
  if (hasFlex) {
    return (
      <div className="content pt-3">
        <FlexibleWorkScheduleTabs />
        <div className="">{renderSelectedTab()}</div>
      </div>
    );
  }
  return null;
};

export default FlexibleWorkSchedule;
