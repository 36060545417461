import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainModal from "../MainModal";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, gql, useLazyQuery } from "@apollo/client";

import { DateTimePickerForm, BSelect, RadioboxForm } from "form-builder";

import moment from "moment";
import {
  EMERGENCY_HALF_DAY,
  NORMAL_HALF_DAY,
  UNPAID_HALF_DAY,
} from "../../Constants/Requests";
import _, { update } from "lodash";
import { updateValueAction } from "../../Store/Actions";
import { Alert } from "@mui/material";
import Loader from "../Loader";
import { GET_COST_CENTERS_MENU } from "../../Graphql/query";
import useAllowCostCenter from "../../Helpers/Hooks/useAllowCostCenter";

const formName = "acceptReqWithEdit";

const HALF_REQ_IDS = [UNPAID_HALF_DAY, NORMAL_HALF_DAY, EMERGENCY_HALF_DAY];

const editAttendanceAndAcceptRequestMutation = gql`
  mutation acceptRequest(
    $RequestId: ID
    $signInOutId: ID
    $workTimingId: ID
    $signOutTime: String
    $signInTime: String
  ) {
    Edit_Attendance_And_Accept_Request(
      input: {
        RequestId: $RequestId
        signInOutId: $signInOutId
        signOutTime: $signOutTime
        signInTime: $signInTime
        workTimingId: $workTimingId
      }
    ) {
      ... on GeneralException {
        message
      }
      ... on Request {
        id
      }
    }
  }
`;

const fetchSignInOutRecord = gql`
  query SignInOutRecord($SignInId: ID) {
    signInOut(id: $SignInId) {
      date
      employee {
        id
        name
      }
      day_type
      sign_in_time
      sign_out_time
      work_timing_id
      getSignInConfigType {
        __typename
      }
      work_timing {
        id
        type: work_timing_profile_type
        archived
        created_at
        updated_at
      }
    }
    work_timings_menu(type: "normal", new_versions_only: true) {
      id
      name
      sign_in_req
      sign_in_start_time
      sign_in_end_time
      work_hours
      archived
      created_at
      updated_at
    }
    half_work_timings_menu: work_timings_menu(
      type: "half"
      new_versions_only: true
    ) {
      id
      name
      sign_in_req
      sign_in_start_time
      sign_in_end_time
      work_hours
      archived
      created_at
      updated_at
    }
  }
`;

const AcceptReqWithEdit = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { allowCostCenter } = useAllowCostCenter();

  const acceptReqWithEdit = useSelector(
    (state) => state?.super?.acceptReqWithEdit
  );

  const dismiss = () => {
    reset();
    dispatch({
      type: "HIDE_ACCEPT_REQUEST_WITH_EDIT",
    });
  };

  const {
    loading: loadingInfo,
    error: errorInfo,
    data: infoData,
  } = useQuery(fetchSignInOutRecord, {
    variables: {
      SignInId: acceptReqWithEdit?.data?.SignInId,
      // your variables
    },
    onCompleted: (data) => {
      let signInTime = data?.signInOut?.sign_in_time
        ? moment(data?.signInOut?.sign_in_time).format("HH:mm")
        : null;
      let signOutTime = data?.signInOut?.sign_out_time
        ? moment(data?.signInOut?.sign_out_time).format("HH:mm")
        : null;
      let signInDate = data?.signInOut?.sign_n_itime
        ? moment(data?.signInOut?.sign_in_time).format("YYYY-MM-DD")
        : moment(data?.signInOut?.date).format("YYYY-MM-DD");
      let signOutDate = data?.signInOut?.sign_in_time
        ? moment(data?.signInOut?.sign_in_time).format("YYYY-MM-DD")
        : moment(data?.signInOut?.date).format("YYYY-MM-DD");
      dispatch(updateValueAction(formName, "signInTime", signInTime));
      dispatch(updateValueAction(formName, "signOutTime", signOutTime));
      dispatch(updateValueAction(formName, "signInDate", signInDate));
      dispatch(updateValueAction(formName, "signOutDate", signOutDate));
    },
    skip:
      !Boolean(acceptReqWithEdit?.data?.SignInId) || !acceptReqWithEdit?.isOpen,
  });

  const [acceptRequest, { loading, data, error, reset }] = useMutation(
    editAttendanceAndAcceptRequestMutation,
    {
      onCompleted(res) {
        // do someting
        dismiss();
      },
      onError(error) {
        // do something
      },
    }
  );

  const [getCostCentersOptions, { data: costCentersMenuQueryData }] =
    useLazyQuery(GET_COST_CENTERS_MENU);

  useEffect(() => {
    if (acceptReqWithEdit?.isOpen) {
      getCostCentersOptions();
    }
  }, [acceptReqWithEdit?.isOpen]);

  const handleSubmit = () => {
    reset();
    acceptRequest({
      variables: {
        RequestId: acceptReqWithEdit?.data?.reqId,
        signInOutId: acceptReqWithEdit?.data?.SignInId,
        workTimingId: acceptReqWithEdit?.workTimingId,
        signInTime: `${acceptReqWithEdit?.signInDate} ${acceptReqWithEdit?.signInTime}`,
        signOutTime: acceptReqWithEdit?.signOutTime ? `${acceptReqWithEdit?.signOutDate} ${acceptReqWithEdit?.signOutTime}` : null,
        costCenterId: acceptReqWithEdit?.cost_center_id,
      },
    });
  };

  const getWorkTimeOpts = () => {
    let wortimeOpts = acceptReqWithEdit?.data?.workTiming
      ? [acceptReqWithEdit?.data?.workTiming]
      : [];
    if (
      (infoData?.signInOut?.getSignInConfigType?.__typename ===
        "AttendanceProfileWorkTiming" ||
        infoData?.signInOut?.getSignInConfigType?.__typename === "Exception") &&
      acceptReqWithEdit?.data?.RequestTypeId &&
      HALF_REQ_IDS?.includes(acceptReqWithEdit?.data?.RequestTypeId?.toString())
    ) {
      wortimeOpts = [
        ...wortimeOpts,
        ...(infoData?.half_work_timings_menu ?? []),
      ];
      return _.uniqBy(wortimeOpts, "id");
    } else {
      wortimeOpts = [
        ...wortimeOpts,
        ...(infoData?.work_timings_menu ?? []),
        ...(infoData?.half_work_timings_menu ?? []),
      ];
      return _.uniqBy(wortimeOpts, "id");
    }
  };

  const FormProps = {
    formName,
  };

  const signInOutDates = [
    {
      label: moment(infoData?.signInOut?.date)
        .subtract(1, "day")
        .format("ddd DD/MM/YYYY"),
      value: moment(infoData?.signInOut?.date)
        .subtract(1, "day")
        .format("YYYY-MM-DD"),
    },
    {
      label: moment(infoData?.signInOut?.date).format("ddd DD/MM/YYYY"),
      value: moment(infoData?.signInOut?.date).format("YYYY-MM-DD"),
    },
    {
      label: moment(infoData?.signInOut?.date)
        .add(1, "day")
        .format("ddd DD/MM/YYYY"),
      value: moment(infoData?.signInOut?.date)
        .add(1, "day")
        .format("YYYY-MM-DD"),
    },
  ];

  return (
    <MainModal
      isOpen={acceptReqWithEdit?.isOpen}
      toggle={dismiss}
      modalTitle={t("accept request")}
      className="work_schedule_modal"
      btnLabel={t("save")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={loading}
      size={"lg"}
    >
      <>
        <div className="d-flex gap-1 px-2">
          <span className="sec-color text-16 font-weight-bold">
            {infoData?.signInOut?.employee?.name}
          </span>
          <div>
            <span className="text-12 ml-2">
              {infoData?.signInOut?.date
                ? moment(infoData?.signInOut?.date, "YYYY-MM-DD").format(
                    "DD-MM-YYYY"
                  )
                : null}
            </span>
          </div>
        </div>

        <div className="p-2">
          <Alert severity="info">
            <strong>
              {t(
                "the current signin/out time doesn't match the halfday worktiming"
              )}
            </strong>
          </Alert>
        </div>

        <div className="px-2">
          {loadingInfo ? (
            <div className="loader_wrapper_style">
              <Loader />
            </div>
          ) : (
            <>
              <div className="row align-items-start mt-3">
                <div className="col-sm-6 attendance_sign_container">
                  <RadioboxForm
                    {...FormProps}
                    name="signInDate"
                    label="sign in time"
                    labelStyle="mb-2"
                    options={signInOutDates}
                    optionsContainerStyle="row sign_in_days"
                    optionLabelStyle
                    optionItemStyle="col-md-6 col-xl-4 attendance_sign_in_item"
                    optionInputStyle="edit_attendance_checkbox"
                  />
                  <DateTimePickerForm
                    {...FormProps}
                    validationName={"input.signInTime"}
                    inputStyle="date-picker-input-default validity-input-style sign_in_out_input attendance_sign_picker"
                    name="signInTime"
                    containerStyle=" "
                    labelStyle="mb-2"
                    validateBy="textRequired"
                    hasIcon
                    timeOnly
                    requestFormat="HH:mm"
                  />
                </div>

                <div className="col-sm-6 attendance_sign_container">
                  <RadioboxForm
                    {...FormProps}
                    name="signOutDate"
                    label={"sign out time"}
                    labelStyle="mb-2"
                    options={signInOutDates}
                    optionsContainerStyle="row sign_in_days"
                    active
                    optionItemStyle="col-md-6 col-xl-4 attendance_sign_in_item"
                    optionInputStyle="edit_attendance_checkbox"
                    optionLabelStyle
                  />
                  <DateTimePickerForm
                    {...FormProps}
                    validationName={"input.signOutTime"}
                    name="signOutTime"
                    containerStyle=" "
                    inputStyle="date-picker-input-default validity-input-style sign_in_out_input attendance_sign_picker"
                    labelStyle="mb-2"
                    hasIcon
                    timeOnly
                    requestFormat="HH:mm"
                  />
                </div>
              </div>
              <BSelect
                {...FormProps}
                containerStyle="mt-3"
                name="workTimingId"
                label="applicable work timing"
                validationName={"input.workTimingId"}
                keepDefaultStyle
                placeholder={t("select option")}
                options={getWorkTimeOpts()}
                optionLabel="name"
                optionValue="id"
                labelStyle="mb-1"
                inputContainerStyle="b-select-style "
                skipLocalization
                isClearable
                getOptionLabel={(opt) => (
                  <span dir="auto">
                    {opt.name}{" "}
                    {opt?.archived
                      ? `(${moment(opt?.created_at).format(
                          "DD-MM-YYYY"
                        )} ~ ${moment(opt?.updated_at).format("DD-MM-YYYY")})`
                      : t("current version")}{" "}
                  </span>
                )}
                icon="calendar"
              />

              {allowCostCenter ? (
                <BSelect
                  {...FormProps}
                  validationName={"input.costCenterId"}
                  containerStyle="mt-3"
                  name="cost_center_id"
                  label="cost center"
                  keepDefaultStyle
                  placeholder={t("select cost center")}
                  options={costCentersMenuQueryData?.cost_centers?.data ?? []}
                  labelStyle="mb-2"
                  inputContainerStyle="b-select-style attendance_work_timing_select"
                  icon="money"
                />
              ) : null}

              {error?.graphQLErrors?.[0]?.extensions?.reason ||
              errorInfo?.graphQLErrors?.[0]?.extensions?.reason ? (
                <div className="error-color mt-1">
                  {error?.graphQLErrors?.[0]?.extensions?.reason ||
                    errorInfo?.graphQLErrors?.[0]?.extensions?.reason}
                </div>
              ) : null}
            </>
          )}
        </div>
      </>
    </MainModal>
  );
};

export default AcceptReqWithEdit;
