import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import {
  billingPeriodOptions,
  promotionTypeOptions,
} from "../../Constants/PaymentTracking";
import moment from "moment";
import { EgyptId } from "../../Constants";
import { showToast } from "../../Helpers/HelperFns";
import { subscriptionRequestFormQuery } from "../../Graphql/query";
import { storeSubscriptionRequestMutation } from "../../Graphql/mutation";
import { onFormResetAction, updateValueAction } from "../../Store/Actions";
import { serializeUpsertSubscription } from "../../Helpers/HelperFns/PaymentTracking";

import {
  BSelect,
  InputForm,
  RadioboxForm,
  DateTimePickerForm,
  CheckboxBooleanForm,
} from "form-builder";
import MainModal from "../MainModal";
import { AddButton } from "../Buttons";
import { TimesIconButton } from "../IconButtonWithTooltip";

const reducer = "paymentTracking";
const formName = "subscriptionForm";
const formNameValidation = "paymentTrackingClientValidation";
const formServerValidation = "paymentTrackingServerValidation";
const formatOption = (str) => ({ id: str, name: str });
const calcEndDate = (period, duration, startDate) => {
  let endDate;
  const date = moment(startDate);
  switch (period) {
    case "Monthly":
      endDate = date.add("months", +duration);
      break;
    case "Quarterly":
      endDate = date.add("months", 3 * +duration);
      break;
    case "Semi-annually":
      endDate = date.add("months", 6 * +duration);
      break;
    case "Annually":
      endDate = date.add("years", +duration);
      break;
    default:
      break;
  }

  return endDate.subtract("days", 1).format("yyyy-MM-DD");
};

const SubscriptionRequestModal = ({ data, onClose, refetchList }) => {
  const dispatch = useDispatch();

  // Local State
  const [errorMsg, setErrorMsg] = React.useState("");
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [options, setOptions] = React.useState({
    agents: [],
    packages: [],
    companies: [],
    countries: [],
    currencies: [],
  });

  // Reducer State
  const formData = useSelector((state) => state?.[reducer]?.[formName]);
  const formClientValidation = useSelector(
    (state) => state?.[reducer]?.[formNameValidation]
  );

  // Server State
  const [store, { loading: storeLoading }] = useMutation(
    storeSubscriptionRequestMutation
  );
  const { loading } = useQuery(subscriptionRequestFormQuery, {
    variables: {},
    onCompleted: ({ packages, agents, countries, companies, currencies }) => {
      // Options
      setOptions((prev) => ({
        ...prev,
        agents: agents || [],
        companies: companies || [],
        countries: countries || [],
        currencies: currencies || [],
        packages: packages?.data || [],
      }));
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  // Constants
  const FormProps = {
    reducer,
    formName,
    formSubmitting,
    formNameValidation,
    formServerValidation,
  };

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    if (
      formData.billingPeriod &&
      formData.contractDuration &&
      formData.startDate
    ) {
      const endDate = calcEndDate(
        formData.billingPeriod,
        formData.contractDuration,
        formData.startDate
      );

      dispatch(updateValueAction(formName, "endDate", endDate));
    }
  }, [formData.billingPeriod, formData.contractDuration, formData.startDate]);

  /* ↓ Helpers ↓ */

  const generateFees = () => {
    formData?.contractDuration &&
      dispatch(
        updateValueAction(
          formName,
          "fees",
          Array.from(Array(+formData?.contractDuration), (_, i) => i + 1).map(
            () => ({ amount: "0" })
          )
        )
      );
  };

  const generatePromotions = () => {
    formData?.contractDuration &&
      dispatch(
        updateValueAction(
          formName,
          "promotions",
          Array.from(Array(+formData?.contractDuration), (_, i) => i + 1).map(
            () => ({ type: "Fixed", amount: "0" })
          )
        )
      );
  };

  const handlePromotionTypeChange = (promotionIdx, type) => {
    dispatch(
      updateValueAction(
        formName,
        "promotions",
        formData.promotions.map((p, i) =>
          promotionIdx === i ? { ...p, type } : p
        )
      )
    );
  };

  const handlePromotionAmountChange = (promotionIdx, amount) => {
    dispatch(
      updateValueAction(
        formName,
        "promotions",
        formData.promotions.map((p, i) =>
          promotionIdx === i ? { ...p, amount } : p
        )
      )
    );
  };

  const handleAddFee = () => {
    dispatch(
      updateValueAction(formName, "fees", [
        ...formData.fees,
        { amount: "0", from: null },
      ])
    );
  };

  const handleRemoveFee = (feeIdx) => {
    dispatch(
      updateValueAction(
        formName,
        "fees",
        formData.fees.filter((_, i) => i !== feeIdx)
      )
    );
  };

  const handleFeeFromDateChange = (feeIdx, from) => {
    dispatch(
      updateValueAction(
        formName,
        "fees",
        formData.fees.map((f, i) => (feeIdx === i ? { ...f, from } : f))
      )
    );
  };

  const handleFeeAmountChange = (feeIdx, amount) => {
    dispatch(
      updateValueAction(
        formName,
        "fees",
        formData.fees.map((f, i) => (feeIdx === i ? { ...f, amount } : f))
      )
    );
  };

  const handleCloseModal = () => {
    onClose();
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formNameValidation));
    dispatch(onFormResetAction(formServerValidation));
  };

  const isInvalidClientValidation = () => {
    if (formClientValidation?.length) return true;

    if (formData?.applyFees) {
      if (formData?.fees?.some((prom) => !prom?.amount)) {
        setErrorMsg("all fees fields are required");
        return true;
      }
    }

    if (formData.applyPromotion) {
      if (
        formData?.applyPromotionAll &&
        (!formData?.promotions?.[0]?.type || !formData?.promotions?.[0]?.amount)
      ) {
        setErrorMsg("all promotions fields are required");
        return true;
      }

      if (
        !formData?.applyPromotionAll &&
        formData?.promotions?.some((prom) => !prom?.type || !prom?.amount)
      ) {
        setErrorMsg("all promotions fields are required");
        return true;
      }
    }

    return false;
  };

  const handleSubmit = () => {
    setFormSubmitting(true);
    if (isInvalidClientValidation()) return;

    store({
      variables: {
        ...serializeUpsertSubscription(formData),
        paid: formData?.paid,
        period: formData?.billingPeriod,
        package_id: +formData?.package_id,
        currency_id: +formData?.currency_id,
      },
      onCompleted: () => {
        refetchList();
        handleCloseModal();
        showToast("success");
      },
      onError: (err) => {
        const validation = err?.graphQLErrors?.[0]?.extensions?.validation;
        dispatch(onFormResetAction(formServerValidation, validation || {}));

        const msg =
          validation?.json?.[0] ||
          err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message;
        setErrorMsg(msg || "");
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={handleCloseModal}
      btnOnClick={handleSubmit}
      btnSubmitLoading={storeLoading}
      modalTitle="New Subscription Request"
    >
      <BSelect
        {...FormProps}
        label="company"
        name="company"
        icon="department"
        rootStyle="w-100"
        validateBy="textRequired"
        options={options.companies}
        isLoading={loading}
        validationName="company_id"
      />
      <div className="d-flex gap-10">
        <BSelect
          {...FormProps}
          label="country"
          name="country"
          icon="globe"
          validateBy="textRequired"
          rootStyle="flex-1"
          options={options.countries}
          isLoading={!options.countries.length}
          validationName="country_id"
        />
        <BSelect
          {...FormProps}
          label="taxation"
          name="taxation"
          icon="document"
          validateBy="textRequired"
          rootStyle="flex-1"
          options={[
            { id: "Custom", name: "Custom" },
            { id: "Egyptian taxation", name: "Egyptian taxation" },
          ]}
          dependOn="country"
          dependancyType="equal"
          dependancyValue={[EgyptId]}
          validationName="taxation"
        />
      </div>
      <div className="d-flex align-items-end justify-content-between gap-10 my-2">
        <RadioboxForm
          {...FormProps}
          name="applyTax"
          options={[
            { label: "Apply VAT", value: 1 },
            { label: "Don't Apply VAT", value: 0 },
          ]}
          optionInputStyle=" "
          optionItemStyle=" "
          containerStyle=" "
          optionsContainerStyle="d-flex gap-20"
          rootStyle="flex-1"
        />
        {formData.taxation === "Custom" && formData.applyTax ? (
          <InputForm
            {...FormProps}
            name="taxAmount"
            placeholder="taxation percentage"
            labelStyle="mb-2 pt-1"
            containerStyle="w-100"
            rootStyle="flex-1"
            validateBy="textRequired"
            dependOn="taxation"
            dependancyType="equal"
            dependancyValue="Custom"
            validationName="taxation"
          />
        ) : null}
      </div>

      <div className="border p-2 mt-4" style={{ borderRadius: 4 }}>
        <BSelect
          {...FormProps}
          label="package"
          name="package_id"
          icon="document"
          rootStyle="w-50 d-inline-block pr-2"
          validateBy="textRequired"
          isLoading={loading}
          options={options.packages}
          validationName="package_id"
        />
        <BSelect
          {...FormProps}
          label="Billing period"
          name="billingPeriod"
          icon="calendar"
          rootStyle="w-50 d-inline-block"
          options={billingPeriodOptions}
          validateBy="textRequired"
        />
        <InputForm
          {...FormProps}
          name="contractDuration"
          label="Contract Duration"
          labelStyle="mb-2 pt-1"
          containerStyle="w-100"
          rootStyle="w-50 d-inline-block pr-2"
          placeholder="No. Of Billing Cycles"
          validateBy="textRequired"
          icon="time"
          validationName="duration"
          disabled={formData?.applyPromotion || formData?.applyFees}
          hasTooltip={Boolean(formData?.applyPromotion || formData?.applyFees)}
          tooltipTitle="select a plan & unselect 'Apply Promotion/Fees' to be able edit"
        />
        <InputForm
          {...FormProps}
          name="employees"
          label="Employees"
          labelStyle="mb-2 pt-1"
          containerStyle="w-100"
          rootStyle="w-50 d-inline-block"
          placeholder="No. of Employees"
          validateBy="textRequired"
          icon="employees"
          validationName="users"
        />
        <InputForm
          {...FormProps}
          name="paid"
          label="paid amount"
          labelStyle="mb-2 pt-1"
          containerStyle="w-100"
          rootStyle="w-50 d-inline-block pr-2"
          validateBy="textRequired"
          icon="money"
          validationName="users"
        />
        <BSelect
          {...FormProps}
          label="currency"
          name="currency_id"
          icon="money"
          validateBy="textRequired"
          isLoading={loading}
          rootStyle="w-50 d-inline-block"
          options={options?.currencies}
        />
        <DateTimePickerForm
          {...FormProps}
          name="startDate"
          label="Start Date"
          labelStyle="mb-2 pt-1"
          containerStyle="w-100"
          rootStyle="w-50 d-inline-block pr-2"
          validateBy="textRequired"
          hasIcon
          isDisabled={!formData.plan && !formData.contractDuration}
          validationName="from"
        />
        <DateTimePickerForm
          {...FormProps}
          name="endDate"
          label="End Date"
          labelStyle="mb-2 pt-1"
          containerStyle="w-100"
          rootStyle="w-50 d-inline-block"
          validateBy="textRequired"
          disabled
          hasIcon
        />
        <BSelect
          {...FormProps}
          label="agent"
          name="agent"
          icon="employee"
          isClearable
          isLoading={loading}
          options={options?.agents}
        />
        <CheckboxBooleanForm
          {...FormProps}
          name="prorateAmount"
          containerStyle="mt-2"
          options={["Prorate the amount of the first month"]}
          disabled
        />
      </div>

      <div style={{ borderRadius: 4 }} className="border px-2 py-3 mt-4">
        <CheckboxBooleanForm
          {...FormProps}
          name="eInvoice"
          options={["E-invoices"]}
        />
      </div>

      {/* Fees */}
      {/* <div style={{ borderRadius: 4 }} className="border px-2 py-3 mt-4">
        <div className="d-flex gap-20 align-items-center">
          <CheckboxBooleanForm
            {...FormProps}
            name="applyFees"
            options={["Apply Fees"]}
            onInterceptInputOnChange={generateFees}
            disabled={!Boolean(+formData?.contractDuration)}
            hasTooltip={!Boolean(+formData?.contractDuration)}
            tooltipTitle="choose a plan and enter your contract duration to apply fees"
          />
          {formData?.applyFees ? (
            <AddButton isIconOnly onClick={handleAddFee} />
          ) : null}
        </div>
        {formData?.applyFees ? (
          <>
            {formData?.fees?.map((fee, i) => (
              <div key={i} className="d-flex gap-20 align-items-end mb-2">
                <DateTimePickerForm
                  hasIcon
                  value={fee?.from || null}
                  name="from"
                  containerStyle="w-100"
                  rootStyle="flex-1"
                  onChange={(date) => handleFeeFromDateChange(i, date)}
                />
                <InputForm
                  name="amount"
                  placeholder="amount"
                  labelStyle="mb-2 pt-1"
                  containerStyle="w-100"
                  inputStyle="w-100 text-left"
                  inputContainerStyle="w-100"
                  value={fee?.amount}
                  rootStyle="flex-1"
                  onChange={(e) => handleFeeAmountChange(i, e.target.value)}
                />
                <TimesIconButton onClick={() => handleRemoveFee(i)} />
              </div>
            ))}
          </>
        ) : null}
      </div> */}

      {/* Promotions */}
      {/* <div style={{ borderRadius: 4 }} className="border px-2 py-3 mt-4">
        <CheckboxBooleanForm
          {...FormProps}
          name="applyPromotion"
          options={["Apply Promotion"]}
          disabled={!Boolean(+formData?.contractDuration)}
          onInterceptInputOnChange={generatePromotions}
          hasTooltip={!Boolean(+formData?.contractDuration)}
          tooltipTitle="choose a plan and enter your contract duration to apply promotions"
        />

        {formData?.applyPromotion && formData?.applyPromotionAll ? (
          <>
            <BSelect
              label="Promotion Type"
              icon="document"
              placeholder="Select Promotion Type"
              rootStyle="w-50 d-inline-block pr-2 mt-2"
              options={promotionTypeOptions}
              value={
                formData?.promotions?.[0]?.type
                  ? formatOption(formData?.promotions?.[0]?.type)
                  : null
              }
              onChange={(val) => handlePromotionTypeChange(0, val.id)}
            />
            <InputForm
              name="amount"
              label={
                formData?.promotions?.[0]?.type === "Percentage"
                  ? "Percent"
                  : "Amount"
              }
              labelStyle="mb-2 pt-1"
              containerStyle="w-100"
              inputStyle="w-100 text-left"
              inputContainerStyle="w-100"
              rootStyle="w-50 d-inline-block mt-2"
              placeholder={
                formData?.promotions?.[0]?.type === "Percentage"
                  ? "Enter percentage"
                  : "Enter amount"
              }
              hasSuffix
              suffixTitle={
                formData?.promotions?.[0]?.type === "Percentage"
                  ? "%"
                  : options?.currencies?.find(
                      (curr) => curr.id === formData?.currency_id
                    )?.name
              }
              value={formData?.promotions?.[0]?.amount}
              onChange={(e) => handlePromotionAmountChange(0, e.target.value)}
            />
          </>
        ) : null}
        {formData?.promotions?.length > 1 ? (
          <CheckboxBooleanForm
            {...FormProps}
            name="applyPromotionAll"
            options={["Apply Promotion to all invoices"]}
            onInterceptInputOnChange={generatePromotions}
            dependOn="applyPromotion"
            dependancyType="equal"
            dependancyValue={[1]}
          />
        ) : null}

        {formData?.applyPromotionAll ? null : (
          <div className="mt-3">
            {formData?.promotions?.map((prom, idx) => (
              <div key={idx} className="d-flex align-items-center gap-10 mb-1">
                <span style={{ minWidth: 70 }}>Invoice #{idx + 1}</span>
                <BSelect
                  icon="document"
                  placeholder="Select Promotion Type"
                  rootStyle="flex-1"
                  options={promotionTypeOptions}
                  value={prom?.type ? formatOption(prom?.type) : null}
                  onChange={(val) => handlePromotionTypeChange(idx, val.id)}
                />
                <InputForm
                  name="amount"
                  labelStyle="mb-2 pt-1"
                  containerStyle="w-100"
                  inputStyle="w-100 text-left"
                  inputContainerStyle="w-100"
                  rootStyle="flex-1"
                  placeholder={
                    prom?.type === "Percentage"
                      ? "Enter percentage"
                      : "Enter amount"
                  }
                  hasSuffix
                  suffixTitle={
                    prom?.type === "Percentage"
                      ? "%"
                      : options?.currencies?.find(
                          (curr) => curr.id === formData?.currency
                        )?.name
                  }
                  value={prom?.amount}
                  onChange={(e) =>
                    handlePromotionAmountChange(idx, e.target.value)
                  }
                />
              </div>
            ))}
          </div>
        )}
      </div> */}

      {errorMsg ? (
        <p role="alert" className="error-color mt-2 mb-0">
          {errorMsg}
        </p>
      ) : null}
    </MainModal>
  );
};

export default SubscriptionRequestModal;
