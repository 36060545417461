import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const DownPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      window.history.pushState({}, null, "/");
    }, 5000);

    return () => {};
  }, []);

  return (
    <section className="statusPage">
      <h2 className="sr-only">{t("forbidden")}</h2>

      <div className="d-flex flex-column align-items-center justify-content-between">
        <div>
          <img
            aria-hidden="true"
            className="down-img-style"
            src="assets/img/unauthorize.png"
            alt=""
          />
        </div>
        <div className="down-message-container">
          <h1>{t("something went worng we are working on it")}</h1>
          <h1>{t("come back soon")}</h1>
        </div>
      </div>
    </section>
  );
};

export default DownPage;
