import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { InputForm } from "../../Builder/Form";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAccessLevelsAttempt,
  onFormResetAction,
  fetchPlanPrivilegesAction,
  dismissAddNewAccessLevelModalAction,
  editRolePrivilegesAttempt,
  upsertRoleToCompanyAttempt,
  deleteCompanyRoleAttmpet,
  fetchRolePresetPrivilegesAction,
  upsertRoleToCompanySuccess,
} from "../../Store/Actions";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import { Spinner } from "reactstrap";
import MainModal from "../../Components/MainModal";
import swal from "sweetalert";
import Select from "react-select";
import RolePrivilegesTreeView from "../../Components/RolePrivilegesTreeView";
import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import AccessLevelAssignment from "./AccessLevelAssignment";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { showToast } from "../../Helpers/HelperFns";

const UPDATE_PLAN_ROLE = gql`
  mutation updatePlanRole($input: AddRoleLimitAccessInput) {
    upsert_limit_access_to_role(input: $input) {
      id
    }
  }
`;

const AccessLevels = () => {
  const formName = "upsertRoleToCompanyForm";
  const dataTableRef = "accessLevels";
  const formNameValidation = "accessLevelsFormValidation";
  const formServerValidation = "accessLevelsServerValidation";
  const [modifiyingState, setModifiyingState] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [presetRole, setpresetRole] = useState(null);
  const [isCompanyRole, setIsCompanyRole] = useState(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const planId = useSelector(
    (state) => state.auth.userProfile?.company?.activeSubscription?.plan?.id
  );
  const accessLevels = useSelector((state) => state.super?.accessLevels);
  const fetchingAccessLevelsLoading = useSelector(
    (state) => state.super?.fetchingAccessLevelsLoading
  );
  const editRoleToCompanyLoading = useSelector(
    (state) => state.super?.editRoleToCompanyLoading
  );
  const upsertRoleToCompanyForm = useSelector(
    (state) => state.super?.upsertRoleToCompanyForm
  );
  const upsertRoleToCompanyLoading = useSelector(
    (state) => state.super?.upsertRoleToCompanyLoading
  );
  const deleteCompanyRoleLoading = useSelector(
    (state) => state.super?.deleteCompanyRoleLoading
  );
  const accessLevelsFormValidation = useSelector(
    (state) => state.super?.accessLevelsFormValidation
  );
  const loadingRolePresetPrivileges = useSelector(
    (state) => state.super?.loadingRolePresetPrivileges
  );
  const managePrivilagesModal = useSelector(
    (state) => state.super?.managePrivilagesModal
  );
  const plan_privileges = useSelector((state) => state.super?.plan_privileges);
  const loadingPlanPrivileges = useSelector(
    (state) => state.super?.loadingPlanPrivileges
  );

  const [updatePlanRole, { loading: updatePlanRoleLoading }] =
    useMutation(UPDATE_PLAN_ROLE);

  useEffect(() => {
    dispatch(fetchAccessLevelsAttempt());
    dispatch(onFormResetAction(dataTableRef));
    return () => {
      dispatch(onFormResetAction(dataTableRef));
      dispatch(onFormResetAction("managePrivilagesModal"));
      setModifiyingState(null);
    };
  }, []);

  useEffect(() => {
    if (presetRole?.value) {
      dispatch(fetchRolePresetPrivilegesAction(presetRole?.value));
    }
  }, [presetRole]);

  useEffect(() => {
    if (!accessLevels.isModalVissible) {
      setIsSubmitting(false);
      setpresetRole(null);
      dispatch(onFormResetAction(formServerValidation));
      dispatch(onFormResetAction(formName));
      dispatch(onFormResetAction("managePrivilagesModal"));
    }
  }, [accessLevels.isModalVissible]);

  if (fetchingAccessLevelsLoading) {
    return <Loader />;
  }

  const renderAccessLevelsByFilter = () => {
    const { filter, data } = accessLevels;
    if (filter) {
      return data.filter((accessLevel) =>
        accessLevel.label.toLowerCase().includes(filter.toLowerCase())
      );
    } else {
      return data;
    }
  };

  const handleEdit = (role, isCompanyRole) => {
    if (loadingPlanPrivileges) {
      return;
    }
    let { value, label } = role;
    dispatch(editRolePrivilegesAttempt(planId, value, label));
    setModifiyingState(label);
    setIsCompanyRole(isCompanyRole);
  };

  const columns = [
    {
      name: t("name"),
      wrap: true,
      selector: "label",
      sortable: false,
      grow: 1,
    },
    {
      name: t("actions"),
      wrap: true,
      selector: "action",
      grow: 0.03,
      cell: (row) => {
        let companyRole = row?.roleable?.type.toLowerCase() === "company";
        return (
          <HasPrivileges
            reqireMain={[Privilages.ADD_EDIT_DELETE_NEW_ACCESS_LEVELS]}
            allowBP
          >
            <div className="cards_table_actions">
              <EditIconButton onClick={() => handleEdit(row, companyRole)} />
              {companyRole ? (
                <RemoveIconButton onClick={() => handleDelete(row)} />
              ) : null}
            </div>
          </HasPrivileges>
        );
      },
      ignoreRowClick: true,
    },
  ];

  const handleAddNewBtn = () => {
    dispatch(fetchPlanPrivilegesAction(planId));
    setModifiyingState("create");
    setIsCompanyRole(true);
  };

  const modalTitle = () => {
    if (modifiyingState === "create") {
      return t("add new access level");
    } else {
      return `${t("edit access level")} - ${modifiyingState}`;
    }
  };

  const handleDelete = (row) => {
    if (loadingPlanPrivileges) {
      return;
    }
    let roleId = row.value;
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteCompanyRoleAttmpet(roleId));
      }
    });
  };

  const handleSubmit = () => {
    let { id, name, assign_all, positions, employees } =
      upsertRoleToCompanyForm;
    const limit_access = assign_all
      ? null
      : positions?.length || employees?.length
        ? { positions: positions || [], employees: employees || [] }
        : null;

    if (isCompanyRole) {
      let { main, all, managed } = managePrivilagesModal;
      setIsSubmitting(true);
      if (accessLevelsFormValidation.length > 0) {
        return;
      }
      if (!main.length && !all.length && !managed.length) {
        return;
      }
      let data = {
        id,
        name,
        privileges: main,
        PrivilegesWithScopeAll: all,
        PrivilegesWithScopeManaged: managed,
        anyOneCanSee: Boolean(assign_all),
        limit_access,
      };
      dispatch(upsertRoleToCompanyAttempt(data));
    } else {
      updatePlanRole({
        variables: {
          input: {
            role_id: id,
            anyOneCanSee: Boolean(assign_all),
            limit_access,
          },
        },
        onCompleted: () => {
          showToast("success");
          dispatch(upsertRoleToCompanySuccess());
        },
        onError: () => {
          showToast("error");
        },
      });
    }
  };

  const handleCancel = () => {
    dispatch(dismissAddNewAccessLevelModalAction());
    setIsSubmitting(false);
  };

  return (
    <div>
      {editRoleToCompanyLoading || deleteCompanyRoleLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}
      {/* filter and add new button start */}
      <div className="d-flex justify-content-between align-items-center my-3">
        <div className="col px-0">
          <InputForm
            name="filter"
            formName={dataTableRef}
            placeholder={t("search")}
            containerStyle=" "
            inputContainerStyle="search_container"
            inputStyle="search_input"
            icon="search"
          />
        </div>
        <HasPrivileges
          reqireMain={[Privilages.ADD_EDIT_DELETE_NEW_ACCESS_LEVELS]}
          allowBP
        >
          <div className="pl-3 text-right">
            <button
              className="btn add_new_btn_style"
              type="button"
              onClick={handleAddNewBtn}
              disabled={loadingPlanPrivileges}
            >
              {loadingPlanPrivileges ? (
                <>
                  <Spinner
                    style={{ width: "1rem", height: "1rem", color: "#fff" }}
                  />
                  <span className="mx-2 text-capitalize">{t("loading")}</span>
                </>
              ) : (
                <>
                  <FontAwesomeIcon className="mr-2" icon={faPlus} />
                  <span>{t("add new")}</span>
                </>
              )}
            </button>
          </div>
        </HasPrivileges>
      </div>
      {/* filter and add new button end */}
      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="cards_table"
        columns={columns}
        data={renderAccessLevelsByFilter()}
        noHeader
        persistTableHead
      />

      <MainModal
        isOpen={accessLevels.isModalVissible}
        btnLabelCancel="Cancel"
        btnOnCancelClick={handleCancel}
        modalTitle={modalTitle()}
        btnOnClick={handleSubmit}
        btnSubmitLoading={upsertRoleToCompanyLoading || updatePlanRoleLoading}
        className="manage_privilages_modal_wrapper_style"
      >
        <InputForm
          name="name"
          formNameValidation={formNameValidation}
          formServerValidation={formServerValidation}
          formName={formName}
          formSubmitting={isSubmitting}
          validateBy="textRequired"
          validationName="input.name"
          placeholder={t("name")}
          inputStyle="form-control access-level-input"
          label={t("name")}
          labelStyle="text-nowrap mr-3 access-level-labels"
          containerStyle="d-flex align-items-center justify-content-start"
          rootStyle="px-custom-padding-1"
          inputContainerStyle="form-group mb-0 d-flex access-levels-inputs-container"
        />
        {isCompanyRole ? (
          <div className="d-flex flex-row align-items-center px-3 py-1">
            <label className="access-level-labels mr-3">{t("copy from")}</label>
            <Select
              className={
                "select-def-input-containe b-select-style col-md-6 col-sm-12 access-levels-inputs-container px-0"
              }
              classNamePrefix={"b-select-style"}
              value={presetRole}
              onChange={(val) => setpresetRole(val)}
              isLoading={loadingRolePresetPrivileges}
              isClearable={true}
              isRtl={document.dir?.toLowerCase() == "rtl"}
              isSearchable
              id="role_preset"
              placeholder={t("select option")}
              options={accessLevels?.data}
            />
          </div>
        ) : null}
        <div className="px-3">
          <AccessLevelAssignment
            isEdit={Boolean(upsertRoleToCompanyForm?.id)}
            FormProps={{
              formName: formName,
              formSubmitting: isSubmitting,
              formNameValidation: formNameValidation,
              formServerValidation: formServerValidation,
            }}
          />
        </div>
        {loadingRolePresetPrivileges ? (
          <div className="loader_wrapper_style">
            <Loader />
          </div>
        ) : null}
        {isCompanyRole ? (
          <RolePrivilegesTreeView
            companyLevel
            parents={JSON.parse(plan_privileges)}
          />
        ) : null}
      </MainModal>
    </div>
  );
};

export default AccessLevels;
