import React from "react";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import {
  showToast,
  getToDate,
  quarterToMonth,
} from "../../../Helpers/HelperFns";
import { clsx } from "clsx";
import moment from "moment";
import { onFormResetAction } from "../../../Store/Actions";
import Constants, { quartersOptions } from "../../../Constants";
import { churnReportQuery } from "../../../Graphql/query/PaymentTracking";

import BSelect from "form-builder/BSelect";
import {
  CRMReportBarChart,
  CRMReportLinearChart,
} from "../../../Components/CRM";
import CheckboxBooleanForm from "form-builder/CheckboxBooleanForm";
import { Skeleton, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ViewCancelledCompanies } from "../../../Components/PaymentTrackingModals";

const reducer = "paymentTracking";
const filtersFormName = "overviewReportsListFilters";
const modalInitState = { isOpen: false, data: null };
const FiltersFormProps = { reducer, formName: filtersFormName };
const getFiltersVariables = (filters) => {
  let input = {
    to_date: null,
    from_date: null,
    display_currency_id: +filters?.display_currency_id || 0,
    payments_currency_id: +filters?.payments_currency_id || 0,
    country_id: filters?.country_id ? filters?.country_id?.map((c) => +c) : [],
  };

  switch (filters.view) {
    case "monthly":
      input.from_date = `${filters?.fromYear}-${filters?.fromMonth}-01`;
      input.to_date = getToDate(`${filters?.toYear}-${filters?.toMonth}`);
      break;

    case "quarterly":
      (input.from_date = `${filters?.fromYear}-${quarterToMonth(
        filters?.fromQuarter,
        "from"
      )}-01`),
        (input.to_date = getToDate(
          `${filters?.toYear}-${quarterToMonth(filters?.toQuarter, "to")}`
        ));
      break;

    case "annually":
      input.from_date = moment()
        .year(filters?.fromYear)
        .startOf("year")
        .format("YYYY-MM-DD");
      input.to_date = moment()
        .year(filters?.toYear)
        .endOf("year")
        .format("YYYY-MM-DD");
      break;

    default:
      break;
  }

  return { input };
};

const Churn = () => {
  const dispatch = useDispatch();

  // Local State
  const [options, setOptions] = React.useState({
    currencies: [],
    countries: [],
  });

  // Reducer State
  const filters = useSelector((state) => state?.[reducer]?.[filtersFormName]);

  // Server State
  const { loading, data } = useQuery(churnReportQuery, {
    notifyOnNetworkStatusChange: true,
    variables: getFiltersVariables(filters),
    onCompleted: (res) => {
      setOptions({
        countries: res?.countries || [],
        currencies: res?.currencies || [],
      });
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  /* ↓ Helpers ↓ */

  const handleViewChange = (_, view) => {
    dispatch(onFormResetAction(filtersFormName, { ...filters, view }));
  };

  const renderDateFilters = () => {
    switch (filters.view) {
      case "monthly":
        return (
          <>
            <div className="d-flex align-items-center gap-10 mr-2">
              <b>from</b>
              <BSelect
                {...FiltersFormProps}
                name="fromMonth"
                inputContainerStyle="w-100"
                options={Constants.MonthsData}
                rootStyle="min-w-150"
                optionLabel="key"
                optionValue="key"
              />
              <BSelect
                {...FiltersFormProps}
                name="fromYear"
                inputContainerStyle="w-100"
                options={Constants.YearData}
                rootStyle="min-w-150"
                optionLabel="value"
                optionValue="value"
              />
            </div>
            <div className="d-flex align-items-center gap-10">
              <b>to</b>
              <BSelect
                {...FiltersFormProps}
                name="toMonth"
                inputContainerStyle="w-100"
                options={Constants.MonthsData}
                rootStyle="min-w-150"
                optionLabel="key"
                optionValue="key"
              />
              <BSelect
                {...FiltersFormProps}
                name="toYear"
                inputContainerStyle="w-100"
                options={Constants.YearData}
                rootStyle="min-w-150"
                optionLabel="value"
                optionValue="value"
              />
            </div>
          </>
        );

      case "quarterly":
        return (
          <>
            <div className="d-flex align-items-center gap-10 mr-2">
              <b>from</b>
              <BSelect
                {...FiltersFormProps}
                name="fromQuarter"
                inputContainerStyle="w-100"
                options={quartersOptions}
                rootStyle="min-w-150"
              />
              <BSelect
                {...FiltersFormProps}
                name="fromYear"
                inputContainerStyle="w-100"
                options={Constants.YearData}
                rootStyle="min-w-150"
                optionLabel="value"
                optionValue="value"
              />
            </div>
            <div className="d-flex align-items-center gap-10">
              <b>to</b>
              <BSelect
                {...FiltersFormProps}
                name="toQuarter"
                inputContainerStyle="w-100"
                options={quartersOptions}
                rootStyle="min-w-150"
              />
              <BSelect
                {...FiltersFormProps}
                name="toYear"
                inputContainerStyle="w-100"
                options={Constants.YearData}
                rootStyle="min-w-150"
                optionLabel="value"
                optionValue="value"
              />
            </div>
          </>
        );

      case "annually":
        return (
          <div className="d-flex gap-10">
            <BSelect
              {...FiltersFormProps}
              label="from"
              name="fromYear"
              labelStyle="font-weight-bold"
              containerStyle="d-flex gap-10 align-items-center"
              placeholder="year"
              inputContainerStyle="w-100"
              options={Constants.YearData}
              rootStyle="min-w-150"
              optionLabel="value"
              optionValue="value"
            />
            <BSelect
              {...FiltersFormProps}
              label="to"
              name="toYear"
              labelStyle="font-weight-bold"
              containerStyle="d-flex gap-10 align-items-center"
              placeholder="year"
              inputContainerStyle="w-100"
              options={Constants.YearData}
              rootStyle="min-w-150"
              optionLabel="value"
              optionValue="value"
            />
          </div>
        );

      default:
        break;
    }
  };

  return (
    <>
      {/* Filters */}
      <div className="d-flex align-items-center gap-20 mb-2">
        <ToggleButtonGroup
          exclusive
          size="small"
          color="primary"
          value={filters.view}
          onChange={handleViewChange}
          aria-label="Select View Mode"
        >
          <ToggleButton value="monthly">monthly</ToggleButton>
          <ToggleButton value="quarterly">quarterly</ToggleButton>
          <ToggleButton value="annually">annually</ToggleButton>
        </ToggleButtonGroup>
        {renderDateFilters()}
        <div className="d-flex gap-10 flex-1">
          <BSelect
            isClearable
            {...FiltersFormProps}
            placeholder="Select Display Currency"
            name="display_currency_id"
            icon="money"
            rootStyle="flex-1"
            options={options?.currencies || []}
          />
          <BSelect
            isClearable
            {...FiltersFormProps}
            placeholder="Select Payment Currency"
            name="payments_currency_id"
            icon="money"
            rootStyle="flex-1"
            options={options?.currencies || []}
          />
          <BSelect
            isMulti
            isClearable
            {...FiltersFormProps}
            placeholder="Select Countries"
            name="country_id"
            rootStyle="flex-1"
            options={options?.countries || []}
          />
        </div>
        <CheckboxBooleanForm
          {...FiltersFormProps}
          setWithValue
          name="forecasted"
          containerStyle="mt-2"
          options={["show forecasted data"]}
          dependOn="view"
          dependancyType="equal"
          dependancyValue={["quarterly", "annually"]}
        />
      </div>

      {/* Reports */}
      <ClientChurnRate
        loading={loading}
        view={filters.view}
        forecasted={filters.forecasted}
        data={data?.churnCharts?.churnRate}
      />
    </>
  );
};

export default Churn;

/*
  Client Churn Rate
*/

const formatClientChurnRateChart = ({ isUsers, ...args }) => {
  if (!args?.data) return { labels: [], datasets: [] };
  const d = getData(args);

  const formattedData = d?.reduce(
    (acc, curr) => {
      // Labels
      curr?.year && acc.years.push(curr.year);
      curr.month_year && acc.months.push(curr.month_year);
      curr?.quarter &&
        acc.quarters.push("Q" + curr.quarter + " - " + curr.year);

      // Data
      if (isUsers) {
        acc.rate.push((curr.users_churn_rate || 0)?.toString());
        acc.active.push(
          (curr.active_subscriptions_users_count || 0)?.toString()
        );
        acc.cancelled.push(
          (curr.cancelled_subscriptions_users_count || 0)?.toString()
        );
      } else {
        acc.rate.push((curr.clients_churn_rate || 0)?.toString());
        acc.active.push((curr.active_subscriptions_count || 0)?.toString());
        acc.cancelled.push(
          (curr.cancelled_subscriptions_count || 0)?.toString()
        );
      }

      // IDs
      acc.ids.push(curr.cancelled_companies_ids?.split(","));

      return acc;
    },
    {
      ids: [],
      rate: [],
      years: [],
      active: [],
      months: [],
      quarters: [],
      cancelled: [],
    }
  );

  let labels = formattedData?.months;
  if (args?.view === "annually") labels = formattedData.years;
  if (args?.view === "quarterly") labels = formattedData?.quarters;
  return {
    labels,
    ids: formattedData.ids,
    datasets: [{ data: formattedData.rate }],
  };
};

const ClientChurnRate = ({ loading, ...props }) => {
  // Local State
  const [isUsers, setIsUsers] = React.useState(false);
  const [showRelevantCompaniesState, setShowRelevantCompaniesState] =
    React.useState(modalInitState);

  // Constants
  const chartData = formatClientChurnRateChart({ ...props, isUsers });

  /* Helpers */

  const handleShowRelevantCompaniesState = (_, ele) => {
    const _index = ele[0]?._index;

    const companiesIDs = chartData?.ids?.[_index];
    if (companiesIDs) {
      setShowRelevantCompaniesState({
        isOpen: true,
        data: { companiesIDs: chartData?.ids?.[_index] },
      });
    }
  };

  return (
    <Widget loading={loading}>
      <div className="d-flex align-items-center justify-content-between">
        <b className="d-block text-16">Client Churn Rate (%)</b>
        <ToggleClientsUsers isUsers={isUsers} setIsUsers={setIsUsers} />
      </div>
      <CRMReportLinearChart
        noLegends
        chartData={chartData}
        options={{
          onClick: handleShowRelevantCompaniesState,
          tooltips: {
            callbacks: { footer: () => "Click to see Cancelled Companies" },
          },
          scales: {
            yAxes: [{ ticks: { callback: (val) => val + "%" } }],
          },
        }}
      />
      {showRelevantCompaniesState?.isOpen ? (
        <ViewCancelledCompanies
          data={showRelevantCompaniesState?.data}
          onClose={() => setShowRelevantCompaniesState(modalInitState)}
        />
      ) : null}
    </Widget>
  );
};

/*
  Utils
*/

const getData = ({ data, view, forecasted }) => {
  let d = data?.monthly || [];

  // Annually
  if (view === "annually" && !forecasted) d = data?.yearly?.current;
  if (view === "annually" && forecasted) d = data?.yearly?.forecasted;

  // Quarterly
  if (view === "quarterly" && !forecasted) d = data?.quarterly?.current;
  if (view === "quarterly" && forecasted) d = data?.quarterly?.forecasted;

  return d;
};

const Widget = ({ loading, children, classes, style }) => (
  <div className={clsx("card overflow-hidden", classes)} style={style}>
    {loading ? (
      <Skeleton width="50vw" height={200} variant="rectangular" />
    ) : (
      <div className="card-body">{children}</div>
    )}
  </div>
);

const ToggleClientsUsers = ({ isUsers, setIsUsers }) => {
  const handleViewChange = (_, view) => {
    setIsUsers(view);
  };

  return (
    <ToggleButtonGroup
      exclusive
      size="small"
      color="primary"
      value={isUsers}
      onChange={handleViewChange}
    >
      <ToggleButton value={false}>Clients</ToggleButton>
      <ToggleButton value={true}>Users</ToggleButton>
    </ToggleButtonGroup>
  );
};
