import React from "react";

import Chart from "chart.js";
import chroma from "chroma-js";
import { uniqueId } from "lodash";
import { Skeleton } from "@mui/material";

const CRMReportLinearChart = ({ loading, chartData = [], ...props }) => {
  const ref = React.useRef();
  const { legend = {}, ...options } = props?.options || {},
    id = `linear-chart-${uniqueId()}`,
    colors = chroma
      .scale(["blue", "red", "green", "purple", "orange"])
      .mode("lch")
      .colors(chartData?.datasets.length),
    data = {
      ...chartData,
      datasets: chartData?.datasets?.map((d, i) => ({
        ...d,
        borderColor: colors[i],
        // backgroundColor: chroma(colors[i]).alpha(0.5).css(),
        fill: false,
      })),
    };

  React.useEffect(() => {
    if (!loading) {
      if (ref.current) ref.current.destroy();
      const ctx = document.getElementById(id).getContext("2d");

      ref.current = new Chart(ctx, {
        type: "line",
        data,
        options: {
          responsive: true,
          hover: {
            animationDuration: 0,
          },
          legend: {
            ...legend,
            display: !props?.noLegends,
          },
          ...options,
        },
      });
    }
  }, [loading, JSON.stringify(data)]);

  return (
    <div className="d-flex justify-content-center mt-4">
      <div
        style={{
          maxWidth: "100%",
          width: props?.child ? 900 : 1100,
          height: loading ? 500 : undefined,
        }}
      >
        {loading ? (
          <div className="h-100 mt-2">
            <div className="d-flex gap-10 mb-2 justify-content-center">
              {chartData?.datasets.map((d) => (
                <Skeleton
                  key={d?.label}
                  variant="rectangular"
                  width={50}
                  height={15}
                />
              ))}
            </div>
            <Skeleton
              height="100%"
              variant="rectangular"
              sx={{ borderRadius: 1 }}
            />
          </div>
        ) : (
          <canvas id={id} width="100%"></canvas>
        )}
      </div>
    </div>
  );
};

export default CRMReportLinearChart;
