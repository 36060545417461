import React, { useState, useEffect } from "react";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import PlaceIcon from "@mui/icons-material/Place";
import IconButtonWithTooltip from "../../../Components/IconButtonWithTooltip";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import { paginatorFragment } from "../../../Graphql/fragments";
import { showToast } from "../../../Helpers/HelperFns";
import { togglefailedSignInsLocationMapModal } from "../../../Store/Actions";
import Loader from "../../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../../Components/Pagination";
import { ColorButton } from "../../../Components/Buttons";
import { BSelect, DateTimePickerForm } from "form-builder";
import FailedSignInLocationMap from "./FailedSignInLocationMap";
import { useDispatch, useSelector } from "react-redux";

// company options query
const fetchCompanyOptionsQuery = gql`
  query fetchCompanyOptionsQuery {
    companiesOptions: companiesList {
      id
      name
    }
  }
`;

// employee and offices options query
const fetchOptionsQuery = gql`
  query fetchOptionsQuery($company_id: ID) {
    employeesOptions: users_by_company(company_id: $company_id) {
      id
      name
    }
    officesOptions: companyOfficesList(company_id: $company_id) {
      id
      name
    }
  }
`;

// failed sign ins query
const fetchFailedSignInsLogsQuery = gql`
  query fetchFailedSignInsLogsQuery(
    $first: Int!
    $page: Int
    $input: FailedSignInOutFilterInput
  ) {
    users_failed_sign_in_outs_attempts(
      first: $first
      page: $page
      input: $input
    ) {
      data {
        id
        employee {
          user {
            id
            name
            email
            company {
              id
              name
            }
          }
        }
        date_time
        user_lat
        user_lng
        calculated_distance
        max_distance
        space {
          id
          name
        }
      }
      paginatorInfo {
        ...paginator
      }
    }
  }

  ${paginatorFragment}
`;

const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const sendFilters = (filters) => {
  return {
    page: 1,
    input: {
      emp_id: filters?.emp_id?.map((el) => el?.id),
      space_id: filters?.space_id?.map((el) => el?.id),
      company_id: filters?.company_id?.id,
      to: filters?.to?.format("YYYY-MM-DD"),
      from: filters?.from?.format("YYYY-MM-DD"),
      orderBy: [
        {
          column: "date_time",
          order: "DESC",
        },
      ],
    },
  };
};

const FailedSignInsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [listData, setData] = useState([]);
  const [pagination, setPagination] = useState(paginationInitState);
  const [options, setOptions] = useState({
    officesOptions: [],
    employeesOptions: [],
    companiesOptions: [],
  });
  const [filters, setFilters] = useState({
    emp_id: null,
    space_id: null,
    company_id: "",
    to: null,
    from: null,
  });

  // Redux States
  const failedSignInsLocationMapModalIsOpen = useSelector(
    (state) => state?.admin?.failedSignInsLocationMapModal?.isOpen
  );

  // handle fetch company filter options query
  const [handleFetchCompanyOptions, { loading: isFetchCompanyOptionsLoading }] =
    useLazyQuery(fetchCompanyOptionsQuery, {
      onCompleted: (data) => {
        setOptions((prev) => ({
          ...prev,
          companiesOptions: data?.companiesOptions || [],
        }));
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });

  // handle fetch filter options query
  const [handleFetchOptions, { loading: isFetchOptionsLoading }] = useLazyQuery(
    fetchOptionsQuery,
    {
      variables: {
        company_id: filters?.company_id?.id ?? null,
      },
      onCompleted: (data) => {
        setOptions((prev) => ({
          ...prev,
          officesOptions: data?.officesOptions || [],
          employeesOptions: data?.employeesOptions || [],
        }));
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    }
  );

  // handle fetch failed sign ins query
  const [getFailedSignInsLogs, { loading, refetch: refetchList }] =
    useLazyQuery(fetchFailedSignInsLogsQuery, {
      notifyOnNetworkStatusChange: true,
      variables: {
        ...sendFilters(filters),
        first: pagination?.perPage,
      },
      onCompleted: (data) => {
        setData(data?.users_failed_sign_in_outs_attempts?.data || []);
        setPagination(
          data?.users_failed_sign_in_outs_attempts?.paginatorInfo ||
            paginationInitState
        );
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });

  // Constants
  const applyFailedSignInFilters = Boolean(filters?.company_id?.id);
  const columns = [
    {
      name: t("employee"),
      wrap: true,
      grow: 1,
      cell: (row) => <>{row?.employee?.user?.name}</>,
    },
    {
      name: t("company"),
      wrap: true,
      grow: 1,
      cell: (row) => <>{row?.employee?.user?.company?.name}</>,
    },
    {
      name: t("date time"),
      wrap: true,
      grow: 1,
      cell: (row) => <>{moment(row?.date_time).format("DD-MM-YYYY hh:mm A")}</>,
    },
    {
      name: t("locations"),
      wrap: true,
      grow: 1,
      cell: (row) => (
        <IconButtonWithTooltip
          label="view location"
          onClick={() => handleViewFailedSignInsLocationMap(row)}
          icon={<PlaceIcon fontSize="small" sx={{ color: "#5c6974" }} />}
        />
      ),
    },
    {
      name: t("calculated distance"),
      wrap: true,
      grow: 1,
      cell: (row) => <>{row?.calculated_distance}</>,
    },
    {
      name: t("maximum distance"),
      wrap: true,
      grow: 1,
      cell: (row) => <>{row?.max_distance}</>,
    },
    {
      name: t("offices"),
      wrap: true,
      grow: 1,
      cell: (row) => <>{row?.space?.name}</>,
    },
  ];

  /* ↓ Helpers ↓ */
  const handleSelectFilters = (val, { name }) => {
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  const handleDateFiltersChange = (name, val) => {
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  const handlePaginate = (page = pagination.currentPage) => {
    refetchList({ page });
  };

  // handle view asset units function
  const handleViewFailedSignInsLocationMap = (modalData) => {
    dispatch(togglefailedSignInsLocationMapModal({ isOpen: true, modalData }));
  };

  useEffect(() => {
    // fetch company options
    handleFetchCompanyOptions();
  }, []);

  // handle fetch the rest of the options according to company selected
  useEffect(() => {
    // fetch list options
    handleFetchOptions();
  }, [filters?.company_id]);

  return (
    <>
      {isFetchCompanyOptionsLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      {/* Filters */}
      <div className="d-flex align-items-end gap-20 my-4">
        <BSelect
          name="company_id"
          label="Companies"
          value={filters?.company_id}
          onChange={handleSelectFilters}
          isLoading={isFetchOptionsLoading}
          inputContainerStyle="w-100"
          labelStyle="mb-0"
          options={options?.companiesOptions}
          rootStyle="flex-1"
          containerStyle="d-flex flex-column gap-10"
        />
        <BSelect
          name="emp_id"
          label="employees"
          value={filters?.emp_id}
          onChange={handleSelectFilters}
          isLoading={isFetchOptionsLoading}
          inputContainerStyle="w-100"
          labelStyle="mb-0"
          options={options?.employeesOptions}
          rootStyle="flex-1"
          isMulti
          containerStyle="d-flex flex-column gap-10"
        />
        <BSelect
          name="space_id"
          label="offices"
          value={filters?.space_id}
          isLoading={isFetchOptionsLoading}
          onChange={handleSelectFilters}
          inputContainerStyle="w-100"
          labelStyle="mb-0"
          options={options?.officesOptions}
          rootStyle="flex-1"
          isMulti
          containerStyle="d-flex flex-column gap-10"
        />
        <DateTimePickerForm
          hasIcon
          label="from"
          value={filters?.from}
          rootStyle="flex-1"
          datePickerContainer="w-100"
          containerStyle="d-flex flex-column gap-10"
          onChange={(val) => handleDateFiltersChange("from", val)}
        />
        <DateTimePickerForm
          hasIcon
          label="to"
          value={filters?.to}
          rootStyle="flex-1"
          datePickerContainer="w-100"
          containerStyle="d-flex flex-column gap-10"
          onChange={(val) => handleDateFiltersChange("to", val)}
        />
        <ColorButton
          onClick={getFailedSignInsLogs}
          label="apply filters"
          disabled={!applyFailedSignInFilters}
          disabledLabel="you need to select company filter to be able to get records"
        />
      </div>

      {/* List */}
      <DataTable
        noHeader
        persistTableHead
        sortServer
        data={listData}
        columns={columns}
        className="cards_table"
        paginationComponent={(i) => (
          <Pagination
            key={i}
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
        pagination
        paginationServer
        progressPending={loading}
        progressComponent={<Loader />}
      />

      {/* Start of failed sign ins location map modal */}
      {failedSignInsLocationMapModalIsOpen ? <FailedSignInLocationMap /> : null}
      {/* End of failed sign ins location map modal */}
    </>
  );
};

export default FailedSignInsList;
