import React from "react";
import ReactDOM from "react-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

import App from "./App";

import * as Sentry from "@sentry/react";

//Import Mixpanel SDK
// import mixpanel from "mixpanel-browser";
// import { Config } from "./App/Config";

// import Hotjar from "@hotjar/browser";

// if (!!Config.SITE_ID && !!Config.HOTJAR_VERSION) {
//   Hotjar.init(Config.SITE_ID, Config.HOTJAR_VERSION);
// }

// Near entry of your product, init Mixpanel
// if (!!Config.PROJECT_TOKEN) {
//   mixpanel.init(Config.PROJECT_TOKEN, {
//     debug: false,
//     track_pageview: true,
//     batch_requests: true,
//     batch_size: 50,
//     async: true,
//   });
// }

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    fallbackLng: "en-US",
    detection: {
      order: [
        "cookie",
        "htmlTag",
        "localStorage",
        "sessionStorage",
        "navigator",
        "path",
        "subdomain",
      ],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
    react: {
      useSuspense: false,
    },
  });

Sentry.init({
  environment: import.meta.env.REACT_APP_ENVIRONMENT,
  dsn: import.meta.env.REACT_APP_SENTRY_KEY,
  release: "2.0.6",
  integrations: [
    Sentry.browserTracingIntegration({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "mawaredhr",
        "mawared-hr",
        "https://serene.qurtobasolutions.com/graphql",
      ],
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: ["https://serene.qurtobasolutions.com/graphql"],
      networkRequestHeaders: ["Cache-Control"],
      networkResponseHeaders: ["Referrer-Policy"],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <Sentry.ErrorBoundary>
    <App />
  </Sentry.ErrorBoundary>,

  document.getElementById("root")
);
