import React, { useState, useEffect, useRef } from "react";
import { BSelect, DateTimePickerForm, InputForm } from "form-builder";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import Divider from "@mui/material/Divider";
import {
  fetchScheduleResourcesQueryFlex,
  fetchFlexScheduleWorkGroupsQuery,
  fetchWorkScheduleQueryFlex,
  fetchScheduleShiftsQuery,
  fetchShiftQueryFlex,
  fetchShiftEmployeesQueryFlex,
  GET_FLEX_SCHEDULE_SHIFTS,
} from "../../Graphql/query";
import {
  upsertShiftByLocationMutationFlex,
  upsertShiftByEmployeeMutationFlex,
  updateShiftMutationFlex,
  cloneShiftMutationFlex,
  deleteShiftMutationFlex,
  upsertFlexScheduleMutation,
  publishScheduleMutationFlex,
} from "../../Graphql/mutation";
import { setWorkScheduleServerValidationAction } from "../../Store/Actions/shiftsActions/actions.js";

// Icons
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Groups3OutlinedIcon from "@mui/icons-material/Group";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOn";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/Add";
import { makeStyles } from "tss-react/mui";
import i18n from "i18next";
import { useSelector } from "react-redux";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import {
  Eventcalendar,
  snackbar,
  setOptions,
  formatDate,
  toast,
  Select,
  Popup,
  Button,
  localeAr,
  localeEn,
} from "@mobiscroll/react";
import moment from "moment";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Constants from "../../Constants";
import _ from "lodash";
import Switch from "@mui/material/Switch";
import { useHistory } from "react-router-dom";

import MainModal from "../../Components/MainModal";
import Loader from "../../Components/Loader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { handleActionMutation } from "../../Helpers/HelperFns";
import { useTranslation } from "react-i18next";
import Validations from "../../Helpers/Validations";
import { Spinner } from "reactstrap";
import HelperFns from "../../Helpers/HelperFns";
import {
  setShouldExitCalendarPage,
  showSuccessToast,
} from "../../Store/Actions";
import { Link } from "react-router-dom";

const useCustomFormValidation = ({
  inputValue,
  validateBy = "textRequired",
}) => {
  const [isValid, setIsValid] = useState(false);
  const [validationMessage, SetValidationMessage] = useState("");

  useEffect(() => {
    Validations.validationSchema
      .validateAt(validateBy, { [validateBy]: inputValue })
      .then((valid) => {
        setIsValid(true);
        SetValidationMessage("");
      })
      .catch((err) => {
        setIsValid(false);
        SetValidationMessage(err?.message);
      });
  }, [inputValue]);
  return { isValid, validationMessage };
};

const horizontalNavbarStyles = makeStyles(
  () => Constants.horizontalNavbarStyles
);

setOptions({
  theme: "ios",
  themeVariant: "light",
});
const contextMenu = [
  {
    text: "Copy",
    value: "copy",
  },
  {
    text: "Delete",
    value: "delete",
  },
];

const pasteContextMenu = [
  {
    text: "Paste",
    value: "paste",
  },
];

//   form props
const formName = "workScheduleForm";
const formNameValidation = "workScheduleFormValidation";
const formServerValidation = "workScheduleFormServerValidation";
const reducerName = "shifts";

const CreateWorkSchedulePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cycleLength = [...Array(100)].map((_, i) => {
    return {
      id: i + 1,
      name: i + 1,
    };
  });

  const [formSubmitting, setFormSubmitting] = useState(false);

  const formProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: formSubmitting,
    reducer: reducerName,
  };

  // Apollo Hooks
  // Queries
  const [fetchResources] = useLazyQuery(fetchScheduleResourcesQueryFlex, {
    onCompleted: (data) => {
      setResources(data?.flexResources);
    },
  });

  const [fetchWorkGroups, { loading: fetchWorkGroupsLoading }] = useLazyQuery(
    fetchFlexScheduleWorkGroupsQuery,
    {
      onCompleted: (data) => {
        setWorkGroups(data?.flex_work_groups_menu);
        loadSchedule();
      },
    }
  );

  const [fetchScheduleShifts] = useLazyQuery(GET_FLEX_SCHEDULE_SHIFTS, {
    onCompleted: (data) => {
      setShifts(data?.flex_schedule_shifts);
    },
  });

  const [fetchWorkSchedule, { loading: fetchWorkScheduleLoading }] =
    useLazyQuery(fetchWorkScheduleQueryFlex, {
      onCompleted: (data) => {
        setSchedule((state) => ({
          ...state,
          ...data?.flexSchedule,
          schedule_days: data?.flexSchedule?.schedule_days.toString(),
          break_into: data?.flexSchedule?.break_into.toString(),
          work_group_id: data?.flexSchedule?.workGroup?.id,
          workTeam: data?.flexSchedule?.employees?.concat(
            data?.flexSchedule?.teams
          ),
        }));
        setWorkGroup(data?.flexSchedule?.workGroup);

        loadResources(data?.flexSchedule);
        setShifts(data?.flex_schedule_shifts);
        initInterval(data?.flexSchedule);
        initInvalidDates(data?.flexSchedule);
        setIsCalendarLoading(false);
        setIsLoading(false);
        setShowCalendar(true);

        if (refDate == null) {
          setRefDate(data?.flexSchedule?.draft_date);
        }
      },
      onError: (error) => {
        toast({
          message: error.message,
          color: "danger",
        });
      },
    });

  const [fetchShift] = useLazyQuery(fetchShiftQueryFlex, {
    onCompleted: (data) => {
      setTempShift((state) => ({
        ...state,
        workTeam: data?.flexShift?.scheduleDetails[0].shift_work_teams,
        work_day_template: data?.flexShift?.scheduleDetails[0].WorkDayTemplate,
        work_day_template_id:
          data?.flexShift?.scheduleDetails[0].WorkDayTemplate?.id,
        location: data?.flexShift?.scheduleDetails[0].location,
        day_off: data?.flexShift?.scheduleDetails[0].day_off,
        workplace_setting: workPlaceSettingOptions.filter((setting) => {
          return (
            setting.id === data?.flexShift?.scheduleDetails[0].workplace_setting
          );
        })[0],
        workplace_setting_signout: workPlaceSettingOptions.filter((setting) => {
          return (
            setting.id ===
            data?.flexShift?.scheduleDetails[0].workplace_setting_signout
          );
        })[0],
        workplace_setting_type:
          data?.flexShift?.scheduleDetails[0].workplace_setting,
        workplace_setting_identical:
          data?.flexShift?.scheduleDetails[0].workplace_setting_identical,
        shift_container_id: data?.flexShift?.id,
        additional_work_places: data?.flexShift?.scheduleDetails[0].workPlaces,
        additional_work_places_signout:
          data?.flexShift?.scheduleDetails[0].workPlacesOut,
      }));
      setTempShifts([]);
      let workDayTemplates = [];

      data?.flexShift?.scheduleDetails.map((shift) => {
        let temp = {
          id: shift.id,
          schedule_detail_id: shift.id,
          work_day_template: shift.WorkDayTemplate,
          work_day_template_id: shift?.WorkDayTemplate?.id,
          weight: {
            name: String(shift.weight) + " Day",
            value: String(shift.weight),
          },
        };
        workDayTemplates[shift.id] = shift?.WorkDayTemplate?.id;
        setTempShifts((state) => [...state, temp]);
      });
      setSelectedWorkDayTemplates(workDayTemplates);
    },
  });

  const [fetchShiftEmployees] = useLazyQuery(fetchShiftEmployeesQueryFlex, {
    onCompleted: (data) => {
      setTempShift((state) => ({
        ...state,
        workTeam: [data?.FlexScheduleDetail],
        work_day_template: data?.FlexScheduleDetail[0].WorkDayTemplate,
        location: data?.FlexScheduleDetail[0].location,
        day_off: data?.FlexScheduleDetail[0].day_off,
        additional_work_places: data?.FlexScheduleDetail[0].workPlacesIn,
        additional_work_places_signout:
          data?.FlexScheduleDetail[0].workPlacesOut,
        workplace_setting_type: data?.FlexScheduleDetail[0].workplace_setting,
        workplace_setting_identical:
          data?.FlexScheduleDetail[0].workplace_setting_identical,
        workplace_setting_signout: workPlaceSettingOptions.filter((setting) => {
          return (
            setting.id === data?.FlexScheduleDetail[0].workplace_setting_signout
          );
        })[0],
        workplace_setting: workPlaceSettingOptions.filter((setting) => {
          return setting.id === data?.FlexScheduleDetail[0].workplace_setting;
        })[0],
      }));
      setTempShifts([]);
      let workDayTemplates = [];

      data?.FlexScheduleDetail.map((shift) => {
        let temp = {
          id: shift.id,
          schedule_detail_id: shift.id,
          work_day_template: shift.WorkDayTemplate,
          work_day_template_id: shift?.WorkDayTemplate?.id,
          weight: {
            name: String(shift.weight) + " Day",
            value: String(shift.weight),
          },
        };
        workDayTemplates[shift.id] = shift?.WorkDayTemplate?.id;
        setTempShifts((state) => [...state, temp]);
      });
      setSelectedWorkDayTemplates(workDayTemplates);
    },
  });

  // Mutations
  const [upsertShiftByLocation] = useMutation(
    upsertShiftByLocationMutationFlex
  );

  const [upsertShiftByEmployee] = useMutation(
    upsertShiftByEmployeeMutationFlex
  );

  const [updateShift] = useMutation(updateShiftMutationFlex);

  const [cloneShift] = useMutation(cloneShiftMutationFlex);

  const [deleteShift] = useMutation(deleteShiftMutationFlex);

  const [upsertSchedule] = useMutation(upsertFlexScheduleMutation);

  const [publishSchedule] = useMutation(publishScheduleMutationFlex);

  const [workScheduleFormValidation, setWorkScheduleFormValidation] = useState(
    []
  );
  const [shifts, setShifts] = React.useState(null);
  const [viewSettings, setViewSettings] = React.useState({
    timeline: {
      type: "day",
      size: 10,
      resolution: "day",
      eventList: true,
    },
  });
  const workPlaceSettingOptions = [
    {
      id: "DEFAULT",
      name: "As set",
    },
    {
      id: "ANY_PLACE",
      name: "Anywhere",
    },
    {
      id: "ALL_COMPANY_WORKPLACES",
      name: "Any Wokrplace in the Company",
    },
    {
      id: "ALL_WORKGROUP_WORKPLACES",
      name: "Any workplace belonging to his workgroup",
    },
  ];
  const tempShiftsInitialState = [
    {
      id: 1,
      schedule_detail_id: null,
      work_day_template: {},
      work_day_template_id: null,
      workplace_setting: workPlaceSettingOptions[0],
      workplace_setting_type: workPlaceSettingOptions[0].id,
      workplace_setting_signout: workPlaceSettingOptions[0],
      workplace_setting_type_signout: workPlaceSettingOptions[0].id,
      workplace_setting_identical: 1,
      weight: { name: "1 Day", value: "1" },
    },
  ];
  const weightsInitialState = [
    { name: "1 day", value: "1" },
    { name: "0.5 Day", value: "0.5" },
    { name: "0.25 day", value: "0.25" },
    { name: "1.5 day", value: "1.5" },
    { name: "2 day", value: "2" },
  ];
  const [selectedEventsToCopy, setSelectedEventsToCopy] = useState([]);
  const [insufficientScheduledEmployees, setInsufficientScheduledEmployees] =
    useState(false);

  const [isCalendarLoading, setIsCalendarLoading] = useState(false);
  const [oldValue, setOldValue] = useState(null);
  const [oldField, setOldField] = useState(null);
  const [scheduleCreated, setScheduleCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDeleteEmployees, setConfirmDeleteEmployees] = useState(false);
  const [confirmScheduleChange, setConfirmScheduleChange] = useState(false);
  const [confirmScheduleChangeLoading, setConfirmScheduleChangeLoading] =
    useState(false);
  const [popupDayOffSwitch, setPopupDayOffSwitch] = useState(false);
  const [schedule, setSchedule] = useState({ repeat: 1 });
  const [weights, setWeights] = useState(weightsInitialState);
  const [resources, setResources] = React.useState([]);
  const [refDate, setRefDate] = React.useState(null);
  const [calendarStartDate, setCalendarStartDate] =
    React.useState("2023-01-14");
  const [refEndDate, setRefEndDate] = React.useState("2023-01-14");
  const [mySelectedEvents, setSelectedEvents] = React.useState([]);
  const [selectedWorkDayTemplates, setSelectedWorkDayTemplates] =
    React.useState([]);
  const [workDayTemplatesCount, setWorkDayTemplatesCount] = React.useState(1);
  const [selectedEvent, setSelectedEvent] = React.useState({});
  const [selectedValue, setSelected] = React.useState(false);
  const [invalidDates, setInvalidDates] = React.useState([]);
  const [eventTitles, setEventTitles] = React.useState([]);
  const [tempShift, setTempShift] = React.useState({
    workplace_setting: workPlaceSettingOptions[0],
    workplace_setting_type: "DEFAULT",
    workplace_setting_signout: workPlaceSettingOptions[0],
    workplace_setting_type_signout: "DEFAULT",
    workplace_setting_identical: 1,
  });
  const [tempShifts, setTempShifts] = React.useState(tempShiftsInitialState);
  const [workGroups, setWorkGroups] = React.useState([]);
  const [workGroup, setWorkGroup] = React.useState({});
  const [isBlockedDate, setIsBlockedDate] = React.useState(false);
  const [removedEmployees, setRemovedEmployees] = React.useState([]);
  const [removedTeams, setRemovedTeams] = React.useState([]);
  const [pasteDate, setPasteDate] = React.useState(null);
  const [pasteResource, setPasteResource] = React.useState(null);
  const [isOpen, setOpen] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [headerText, setHeader] = React.useState("");
  const [shiftDate, setDate] = React.useState([]);
  const [cycleDays, setCycleDays] = React.useState(null);
  const [shiftNotes, setNotes] = React.useState("");
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [menuAnchor, setMenuAnchor] = React.useState();
  const [pasteAnchor, setPasteAnchor] = React.useState();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [pasteMenuOpen, setPasteMenuOpen] = React.useState(false);
  const [activeValue, setActiveValue] = React.useState(1);
  const [interval, setInterval] = React.useState(1);
  const [intervalArray, setIntervalArray] = React.useState([]);
  const [cycleDaysSelect, setCycleDaysSelect] = React.useState([]);
  const [dropDownWorkDayTemplates, setDropDownWorkDayTemplates] =
    React.useState([]);
  const [upsertShiftLoading, setUpsertShiftLoading] = useState(false);
  const [publishScheduleLoading, setPublishScheduleLoading] = useState(false);
  const [confirmPublishSchedule, setConfirmPublishSchedule] = useState(false);
  const [teamAnchor, setTeamAnchor] = useState();
  const [isTeamOpen, setIsTeamOpen] = useState(false);
  const [tooltipEvent, setTooltipEvent] = useState({});
  const [isEventOpen, setEventOpen] = useState(false);
  const [eventAnchor, setEventAnchor] = useState(null);
  const [popupTeam, setPopupTeam] = useState({});
  const [workGroupBlocked, setWorkgroupBlocked] = useState(true);
  const { classes } = horizontalNavbarStyles();
  const timerRef = React.useRef(null);

  const handleWorkplaceIdenticalChange = () => {
    setTempShift((state) => ({
      ...state,
      workplace_setting_identical:
        tempShift.workplace_setting_identical == 1 ? 0 : 1,
    }));
  };
  const handleSwitchChange = () => {
    setSelectedEvents([]);
    setSelectedEventsToCopy([]);
    setIsCalendarLoading(true);
    if (schedule.draft_employees == 1) {
      setSchedule((state) => ({
        ...state,
        draft_employees: 0,
      }));
      schedule.draft_employees = 0;
    } else {
      setSchedule((state) => ({
        ...state,
        draft_employees: 1,
      }));
      schedule.draft_employees = 1;
    }
    setTimeout(() => {
      loadSchedule();
      loadResources(schedule);
    }, 1000);

    const newRefDate = schedule.draft_date;
    setRefDate(newRefDate);

    updateScheduleFields({ reset: 0, skipLoading: true });
  };
  const deleteAllShifts = () => {
    setIsLoading(true);
    updateScheduleFields({ reset: 1, skipLoading: true, mirror: false });
  };

  const handleRepeatSwitchChange = (args) => {
    setSchedule((state) => ({
      ...state,
      repeat: schedule.repeat == 1 ? 0 : 1,
    }));
    schedule.repeat = schedule.repeat == 1 ? 0 : 1;

    updateScheduleFields({ reset: 0, skipLoading: true });
  };

  const handlePopupDayOffSwitchChange = () => {
    if (tempShift.day_off == 1) {
      setPopupDayOffSwitch(false);
      setTempShift((state) => ({
        ...state,
        day_off: 0,
      }));
    } else {
      setTempShift((state) => ({
        ...state,
        day_off: 1,
        location: {},
        work_day_template: {},
      }));
    }
  };

  const isRtl =
    i18n.dir(useSelector((state) => state.user.userProfile.lng)) === "rtl"
      ? true
      : false;

  const loadResources = function (schedule) {
    if (schedule.draft_employees == 1) {
      fetchResources({
        variables: {
          schedule_id: schedule.id,
        },
      });
    } else {
      setResources(schedule.workGroup.locations);
    }
  };

  const history = useHistory();

  useEffect(() => {
    fetchWorkGroups({
      variables: {
        start_date: moment().format("YYYY-MM-DD"),
      },
    });
    return () => {
      setFormSubmitting(false);
    };
  }, []);

  useEffect(() => {
    adjustWorkDayTemplateDropDown();
  }, [tempShifts, selectedWorkDayTemplates]);

  const reloadShifts = () => {
    const get_schedule_id = new URLSearchParams(window.location.search).get(
      "schedule_id"
    );

    if (get_schedule_id) {
      fetchScheduleShifts({
        variables: {
          id: get_schedule_id,
        },
      });
    }
  };

  const loadSchedule = () => {
    const get_schedule_id = new URLSearchParams(window.location.search).get(
      "schedule_id"
    );

    if (get_schedule_id) {
      setScheduleCreated(true);

      fetchWorkSchedule({
        variables: {
          id: get_schedule_id,
        },
      });
    }
  };

  const saveEvent = React.useCallback(() => {
    setFormSubmitting(true);
    if (schedule.draft_employees == 0) {
      if (
        ((!tempShift?.work_day_template_id || !tempShift?.workTeam?.length) &&
          !tempShift?.day_off) ||
        (tempShift.day_off && !tempShift?.workTeam?.length)
      ) {
        return;
      }
      setUpsertShiftLoading(true);
      handleActionMutation(upsertShiftByLocation, {
        variables: {
          schedule_id: schedule.id,
          shift: {
            id: isNaN(tempShift.id) ? null : tempShift.id,
            shift_container_id: isNaN(tempShift.id) ? null : tempShift.id,
            start: moment(tempShift.start).format("YYYY-MM-DD"),
            end: moment(tempShift.end).format("YYYY-MM-DD"),
            day_off: tempShift?.day_off ? tempShift?.day_off : 0,
            work_day_template_id: tempShifts[0]?.work_day_template_id ?? null,
            work_day_templates: tempShifts,
            workplace_setting: tempShift.workplace_setting,
            additional_work_places: tempShift.additional_work_places,
            workplace_setting_signout: tempShift.workplace_setting_signout,
            additional_work_places_signout:
              tempShift.additional_work_places_signout,
            workplace_setting_identical: tempShift.workplace_setting_identical,
            resource: tempShift.resource,
            workTeam: tempShift.workTeam,
          },
        },
      }).then(({ response, error, serverError }) => {
        setUpsertShiftLoading(false);
        if (error || serverError) {
          return toast({
            message: "There was an error",
            color: "danger",
          });
        }
        setFormSubmitting(false);
        loadSchedule();
        setOpen(false);
        setTempShift(tempShiftsInitialState[0]);
        setTempShifts(tempShiftsInitialState);
        setSelectedWorkDayTemplates([]);
        setDropDownWorkDayTemplates([]);
      });
    } else {
      // if (
      //   (!tempShift?.work_day_template_id || !tempShift?.location?.id) &&
      //   !tempShift?.day_off
      // ) {
      
      //   return;
      // }
      setUpsertShiftLoading(true);

      handleActionMutation(upsertShiftByEmployee, {
        variables: {
          schedule_id: schedule.id,
          shift_employee: {
            id: isNaN(tempShift.id) ? null : tempShift.id,
            shift_container_id: !tempShift.id.includes("---")
              ? null
              : tempShift.id.split("---")[0],
            start: moment(tempShift.start).format("YYYY-MM-DD"),
            day_off: tempShift?.day_off ? tempShift?.day_off : 0,
            work_day_template_id: tempShifts[0]?.work_day_template_id ?? null,
            work_day_templates: tempShifts,
            workplace_setting: tempShift.workplace_setting,
            additional_work_places: tempShift.additional_work_places,
            workplace_setting_signout: tempShift.workplace_setting_signout,
            additional_work_places_signout:
              tempShift.additional_work_places_signout,
            workplace_setting_identical: tempShift.workplace_setting_identical,
            office_id: tempShift.location?.id ?? null,
            resource: tempShift.resource,
            end: moment(tempShift.end).format("YYYY-MM-DD"),
          },
        },
      }).then(({ response, error, serverError }) => {
        setUpsertShiftLoading(false);
        if (error || serverError) {
          return toast({
            message: "There was an error",
            color: "danger",
          });
        }
        setFormSubmitting(false);

        // setShifts((state) => ({
        //   ...state,
        //   ...response?.data?.upsert_schedule_detail_employee,
        // }));
        setTempShifts(tempShiftsInitialState);
        setSelectedWorkDayTemplates([]);
        setDropDownWorkDayTemplates([]);

        toast({
          message: "Shift updated",
        });

        loadSchedule();
        setOpen(false);
      });
    }

    // close the popup
  }, [isEdit, shifts, shiftNotes, tempShift, shiftDate, tempShifts]);

  const deleteEvent = React.useCallback(
    (event) => {
      setShifts(shifts.filter((item) => item.id !== event.id));
      setTimeout(() => {
        snackbar({
          button: {
            action: () => {
              setShifts((prevEvents) => [...prevEvents, event]);
            },
            text: "Undo",
          },
          message: "Deleted",
        });
      });
    },
    [shifts]
  );

  const getSelectedEventTitles = React.useCallback((events) => {
    let titles = [];

    for (const event of events) {
      titles = [...titles, event.title];
    }
    return titles;
  }, []);

  const loadPopupForm = React.useCallback((event) => {
    setDate([event.start, event.end]);
    //setTempSchedule({});
  }, []);
  let teamPopupRef = useRef();
  useEffect(() => {
    let teamMenuHandler = (e) => {
      if (
        teamPopupRef.current !== null &&
        teamPopupRef.current !== undefined &&
        !teamPopupRef.current.contains(e.target)
      ) {
        setIsTeamOpen(false);
      }
    };

    document.addEventListener("mousedown", teamMenuHandler);

    return () => {
      document.removeEventListener("mousedown", teamMenuHandler);
    };
  });
  // scheduler options
  const onEventClick = (args) => {
    const event = args.event;

    if (event?.id && event?.id?.includes("mbsc")) {
      return;
    }

    if (schedule.draft_employees == 0) {
      fetchShift({
        variables: {
          id: event.id,
        },
      });
    } else {
      fetchShiftEmployees({
        variables: {
          id: event.id,
        },
      });
    }

    setHeader(
      formatDate("DDDD", new Date(event.start)) +
        ", " +
        formatDate("DD MMMM YYYY", new Date(event.start))
    );

    setEdit(true);
    setTempShift(event); // fetching the shift above does the same thing so this should be deleted
    // fill popup form with event data
    loadPopupForm(event);
    setOpen(true);
  };

  const cancelEventAdd = () => {
    setFormSubmitting(false);
    setTempShift(tempShiftsInitialState[0]);
    setTempShifts(tempShiftsInitialState);
    setSelectedWorkDayTemplates([]);
    setDropDownWorkDayTemplates([]);
    setShifts([...shifts]);
    setOpen(false);
  };
  const openTeamPopup = (team, args) => {
    if (team.type == "team" && team?.team_members) {
      setTeamAnchor(args.target);
      setPopupTeam(team);
      setTimeout(() => {
        setIsTeamOpen(true);
      });
    }
  };
  const onEventCreated = React.useCallback(
    (args) => {
      const event = args.event;
      event.workplace_setting = tempShiftsInitialState[0].workplace_setting;
      event.workplace_setting_type =
        tempShiftsInitialState[0].workplace_setting_type;
      event.workplace_setting_signout =
        tempShiftsInitialState[0].workplace_setting;
      event.workplace_setting_type_signout =
        tempShiftsInitialState[0].workplace_setting_type;
      event.workplace_setting_identical = 1;
      //const slot = slots.find((s) => { return s.id === event.slot });
      setHeader(
        formatDate("DDDD", new Date(event.start)) +
          ", " +
          formatDate("DD MMMM YYYY", new Date(event.start))
      );
      setEdit(false);
      
      // setMinTime(event.slot === 1 ? '07:00' : '12:00');
      // setMaxTime(event.slot === 1 ? '13:00' : '18:00');
      setTempShift(event);
      
      // fill popup form with event data
      loadPopupForm(event);
      // open the popup
      setOpen(true);
      adjustWorkDayTemplateDropDown();
    },
    [loadPopupForm, schedule]
  );

  const onEventDeleted = React.useCallback(
    (args) => {
      deleteEvent(args.event);
    },
    [deleteEvent]
  );

  const extendDefaultEvent = React.useCallback((args) => {
    const d = args.start;
    const start = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 7);
    const end = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 13);

    return {
      title: formatDate("HH:mm", start) + " - " + formatDate("HH:mm", end),
      start: start,
      end: end,
      resource: args.resource,
    };
  }, []);

  const refreshSelectedEvents = React.useCallback(
    (events) => {
      setSelectedEvents(events);
      setEventTitles(getSelectedEventTitles(events));
    },
    [getSelectedEventTitles]
  );

  const renderMyResource = (resource) => {
    return (
      <div className="employee-shifts-cont">
        {schedule.draft_employees == 1 ? (
          resource.type == "team" ? (
            <div
              className="employee-shifts-name"
              onClick={(event) => openTeamPopup(resource, event)}
            >
              <span>
                <Groups3OutlinedIcon />
                {resource.name}
              </span>
            </div>
          ) : (
            <Link
              className="custom-link-style"
              to={`/employees/employee-profile/${resource?.id?.split("-")[1]}`}
            >
              <PersonOutlineOutlinedIcon />
              {resource.name}
            </Link>
          )
        ) : (
          <div className="employee-shifts-name">
            <span>
              {" "}
              <LocationOnOutlinedIcon /> {resource.name}
            </span>
          </div>
        )}
      </div>
    );
  };

  const myCustomResourceHeaderTemplate = () => {
    return (
      <>
        <div>{schedule.draft_employees ? t("employees") : t("locations")}</div>
      </>
    );
  };

  const scheduleStartDateChange = (args) => {
    setOldField("draft_date");
    setOldValue(schedule?.draft_date);
    setSchedule((state) => ({
      ...state,
      draft_date: moment(args).format("YYYY-MM-DD"),
    }));

    if (scheduleCreated) {
      setConfirmScheduleChange(true);
    }
  };

  const handleChangeApplyFrom = (args) => {
    const get_schedule_id = new URLSearchParams(window.location.search).get(
      "schedule_id"
    );

    setSchedule((state) => ({
      ...state,
      start: moment(args).format("YYYY-MM-DD"),
    }));

    fetchWorkGroups({
      variables: {
        start_date: moment(args).format("YYYY-MM-DD"),
      },
    });

    if (moment(args).isValid()) {
      setWorkgroupBlocked(false);
      setWorkGroup({});
      setSchedule((state) => ({
        ...state,
        work_group_id: null,
        workGroup: {},
        workTeam: null,
      }));
    }

    if (get_schedule_id) {
      schedule.start = moment(args).format("YYYY-MM-DD");
      handleTableDraw();
    }
  };

  const handleChangeTab = (_event, newValue) => {
    let newRefDate;
    if (Number(newValue) !== 1) {
      newRefDate = moment(schedule.draft_date)
        .add((Number(newValue) - 1) * Number(schedule.break_into), "days")
        .format("YYYY-MM-DD");
    } else {
      newRefDate = schedule.draft_date;
    }
    setRefDate(newRefDate);
    setActiveValue(newValue);
  };

  const handleWorkGroupChange = (selectedOptions) => {
    schedule.workTeam = null;
    setWorkGroup(selectedOptions);
    setSchedule((state) => ({
      ...state,
      work_group_id: selectedOptions.id,
      workGroup: selectedOptions,
      workTeam: null,
    }));
    if (scheduleCreated) {
      handleTableDraw();
    }
  };

  const handlePopupWorkDayTemplateChange = (selectedOptions, shift_id) => {
    let workDayTemplates = selectedWorkDayTemplates;
    workDayTemplates[shift_id] = selectedOptions.id;
    setSelectedWorkDayTemplates(workDayTemplates);
    setTempShift((state) => ({
      ...state,
      work_day_template_id: selectedOptions.id,
      work_day_template: selectedOptions,
    }));
    const newState = tempShifts.map((shift) => {
      if (shift.id === shift_id) {
        return {
          ...shift,
          work_day_template_id: selectedOptions.id,
          work_day_template: selectedOptions,
        };
      }

      return shift;
    });
    setTempShifts(newState);
  };

  const handlePopupWeightChange = (selectedOptions, shift_id) => {
    const newState = tempShifts.map((shift) => {
      if (shift.id === shift_id) {
        return { ...shift, weight: selectedOptions };
      }

      return shift;
    });
    setTempShifts(newState);
  };
  const removeWorkDayTemplate = (args, shift_id, work_day_template_id) => {
    let shifts = tempShifts.filter((shifts, kkk) => shifts.id !== shift_id);
    setTempShifts(shifts);
    selectedWorkDayTemplates[shift_id] = null;
    setSelectedWorkDayTemplates(selectedWorkDayTemplates);
  };
  const addWorkDayTemplate = (args) => {
    setTempShifts((state) => [
      ...state,
      {
        id: Number(tempShifts[Number(tempShifts.length) - 1].id) + 1,
        schedule_detail_id: null,
        work_day_template: {},
        work_day_template_id: null,
        weight: { name: "1 Day", value: "1" },
      },
    ]);
  };
  const adjustWorkDayTemplateDropDown = () => {
    
    let dropDownWorkDayTemplates = [];
    setDropDownWorkDayTemplates([]);
    tempShifts.map((shift) => {
      schedule?.workGroup?.workDayTemplates.map((workDayTemplate) => {
        let disabled = false;
        selectedWorkDayTemplates.map((selectedWorkDayTemplate, index) => {
          if (
            selectedWorkDayTemplate == workDayTemplate.id &&
            index != shift.id
          ) {
            disabled = true;
          }
        });
        if (!disabled) {
          if (dropDownWorkDayTemplates[shift.id] == undefined) {
            dropDownWorkDayTemplates[shift.id] = [];
          }
          dropDownWorkDayTemplates[shift.id].push(workDayTemplate);
          setDropDownWorkDayTemplates(dropDownWorkDayTemplates);
        }
      });
    });
  };

  const handleWorkTeamChange = (selectedOptions, event) => {
    if (event.action === "remove-value" || event.action === "pop-value") {
      let toBeRemoved = {
        id: event?.removedValue?.id,
        name: event?.removedValue?.name,
      };

      if (
        event.removedValue !== undefined &&
        event.removedValue.user_type === "Employee"
      ) {
        setRemovedEmployees((state) => [...state, toBeRemoved]);
      } else {
        setRemovedTeams((state) => [...state, toBeRemoved]);
      }

      if (scheduleCreated) {
        setOldField("workTeam");
        setOldValue(schedule.workTeam);
        setConfirmDeleteEmployees(true);
      }
    }

    if (event.action === "select-option") {
      if (scheduleCreated) {
        schedule.workTeam = selectedOptions;
        handleTableDraw();
      }
    }

    setSchedule((state) => ({
      ...state,
      workTeam: selectedOptions,
    }));
  };

  const handlePopupLocationChange = (selectedOptions) => {
    setTempShift((state) => ({
      ...state,
      location: selectedOptions,
    }));
  };

  const handleAdditionalWorkplacesChange = (type, selectedOptions) => {
    if (type == "signin") {
      setTempShift((state) => ({
        ...state,
        additional_work_places: selectedOptions,
      }));
    }
    if (type == "signout") {
      setTempShift((state) => ({
        ...state,
        additional_work_places_signout: selectedOptions,
      }));
    }
  };

  const handleWorkPlaceSettingChange = (type, selectedOptions) => {
    if (type == "signin") {
      setTempShift((state) => ({
        ...state,
        workplace_setting: selectedOptions,
        workplace_setting_type: selectedOptions.id,
      }));
    }
    if (type == "signout") {
      setTempShift((state) => ({
        ...state,
        workplace_setting_signout: selectedOptions,
        workplace_setting_type_signout: selectedOptions.id,
      }));
    }
  };

  const handlePopupWorkTeamChange = (selectedOptions) => {
    setTempShift((state) => ({
      ...state,
      workTeam: selectedOptions,
    }));
  };

  const cycleDaysChange = (args) => {
    setOldValue(schedule.schedule_days);
    setOldField("schedule_days");
    setSchedule((state) => ({
      ...state,
      // schedule_days: args.id,
      schedule_days: args.name,
    }));

    if (scheduleCreated) {
      setConfirmScheduleChange(true);
    }
  };

  const breakIntoChange = (args) => {
    const get_schedule_id = new URLSearchParams(window.location.search).get(
      "schedule_id"
    );

    setSchedule((state) => ({
      ...state,
      // break_into: Number(args.id),
      break_into: args.target.value,
    }));

    if (get_schedule_id) {
      schedule.break_into = args.id;
      // handleTableDraw();
    }
  };

  const onSelectedEventsChange = React.useCallback(
    (args) => {
      refreshSelectedEvents(args.events);
    },
    [refreshSelectedEvents]
  );

  const onEventRightClick = (args) => {
    let event_ids = [];
    mySelectedEvents.forEach((eve) => {
      event_ids.push(eve.id);
    });
    if (!event_ids.includes(args?.event?.id)) {
      setSelectedEvents((state) => [...state, args?.event]);
    }
    args?.domEvent?.preventDefault();
    setMenuAnchor(args?.domEvent?.target);
    setTempShift(args?.event);
    setTimeout(() => {
      setMenuOpen(true);
    });
  };

  const onMouseEnterEvent = React.useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  const onMouseLeaveEvent = React.useCallback(() => {
    timerRef.current = setTimeout(() => {
      setOpen(false);
    }, 200);
  }, []);

  const onEventUpdate = async (args) => {
    const { error } = await handleActionMutation(updateShift, {
      variables: {
        schedule_id: schedule.id,
        paste_date: moment(args.event.start).format("YYYY-MM-DD"),
        paste_resource: args.event.resource,
        shift_container_id: args.event.id,
      },
      disableSuccessToast: true,
    });

    if (error) {
      return reloadShifts();
    }

    setOpen(false);
    loadSchedule();
  };

  const onCellRightClick = React.useCallback((args) => {
    args.domEvent.preventDefault();
    if (schedule.draft_employees == 0) {
      if (
        args.inst._invalidsMap[moment(args.date).format("YYYY-MM-DD")] ===
        undefined
      ) {
        setIsBlockedDate(false);
      } else {
        setIsBlockedDate(true);
      }
    } else {
      if (
        args.inst._invalidsMap[moment(args.date).format("YYYY-MM-DD")] ===
        undefined
      ) {
        setIsBlockedDate(false);
      } else {
        setIsBlockedDate(false);
        if (
          args.inst._invalidsMap[moment(args.date).format("YYYY-MM-DD")][0]
            .resource == args.resource
        ) {
          setIsBlockedDate(true);
        }
      }
    }
    
    setPasteDate(args.date);
    setPasteResource(args.resource);
    setPasteAnchor(args.domEvent.target);
    setTimeout(() => {
      setPasteMenuOpen(true);
    });
  }, []);

  const paste = async (args) => {
    setSelected(args.value);
    if (schedule.draft_employees == 1) {
      let work_day_template_ids = [];
      for (let i = 0; i < selectedEventsToCopy.length; i++) {
        if (
          !work_day_template_ids.includes(
            selectedEventsToCopy[i].work_day_template_id
          )
        ) {
          work_day_template_ids.push(
            selectedEventsToCopy[i].work_day_template_id
          );
        } else {
          toast({
            message:
              "Cannot paste the same work day template more than one time",
            color: "danger",
          });
          return false;
        }
        if (
          selectedEventsToCopy[i].day_off == 1 &&
          selectedEventsToCopy.length > 1
        ) {
          toast({
            message:
              "Cannot paste a work day template and a day-off at the same time",
            color: "danger",
          });
          return false;
        }
      }
    }

    const { error } = await handleActionMutation(cloneShift, {
      variables: {
        paste_date: moment(pasteDate).format("YYYY-MM-DD"),
        paste_resource: pasteResource,
        schedule_id: schedule.id,
        shifts: selectedEventsToCopy.map((shift) => {
          return {
            id: shift.id,
            schedule_detail_id: shift.schedule_detail_id,
          };
        }),
      },
      disableSuccessToast: true,
    });

    if (!error) {
      loadSchedule();
    }
  };

  const selectChange = async (args) => {
    setSelected(args.value);

    if (args.value === "copy") {
      setSelectedEvent(tempShift);
      if (mySelectedEvents.length > 1) {
        toast({
          message: "Cannot copy more than one shift",
        });
        setSelectedEventsToCopy([]);
      } else {
        setSelectedEventsToCopy(mySelectedEvents);
        toast({
          message: "Copied",
        });
      }
    }

    if (args.value === "delete") {
      const { response, error } = await handleActionMutation(deleteShift, {
        variables: {
          schedule_id: schedule.id,
          shift_container_ids: mySelectedEvents?.map((event) => event.id),
        },
      });

      if (error) {
        return toast({
          message: "There was an error",
        });
      }

      setSelectedEvents([]);
      setSelectedEventsToCopy([]);
      toast({
        message: "Deleted",
      });
      loadSchedule();
    }
  };

  const menuClose = React.useCallback(() => {
    setSelected("");
    setMenuOpen(false);
  }, []);

  const pasteMenuClose = React.useCallback(() => {
    setSelected("");
    setPasteMenuOpen(false);
  }, []);

  const initInvalidDates = (schedule) => {
    if (schedule.draft_employees == 1) {
      schedule.blockedDates.push({
        // multi day range with date object
        allDay: true,
        start: moment(schedule.draft_date)
          .add(Number(schedule.schedule_days), "days")
          .format("YYYY-MM-DD"),
        end: moment(schedule.draft_date)
          .add(
            Number(schedule.schedule_days) + Number(schedule.break_into),
            "days"
          )
          .format("YYYY-MM-DD"),
        cssClass: "blocked-date",
      });

      setInvalidDates(schedule?.blockedDates);
    } else {
      setInvalidDates([
        {
          // multi day range with date object
          allDay: true,
          start: moment(schedule?.draft_date)
            .add(Number(schedule?.schedule_days), "days")
            .format("YYYY-MM-DD"),
          end: moment(schedule?.draft_date)
            .add(
              Number(schedule?.schedule_days) + Number(schedule?.break_into),
              "days"
            )
            .format("YYYY-MM-DD"),
          cssClass: "blocked-date",
        },
      ]);
    }
  };

  const [eventTouchStartTime, setEventTouchStartTime] = useState(null);

  const handleOnTouchStart = () => {
    setEventTouchStartTime(moment());
  };

  const handleOnTouchEnd = (event, elementEvent) => {
    if (!!eventTouchStartTime) {
      const eventTouchEndTime = moment();
      const differenceInSeconds =
        eventTouchEndTime.diff(eventTouchStartTime) / 1000;

      if (differenceInSeconds > 1.5) {
        onEventRightClick({
          event,
          domEvent: {
            preventDefault: elementEvent.preventDefault,
            target: elementEvent.target,
          },
        });
      }
    }
    setEventTouchStartTime(null);
  };

  const renderEvent = (event) => {
    let isEventSelected = mySelectedEvents?.find(
      (ev) => ev?.id == event?.original?.id
    );
    let final_title = [];
    if (event?.original?.title?.includes(",")) {
      event.original.title.split(",")?.forEach((work_day_template) => {
        final_title.push(
          <div dir="auto" className="single-worktiming">
            {work_day_template}
          </div>
        );
      });
    } else {
      final_title = (
        <div dir="auto" className="single-worktiming">
          {event.original.title}
        </div>
      );
    }
    return (
      <div
        onTouchStart={handleOnTouchStart}
        onTouchEnd={(elementEvent) => handleOnTouchEnd(event, elementEvent)}
        className={isEventSelected ? "event-day selected" : "event-day"}
      >
        {schedule.draft_employees == 0 ? (
          <div className="user_count">
            <PersonOutlineOutlinedIcon /> {event.original.user_count}{" "}
          </div>
        ) : (
          ""
        )}
        {event.original.day_off == 1 || event.original.day_off == null ? (
          <div className="single-worktiming"> {t("Day Off")}</div>
        ) : (
          final_title
        )}{" "}
        {/* {schedule.draft_employees == 0 ? (
          ""
        ) : event?.original?.day_off == 0 ? (
          <p className="mb-0">
            {schedule?.workGroup?.locations?.find(
              (location) => location?.id == event.original.office_id
            )?.name ?? "location namessssssssss"}
          </p>
        ) : null} */}
        {/* <br/><img src="/user-calendar.png" height={30} /> <span className="employee-count">{event.original.emp_count}</span> */}
        {schedule.draft_employees == 1 ? (
          <div className="single-worktiming">
            <LocationOnOutlinedIcon />{" "}
            {event.original.location_name
              ? event.original.location_name
              : "None"}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const renderPasteMenuItem = (item) => {
    let attributes = false;
    if (selectedEventsToCopy.length == 0 || isBlockedDate) {
      attributes = true;
    }
    if (selectedEventsToCopy.length > 1 && schedule.draft_employees == 0) {
      attributes = false;
    }
    return (
      <div className="my-custom-class">
        <button className="paste-button" disabled={attributes}>
          {item.display}
        </button>
      </div>
    );
  };

  const updateScheduleFields = async ({
    reset = 0,
    skipLoading = false,
    mirror = true,
  } = {}) => {
    if (!skipLoading) {
      setIsLoading(true);
    }

    const get_schedule_id = new URLSearchParams(window.location.search).get(
      "schedule_id"
    );

    const { response, error, serverError } = await handleActionMutation(
      upsertSchedule,
      {
        variables: {
          input: {
            id: schedule.id ?? null,
            ...(get_schedule_id
              ? { work_group_id: schedule?.workGroup?.id }
              : { work_group_id: schedule?.work_group_id }),
            work_team_id: schedule?.workTeam?.map((teamOrEmployee) => {
              return {
                id: teamOrEmployee?.id,
                name: teamOrEmployee?.name,
                ...(teamOrEmployee?.user_type
                  ? {
                      user_type: teamOrEmployee?.user_type,
                    }
                  : { work_group_id: teamOrEmployee?.work_group_id }),
              };
            }),
            schedule_start_date: schedule?.draft_date,
            apply_from: schedule?.start,
            cycle_days: +schedule?.schedule_days,
            break_into: +schedule?.break_into,
            draft_employees: +schedule?.draft_employees ?? 0,
            repeat: parseInt(schedule?.repeat),
            reset: reset,
            mirror: mirror,
          },
        },
        disableSuccessToast: skipLoading,
      }
    );

    setIsLoading(false);

    if (serverError || error) {
      setShowCalendar(false);

      if (serverError) {
        return dispatch(setWorkScheduleServerValidationAction(serverError));
      }

      return toast({
        message: "There was an error",
        color: "danger",
        duration: 6000,
      });
    }

    setSchedule((state) => ({
      ...state,
      ...response?.data?.flex_upsert_schedule,
    }));

    window.history.pushState(
      {},
      null,
      `/flex-schedules/form?schedule_id=${response?.data?.flex_upsert_schedule?.id}`
    );

    loadSchedule();
    setRemovedEmployees([]);
    setRemovedTeams([]);
    setShowCalendar(true);
    setFormSubmitting(false);
  };
  const confirmPublish = async () => {
    let scheduledEmployees = schedule.scheduledEmployeesAndTeams.length;
    let scheduleWorkTeam =
      Number(schedule.employees.length) + Number(schedule.teams.length);
    if (scheduledEmployees < scheduleWorkTeam) {
      setInsufficientScheduledEmployees(true);
      return false;
    }
    setConfirmPublishSchedule(true);
  };

  const handlePublish = async () => {
    setPublishScheduleLoading(true);
    const { error } = await handleActionMutation(publishSchedule, {
      variables: {
        schedule_id: schedule.id,
      },
    });

    if (!error) {
      toast({
        message: "Published Successfully",
        color: "success",
        duration: 6000,
      });
      history.push("/attendance?tab=work-calendar");
      dispatch(setShouldExitCalendarPage(true));
    }
    setPublishScheduleLoading(false);
  };

  const scheduleChangeCanceled = () => {
    setSchedule((state) => ({
      ...state,
      [oldField]: oldValue,
    }));
    setConfirmScheduleChange(false);
  };
  const schedulePublishCanceled = () => {
    setSchedule((state) => ({
      ...state,
      [oldField]: oldValue,
    }));
    setConfirmPublishSchedule(false);
  };

  const scheduleChangeConfirmed = () => {
    setConfirmScheduleChange(false);
    handleTableDraw(1);
  };

  const deleteCanceled = () => {
    setConfirmDeleteEmployees(false);
    setSchedule((state) => ({
      ...state,
      [oldField]: oldValue,
    }));
    setRemovedEmployees([]);
    setRemovedTeams([]);
  };

  const deleteConfirmed = () => {
    setConfirmDeleteEmployees(false);
    handleTableDraw();
  };

  const onEventHoverIn = React.useCallback((args) => {
    const event = args.event;
    if (args.event.day_off == 1) {
      return;
    }

    setTooltipEvent(event);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    setEventAnchor(args.domEvent.target);
    setEventOpen(true);
  }, []);

  const onEventHoverOut = React.useCallback(() => {
    timerRef.current = setTimeout(() => {
      setEventOpen(false);
    }, 200);
  }, []);

  const submitHandler = () => {
    if (
      scheduleCreated &&
      (removedEmployees.length > 0 || removedTeams.length > 0)
    ) {
      setConfirmDeleteEmployees(true);
      return false;
    } else {
      handleTableDraw();
    }
  };

  const handleTableDraw = async (reset = 0) => {
    setFormSubmitting(true);
    if (
      +schedule?.break_into > +schedule?.schedule_days ||
      !workGroupValidation.isValid ||
      !startFromValidation.isValid ||
      !applyFromValidation.isValid ||
      !workTeamsValidation.isValid ||
      !cycleDaysValidation.isValid ||
      !breakIntoValidation.isValid
    ) {
      return;
    }
    dispatch(setWorkScheduleServerValidationAction({}));
    await updateScheduleFields({ reset });

    const endDate = moment(schedule?.draft_date)
      .add(Number(schedule?.schedule_days) - 1, "days")
      .format("YYYY-MM-DD");
    initInvalidDates(schedule);
    setRefEndDate(endDate);
    setRefDate(schedule.draft_date);
    setCalendarStartDate(schedule.draft_date);
    setViewSettings({
      timeline: {
        type: "day",
        size: Number(schedule.break_into),
        resolution: "day",
        eventList: true,
      },
    });
    initInterval(schedule);
  };

  const initInterval = function (schedule) {
    setViewSettings({
      timeline: {
        type: "day",
        size: Number(schedule.break_into),
        resolution: "day",
        eventList: true,
      },
    });
    setInterval(
      Math.ceil(Number(schedule.schedule_days) / Number(schedule.break_into))
    );
    setIntervalArray(
      _.range(
        Math.ceil(Number(schedule.schedule_days) / Number(schedule.break_into))
      )
    );
    // deleted for not going back to interval 1 after adding a shift in interval 2
    // setActiveValue(1);
  };

  const employeeTeamsOptions = workGroup?.flexWorkTeams?.concat(
    workGroup?.flexEmployeesNotInTeams
      ?.map((employee) => {
        return {
          id: employee?.user?.id,
          name: employee?.user?.name,
          user_type: employee?.user?.user_type,
        };
      })
      ?.filter((el) => el !== undefined)
  );

  // validation start
  const workGroupValidation = useCustomFormValidation({
    inputValue: schedule?.work_group_id,
  });

  const startFromValidation = useCustomFormValidation({
    inputValue: schedule?.draft_date,
  });

  const applyFromValidation = useCustomFormValidation({
    inputValue: schedule?.start,
  });

  const workTeamsValidation = useCustomFormValidation({
    validateBy: "arrayRequired",
    inputValue: schedule?.workTeam ?? [],
  });

  const cycleDaysValidation = useCustomFormValidation({
    inputValue: schedule?.schedule_days,
  });

  const breakIntoValidation = useCustomFormValidation({
    inputValue: schedule?.break_into,
  });
  // validation end

  const ShiftModalHeader = () => {
    return (
      <div className="custom-shift-modal-header">
        <p className="mb-0 mr-5">{headerText}</p>
        <input
          id="shifts-day-off"
          className="shifts-custom-checkbox d-none"
          type="checkbox"
          checked={tempShift?.day_off}
          onChange={handlePopupDayOffSwitchChange}
        />
        <label htmlFor="shifts-day-off" className="user-select-none d-none">
          {t("make this a day off")}
        </label>
      </div>
    );
  };

  const handleSaveAsDraft = () => {
    setTimeout(() => {
      history.push("/attendance?tab=work-calendar");
    }, 2000);
    dispatch(showSuccessToast("saved as draft"));
  };

  const handleEmployeesWorkTeamsSelectAll = () => {
    schedule.workTeam = employeeTeamsOptions;

    setSchedule((state) => ({
      ...state,
      workTeam: employeeTeamsOptions,
    }));

    if (scheduleCreated) {
      handleTableDraw();
    }
  };

  return (
    <div className="content create_work_schedule_container px-5">
      {/* {isLoading ? ( */}
      {fetchWorkGroupsLoading || fetchWorkScheduleLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : (
        ""
      )}
      <h1 className="page-title">{t("New work schedule")}</h1>

      <div className="row align-items-start">
        <div className="col-3">
          <DateTimePickerForm
            {...formProps}
            datePickerContainer="w-100"
            label="Apply this starting from"
            labelStyle="my-2"
            name="applyFrom"
            id="applyFrom"
            isDisabled={scheduleCreated}
            disabled={scheduleCreated}
            value={!!schedule?.start ? moment(schedule?.start) : null}
            // validateBy="textRequired"
            validationName="input.apply_from"
            onChange={handleChangeApplyFrom}
            onSelect={handleChangeApplyFrom}
            containerStyle={`flex-column align-items-start ${
              formSubmitting && !applyFromValidation.isValid
                ? "invalid-schedule-input-style"
                : ""
            }`}
          />
          {formSubmitting && !applyFromValidation.isValid ? (
            <p className="shift-validation-message">
              {t(applyFromValidation.validationMessage)}
            </p>
          ) : null}
        </div>
        <div className="col-6">
          <BSelect
            {...formProps}
            name="work_group_id"
            id="work_group_id"
            placeholder="Select work group"
            validationName="input.work_group_id"
            label={"work group"}
            keepDefaultStyle
            options={workGroups}
            isDisabled={scheduleCreated}
            onChange={handleWorkGroupChange}
            value={schedule?.workGroup ?? null}
            optionLabel="name"
            defaultValue={workGroup}
            optionValue="id"
            labelStyle="my-2"
            containerStyle="flex-column align-items-start"
            inputContainerStyle={`flex-grow-1 mr-0 w-100 ${
              formSubmitting && !workGroupValidation.isValid
                ? "invalid-schedule-input-style"
                : ""
            }`}
            skipLocalization
            icon="people"
            // validateBy="textRequired"
            // validateMessage="invalid"
          />
          {formSubmitting && !workGroupValidation.isValid ? (
            <p className="shift-validation-message">
              {t(workGroupValidation.validationMessage)}
            </p>
          ) : null}
        </div>
        <div className="col-3">
          <DateTimePickerForm
            {...formProps}
            datePickerContainer="w-100"
            label="Starting From"
            labelStyle="my-2"
            name="start"
            id="scheduleStartDate"
            value={!!schedule.draft_date ? moment(schedule.draft_date) : null}
            onChange={scheduleStartDateChange}
            onSelect={scheduleStartDateChange}
            containerStyle={`flex-column align-items-start ${
              formSubmitting && !startFromValidation.isValid
                ? "invalid-schedule-input-style"
                : ""
            }`}
            // validationName={`input.${props.validationInputName}.${index}.start`}
            // onSelect={handleChildDateInputChange}
          />
          {formSubmitting && !startFromValidation.isValid ? (
            <p className="shift-validation-message">
              {t(startFromValidation.validationMessage)}
            </p>
          ) : null}
        </div>
      </div>

      <div className="selectall_dropdown mt-4">
        <BSelect
          {...formProps}
          name="work_team_id"
          id="work_team_id"
          value={schedule.workTeam}
          label={t("employees") + "/" + t("work teams")}
          keepDefaultStyle
          isMulti={true}
          options={employeeTeamsOptions}
          onChange={handleWorkTeamChange}
          optionLabel="name"
          optionValue="id"
          labelStyle="my-2"
          rootStyle="w-100"
          containerStyle="containerStyle flex-column align-items-start"
          inputContainerStyle={`flex-grow-1 mr-0 w-100 ${
            formSubmitting && !workTeamsValidation.isValid
              ? "invalid-schedule-input-style"
              : ""
          }`}
          skipLocalization
          icon="people"
          isClearable={false}
          validationName="input.work_team_id"

          // validateBy="textRequired"
          // isDisabled={showCalendar}
        />
        <span onClick={handleEmployeesWorkTeamsSelectAll}>
          {t("select all")}
        </span>
      </div>
      {formSubmitting && !workTeamsValidation.isValid ? (
        <p className="shift-validation-message">
          {t(workTeamsValidation?.validationMessage)}
        </p>
      ) : null}

      <h3 className="subtitle">{t("Schedule")}</h3>
      <div className="row align-items-start">
        <div className="col-3 align-self-start">
          <BSelect
            {...formProps}
            name="cycle_days"
            validationName="input.cycle_days"
            label={"No. of days in a cycle"}
            keepDefaultStyle
            isMulti={false}
            onChange={cycleDaysChange}
            options={cycleLength}
            bValue={schedule.schedule_days}
            optionLabel="name"
            optionValue="id"
            labelStyle="my-2 col-6"
            containerStyle="containerStyle flex-row align-items-center"
            inputContainerStyle={`flex-grow-1 mr-0 w-100 ${
              formSubmitting && !cycleDaysValidation.isValid
                ? "invalid-schedule-input-style"
                : ""
            }`}
            skipLocalization
            icon="type"
            // validateBy="textRequired"
          />
          {/* <InputForm
            {...formProps}
            label={"No. of days in a cycle"}
            labelStyle="my-2 mr-5"
            name="cycle_days"
            validationName="input.cycle_days"
            type="text"
            value={schedule.schedule_days}
            hasSuffix
            suffixTitle="Days"
            containerStyle="containerStyle d-flex align-items-center justify-content-start"
            inputContainerStyle={`d-flex align-items-center ${
              formSubmitting && !cycleDaysValidation.isValid
                ? "invalid-schedule-days-input-style"
                : ""
            }`}
            onChange={cycleDaysChange}
          />
          {formSubmitting && !cycleDaysValidation.isValid ? (
            <p className="shift-validation-message">
              {t(cycleDaysValidation.validationMessage)}
            </p>
          ) : null} */}
        </div>

        <div className="col-3">
          {/* <BSelect
            {...formProps}
            name="break_into"
            validationName="input.break_into"
            label={"Break into"}
            keepDefaultStyle
            isMulti={false}
            onChange={breakIntoChange}
            options={cycleLength}
            bValue={schedule.break_into}
            optionLabel="name"
            optionValue="id"
            labelStyle="my-2"
            containerStyle="containerStyle flex-column align-items-start"
            inputContainerStyle={`flex-grow-1 mr-0 w-100 ${
              formSubmitting &&
              (!breakIntoValidation.isValid ||
                schedule?.break_into > schedule?.schedule_days)
                ? "invalid-schedule-input-style"
                : ""
            }`}
            skipLocalization
            icon="type"
            // validateBy="textRequired"
          />
           */}
          <InputForm
            {...formProps}
            labelStyle="my-2 mr-5"
            name="break_into"
            validationName="input.break_into"
            label={"Break into"}
            type="text"
            value={schedule.break_into}
            hasSuffix
            suffixTitle="Days"
            containerStyle="containerStyle d-flex align-items-center flex-row justify-content-start"
            inputContainerStyle={`d-flex align-items-center ${
              formSubmitting &&
              (!breakIntoValidation.isValid ||
                +schedule?.break_into > +schedule?.schedule_days)
                ? "invalid-schedule-days-input-style"
                : ""
            }`}
            onChange={breakIntoChange}
          />
          {formSubmitting && !breakIntoValidation.isValid ? (
            <p className="shift-validation-message">
              {t(breakIntoValidation.validationMessage)}
            </p>
          ) : null}
          {formSubmitting &&
          +schedule?.break_into > +schedule?.schedule_days ? (
            <div className="invalid-container-style">
              <p className="validity-msg-style">{`this value can't be more than ${schedule?.schedule_days}`}</p>
            </div>
          ) : null}
        </div>

        {showCalendar ? (
          <div className="col-2 d-none">
            <span>{t("repeat schedule")}</span>
            <Switch
              checked={schedule.repeat == 1}
              onChange={handleRepeatSwitchChange}
              color="primary"
              name="checkedB"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
        ) : (
          ""
        )}
        <div className="col-1">
          {scheduleCreated ? (
            <button
              className="draw_btn"
              onClick={deleteAllShifts}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  style={{ width: "1rem", height: "1rem", color: "#fff" }}
                />
              ) : !scheduleCreated ? (
                t("Reset Shifts")
              ) : (
                t("Reset Shifts")
              )}
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="col-1">
          <button
            className="draw_btn"
            onClick={submitHandler}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem", color: "#fff" }}
              />
            ) : !scheduleCreated ? (
              t("draw")
            ) : (
              t("Update")
            )}
          </button>
        </div>
      </div>
      {showCalendar ? (
        <div className="table_container scheduler_container">
          <div className="calendar_switch">
            <span>{t("locations")}</span>
            <Switch
              checked={schedule.draft_employees == 1}
              onChange={handleSwitchChange}
              color="primary"
              name="checkedB"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <span>{t("employees")}</span>
          </div>

          <div className="mb-3">
            <Tabs
              value={activeValue}
              onChange={handleChangeTab}
              orientation={"horizontal"}
              scrollButtons="auto"
              variant="fullWidth"
              // classes={{
              //   root: classes.root,
              //   indicator: classes.indicator,
              //   flexContainer: classes.flexContainer,
              // }}
            >
              {intervalArray.map((iteration) => (
                <Tab
                  // classes={{
                  //   root: classes.tabRoot,
                  //   wrapper: "top-bar-label",
                  //   selected: isRtl ? classes.selectedRtl : classes.selected,
                  // }}
                  value={Number(iteration) + 1}
                  label={
                    <div className="routeBar-text">
                      {t("interval")} {Number(iteration) + 1}
                    </div>
                  }
                />
              ))}
            </Tabs>
          </div>
          {!isCalendarLoading ? (
            <Eventcalendar
              view={viewSettings}
              locale={
                document.documentElement.lang?.includes("ar")
                  ? localeAr
                  : localeEn
              }
              data={shifts}
              resources={resources}
              invalid={invalidDates}
              dragToCreate={true}
              dragToResize={false}
              dragToMove={true}
              clickToCreate={true}
              extendDefaultEvent={extendDefaultEvent}
              onEventDoubleClick={onEventClick}
              onEventCreated={onEventCreated}
              onEventDeleted={onEventDeleted}
              renderResource={renderMyResource}
              cssClass="md-employee-shifts"
              selectMultipleEvents={true}
              selectedEvents={mySelectedEvents}
              onSelectedEventsChange={onSelectedEventsChange}
              // renderScheduleEventContent={myScheduleEvent}
              renderScheduleEvent={renderEvent}
              renderResourceHeader={myCustomResourceHeaderTemplate}
              refDate={refDate}
              // min={refDate}
              // max={refEndDate}
              selectedDate={refDate}
              onEventRightClick={onEventRightClick}
              onCellRightClick={onCellRightClick}
              onEventUpdated={onEventUpdate}
              // onEventHoverIn={onEventHoverIn}
              // onEventHoverOut={onEventHoverOut}
              dir
            />
          ) : (
            ""
          )}
          <Popup
            display="anchored"
            isOpen={isEventOpen}
            anchor={eventAnchor}
            touchUi={false}
            showOverlay={false}
            contentPadding={false}
            closeOnOverlayClick={false}
            width={350}
            cssClass="md-tooltip"
          >
            <div
              onMouseEnter={onMouseEnterEvent}
              onMouseLeave={onMouseLeaveEvent}
            >
              <div
                className="md-tooltip-header"
                style={{ backgroundColor: "#2665ac" }}
              >
                <span className="md-tooltip-name-age">
                  {tooltipEvent.title}
                </span>
                <span className="md-tooltip-time"></span>
              </div>
              <div className="md-tooltip-info">
                <LocationOnOutlinedIcon />{" "}
                {tooltipEvent?.location_name
                  ? tooltipEvent?.location_name
                  : "None"}
                {tooltipEvent?.shift_teams?.split(",").map((teamMember) => (
                  <div>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete"></IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <Groups3OutlinedIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={teamMember} secondary={null} />
                    </ListItem>
                  </div>
                ))}
                {tooltipEvent?.shift_users?.split(",").map((teamMember) => (
                  <div>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete"></IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <PersonOutlineOutlinedIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={teamMember} secondary={null} />
                    </ListItem>
                  </div>
                ))}
              </div>
            </div>
          </Popup>
          <Popup
            display="anchored"
            isOpen={isTeamOpen}
            anchor={teamAnchor}
            touchUi={false}
            showOverlay={false}
            contentPadding={false}
            closeOnOverlayClick={false}
            width={350}
            cssClass="md-tooltip"
          >
            {popupTeam.name ? (
              <div ref={teamPopupRef}>
                <div className="md-tooltip-info">
                  {popupTeam.team_members?.split(",")?.map((teamMember) => (
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete"></IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <PersonOutlineOutlinedIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={teamMember} secondary={null} />
                    </ListItem>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}
          </Popup>
          {!isCalendarLoading ? (
            <div className="calendar-buttons-container">
              <button
                className="calendar-button save-as-draft"
                onClick={handleSaveAsDraft}
              >
                {t("save as draft")}
              </button>
              <button
                className="calendar-button save-and-publish"
                onClick={confirmPublish}
              >
                {t("save and publish")}
              </button>
            </div>
          ) : (
            ""
          )}
          <Select
            inputProps={{ type: "hidden" }}
            display="anchored"
            touchUi={false}
            anchor={menuAnchor}
            data={contextMenu}
            value={selectedValue}
            isOpen={menuOpen}
            onChange={selectChange}
            onClose={menuClose}
          />
          <Select
            inputProps={{ type: "hidden" }}
            display="anchored"
            touchUi={false}
            anchor={pasteAnchor}
            data={pasteContextMenu}
            value={selectedValue}
            isOpen={pasteMenuOpen}
            onChange={paste}
            onClose={pasteMenuClose}
            disabled={true}
            renderItem={renderPasteMenuItem}
          />
        </div>
      ) : null}

      <div className="add-shift-modal-container">
        <MainModal
          isOpen={confirmScheduleChange}
          modalTitle={"Warning!"}
          toggle={scheduleChangeCanceled}
          btnOnClick={scheduleChangeConfirmed}
          btnOnCancelClick={scheduleChangeCanceled}
          btnLabel={t("Confirm")}
          btnLabelCancel={t("Cancel")}
          // className="employee-shifts-popup"
          // modalFooterComponent={() => (
          //   <div className="add-shift-footer">
          //     <button
          //       type="button"
          //       className="modal-cancel"
          //       onClick={scheduleChangeCanceled}
          //     >
          //       Cancel
          //     </button>
          //     <button
          //       type="button"
          //       className="modal-Save"
          //       onClick={scheduleChangeConfirmed}
          //     >
          //       Confirm and Continue
          //     </button>
          //   </div>
          // )}
        >
          <h4>
            {t(
              "By applying this change the schedule will be reset and all the changes done to the shifts will be discarded"
            )}
          </h4>
        </MainModal>

        <MainModal
          isOpen={insufficientScheduledEmployees}
          modalTitle={"Cannot publish schedule!"}
          // toggle={scheduleChangeCanceled}
          btnOnClick={() => setInsufficientScheduledEmployees(false)}
          // btnOnCancelClick={scheduleChangeCanceled}
          btnLabel={t("Ok")}
          // btnLabelCancel={t("Cancel")}
          // className="employee-shifts-popup"
          // modalFooterComponent={() => (
          //   <div className="add-shift-footer">
          //     <button
          //       type="button"
          //       className="modal-cancel"
          //       onClick={scheduleChangeCanceled}
          //     >
          //       Cancel
          //     </button>
          //     <button
          //       type="button"
          //       className="modal-Save"
          //       onClick={scheduleChangeConfirmed}
          //     >
          //       Confirm and Continue
          //     </button>
          //   </div>
          // )}
        >
          <h4>{t("There are employees / teams with no shifts")}</h4>
        </MainModal>

        <MainModal
          isOpen={confirmPublishSchedule}
          modalTitle={"Warning!"}
          // toggle={scheduleChangeCanceled}
          btnOnClick={handlePublish}
          btnOnCancelClick={schedulePublishCanceled}
          btnLabel={t("Confirm")}
          btnLabelCancel={t("Cancel")}
          btnSubmitLoading={publishScheduleLoading}
          // className="employee-shifts-popup"
          // modalFooterComponent={() => (
          //   <div className="add-shift-footer">
          //     <button
          //       type="button"
          //       className="modal-cancel"
          //       onClick={scheduleChangeCanceled}
          //     >
          //       Cancel
          //     </button>
          //     <button
          //       type="button"
          //       className="modal-Save"
          //       onClick={scheduleChangeConfirmed}
          //     >
          //       Confirm and Continue
          //     </button>
          //   </div>
          // )}
        >
          <h4>
            {t(
              "By publishing this schedule all sign ins, assignments, exceptions, and requests will be deleted for all included employees starting from (" +
                moment(schedule.start ?? undefined).format("DD/MM/YYYY") +
                ")"
            )}
          </h4>
        </MainModal>

        <MainModal
          modalTitle={"Warning!"}
          isOpen={confirmDeleteEmployees}
          toggle={deleteCanceled}
          btnOnClick={deleteConfirmed}
          btnOnCancelClick={deleteCanceled}
          btnLabel={t("Confirm")}
          // className="employee-shifts-popup"
        >
          <p>
            All the shifts for the following employees / teams are going to be
            reset
          </p>
          <div className="row ">
            {removedEmployees.length > 0 ? (
              <div className="col-6">
                <h4>Employees</h4>

                {removedEmployees.map((iteration) => (
                  <ListItem
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete"></IconButton>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <PersonOutlineOutlinedIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={iteration.name} secondary={null} />
                  </ListItem>
                ))}
              </div>
            ) : (
              ""
            )}
            <div className="col-6">
              {" "}
              {removedTeams.length > 0 ? <h4>Teams</h4> : ""}
              {removedTeams.map((iteration) => (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete"></IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <Groups3OutlinedIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={iteration.name} secondary={null} />
                </ListItem>
              ))}
            </div>
          </div>
        </MainModal>

        <MainModal
          isOpen={isOpen}
          modalHeader={<ShiftModalHeader />}
          toggle={cancelEventAdd}
          btnOnClick={saveEvent}
          btnSubmitLoading={upsertShiftLoading}
          btnOnCancelClick={cancelEventAdd}
          // className="employee-shifts-popup"
        >
          {/* <span>Make this day off </span>
          <Switch
            checked={tempShift.day_off === 1}
            onChange={handlePopupDayOffSwitchChange}
            color="primary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          /> */}

          <h3 className="shift-form-location-title">
            {schedule?.draft_employees == 0
              ? `${t("location")}: ${
                  schedule?.workGroup?.locations?.find(
                    (location) => location?.id === tempShift?.resource
                  )?.name
                }`
              : tempShift?.resource?.indexOf("team") > -1
                ? `${t("team")}: ${
                    schedule?.teams?.find(
                      (team) => team?.id === tempShift?.resource?.split("-")[1]
                    )?.name
                  }`
                : `${t("employee")}: ${
                    schedule?.employees?.find(
                      (employee) =>
                        employee?.id === tempShift?.resource?.split("-")[1]
                    )?.name
                  }`}
          </h3>

          <h5>
            Work Day Template
            <IconButton
              className="d-none"
              color="success"
              aria-label="Select work day template"
              onClick={addWorkDayTemplate}
              disabled={tempShift?.day_off}
            >
              <AddCircleIcon />
            </IconButton>
          </h5>
          {tempShifts.map((shift, index) => (
            <div>
              <div className="row">
                <div className="col-6">
                  <BSelect
                    {...formProps}
                    name="popup_work_day_template_id"
                    validateBy="textRequired"
                    keepDefaultStyle
                    onChange={(event) =>
                      handlePopupWorkDayTemplateChange(event, shift.id)
                    }
                    // value={
                    //   shift.WorkDayTemplate !== null
                    //     ? shift.work_timing
                    //     : { name: "select work day template", value: null }
                    // }
                    placeholder="select work day template"
                    value={
                      shift.work_day_template?.id
                        ? shift.work_day_template
                        : null
                    }
                    options={
                      dropDownWorkDayTemplates[shift.id] !== undefined
                        ? dropDownWorkDayTemplates[shift.id]
                        : []
                    }
                    optionLabel="name"
                    optionValue="id"
                    labelStyle="my-2"
                    containerStyle="containerStyle flex-column align-items-start"
                    inputContainerStyle="flex-grow-1 mr-0 w-100"
                    skipLocalization
                    isDisabled={tempShift.day_off == 1}
                    icon="people"
                  />
                </div>
                <div className="col-5">
                  <BSelect
                    {...formProps}
                    name="weight"
                    validateBy="textRequired"
                    validationName="input.weight"
                    // label={"Break into"}
                    keepDefaultStyle
                    isMulti={false}
                    onChange={(event) =>
                      handlePopupWeightChange(event, shift.id)
                    }
                    options={weights}
                    value={shift.weight}
                    optionLabel="name"
                    optionValue="value"
                    labelStyle="my-2"
                    containerStyle="containerStyle flex-column align-items-start"
                    inputContainerStyle="flex-grow-1 mr-0 w-100"
                    skipLocalization
                    isDisabled={tempShift.day_off == 1}
                    icon="type"
                  />
                </div>
                <div className="col-1 p-0">
                  {index != 0 ? (
                    <IconButton
                      color="secondary"
                      aria-label="delete"
                      onClick={(event) =>
                        removeWorkDayTemplate(
                          event,
                          shift.id,
                          shift.work_day_template_id
                        )
                      }
                    >
                      <DeleteIcon color="warning" />
                    </IconButton>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ))}

          {schedule.draft_employees == 0 ? (
            <BSelect
              {...formProps}
              name="work_team_id"
              validateBy="textRequired"
              label={t("employees") + "/" + t("work teams")}
              keepDefaultStyle
              isMulti={true}
              onChange={handlePopupWorkTeamChange}
              options={
                schedule.employees !== undefined
                  ? schedule.employees.concat(schedule.teams)
                  : []
              }
              value={tempShift.workTeam}
              optionLabel="name"
              optionValue="id"
              labelStyle="my-2"
              containerStyle="containerStyle flex-column align-items-start"
              inputContainerStyle="flex-grow-1 mr-0 w-100"
              skipLocalization
              icon="people"
            />
          ) : (
            <BSelect
              {...formProps}
              name="office_id"
              validateBy="textRequired"
              label={"Location"}
              keepDefaultStyle
              isMulti={false}
              onChange={handlePopupLocationChange}
              isDisabled={tempShift.day_off == 1}
              options={workGroup.locations}
              value={tempShift.location}
              optionLabel="name"
              optionValue="id"
              labelStyle="my-2"
              containerStyle="containerStyle flex-column align-items-start"
              inputContainerStyle="flex-grow-1 mr-0 w-100"
              skipLocalization
              icon="people"
            />
          )}
          <BSelect
            {...formProps}
            name="workplace_setting"
            // validateBy="textRequired"
            label={"Employee can additionally sign in From "}
            keepDefaultStyle
            isMulti={false}
            onChange={(event) => handleWorkPlaceSettingChange("signin", event)}
            isDisabled={tempShift.day_off == 1}
            options={workPlaceSettingOptions}
            value={tempShift.workplace_setting}
            optionLabel="name"
            optionValue="id"
            labelStyle="my-2"
            containerStyle="containerStyle flex-column align-items-start"
            inputContainerStyle="flex-grow-1 mr-0 w-100"
            skipLocalization
            icon="people"
          />
          {tempShift.workplace_setting_type == "DEFAULT" ? (
            <BSelect
              {...formProps}
              name="additional_workplaces"
              // validateBy="textRequired"
              label={"Additional Workplaces"}
              keepDefaultStyle
              isMulti={true}
              onChange={(event) =>
                handleAdditionalWorkplacesChange("signin", event)
              }
              isDisabled={tempShift.day_off == 1}
              options={workGroup.locations}
              value={tempShift.additional_work_places}
              optionLabel="name"
              optionValue="id"
              labelStyle="my-2"
              containerStyle="containerStyle flex-column align-items-start"
              inputContainerStyle="flex-grow-1 mr-0 w-100"
              skipLocalization
              icon="people"
            />
          ) : (
            ""
          )}
          <div className="col-12">
            <span>{t("Apply this for both signins and signouts")}</span>
            <Switch
              checked={tempShift.workplace_setting_identical == 1}
              onChange={handleWorkplaceIdenticalChange}
              color="primary"
              name="checkedB"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
          {tempShift.workplace_setting_identical == 0 ? (
            <div>
              <h4>Signout settings</h4>
              <BSelect
                {...formProps}
                name="workplace_setting_signout"
                // validateBy="textRequired"
                label={"Employee can additionally sign in From "}
                keepDefaultStyle
                isMulti={false}
                onChange={(event) =>
                  handleWorkPlaceSettingChange("signout", event)
                }
                isDisabled={tempShift.day_off == 1}
                options={workPlaceSettingOptions}
                value={tempShift.workplace_setting_signout}
                optionLabel="name"
                optionValue="id"
                labelStyle="my-2"
                containerStyle="containerStyle flex-column align-items-start"
                inputContainerStyle="flex-grow-1 mr-0 w-100"
                skipLocalization
                icon="people"
              />
              {tempShift.workplace_setting_signout.id == "DEFAULT" ? (
                <BSelect
                  {...formProps}
                  name="additional_workplaces_signout"
                  // validateBy="textRequired"
                  label={"Additional Workplaces"}
                  keepDefaultStyle
                  isMulti={true}
                  onChange={(event) =>
                    handleAdditionalWorkplacesChange("signout", event)
                  }
                  isDisabled={tempShift.day_off == 1}
                  options={workGroup.locations}
                  value={tempShift.additional_work_places_signout}
                  optionLabel="name"
                  optionValue="id"
                  labelStyle="my-2"
                  containerStyle="containerStyle flex-column align-items-start"
                  inputContainerStyle="flex-grow-1 mr-0 w-100"
                  skipLocalization
                  icon="people"
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {/* {isEdit && <div className="mbsc-button-group">
                <Button className="mbsc-button-block" color="danger" variant="outline" onClick={onDeleteClick}>Delete shift</Button>
            </div>} */}
        </MainModal>
      </div>
    </div>
  );
};

export default CreateWorkSchedulePage;
