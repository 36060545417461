import React from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { agentListQuery } from "../../Graphql/query";
import HelperFns, { showToast } from "../../Helpers/HelperFns";

import {
  Box,
  Card,
  Badge,
  Avatar,
  Divider,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Home,
  ArrowUpward,
  ArrowDownward,
  KeyboardArrowDown,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import Pagination from "../../Components/Pagination";
import InfoTooltip from "../EmployeeProfile/InfoTooltip";
import RenderMultipleSignIns from "./../../Components/RenderMultipleSignIns";

const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const AgentsList = () => {
  // Local State
  const [agents, setAgents] = React.useState([]);
  const [pagination, setPagination] = React.useState(paginationInitState);

  // Server State
  const { loading, refetch: refetchList } = useQuery(agentListQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      first: pagination.perPage,
      page: pagination.currentPage,
    },
    onCompleted: ({ agents }) => {
      setAgents(agents?.data || []);
      setPagination(agents?.paginatorInfo);
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  const handlePaginate = (page = pagination.currentPage) => {
    refetchList({ page });
  };

  return loading ? (
    <div style={{ width: "90vw" }}>
      <Loader inner />
    </div>
  ) : (
    <>
      <Box
        sx={{
          mt: 5,
          mb: 2,
          gap: 1,
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(365px, 1fr))",
        }}
      >
        {agents?.map((agent) => (
          <AgentCard key={agent?.id} {...agent} />
        ))}
      </Box>
      <Pagination
        styleWraper=""
        onPaginate={handlePaginate}
        customPaginator={pagination}
      />
    </>
  );
};

export default AgentsList;

const AgentCard = ({ emp_multiple_sign_ins, ...props }) => {
  const { t } = useTranslation();

  /* ↓ helpers ↓ */

  const getCurrentSignIn = () => {
    if (emp_multiple_sign_ins?.length) {
      let currentSignIn = emp_multiple_sign_ins?.find(
        (signIn) =>
          (!!signIn?.sign_in_time &&
            !!!signIn?.sign_out_time &&
            !!signIn?.status) ||
          !!signIn?.status
      );
      if (currentSignIn) {
        return currentSignIn;
      } else {
        return emp_multiple_sign_ins?.[0];
      }
    } else {
      return null;
    }
  };

  return (
    <Card
      component="article"
      variant="outlined"
      sx={{
        width: 1,
        height: 130,
        display: "flex",
        border: "1px solid #eaeef0",
        justifyContent: "space-between",
        boxShadow: "0 3px 15px 0 rgba(0,0,0,.09)",
        bgcolor: props?.isDimmed ? "#f3f3f3" : "#fff",
      }}
    >
      {/* Image Side */}
      <Box
        className="employee-img"
        sx={{
          py: 0.5,
          minWidth: 80,
          display: "grid",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <Avatar
          src={props?.profile_picture?.path ?? props?.face?.path}
          className={
            +props?.on_break
              ? "avatar blackish-avatar on_break_status_style"
              : "avatar blackish-avatar"
          }
        >
          {HelperFns.getIntials(props?.name)}
        </Avatar>

        {!props?.isTeamView ? (
          <div className="d-flex employee_card_btns_wrapper_style mt-1">
            <InfoTooltip data={props} />
          </div>
        ) : null}
      </Box>

      <Divider light flexItem orientation="vertical" sx={{ my: 1, mr: 1 }} />

      {/* Text Side */}
      <Box
        sx={{
          pb: 1,
          pt: 0.75,
          width: "80%",
          display: "grid",
          marginInlineStart: 0.5,
        }}
      >
        <Box sx={{ width: "95%", overflow: "hidden" }}>
          {/* Name */}
          <Tooltip
            classes={{
              tooltip: "custom_tooltip_style",
              arrow: "custom_arrow_tooltip_style",
              popper: "custom_employee_name_tooltip_style",
            }}
            arrow
            title={
              props?.name?.length > 20 ? (
                <h4 className="employee_name_tooltip_style">
                  {props?.name?.toLowerCase()}
                </h4>
              ) : (
                ""
              )
            }
          >
            <Typography
              noWrap
              component="h2"
              sx={{ textAlign: "left" }}
              dir={HelperFns.isStringRTL(props?.name) ? "rtl" : "ltr"}
            >
              <Link
                className="employee_name_style"
                onContextMenu={(e) => e.preventDefault()}
                to={{
                  pathname: `/crm/agent/${props.id}`,
                  state: { name: props.name },
                }}
              >
                {props?.name?.toLowerCase()}
              </Link>
            </Typography>
          </Tooltip>

          {/* Position */}
          <Tooltip
            classes={{
              tooltip: "custom_tooltip_style",
              arrow: "custom_arrow_tooltip_style",
              popper: "custom_employee_name_tooltip_style",
            }}
            arrow
            title={
              props?.position?.name.length > 20 ? (
                <Typography variant="body2" color="text.secondary">
                  {props?.user_type === "Owner"
                    ? props?.user_type
                    : props?.position?.name}
                </Typography>
              ) : null
            }
          >
            <Typography component="div" variant="body2" color="text.secondary">
              {props?.user_type === "Owner"
                ? props?.user_type
                : props?.position?.name}

              {/* Department */}
              <Tooltip
                classes={{
                  tooltip: "custom_tooltip_style",
                  arrow: "custom_arrow_tooltip_style",
                  popper: "custom_employee_name_tooltip_style",
                }}
                arrow
                title={
                  props?.department?.name.length > 25 ? (
                    <div className="employee_title_style">
                      {props?.department?.name}
                    </div>
                  ) : null
                }
              >
                <div className="employee_title_style">
                  {props?.department?.name}
                </div>
              </Tooltip>
            </Typography>
          </Tooltip>
        </Box>

        {/* Lower Part */}
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            columnGap: 1,
          }}
        >
          {/* Attendance */}
          {getCurrentSignIn() !== null ? (
            <div
              className={`d-flex ${
                emp_multiple_sign_ins?.length > 1 ||
                (!getCurrentSignIn()?.sign_in_time &&
                  getCurrentSignIn()?.sign_in_req !== null)
                  ? ""
                  : "employee-sign-ins-container"
              } ${
                props.active
                  ? `${getCurrentSignIn()?.status}_status_style`
                  : `${
                      props.is_blacklisted
                        ? "blacklist_status_style"
                        : "suspend_status_style"
                    }`
              }`}
            >
              <Tooltip
                title={
                  props.is_blacklisted
                    ? "Blacklisted Employee"
                    : getCurrentSignIn()?.work_timing_name
                }
                className="d-flex align-items-center"
              >
                {getCurrentSignIn()?.sign_in_time ? (
                  <div className="d-flex align-items-center">
                    {+getCurrentSignIn()?.from_home ? (
                      <Home
                        sx={{
                          fontSize: 12,
                          color: "#313030",
                          marginInlineEnd: 0.25,
                        }}
                      />
                    ) : null}
                    <div
                      style={{
                        fontSize: emp_multiple_sign_ins?.length > 1 ? 10 : 11,
                      }}
                    >
                      <ArrowDownward sx={{ fontSize: 11, color: "#27b40c" }} />
                      {getCurrentSignIn()?.sign_in_time
                        ? moment(getCurrentSignIn()?.sign_in_time).format(
                            "hh:mm A"
                          )
                        : " _ _ : _ _"}
                      <span className="px-1">-</span>
                      <ArrowUpward sx={{ fontSize: 11, color: "#ff6a6a" }} />
                      {getCurrentSignIn()?.sign_out_time
                        ? moment(getCurrentSignIn()?.sign_out_time).format(
                            "hh:mm A"
                          )
                        : "_ _ : _ _"}
                    </div>
                  </div>
                ) : (
                  <div>
                    {props?.user_type === "Owner" ||
                    (props.active &&
                      !getCurrentSignIn()?.sign_in_req &&
                      getCurrentSignIn()?.sign_in_req !== null)
                      ? t("present")
                      : props.active !== undefined
                        ? props.active
                          ? t(getCurrentSignIn()?.status?.toLowerCase())
                          : t("suspended")
                        : ""}
                  </div>
                )}
              </Tooltip>
            </div>
          ) : props?.user_type === "Owner" ? null : (
            <p>{t("not signed in")}</p>
          )}

          <div>
            {emp_multiple_sign_ins?.length > 1 ? (
              <div className="dropdown-icon-container mb-1">
                <Tooltip
                  classes={{
                    arrow: "custom_arrow_tooltip_style",
                    tooltip: "custom-employee-card-tooltip",
                    popper: "custom_employee_name_tooltip_style",
                  }}
                  arrow
                  color="#333"
                  title={
                    <div>
                      {emp_multiple_sign_ins?.map((signIn, index) => (
                        <RenderMultipleSignIns
                          empList
                          index={index}
                          signIn={signIn}
                          signInTime={getCurrentSignIn()?.sign_in_time}
                          emp={{ id: props?.id, name: props?.name }}
                        />
                      ))}
                    </div>
                  }
                >
                  <Badge
                    color="success"
                    badgeContent={emp_multiple_sign_ins?.length}
                  >
                    <KeyboardArrowDown className="dropdown-icon" />
                  </Badge>
                </Tooltip>
              </div>
            ) : null}
          </div>
        </Box>
      </Box>
    </Card>
  );
};
