import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateTimePickerForm, RadioboxForm, InputForm } from "form-builder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../Components/Loader";
import Pagination from "../../Components/Pagination";
import ExportModal from "./ExportModal";
import {
  onInputResetAction,
  showExportMonthlyReportModal,
  updateValueAction,
  fetchMonthlyReportListSuccess,
} from "../../Store/Actions";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";

import ToggleModal from "./ToggleModal";

import { useTranslation } from "react-i18next";
import moment from "moment";
import { AgGridTable } from "../../Components/AgGridTable";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import { BarsIconButton } from "../../Components/IconButtonWithTooltip";
import FiltersDrawer from "../../Components/FiltersDrawer";
import { useLazyQuery } from "@apollo/client";
import {
  GET_MONTHLY_REPORT_OPTIONS,
  monthlyReportQuery,
} from "../../Graphql/query";
import FilterBadge from "../../Components/FilterBadge";
import ClearIcon from "@mui/icons-material/Clear";
import useCompanyAttType from "../../Helpers/Hooks/useCompanyAttType";

const dataTableRef = "monthlyReport";
const filterInitState = {
  from_month_year: moment().format("YYYY-MM"),
  to_month_year: moment().format("YYYY-MM"),
  from: moment().startOf("month").format("YYYY-MM-DD"),
  to: moment().endOf("month").format("YYYY-MM-DD"),
  offices: "",
  departments: "",
  positions: "",
  employees: "",
  employee_search: "",
};
const MonthlyReport = ({ customReport = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isToggleModalVissible, setIsToggleModalVissible] = useState(false);
  const gridRef = useRef();
  const [dateFilter, setDateFilter] = useState({
    fromVar: "from_month_year",
    toVar: "to_month_year",
    pickerVar: "month",
    formatVar: "MM-yyyy",
    requestFormatVar: "yyyy-MM",
    formName: "monthlyReportColumns",
  });

  const { formName } = dateFilter;

  // Redux Hooks

  const user = useSelector((state) => state.auth.userProfile);
  const tableState = useSelector((state) => state.super[dataTableRef]);

  const isColumnActive = useSelector(
    (state) => state.monthlyReport?.[formName]
  );
  const [filtersState, setFiltersState] = useState(filterInitState);

  const [type, setType] = useState("both");

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

  const [fetchMonthlyReportData, { loading, variables }] =
    useLazyQuery(monthlyReportQuery);

  const fetchVar = {
    type,
    user_id: filtersState.employees?.length ? filtersState.employees : null,
    office_id: filtersState.offices?.length ? filtersState.offices : null,
    department_id: filtersState.departments?.length
      ? filtersState.departments
      : null,
    position_id: filtersState.positions?.length ? filtersState.positions : null,
    from_month_year: filtersState?.from_month_year,
    to_month_year: filtersState?.to_month_year,
    from_date: filtersState?.from,
    to_date: filtersState?.to,
    ...(filtersState?.employee_search
      ? {
          employee_search: filtersState?.employee_search,
        }
      : {}),
    custom_report: customReport,
    column:
      tableState?.orderBy?.column == "total_over_time_hours"
        ? "total_over_time_hours_sec"
        : tableState?.orderBy?.column,
    order: tableState?.orderBy?.order,
    rows: tableState.pagination.perPage,
    page: tableState.pagination.currentPage,
  };

  const handelFecthData = (filters = {}) => {
    fetchMonthlyReportData({
      variables: {
        ...fetchVar,
        ...filters,
      },
      onCompleted: (res) => {
        dispatch(
          fetchMonthlyReportListSuccess(
            {
              ref: dataTableRef,
              [dataTableRef]: {
                ...tableState,
                customReport,
                pagination: {
                  ...tableState?.pagination,
                  currentPage: 1,
                },
              },
            },
            res?.monthlyReport
          )
        );
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ?? err?.message
        );
      },
    });
  };

  const [filterOptions, setFilterOptions] = useState(null);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (open && !filterOptions) {
      fetchOptions({
        onCompleted: (res) => {
          setFilterOptions({
            employeesOptions: res?.extraUsersFilter?.data ?? [],
            positionsOptions: res?.company_positions?.data ?? [],
            departmentsOptions: res?.company_departments?.data ?? [],
          });
        },
        onError: (err) => {
          showToast(
            "error",
            err?.graphQLErrors?.[0]?.extensions?.reason ?? err?.message
          );
        },
      });
    }
    setIsFilterDrawerOpen(open);
  };
  const onFilterReset = () => {
    setFiltersState(filterInitState);
  };

  const onFilterSubmit = () => {
    let cleanFetchVar = fetchVar;
    Object.keys(cleanFetchVar).forEach(
      (key) => cleanFetchVar[key] === undefined && delete cleanFetchVar[key]
    );
    let { skipOptions, ...cleaQueryVar } = variables;
    const isDifferent =
      JSON.stringify({ ...cleanFetchVar, page: 1 }) !==
      JSON.stringify({ ...cleaQueryVar, page: 1 });
    if (isDifferent) handelFecthData();
    setIsFilterDrawerOpen(false);
  };

  const [clearOrCancel, setClearOrCancel] = useState(false);

  useEffect(() => {
    if (clearOrCancel) {
      handelFecthData();
      setClearOrCancel(false);
      return;
    }
  }, [filtersState]);

  const handelClearFilters = () => {
    onFilterReset();
    setClearOrCancel(true);
  };

  useEffect(() => {
    handelFecthData();
  }, [type]);

  useEffect(() => {
    handelFecthData();

    return () => {
      onFilterReset();
      dispatch(onInputResetAction(dataTableRef, "custom_report"));
    };
  }, [customReport]);

  useEffect(() => {
    if (customReport) {
      setDateFilter({
        fromVar: "from",
        toVar: "to",
        pickerVar: "date",
        formatVar: "DD-MM-yyyy",
        requestFormatVar: "YYYY-MM-DD",
        formName: "customReportColumns",
      });
      dispatch(
        updateValueAction(
          dataTableRef,
          "from",
          user?.company?.getMonthBoundaries?.from
        )
      );
      dispatch(
        updateValueAction(
          dataTableRef,
          "to",
          user?.company?.getMonthBoundaries?.to
        )
      );
      if (tableState?.from) {
        handelFecthData();
      }
    } else {
      setDateFilter({
        fromVar: "from_month_year",
        toVar: "to_month_year",
        pickerVar: "month",
        formatVar: "MM-yyyy",
        requestFormatVar: "yyyy-MM",
        formName: "monthlyReportColumns",
      });
    }
  }, [customReport]);

  const handlePaginate = (page = tableState?.pagination?.currentPage) => {
    handelFecthData({ page, type });
  };

  // Total value grid row when user is filtered
  const totalRow = useMemo(() => {
    const totalDuration = (row) => {
      const durations = tableState?.data?.map((item) => item[row]);
      let totalMilliSeconds;
      if (
        row === "total_hours_secs" ||
        row === "expected_hours_secs" ||
        row === "total_checkIn_hours_secs" ||
        row === "total_hours_with_out_over_time_sec"
      ) {
        totalMilliSeconds = durations.reduce((acc, curr) => {
          return +acc + +curr;
        }, 0);
        return totalMilliSeconds;
      } else {
        totalMilliSeconds = durations.reduce((acc, curr) => {
          const currentMs = moment.duration(curr).asMilliseconds();
          return acc + currentMs;
        }, 0);

        const hours = Math.trunc(moment.duration(totalMilliSeconds).asHours());
        const minutesSeconds = moment.utc(totalMilliSeconds).format("mm:ss");
        if (hours <= 0 && minutesSeconds === "00:00") {
          return "00:00:00";
        }
        if (hours.toString().length === 1) {
          return `0${hours}:${minutesSeconds}`;
        }
        return `${hours}:${minutesSeconds}`;
      }
    };

    return {
      name: "Total",
      emp_code: "----",
      month_year: null,
      department: "----",
      position: "----",
      holidays: tableState?.data
        ?.reduce((acc, curr) => +acc + +curr?.holidays, 0)
        ?.toFixed(2),
      weekends: tableState?.data
        ?.reduce((acc, curr) => +acc + +curr?.weekends, 0)
        ?.toFixed(2),
      attended_days: tableState?.data
        ?.reduce((acc, curr) => +acc + +curr?.attended_days, 0)
        ?.toFixed(2),
      ontime_total: tableState?.data
        ?.reduce((acc, curr) => +acc + +curr?.ontime_total, 0)
        ?.toFixed(2),
      ontime_normal: tableState?.data
        ?.reduce((acc, curr) => +acc + +curr?.ontime_normal, 0)
        ?.toFixed(2),
      ontime_half: tableState?.data
        ?.reduce((acc, curr) => +acc + +curr?.ontime_half, 0)
        ?.toFixed(2),
      late_total: tableState?.data
        ?.reduce((acc, curr) => +acc + +curr?.late_total, 0)
        ?.toFixed(2),
      late_normal: tableState?.data
        ?.reduce((acc, curr) => +acc + +curr?.late_normal, 0)
        ?.toFixed(2),
      late_half: tableState?.data
        ?.reduce((acc, curr) => +acc + +curr?.late_half, 0)
        ?.toFixed(2),
      late_duration: totalDuration("late_duration"),
      absent_total: tableState?.data
        ?.reduce((acc, curr) => +acc + +curr?.absent_total, 0)
        ?.toFixed(2),
      normal_leaves: tableState?.data
        ?.reduce((acc, curr) => +acc + +curr?.normal_leaves, 0)
        ?.toFixed(2),
      emergency_leaves: tableState?.data
        ?.reduce((acc, curr) => +acc + +curr?.emergency_leaves, 0)
        ?.toFixed(2),
      sick_leaves: tableState?.data
        ?.reduce((acc, curr) => +acc + +curr?.sick_leaves, 0)
        ?.toFixed(2),
      unpaid_leaves: tableState?.data
        ?.reduce((acc, curr) => +acc + +curr?.unpaid_leaves, 0)
        ?.toFixed(2),
      half_days: tableState?.data
        ?.reduce((acc, curr) => +acc + +curr?.half_days, 0)
        ?.toFixed(2),
      total_checkIn_hours_secs: totalDuration("total_checkIn_hours_secs"),
      permissions: tableState?.data
        ?.reduce((acc, curr) => +acc + +curr?.permissions, 0)
        ?.toFixed(2),
      permissions_time: totalDuration("permissions_time"),
      unpaid_permissions: tableState?.data
        ?.reduce((acc, curr) => +acc + +curr?.unpaid_permissions, 0)
        ?.toFixed(2),
      unpaid_permissions_time: totalDuration("unpaid_permissions_time"),
      breaks: tableState?.data?.reduce((acc, curr) => +acc + +curr?.breaks, 0),
      breaks_time: totalDuration("breaks_time"),
      total_early_sign_out_sec: totalDuration("total_early_sign_out_sec"),
      total_penalties_as_days: tableState?.data
        ?.reduce((acc, curr) => +acc + +curr?.total_penalties_as_days, 0)
        ?.toFixed(2),
      total_penalties_as_fixed_amount: tableState?.data
        ?.reduce(
          (acc, curr) => +acc + +curr?.total_penalties_as_fixed_amount,
          0
        )
        ?.toFixed(2),
      total_penalties_as_hours: tableState?.data
        ?.reduce((acc, curr) => +acc + +curr?.total_penalties_as_hours, 0)
        ?.toFixed(2),
      expected_hours_secs: totalDuration("expected_hours_secs"),
      total_hours_secs: totalDuration("total_hours_secs"),
      total_hours_with_out_over_time_sec: totalDuration(
        "total_hours_with_out_over_time_sec"
      ),
      total_over_time_hours: totalDuration("total_over_time_hours"),
      overtime_days: tableState?.data
        ?.reduce((acc, curr) => +acc + +curr?.overtime_days, 0)
        ?.toFixed(2),
      accepted_total_daily_overtime_hours: totalDuration(
        "accepted_total_daily_overtime_hours"
      ),
      mocked_location_in_count: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.mocked_location_in_count,
        0
      ),
      mocked_location_out_count: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.mocked_location_out_count,
        0
      ),
      spoofed_sign_in_image_count: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.spoofed_sign_in_image_count,
        0
      ),
      spoofed_sign_out_image_count: tableState?.data?.reduce(
        (acc, curr) => +acc + +curr?.spoofed_sign_out_image_count,
        0
      ),
    };
  }, [tableState?.data]);

  const [fetchOptions, { loading: fetchOptionsLoading }] = useLazyQuery(
    GET_MONTHLY_REPORT_OPTIONS
  );

  // Rows to be rendered in the grid
  const gridRowData = useMemo(() => {
    if (tableState?.user_id && tableState?.data?.length > 0) {
      return [...tableState?.data, totalRow];
    }

    return tableState?.data;
  }, [tableState?.data, totalRow, tableState?.user_id]);

  const columnDefs = useMemo(() => {
    return [
      // {
      //   headerValueGetter: () => t("name"),
      //   field: "name",
      //   minWidth: 220,
      //   pinned: user?.lng === "ar-EG" ? "right" : "left",
      //   lockPinned: true,
      //   valueFormatter: (cell) => (cell.value ? cell.value : null),
      // },
      {
        headerValueGetter: () =>
          isColumnActive?.emp_code ? t("employee") : null,
        pinned: user?.lng === "ar-EG" ? "right" : "left",
        hide: true,
        children: [
          {
            headerValueGetter: () => t("name"),
            field: "name",
            minWidth: 220,
            valueFormatter: (cell) => (cell.value ? cell.value : null),
            pinned: user?.lng === "ar-EG" ? "right" : "left",
          },
          {
            headerValueGetter: () => t("employee code"),
            field: "emp_code",
            minWidth: 100,
            hide: isColumnActive?.emp_code ? false : true,
            pinned: user?.lng === "ar-EG" ? "right" : "left",
            valueFormatter: (cell) => (cell.value ? cell.value : "---"),
          },
        ],
      },
      {
        headerValueGetter: () => t("Month"),
        field: "month_year",
        hide:
          isColumnActive?.month_year &&
          filtersState?.from_month_year < filtersState?.to_month_year &&
          !customReport
            ? false
            : true,
        valueFormatter: (cell) =>
          cell?.value ? moment(cell?.value, "MM-YYY").format("MMMM") : "----",
      },
      {
        headerValueGetter: () => t("office"),
        field: "office",
        minWidth: 200,
        hide: isColumnActive?.office ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : null),
      },
      {
        headerValueGetter: () => t("department"),
        field: "department",
        minWidth: 200,
        hide: isColumnActive?.department ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : null),
      },
      {
        headerValueGetter: () => t("position"),
        field: "position",
        minWidth: 200,
        hide: isColumnActive?.position ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : null),
      },

      {
        headerValueGetter: () => t("holidays"),
        field: "holidays",
        hide: isColumnActive?.holidays ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : 0),
      },
      {
        headerValueGetter: () => t("Days Off"),
        field: "weekends",
        hide: isColumnActive?.weekends ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : 0),
      },
      {
        headerValueGetter: () => t("workdays"),
        hide: isColumnActive?.attended_days ? false : true,
        headerClass: "workdays_header_group",
        children: [
          {
            headerValueGetter: () => t("attended"),
            field: "attended_days",
            hide: isColumnActive?.attended_days ? false : true,
          },
          {
            headerValueGetter: () => t("ontime"),
            headerClass: "ontime_header_group",
            hide:
              isColumnActive?.attended_days && isColumnActive?.ontime
                ? false
                : true,
            children: [
              {
                headerValueGetter: () => t("total"),
                field: "ontime_total",
                hide:
                  isColumnActive?.ontime && isColumnActive?.ontime_total
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
              {
                headerValueGetter: () => t("normal"),
                field: "ontime_normal",
                hide:
                  isColumnActive?.ontime && isColumnActive?.ontime_normal
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
              {
                headerValueGetter: () => t("half-days"),
                field: "ontime_half",
                hide:
                  isColumnActive?.ontime && isColumnActive?.ontime_half
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
            ],
          },
          {
            headerValueGetter: () => t("late"),
            headerClass: "late_header_group",
            hide:
              isColumnActive?.attended_days && isColumnActive?.late
                ? false
                : true,
            children: [
              {
                headerValueGetter: () => t("total"),
                field: "late_total",
                hide:
                  isColumnActive?.late && isColumnActive?.late_total
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
              {
                headerValueGetter: () => t("normal"),
                field: "late_normal",
                hide:
                  isColumnActive?.late && isColumnActive?.late_normal
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
              {
                headerValueGetter: () => t("half-days"),
                field: "late_half",
                hide:
                  isColumnActive?.late && isColumnActive?.late_half
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
              {
                headerValueGetter: () => t("duration"),
                field: "late_duration",
                hide:
                  isColumnActive?.late && isColumnActive?.late_duration
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
            ],
          },
        ],
      },
      {
        headerValueGetter: () => t("absent"),
        field: "absent_total",
        hide: isColumnActive?.absent_total ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : 0),
      },
      {
        headerValueGetter: () => t("leaves"),
        headerClass: "leaves_header_group",
        hide:
          isColumnActive?.leaves &&
          (isColumnActive?.normal_leaves ||
            isColumnActive?.emergency_leaves ||
            isColumnActive?.sick_leaves ||
            isColumnActive?.unpaid_leaves ||
            isColumnActive?.half_days)
            ? false
            : true,
        children: [
          {
            headerValueGetter: () => t("normal"),
            field: "normal_leaves",
            hide:
              isColumnActive?.leaves && isColumnActive?.normal_leaves
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("emergency"),
            field: "emergency_leaves",
            hide:
              isColumnActive?.leaves && isColumnActive?.emergency_leaves
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("sick"),
            field: "sick_leaves",
            hide:
              isColumnActive?.leaves && isColumnActive?.sick_leaves
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("unpaid"),
            field: "unpaid_leaves",
            hide:
              isColumnActive?.leaves && isColumnActive?.unpaid_leaves
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("half-days"),
            field: "half_days",
            hide:
              isColumnActive?.leaves && isColumnActive?.half_days
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
        ],
      },
      {
        headerValueGetter: () => t("Check-in Hours"),
        headerClass: "checkin_header_group",
        hide: isColumnActive?.total_checkIn_hours_secs ? false : true,
        children: [
          {
            headerValueGetter: () => t("duration"),
            minWidth: 120,
            field: "total_checkIn_hours_secs",
            hide: isColumnActive?.total_checkIn_hours_secs ? false : true,
            valueFormatter: (cell) => HelperFns.secToTime(cell.value),
          },
        ],
      },
      {
        headerValueGetter: () => t("permissions"),
        headerClass: "permissions_header_group",
        hide:
          isColumnActive?.permissionsColumn &&
          (isColumnActive?.permissions || isColumnActive?.permissions_time)
            ? false
            : true,
        children: [
          {
            headerValueGetter: () => t("paid permissions"),
            field: "permissions",
            hide:
              isColumnActive?.permissionsColumn && isColumnActive?.permissions
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("paid duration"),
            field: "permissions_time",
            hide:
              isColumnActive?.permissionsColumn &&
              isColumnActive?.permissions_time
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("unpaid permissions"),
            field: "unpaid_permissions",
            hide:
              isColumnActive?.permissionsColumn &&
              isColumnActive?.unpaid_permissions
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("unpaid duration"),
            field: "unpaid_permissions_time",
            hide:
              isColumnActive?.permissionsColumn &&
              isColumnActive?.unpaid_permissions_time
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
        ],
      },
      {
        headerValueGetter: () => t("breaks"),
        headerClass: "breaks_header_group",
        hide:
          isColumnActive?.breaksColumn &&
          (isColumnActive?.breaks || isColumnActive?.breaks_time)
            ? false
            : true,
        children: [
          {
            headerValueGetter: () => t("number"),
            field: "breaks",
            hide:
              isColumnActive?.breaksColumn && isColumnActive?.breaks
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("duration"),
            field: "breaks_time",
            hide:
              isColumnActive?.breaksColumn && isColumnActive?.breaks_time
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
        ],
      },
      {
        headerValueGetter: () => t("penalties"),
        headerClass: "breaks_header_group",
        hide:
          !isColumnActive?.total_early_sign_out_time &&
          !isColumnActive?.total_penalties_as_days &&
          !isColumnActive?.total_penalties_as_fixed_amount &&
          !isColumnActive?.total_penalties_as_hours,
        children: [
          {
            headerValueGetter: () => t("total early sign out time"),
            field: "total_early_sign_out_sec",
            width: 160,
            hide: isColumnActive?.total_early_sign_out_time ? false : true,
          },
          {
            headerValueGetter: () => t("total penalties as days"),
            field: "total_penalties_as_days",
            width: 160,
            hide: isColumnActive?.total_penalties_as_days ? false : true,
          },
          {
            headerValueGetter: () => t("total penalties as fixed amount"),
            field: "total_penalties_as_fixed_amount",
            width: 160,
            hide: isColumnActive?.total_penalties_as_fixed_amount
              ? false
              : true,
          },
          {
            headerValueGetter: () => t("total penalties as hours"),
            field: "total_penalties_as_hours",
            width: 160,
            hide: isColumnActive?.total_penalties_as_hours ? false : true,
          },
        ],
      },
      {
        headerValueGetter: () => t("work hours"),
        headerClass: "work_hours_header_group",
        hide:
          isColumnActive?.workHours &&
          (isColumnActive?.total_hours_secs ||
            isColumnActive?.expected_hours_secs)
            ? false
            : true,
        children: [
          {
            headerValueGetter: () => t("expected work hrs"),
            field: "expected_hours_secs",
            width: 120,
            hide:
              isColumnActive?.workHours && isColumnActive?.expected_hours_secs
                ? false
                : true,
            valueFormatter: (cell) => HelperFns.secToTime(cell.value),
          },
          {
            headerValueGetter: () => t("total hrs worked"),
            field: "total_hours_secs",
            width: 120,
            hide:
              isColumnActive?.workHours && isColumnActive?.total_hours_secs
                ? false
                : true,
            valueFormatter: (cell) => HelperFns.secToTime(cell.value),
          },
          {
            headerValueGetter: () => t("total hrs without overtime"),
            field: "total_hours_with_out_over_time_sec",
            width: 120,
            hide:
              isColumnActive?.workHours &&
              isColumnActive?.total_hours_with_out_over_time_sec
                ? false
                : true,
            valueFormatter: (cell) => {
              return HelperFns.secToTime(cell.value);
            },
          },
        ],
      },
      {
        headerValueGetter: () => t("remote work"),
        headerClass: "remote_work_header_group",
        hide:
          isColumnActive?.remote_work &&
          (isColumnActive?.from_home || isColumnActive?.from_field)
            ? false
            : true,
        children: [
          {
            headerValueGetter: () => t("from_home"),
            field: "from_home",
            width: 120,
            hide:
              isColumnActive?.workHours && isColumnActive?.from_home
                ? false
                : true,
            valueFormatter: (cell) => +cell?.value,
          },
          {
            headerValueGetter: () => t("from field"),
            field: "from_field",
            width: 120,
            hide:
              isColumnActive?.workHours && isColumnActive?.from_field
                ? false
                : true,
            valueFormatter: (cell) => +cell?.value,
          },
        ],
      },
      {
        headerValueGetter: () => t("total overtime hours"),
        field: "total_over_time_hours",
        width: 160,
        hide: isColumnActive?.total_over_time_hours ? false : true,
      },

      {
        headerValueGetter: () => t("accepted overtime"),
        headerClass: "overtime_header_group",
        hide:
          isColumnActive?.overtime &&
          (isColumnActive?.overtime_days ||
            isColumnActive?.accepted_total_daily_overtime_hours)
            ? false
            : true,
        children: [
          {
            headerValueGetter: () => t("days"),
            field: "overtime_days",
            hide:
              isColumnActive?.overtime && isColumnActive?.overtime_days
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("monthly duration"),
            field: "accepted_monthly_overtime_hours",
            hide:
              isColumnActive?.overtime &&
              isColumnActive?.accepted_monthly_overtime_hours
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("daily overtime"),
            field: "accepted_total_daily_overtime_hours",
            width: 160,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
            hide:
              isColumnActive?.overtime &&
              isColumnActive?.accepted_total_daily_overtime_hours
                ? false
                : true,
          },
        ],
      },
      {
        headerValueGetter: () => t("Mocked Location Count"),
        headerClass: "mocked_location_header_group",
        children: [
          {
            headerValueGetter: () => t("sign in"),
            field: "mocked_location_in_count",
            width: 120,
            hide: isColumnActive?.mocked_location_in_count ? false : true,
            valueFormatter: (cell) => (cell.value ? cell.value : null),
          },
          {
            headerValueGetter: () => t("sign out"),
            field: "mocked_location_out_count",
            width: 120,
            hide: isColumnActive?.mocked_location_out_count ? false : true,
            valueFormatter: (cell) => (cell.value ? cell.value : null),
          },
        ],
      },
      {
        headerValueGetter: () => t("Spoofed image count"),
        headerClass: "spoofed_image_header_group",
        children: [
          {
            headerValueGetter: () => t("sign in"),
            field: "spoofed_sign_in_image_count",
            width: 120,
            hide: isColumnActive?.spoofed_sign_in_image_count ? false : true,
            valueFormatter: (cell) => (cell.value ? cell.value : null),
          },
          {
            headerValueGetter: () => t("sign out"),
            field: "spoofed_sign_out_image_count",
            width: 120,
            hide: isColumnActive?.spoofed_sign_out_image_count ? false : true,
            valueFormatter: (cell) => (cell.value ? cell.value : null),
          },
        ],
      },
    ];
  }, [tableState, isColumnActive, user?.lng, t]);

  useEffect(() => {
    gridRef?.current?.api?.sizeColumnsToFit({
      defaultMinWidth: 118,
    });
  }, [tableState, isColumnActive, user?.lng, t]);

  const renderSelectedFilters = () => {
    const onCancelFilter = (key, name, emptyVal = null) => {
      setFiltersState((prev) => ({ ...prev, [name]: emptyVal }));
      setClearOrCancel(true);
    };

    const selectedEmployees = filterOptions?.employeesOptions?.filter((emp) =>
      variables?.user_id?.includes(emp?.id)
    );

    const selectedPositons = filterOptions?.positionsOptions?.filter((pos) =>
      variables?.position_id?.includes(pos?.id)
    );

    const selectedOffices = user?.offices?.filter((off) =>
      variables?.office_id?.includes(off?.id)
    );

    const selectedDepartments = filterOptions?.departmentsOptions?.filter(
      (dep) => variables?.department_id?.includes(dep?.id)
    );

    const employeeSearch = variables?.employee_search
      ? [{ name: variables?.employee_search }]
      : [];

    return (
      <div className="d-flex flex-wrap gap-5">
        <FilterBadge
          label={t("employee search")}
          selectedItems={employeeSearch}
          onCancelFilter={() =>
            onCancelFilter("employee_search", "employee_search", null)
          }
        />

        <FilterBadge
          label={t("from")}
          selectedItems={
            variables?.from_month_year || (variables?.from_date && customReport)
              ? [
                  {
                    name: customReport
                      ? moment(variables?.from_date, "YYYY-MM-DD").format(
                          "DD/MM/YYYY"
                        )
                      : moment(variables?.from_month_year, "yyyy-MM").format(
                          "MM/YYYY"
                        ),
                  },
                ]
              : []
          }
          onCancelFilter={() => onCancelFilter("from_month_year", "from", "")}
          hideCancel
        />
        <FilterBadge
          label={t("to")}
          selectedItems={
            variables?.to_month_year || (variables?.to_date && customReport)
              ? [
                  {
                    name: customReport
                      ? moment(variables?.to_date, "YYYY-MM-DD").format(
                          "DD/MM/YYYY"
                        )
                      : moment(variables?.to_month_year, "yyyy-MM").format(
                          "MM/YYYY"
                        ),
                  },
                ]
              : []
          }
          onCancelFilter={() => onCancelFilter("to_month_year", "to", "")}
          hideCancel
        />

        <FilterBadge
          label={t("employees")}
          selectedItems={selectedEmployees ?? []}
          onCancelFilter={() => onCancelFilter("user_id", "employees", null)}
        />
        <FilterBadge
          label={t("positions")}
          selectedItems={selectedPositons ?? []}
          onCancelFilter={() =>
            onCancelFilter("position_id", "positions", null)
          }
        />
        <FilterBadge
          label={t("departments")}
          selectedItems={selectedDepartments ?? []}
          onCancelFilter={() =>
            onCancelFilter("department_id", "departments", null)
          }
        />
        <FilterBadge
          label={t("offices")}
          selectedItems={selectedOffices ?? []}
          onCancelFilter={() => onCancelFilter("office_id", "offices", null)}
        />
      </div>
    );
  };

  const appliedFiltersLength =
    (variables?.user_id?.length ? 1 : 0) +
    (variables?.office_id?.length ? 1 : 0) +
    (variables?.department_id?.length ? 1 : 0) +
    (variables?.position_id?.length ? 1 : 0) +
    (variables?.employee_search ? 1 : 0);

  const handelDateLocalChange = (value, name) => {
    setFiltersState((prev) => ({ ...prev, [name]: value?.format("YYYY-MM") }));
    return true;
  };

  // handle employee search input on change function
  const handleEmployeeSearchInputOnChange = (e) => {
    setFiltersState((prev) => ({
      ...prev,
      [e?.target?.name]: e?.target?.value,
    }));
    return true;
  };

  const { hasFlex, hasFixed } = useCompanyAttType();

  return (
    <>
      <div className="content payroll_wrapper_style monthly-report-style no-padding-first-child position-relative">
        <div
          className={
            "d-lg-flex align-items-center gap-5 mb-3 justify-content-between"
          }
        >
          {renderSelectedFilters()}
          <div className={"d-flex align-items-center gap-5 my-2 my-lg-0"}>
            {appliedFiltersLength ? (
              <div onClick={handelClearFilters} className="logs-btn clear-btn">
                <ClearIcon color="inherit" fontSize="small" />
                {t("clear")}
              </div>
            ) : null}
            <div
              onClick={toggleDrawer(true)}
              className={`logs-btn ${
                appliedFiltersLength
                  ? "filter-btn-active"
                  : "filter-btn-inactive"
              }`}
            >
              <FontAwesomeIcon icon={faSlidersH} />
              {t("filters")}
              {appliedFiltersLength ? (
                <div className="filter-count-container">
                  {t(appliedFiltersLength)}
                </div>
              ) : null}
            </div>
            <div className="d-flex align-items-center gap-5">
              <HasPrivileges
                reqireMain={[Privilages.EXPORT_ATTENDANCE_MONTHLY_REPORT]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <button
                    className="btn py-0 monthly-export mx-2"
                    type="button"
                    disabled
                  >
                    <FontAwesomeIcon className="mr-2" icon={faFileExport} />
                    {t("export")}
                  </button>
                }
              >
                <button
                  className="btn py-0 monthly-export text-nowrap mx-2"
                  type="button"
                  onClick={() =>
                    dispatch(
                      showExportMonthlyReportModal({
                        ref: "exportMonthlyReportModal",
                      })
                    )
                  }
                >
                  <FontAwesomeIcon className="mr-2" icon={faFileExport} />
                  {t("export")}
                </button>
              </HasPrivileges>

              <BarsIconButton onClick={() => setIsToggleModalVissible(true)} />
            </div>
          </div>
        </div>

        <RadioboxForm
          formName={dataTableRef}
          options={[
            {
              label: "all",
              value: "both",
            },
            ...(hasFixed
              ? [
                  {
                    label: "fixed",
                    value: "fixed",
                  },
                ]
              : []),
            ...(hasFlex
              ? [
                  {
                    label: "flexible",
                    value: "flex",
                  },
                ]
              : []),
          ]}
          value={type}
          onChange={(e) => setType(e?.target?.value)}
          name="type"
          type="radio"
          containerStyle="my-2 my-lg-0"
          labelStyle="label-style"
          optionsContainerStyle="optionsContainerStyle flex-nowrap"
          optionItemStyle="optionItemStyle payroll_option_items"
          optionInputStyle=" "
          optionLabelStyle="optionLabelStyle"
          rootStyle="mb-4"
        />

        {loading ? (
          <div className="loader_wrapper_style position-absolute">
            <Loader />
          </div>
        ) : null}

        <AgGridTable
          key={user?.lng}
          ref={gridRef}
          className="monthlyreport-table"
          isSortingAction={false}
          columnDefs={columnDefs}
          rowData={gridRowData}
          enableSorting={true}
          tableState={tableState}
          tableFetcherAction={handelFecthData}
          dataTableRef={dataTableRef}
          gridOptions={{
            enableRtl: user?.lng === "ar-EG" ? true : false,
          }}
        />

        <Pagination
          tableRef={dataTableRef}
          styleWraper=""
          onPaginate={handlePaginate}
          reducer="super"
        />

        {/* Start of Toggled Modal */}
        <ToggleModal
          isModalVissible={isToggleModalVissible}
          toggleModal={() => setIsToggleModalVissible(!isToggleModalVissible)}
          reportFormName={formName}
        />
        {/* End of Toggled Modal  */}

        {/* Start of Export Modal */}
        <ExportModal
          column={tableState?.orderBy?.column}
          order={tableState?.orderBy?.order}
          filters={fetchVar}
        />
        {/* End of Export Modal  */}
      </div>

      <FiltersDrawer
        isFilterDrawerOpen={isFilterDrawerOpen}
        toggleDrawer={toggleDrawer}
        onFilterReset={onFilterReset}
        onFilterSubmit={onFilterSubmit}
        dataTableRef={dataTableRef}
        isLoading={loading || fetchOptionsLoading}
        formData={filtersState}
        employeesOptions={filterOptions?.employeesOptions ?? []}
        officesOptions={user?.offices}
        positionsOptions={filterOptions?.positionsOptions ?? []}
        departmentsOptions={filterOptions?.departmentsOptions ?? []}
        hideFromTo={!customReport}
        requestFormat="YYYY-MM-DD"
        isLocal
        setLocalState={setFiltersState}
      >
        <InputForm
          formName={dataTableRef}
          reducer="super"
          labelStyle="mb-2"
          label={t("employee search")}
          name="employee_search"
          icon="search"
          type="search"
          onChange={(e) => handleEmployeeSearchInputOnChange(e)}
          placeholder={t("employee search")}
          value={filtersState?.employee_search?.trimStart()}
          inputContainerStyle="w-100"
        />

        {!customReport ? (
          <>
            <DateTimePickerForm
              name="from_month_year"
              formName={dataTableRef}
              label={t("from")}
              hasIcon
              reducer="super"
              rootStyle="flex-fill"
              datePickerContainer="w-100"
              labelStyle="date_picker_label"
              placeholder={t("select date")}
              isLoading={tableState?.isLoading}
              allowClear={false}
              picker="month"
              requestFormat="yyyy-MM"
              format="MM-yyyy"
              onIntercept={handelDateLocalChange}
              value={
                Boolean(filtersState?.from_month_year)
                  ? moment(filtersState?.from_month_year, "YYYY-MM-DD")
                  : null
              }
            />
            <DateTimePickerForm
              name="to_month_year"
              formName={dataTableRef}
              label={t("to")}
              hasIcon
              reducer="super"
              rootStyle="flex-fill"
              datePickerContainer="w-100"
              labelStyle="date_picker_label"
              placeholder={t("select date")}
              isLoading={tableState?.isLoading}
              allowClear={false}
              picker="month"
              requestFormat="yyyy-MM"
              format="MM-yyyy"
              onIntercept={handelDateLocalChange}
              value={
                Boolean(filtersState?.to_month_year)
                  ? moment(filtersState?.to_month_year, "YYYY-MM-DD")
                  : null
              }
            />
          </>
        ) : null}
      </FiltersDrawer>
    </>
  );
};

export default MonthlyReport;
