import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { InputForm, BSelect, DateTimePickerForm } from "form-builder";
import { handleActionMutation } from "../../Helpers/HelperFns";
import { onInputResetAction } from "../../Store/Actions";
import moment from "moment";

// Apollo
import { useMutation, useQuery } from "@apollo/client";
import {
  FLEX_WORK_TEAM_MODAL_INFO,
  fetchWorkTeamModalQuery,
} from "../../Graphql/query";
import {
  UPSERT_FLEX_WORK_TEAM,
  upsertWorkTeamMutation,
} from "../../Graphql/mutation";

// Actions
import { onFormResetAction } from "../../Store/Actions";

// Ui
import MainModal from "../MainModal";
import BlockUi from "react-block-ui";
import Swal from "sweetalert2";

//   form props
const reducerName = "flex";
const formName = "upsertFlexWorkTeamForm";
const formNameValidation = "upsertFlexWorkTeamFormValidation";
const formServerValidation = "upsertFlexWorkTeamFormServerValidation";

const FlexWorkTeamModal = ({ isModalOpen, setIsModalOpen, fetchWorkTeams }) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [generalExceptionMessage, setGeneralExceptionMessage] = useState("");

  const dispatch = useDispatch();
  const upsertFlexWorkTeamForm = useSelector(
    (state) => state?.[reducerName]?.[formName]
  );
  const formClientValidation = useSelector(
    (state) => state?.[reducerName]?.[formNameValidation]
  );

  const { data: flexWorkTeamOptions, loading: fetchWorkTeamModalLoading } =
    useQuery(FLEX_WORK_TEAM_MODAL_INFO, {
      onError: (error) => {
        Swal.fire({
          title: t("error"),
          text: error.message ? error.message : t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
          showDenyButton: false,
        });
      },
    });

  const [upsertFlexWorkTeamAttempt, { loading: upsertWorkTeamLoading }] =
    useMutation(UPSERT_FLEX_WORK_TEAM, {
      variables: {
        input: {
          ...upsertFlexWorkTeamForm,
          workGroup: undefined,
          workGroupId: upsertFlexWorkTeamForm?.workGroup?.id,
          start: undefined,
          // start: moment(upsertFlexWorkTeamForm?.start).format("YYYY-MM-DD"),
        },
      },
    });

  const formProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
    reducer: reducerName,
  };

  useEffect(() => {
    return () => dispatch(onFormResetAction(formName));
  }, []);

  useEffect(() => {
    dispatch(onInputResetAction(formName, "employeesIds"));
    setGeneralExceptionMessage("");
  }, [upsertFlexWorkTeamForm?.workGroup]);

  const handleSubmit = async () => {
    setGeneralExceptionMessage("");
    setIsSubmitting(true);

    if (formClientValidation?.length === 0) {
      setIsSubmitting(false);
      const { response } = await handleActionMutation(
        upsertFlexWorkTeamAttempt,
        {
          isReturnUnion: true,
          refetchQuery: fetchWorkTeams,
          disableFailedToast: true,
        }
      );

      if (
        response?.data?.updateOrCreateFlexWorkTeam?.__typename !==
        "GeneralException"
      ) {
        setIsModalOpen(false);
      } else {
        setGeneralExceptionMessage(
          response?.data?.updateOrCreateFlexWorkTeam?.message ??
            t("something went wrong")
        );
      }
    }
  };

  const workGroupMembers = upsertFlexWorkTeamForm?.workGroup?.employees?.map(
    (employee) => {
      let workTeamName =
        employee?.relevantAttendanceTypeConfiguration?.workTeam?.name;
      let joinFrom = employee?.relevantAttendanceTypeConfiguration?.join_from;
      let isJoinFromDateInFuture =
        moment(
          employee?.relevantAttendanceTypeConfiguration?.join_from,
          "YYYY-MM-DD"
        ).diff(moment(), "days") > 0;

      return {
        id: employee?.user?.id,
        name:
          joinFrom && isJoinFromDateInFuture
            ? `${employee?.user?.name} (${workTeamName} | ${joinFrom})`
            : `${employee?.user?.name} ${
                workTeamName ? `(${workTeamName})` : ""
              }`,
      };
    }
  );

  return (
    <>
      <MainModal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(false)}
        modalTitle={t("new work team")}
        btnLabel={t("save")}
        btnOnClick={handleSubmit}
        btnSubmitLoading={upsertWorkTeamLoading}
      >
        <BlockUi tag="div" blocking={fetchWorkTeamModalLoading}>
          <InputForm
            {...formProps}
            validateBy="textRequired"
            label={"team name"}
            name="name"
            validationName="input.name"
            type="text"
            placeholder={t("team name")}
            containerStyle="name_field_container"
            inputContainerStyle="name_field_input"
            labelStyle="name_field_label"
            icon="people"
          />

          <BSelect
            {...formProps}
            name="workGroup"
            validateBy="textRequired"
            validationName="input.workGroupId"
            label={"work group"}
            keepDefaultStyle
            options={flexWorkTeamOptions?.flex_work_groups_menu}
            labelStyle="my-2"
            containerStyle="containerStyle flex-column align-items-start"
            inputContainerStyle="flex-grow-1 mr-0 w-100"
            skipLocalization
            value={upsertFlexWorkTeamForm?.workGroup}
            isValueObject
            icon="people"
          />

          {/* <DateTimePickerForm
            {...formProps}
            datePickerContainer="w-100"
            label="Starting From"
            containerStyle="flex-column align-items-start"
            labelStyle="custom-label-style mb-2"
            name="start"
            validateBy="textRequired"
            validationName="input.start"
          /> */}

          <div className="d-none">
            <BSelect
              {...formProps}
              name="employeesIds"
              // validateBy="textRequired"
              // validationName="input.employeesIds"
              keepDefaultStyle
              label={"team members"}
              labelStyle="my-2"
              optionLabel="name"
              optionValue="id"
              options={workGroupMembers}
              isMulti={true}
              inputContainerStyle="mt-0"
              placeholder={t("select team members")}
              isDisabled={!upsertFlexWorkTeamForm?.workGroup?.id}
              icon="people"
            />
          </div>
        </BlockUi>

        <div className="invalid-container-style">
          <p className="validity-msg-style mt-3">{generalExceptionMessage}</p>
        </div>
      </MainModal>
    </>
  );
};

export default FlexWorkTeamModal;
