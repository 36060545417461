import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ArrowDownward, ArrowUpward, LocationOn } from "@mui/icons-material";
import {
  EditIconButton,
  RemoveIconButton,
} from "../../../Components/IconButtonWithTooltip";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLazyQuery, useMutation } from "@apollo/client";
import { viewFlexEditAttQuery } from "../../../Graphql/query";
import { EDIT_ATTENDANCE } from "../../../Constants/Requests";
import HelperFns, {
  openSwalConfirm,
  showToast,
} from "../../../Helpers/HelperFns";
import { showErrorToast } from "../../../Store/Actions";
import { DELETE_CLOCKIN } from "../../../Graphql/mutation";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import Privilages from "../../../Constants/Privilages";

const MyClockinCard = ({
  sign_in_order,
  clockIn,
  refetchTimeSheet,
  editFlexAttendanceModal,
  setEditFlexAttendanceModal,
  isMyProfile = true,
  handleAddOrEditClockin = () => {},
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleEdit = () => {
    if (isMyProfile) {
      dispatch({ type: "SET_TIME_SHEET_FORM_DATA", payload: clockIn });
      setEditFlexAttendanceModal({ isOpen: true, isView: false });
    } else {
      handleAddOrEditClockin(clockIn?.id);
    }
  };

  const [attemptDeleteClockin, { loading: deleteClockinLoading }] =
    useMutation(DELETE_CLOCKIN);

  const handleDelete = () => {
    openSwalConfirm((isConfirmed) => {
      if (isConfirmed) {
        attemptDeleteClockin({
          variables: {
            id: +clockIn?.id,
          },

          onCompleted: (data) => {
            if (
              data?.deleteClockIn?.__typename === "StatusResponse" &&
              data?.deleteClockIn?.status == "fail"
            ) {
              dispatch(
                showErrorToast(
                  data?.deleteClockIn?.message ?? "Something went wrong"
                )
              );
            }

            if (data?.deleteClockIn?.__typename === "Clockin") {
              refetchTimeSheet();
            }
          },
          onError: (error) => {
            dispatch(
              showErrorToast(
                error?.graphQLErrors?.[0]?.extensions?.reason || error?.message
              )
            );
          },
        });
      }
    });
  };

  const userId = useSelector((state) => state.auth.userProfile?.id);

  const [fetchFlexEditAttendanceData, { loading: flexEditAttLoading }] =
    useLazyQuery(viewFlexEditAttQuery);

  const handleView = () => {
    fetchFlexEditAttendanceData({
      variables: {
        emp_id: isMyProfile ? userId : props.id,
        date: moment(clockIn?.clock_in_time).format("YYYY-MM-DD"),
        type: EDIT_ATTENDANCE,
      },
      onCompleted: (res) => {
        dispatch({
          type: "SET_TIME_SHEET_FORM_DATA",
          payload: res?.view_flex_request,
        });
        setEditFlexAttendanceModal({ isOpen: true, isView: true });
      },
      onError: (error) => {
        showToast(
          "error",
          error?.graphQLErrors?.[0]?.extensions?.reason ?? error?.message
        );
      },
    });
  };

  const LEAVE_TYPES = {
    1: "normal leaves",
    6: "sick leaves",
    7: "unpaid leaves",
  };

  return (
    <div className="clockin-container">
      <p className="sign-in-order">{sign_in_order}</p>

      {!!clockIn?.leave_type ? (
        <div className="sign-out text-nowrap">
          {`${t("leave")} ${clockIn?.leave_hours} (${t(`${LEAVE_TYPES?.[clockIn?.leave_type] ?? "custom leaves"}`)})`}
        </div>
      ) : (
        <>
          <div className="sign-in">
            <div className="sign-in-time-container">
              <ArrowDownward className="sign_in_icon" />
              <p className="sign-in-time">
                {!!clockIn?.clock_in_time
                  ? moment(clockIn?.clock_in_time).format("hh:mm A")
                  : "--------"}
              </p>
            </div>

            <div className="sign-in-out-location-container">
              <LocationOn
                fontSize="small"
                className="sign-in-out-location-icon"
              />
              <p className="text-ellipsis sign-in-location">
                {clockIn?.clockInOffice?.name}
              </p>
            </div>
          </div>
          <div className="sign-out">
            <div className="sign-out-time-container">
              <ArrowUpward className="sign_out_icon" />
              <p className="sign-out-time">
                {!!clockIn?.clock_out_time
                  ? moment(clockIn?.clock_out_time).format("hh:mm A")
                  : "--------"}
              </p>
            </div>
            <div className="sign-in-out-location-container">
              <LocationOn
                fontSize="small"
                className="sign-in-out-location-icon"
              />
              <p className="text-ellipsis sign-out-location">
                {clockIn?.clockOutOffice?.name}
              </p>
            </div>
          </div>
          <p className="sign-in-out-interval">
            <AccessTimeIcon className="time-icon" />
            <p className="m-0">{clockIn?.working_time}</p>
          </p>
          <p className="info-icon"></p>
          {/* <p className="checkins">2 check-ins</p> */}
        </>
      )}
      <div className="actions">
        {clockIn?.hasPendingEditAttendanceRequests && isMyProfile ? (
          <VisibilityIcon
            onClick={handleView}
            className="attendance_status_icon"
          />
        ) : (
          <>
            {isMyProfile ? (
              <HasPrivileges
                allowBP
                reqireMain={[Privilages.APPLY_FOR_EDIT_ATTENDANCE_REQUESTS]}
              >
                <EditIconButton onClick={handleEdit} />
              </HasPrivileges>
            ) : (
              <HasPrivileges
                allowBP
                reqireMain={[Privilages.MANAGE_TIMESHEET_RECORDS]}
              >
                <EditIconButton onClick={handleEdit} />
              </HasPrivileges>
            )}

            <HasPrivileges
              allowBP
              reqireMain={[Privilages.MANAGE_TIMESHEET_RECORDS]}
            >
              {!isMyProfile ? (
                <RemoveIconButton
                  disabled={deleteClockinLoading}
                  onClick={handleDelete}
                />
              ) : null}
            </HasPrivileges>
          </>
        )}
      </div>
    </div>
  );
};

export default MyClockinCard;
