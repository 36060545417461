import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  CheckboxBooleanForm,
  DateTimePickerForm,
  InputForm,
  RadioboxForm,
  BSelect,
} from "form-builder";

import Constants from "../../Constants";
import moment from "moment";
import { useTranslation } from "react-i18next";

import {
  onInputChangeAction,
  onInputResetWithValueAction,
} from "../../Store/Actions";

import AttViolationSection from "./AttViolationSection";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";

const AttTardinessSection = (props) => {
  const { formProps } = props;
  const isMinutes = props.tableData.tardiness_type_sign_in === "minutes";

  const { t } = useTranslation();
  let majorArray = [];
  for (let i = 1; i < 11; ++i) {
    majorArray[i] = i + 1;
  }

  const absentTime = () => {
    const {
      apply_minor_tardiness,
      apply_major_tardiness,
      sign_in_end_time,
      minor_tardiness_range,
    } = props.tableData;

    if (!apply_minor_tardiness && sign_in_end_time) {
      return moment(sign_in_end_time, "hh:mm a").format("hh:mm a").toString();
    }
    if (
      apply_minor_tardiness &&
      !apply_major_tardiness &&
      minor_tardiness_range
    ) {
      return moment(minor_tardiness_range, "hh:mm a")
        .format("hh:mm a")
        .toString();
    }

    let getMajorsList = Object.entries(props.tableData).filter(([key, value]) =>
      key.includes("apply_major_tardiness")
    );
    let sortedMajorsList = getMajorsList.sort(
      (a, b) =>
        b[0].replace(/apply_major_tardiness/g, "") -
        a[0].replace(/apply_major_tardiness/g, "")
    );
    let lastAppliedMajorOrder =
      sortedMajorsList.findIndex((major) => major[1] == 1) > -1
        ? 10 - sortedMajorsList.findIndex((major) => major[1] == 1)
        : -1;
    let suffix =
      lastAppliedMajorOrder == 0
        ? ""
        : lastAppliedMajorOrder > -1
          ? lastAppliedMajorOrder
          : null;
    if (
      lastAppliedMajorOrder !== null &&
      props.tableData?.[`apply_major_tardiness${suffix}`] &&
      props.tableData?.[`major_tardiness_range${suffix}`]
    ) {
      return moment(
        props.tableData?.[`major_tardiness_range${suffix}`],
        "hh:mm a"
      )
        .format("hh:mm a")
        .toString();
    }
    return "--:--:--";
  };

  const handleApplyTardiness = () => {
    if (props.tableData?.apply_minor_tardiness) {
      let e = {
        target: {
          name: "max_lateness_permissibility",
          value: props?.tableData?.sign_in_end_time,
        },
      };
      props.onInputChangeAction(formProps.formName, e);
    }
  };

  const onApplyTardinessChange = useDidUpdateEffect(handleApplyTardiness, [
    props.tableData?.apply_minor_tardiness,
  ]);

  useEffect(() => {
    !isMinutes &&
      props.onInputResetWithValueAction(
        formProps?.formName,
        "annual_leave_hours_sign_in",
        null
      );
  }, [isMinutes]);

  return (
    <>
      <div className={+props.tableData?.sign_in_req ? " " : "d-none"}>
        <h4 className="my-2 sub-title-style">{t("Tardiness Policy")}</h4>

        <div className="">
          <CheckboxBooleanForm
            dependOn="sign_in_req"
            dependancyType="equal"
            dependancyValue={[1]}
            {...formProps}
            options={["Apply Penalty If Employee Is Late"]}
            name="apply_minor_tardiness"
            validationName="input.apply_minor_tardiness"
            type="checkbox"
          />

          <RadioboxForm
            {...formProps}
            options={[
              { label: "Time Intervals", value: "time_interval" },
              { label: "Minutes", value: "minutes" },
            ]}
            labelStyle="d-block font-weight-bold gray-color"
            optionsContainerStyle="penalty-days-style fit-width flex-md-row flex-column"
            optionItemStyle=" "
            optionLabelStyle="mb-0 mr-3"
            optionInputStyle=" "
            containerStyle="d-flex flex-wrap align-items-center gap-20 my-2"
            label="tardiness penalty should be deducted according to"
            name="tardiness_type_sign_in"
            validationName="input.tardiness_type_sign_in"
            validateBy="textRequired"
            dependOn="apply_minor_tardiness"
            dependancyType="equal"
            dependancyValue={[1]}
          />

          <div className="d-flex flex-column align-items-start w-100">
            <RadioboxForm
              dependOn="apply_minor_tardiness"
              dependancyType="equal"
              dependancyValue={[1]}
              {...formProps}
              options={Constants.penaltiesDeducted}
              containerStyle="custom-tardiness-container justify-content-start"
              labelStyle="d-flex mr-3"
              optionsContainerStyle="penalty-days-style fit-width flex-md-row flex-column"
              optionItemStyle="d-flex alignbaseline-center"
              optionLabelStyle="mb-0 mr-3"
              optionInputStyle=" "
              label="deduct from"
              name="late_tardiness_penalty_type"
              validationName="input.late_tardiness_penalty_type"
              validateBy="textRequired"
            />
          </div>

          <div
            className={
              props.tableData.apply_minor_tardiness != 1
                ? "d-none"
                : "sub-container-style"
            }
          >
            {isMinutes ? (
              <InputForm
                {...formProps}
                type="number"
                label="Annual Leave Hours"
                hasSuffix
                suffixTitle="Hours"
                name="annual_leave_hours_sign_in"
                containerStyle="d-flex align-items-center justify-content-between"
                dependOn="late_tardiness_penalty_type"
                dependancyType="equal"
                dependancyValue={["Leaves"]}
                validateBy="textRequired"
              />
            ) : null}
            <div className="d-flex px-0 align-items-baseline justify-content-around my-1">
              <h6 className="content-header-sub-label my-1 col-12 col-lg-5 px-0">
                {t("Apply Tier Penalty if employee signs in", { tier: 1 })}
              </h6>
              <div className="col my-1 d-flex flex-column flex-md-row justify-content-around">
                <DateTimePickerForm
                  timeOnly
                  {...formProps}
                  dependOn="apply_minor_tardiness"
                  dependancyType="equal"
                  dependancyValue={[1, "1"]}
                  datePickerContainer="ml-0"
                  containerStyle="d-flex flex-column flex-md-row align-items-center"
                  labelStyle="mr-3"
                  validateBy="textRequired"
                  // dependTime="sign_in_end_time"
                  label="from"
                  name="max_lateness_permissibility"
                  validationName="input.max_lateness_permissibility"
                  // validateMessage={t("tardiness_policy_from_valdation_message")}
                />
              </div>
              <div className="my-1 d-flex justify-content-end p-0">
                <DateTimePickerForm
                  timeOnly
                  {...formProps}
                  dependOn="apply_minor_tardiness"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  containerStyle="d-flex flex-column flex-md-row align-items-center"
                  validateBy="textRequired"
                  datePickerContainer="ml-0"
                  labelStyle="mr-3"
                  // dependTime="max_lateness_permissibility"
                  label="to"
                  name="minor_tardiness_range"
                  validationName="input.minor_tardiness_range"
                  // validateMessage={t("tardiness_policy_to_valdation_message")}
                />
              </div>
            </div>

            <AttViolationSection
              formProps={formProps}
              parent="sign_in"
              childName="minor_violations"
              penalty_type="minor"
            />

            <CheckboxBooleanForm
              {...formProps}
              dependOn="apply_minor_tardiness"
              dependancyType="equal"
              dependancyValue={[1]}
              options={[
                "Skip penalties not applied when calculating violations",
              ]}
              name="skip_not_applied_minor_penalties"
              validationName="input.skip_not_applied_minor_penalties"
              type="checkbox"
            />
            {props.tableData?.apply_minor_tardiness &&
            (!isMinutes ||
              (isMinutes &&
                props.tableData?.minor_violations?.[0]?.deduct_minutes_by !==
                  "minutes")) ? (
              <BSelect
                {...formProps}
                name="minor_violations_deduct_as"
                label="deduct as"
                labelStyle="deduct-as-label-style"
                dependOn="late_tardiness_penalty_type"
                dependancyType="equal"
                dependancyValue={["Salary"]}
                // validationName="input.quantity_unit"
                keepDefaultStyle
                options={Constants.violdationQuantityUnitsOptions}
                optionLabel="label"
                optionValue="value"
                validateBy="textRequired"
                containerStyle="custom-tardiness-container justify-content-start p-0"
                inputContainerStyle="violation_type_b_select"
                placeholder={t("type")}
                icon="money"
              />
            ) : null}

            <hr />

            <div className="my-1">
              <div className="d-flex px-0 my-2 align-items-baseline justify-content-around my-1">
                <div className="col-12 col-lg-5 my-1 px-0">
                  <CheckboxBooleanForm
                    dependOn="apply_minor_tardiness"
                    dependancyType="equal"
                    dependancyValue={[1]}
                    {...formProps}
                    options={[
                      t("Apply Tier Penalty if employee signs in", { tier: 2 }),
                    ]}
                    containerStyle="mt-2 pb-0"
                    optionLabelStyle="content-header-sub-label"
                    name="apply_major_tardiness"
                    validationName="input.apply_major_tardiness"
                    type="checkbox"
                  />
                </div>
                <div className="col my-1 d-flex flex-column flex-md-row justify-content-around">
                  <DateTimePickerForm
                    timeOnly
                    readOnly
                    disabled
                    {...formProps}
                    dependOn="apply_major_tardiness"
                    dependancyType="equal"
                    containerStyle="d-flex flex-column flex-md-row align-items-center"
                    datePickerContainer="ml-0"
                    labelStyle="mr-3"
                    dependancyValue={[1]}
                    validateBy="textRequired"
                    label="from"
                    name="minor_tardiness_range"
                    validationName="input.minor_tardiness_range"
                  />
                </div>
                <div className="my-1 d-flex justify-content-end p-0">
                  <DateTimePickerForm
                    timeOnly
                    {...formProps}
                    dependOn="apply_major_tardiness"
                    dependancyType="equal"
                    containerStyle="d-flex flex-column flex-md-row align-items-center"
                    datePickerContainer="ml-0"
                    dependancyValue={[1]}
                    validateBy="textRequired"
                    // dependTime="minor_tardiness_range"
                    // labelStyle="mr-3"
                    labelStyle="mr-3"
                    label="to"
                    name="major_tardiness_range"
                    validationName="input.major_tardiness_range"
                  />
                </div>
              </div>

              {props.tableData?.apply_major_tardiness ? (
                <AttViolationSection
                  formProps={formProps}
                  parent="sign_in"
                  childName="major_violations"
                  penalty_type="major"
                />
              ) : null}

              <CheckboxBooleanForm
                {...formProps}
                dependOn="apply_major_tardiness"
                dependancyType="equal"
                dependancyValue={[1]}
                options={[
                  "Skip penalties not applied when calculating violations",
                ]}
                name="skip_not_applied_major_penalties"
                validationName="input.skip_not_applied_major_penalties"
                type="checkbox"
              />
              {props.tableData?.apply_major_tardiness &&
              (!isMinutes ||
                (isMinutes &&
                  props.tableData?.major_violations?.[0]?.deduct_minutes_by !==
                    "minutes")) ? (
                <BSelect
                  {...formProps}
                  name="major_violations_deduct_as"
                  label="deduct as"
                  labelStyle="deduct-as-label-style"
                  dependOn="late_tardiness_penalty_type"
                  dependancyType="equal"
                  dependancyValue={["Salary"]}
                  // validationName="input.quantity_unit"
                  keepDefaultStyle
                  options={Constants.violdationQuantityUnitsOptions}
                  optionLabel="label"
                  optionValue="value"
                  validateBy="textRequired"
                  containerStyle="custom-tardiness-container justify-content-start p-0 mt-1"
                  inputContainerStyle="violation_type_b_select"
                  placeholder={t("type")}
                  icon="money"
                />
              ) : null}
            </div>
            {majorArray.map((value, i) => (
              <div
                className={
                  props.tableData?.["apply_major_tardiness" + (i - 1)] ||
                  (i == 1 && props.tableData?.apply_major_tardiness)
                    ? ""
                    : "d-none"
                }
              >
                <hr />
                <div className="my-1">
                  <div className="row ml-0 px-0 my-2 align-items-baseline justify-content-around my-1">
                    <div className="col-12 col-lg-5 my-1 px-0">
                      <CheckboxBooleanForm
                        dependOn={
                          i == 1
                            ? "apply_major_tardiness"
                            : "apply_major_tardiness" + (i - 1)
                        }
                        dependancyType="equal"
                        dependancyValue={[1]}
                        {...formProps}
                        options={[
                          t("Apply Tier Penalty if employee signs in", {
                            tier: +(i + 2),
                          }),
                        ]}
                        containerStyle="mt-2 pb-0"
                        optionLabelStyle="content-header-sub-label"
                        name={"apply_major_tardiness" + i}
                        validationName={"input.apply_major_tardiness" + i}
                        type="checkbox"
                      />
                    </div>
                    <div className="col my-1 d-flex flex-column flex-md-row justify-content-around">
                      <DateTimePickerForm
                        timeOnly
                        readOnly
                        disabled
                        {...formProps}
                        dependOn={"apply_major_tardiness" + i}
                        dependancyType="equal"
                        containerStyle="d-flex flex-column flex-md-row align-items-center"
                        datePickerContainer="ml-0"
                        labelStyle="mr-3"
                        dependancyValue={[1]}
                        validateBy="textRequired"
                        label="from"
                        name={
                          i == 1
                            ? "major_tardiness_range"
                            : "major_tardiness_range" + (i - 1)
                        }
                        validationName={
                          i == 1
                            ? "input.major_tardiness_range"
                            : "input.major_tardiness_range" + (i - 1)
                        }
                      />
                    </div>
                    <div className="my-1 d-flex justify-content-end p-0">
                      <DateTimePickerForm
                        timeOnly
                        {...formProps}
                        dependOn={"apply_major_tardiness" + i}
                        dependancyType="equal"
                        containerStyle="d-flex flex-column flex-md-row align-items-center"
                        datePickerContainer="ml-0"
                        dependancyValue={[1]}
                        validateBy="textRequired"
                        // dependTime="minor_tardiness_range"
                        // labelStyle="mr-3"
                        labelStyle="mr-3"
                        label="to"
                        name={"major_tardiness_range" + i}
                        validationName={"major_tardiness_range" + i}
                      />
                    </div>
                  </div>

                  {props.tableData?.["apply_major_tardiness" + i] ? (
                    <AttViolationSection
                      formProps={formProps}
                      parent="sign_in"
                      childName={"major_violations" + i}
                      penalty_type={"major" + i}
                    />
                  ) : null}

                  <CheckboxBooleanForm
                    {...formProps}
                    dependOn={"apply_major_tardiness" + i}
                    dependancyType="equal"
                    dependancyValue={[1]}
                    options={[
                      "Skip penalties not applied when calculating violations",
                    ]}
                    name={"skip_not_applied_major_penalties" + i}
                    validationName={
                      "input.skip_not_applied_major_penalties" + i
                    }
                    type="checkbox"
                  />

                  {props.tableData?.["apply_major_tardiness" + i] &&
                  (!isMinutes ||
                    (isMinutes &&
                      props.tableData?.[`major_violations${i}`]?.[0]
                        ?.deduct_minutes_by !== "minutes")) ? (
                    <BSelect
                      {...formProps}
                      name={`major_violations_${i}_deduct_as`}
                      label="deduct as"
                      labelStyle="deduct-as-label-style"
                      dependOn="late_tardiness_penalty_type"
                      dependancyType="equal"
                      dependancyValue={["Salary"]}
                      // validationName="input.quantity_unit"
                      keepDefaultStyle
                      options={Constants.violdationQuantityUnitsOptions}
                      optionLabel="label"
                      optionValue="value"
                      validateBy="textRequired"
                      containerStyle="custom-tardiness-container justify-content-start p-0"
                      inputContainerStyle="violation_type_b_select"
                      placeholder={t("type")}
                      icon="money"
                    />
                  ) : null}
                </div>
              </div>
            ))}
          </div>

          <div className="col-12 my-1 px-0">
            <BSelect
              {...formProps}
              dependOn="apply_minor_tardiness"
              dependancyType="equal"
              dependancyValue={[1, "1"]}
              name="late_reset_period"
              validationName="input.late_reset_period"
              label={
                "restart calculating penalties from the first violation every"
              }
              keepDefaultStyle
              options={Constants.resetOptions}
              optionLabel="label"
              optionValue="value"
              validateBy="textRequired"
              containerStyle="custom-tardiness-container align-items-baseline"
              inputContainerStyle="w-25 max-w-208"
              icon="calendar"
            />
          </div>

          {+props.tableData.sign_in_req ? (
            <h6 style={{ fontSize: "13px" }} className="content-header mb-4">
              {t("Employee will be considered Absent After")}{" "}
              <span
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  paddingLeft: "20px",
                }}
              >
                {absentTime()}
              </span>
            </h6>
          ) : null}
        </div>
      </div>

      <div className="">
        <div className="d-flex align-items-baseline">
          <h4 className="sub-title-style my-2">{t("No Show Deduction")}</h4>
        </div>

        <AttViolationSection
          formProps={formProps}
          childName="absent_violations"
          penalty_type="absent"
        />

        <CheckboxBooleanForm
          {...formProps}
          options={["Skip penalties not applied when calculating violations"]}
          name="skip_not_applied_absent_penalties"
          validationName="input.skip_not_applied_absent_penalties"
          type="checkbox"
        />
        <div className="d-flex flex-column align-items-start w-100">
          <RadioboxForm
            {...formProps}
            options={Constants.penaltiesDeducted}
            validateBy="textRequired"
            label="deduct from"
            name="emergency_penalty_type"
            validationName="input.emergency_penalty_type"
            type="checkbox"
            optionsContainerStyle="d-flex justify-content-end "
            containerStyle="custom-tardiness-container justify-content-start"
            optionItemStyle="option-item"
            optionInputStyle=" "
            optionLabelStyle="optionLabelStyle"
          />
          {!isMinutes && (
            <BSelect
              {...formProps}
              name="absent_violations_deduct_unit"
              label="deduct as"
              labelStyle="deduct-as-label-style"
              dependOn="emergency_penalty_type"
              dependancyType="equal"
              dependancyValue={["Salary"]}
              // validationName="input.quantity_unit"
              keepDefaultStyle
              options={Constants.violdationQuantityUnitsOptions}
              optionLabel="label"
              optionValue="value"
              validateBy="textRequired"
              containerStyle="custom-tardiness-container justify-content-start p-0"
              inputContainerStyle="violation_type_b_select"
              placeholder={t("type")}
              icon="money"
            />
          )}
        </div>

        <BSelect
          {...formProps}
          name="absent_reset_period"
          validationName="input.absent_reset_period"
          label={"restart calculating penalties from the first violation every"}
          keepDefaultStyle
          options={Constants.resetOptions}
          optionLabel="label"
          optionValue="value"
          validateBy="textRequired"
          containerStyle="custom-tardiness-container align-items-baseline"
          inputContainerStyle="w-25 max-w-208"
          icon="calendar"
        />
      </div>
    </>
  );
};

const mapStateToProps = (state, { formProps }) => {
  return {
    tableData: state.super[formProps.formName],
  };
};

export default connect(mapStateToProps, {
  onInputChangeAction,
  onInputResetWithValueAction,
})(AttTardinessSection);
