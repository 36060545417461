import Types from "../Actions/types";

import { v4 as uuid } from "uuid";
import FormField from "../../models/FormField";

export const initState = {
  recruitmentProcessesList: {
    status: "active",
    name: "",
    pagination: {
      lastItem: 30,
      total: 30,
      perPage: 30,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    data: [],
    isLoading: false,
  },

  deleteOrCloneRecruitmentProcessLoading: false,
  updateRecruitmentProcessStatusLoading: false,

  intakeFormsList: {
    status: "all",
    name: "",
    pagination: {
      lastItem: 30,
      total: 30,
      perPage: 30,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    data: [],
    isLoading: false,
  },

  deleteOrCloneIntakeFormLoading: false,
  updateIntakeFormStatusLoading: false,

  jobPostsList: {
    status: "all",
    name: "",
    pagination: {
      lastItem: 30,
      total: 30,
      perPage: 30,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    data: [],
    isLoading: false,
  },

  deleteJobPostsLoading: false,
  updateJobPostStatusLoading: false,

  applicationsList: {
    status: "all",
    name: "",
    stage_id: null,
    jobPostName: "",
    pagination: {
      lastItem: 30,
      total: 30,
      perPage: 30,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    data: [],
    isLoading: false,
  },

  stagesOptions: [],

  submittedField: null,

  // recruitmentProcessesForm
  recruitmentProcessesForm: {
    id: null,
    name: "",
    is_active: true,
    stages: [
      {
        id: uuid(),
        name: "",
        process_id: null,
        initial_flag: true,
        inactivate_application: false,
        form: {
          id: null,
          fields: [],
        },
      },
      {
        id: uuid(),
        name: "hired",
        process_id: null,
        initial_flag: false,
        inactivate_application: true,
        form: {
          id: null,
          fields: [],
        },
      },
    ],
  },
  recruitmentProcessFormServerValidation: {},
  recruitmentProcessFormSubmitting: false,

  recruitmentProcessestSages: [
    {
      name: "",
      process_id: null,
      inactivate_application: false,
      form: {
        id: uuid(),
        fields: [],
      },
    },
  ],

  openField: null,

  isRecruitmentProcessFormLoading: false,
  recruitmentProcessValidationOpts: [],

  recruitmentIntakeForm: {
    email: true,
    phone_number: true,
    id: null,
    name: "",
    fields: [
      {
        ...new FormField(null, 1),
        name: "name",
        suggested_field_type: "name",
        type: "text",
      },
    ],
  },
  submittedIntakeFields: [],
  openIntakeFields: [],
  recruitmentIntakeFormFields: [
    {
      form: {
        fields: [],
      },
    },
  ],
  initRecruitmentIntakeLoading: false,
  recruitmentIntakeFormServerValidation: {},
  upsertRecruitmentIntakeLoading: false,
  updateApplicationStageStatusLoading: [],

  applicantsList: {
    job_post_id: null,
    status: "all",
    name: "",
    stage_id: null,
    pagination: {
      lastItem: 30,
      total: 30,
      perPage: 30,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    data: [],
    isLoading: false,
  },
  deleteApplicantLoading: false,

  jobPostsOptions: [],

  jobPostModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  jobPostModal: {
    title: "",
    description: "",
    allow_multiple_apply: false,
    limit_of_recurring_applications: "",
    custom_intakeForm_and_process: false,
    process_id: null,
    intake_form_id: null,
    stages_map: [],
  },
  jobPostModalValidation: [],
  jobPostModalServerValidation: {},

  JobPostProcessesOptions: [],
  JobPostIntakeFormsOptions: [],
  fetchJobPostFormOptionsLoading: false,
  processFormOpenFields: [],
  processFormSubmittedFields: [],

  applicationModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  applicationModal: {
    name: "",
    email: "",
    phone: "",
    attachment: [],
  },
  applicationModalValidation: [],
  applicationModalServerValidation: {},

  applicantProfileLoading: false,
  selectedApplicantProfile: {},

  editApplicantEmailModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  editApplicantEmailModal: {
    applicant_id: null,
    email: "",
  },
  editApplicantEmailModalValidation: [],
  editApplicantEmailModalServerValidation: {},

  addApplicantPhoneModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  addApplicantPhoneModal: {
    applicant_id: null,
    phone_number: "",
  },
  addApplicantPhoneModalValidation: [],
  addApplicantPhoneModalServerValidation: {},

  editApplicantPhoneModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  editApplicantPhoneModal: {
    applicant_id: null,
    phone_number: "",
  },
  editApplicantPhoneModalValidation: [],
  editApplicantPhoneModalServerValidation: {},

  deleteApplicantPhoneNumberLoading: false,

  // updated

  isFetchJobPostIntakeFormLoading: false,
  jobPostIntakeForm: {},
  jobPostIntakeFormFields: [],

  submittedStageForm: {},
  isFillStageDataLoading: false,
  submitStageServerValidation: {},
};

export const recruitmentReducer = (state = initState, action) => {
  switch (action.type) {
    // handle children input
    case Types.ON_RECRUITMENT_CHILD_INPUT_CHANGE:
      let newValues = [
        ...state[action.payload.formName][action.payload.childName],
      ];
      newValues[action.payload.index][action.payload.name] =
        action.payload.value;
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],
          [action.payload.childName]: newValues,
        },
      };

    case Types.FETCH_RECRUITMENT_PROCESSES_LIST_ATTEMPT:
    case Types.FETCH_INTAKE_FORMS_LIST_ATTEMPT:
    case Types.FETCH_JOB_POSTS_LIST_ATTEMPT:
    case Types.FETCH_APPLICATION_LIST_ATTEMPT:
    case Types.FETCH_APPLICANTS_LIST_ATTEMPT:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
        },
      };
    case Types.FETCH_RECRUITMENT_PROCESSES_LIST_SUCCESS:
    case Types.FETCH_INTAKE_FORMS_LIST_SUCCESS:
    case Types.FETCH_JOB_POSTS_LIST_SUCCESS:
    case Types.FETCH_APPLICANTS_LIST_SUCCESS:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          data: action?.payload?.list?.data,
          pagination: action?.payload?.list?.paginatorInfo,
          isLoading: false,
        },
      };
    case Types.FETCH_APPLICATION_LIST_SUCCESS:
      return {
        ...state,
        applicationsList: {
          ...state.applicationsList,
          jobPostName: action?.payload?.jobPostName?.title,
          data: action?.payload?.list?.data,
          pagination: action?.payload?.list?.paginatorInfo,
          isLoading: false,
        },
      };
    case Types.FETCH_RECRUITMENT_PROCESSES_LIST_FAILED:
    case Types.FETCH_INTAKE_FORMS_LIST_FAILED:
    case Types.FETCH_JOB_POSTS_LIST_FAILED:
    case Types.FETCH_APPLICATION_LIST_FAILED:
    case Types.FETCH_APPLICANTS_LIST_FAILED:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
        },
      };

    case Types.DELETE_RECRUITMENT_PROCESSES_ATTEMPT:
    case Types.CLONE_RECRUITMENT_PROCESSES_ATTEMPT:
      return {
        ...state,
        deleteOrCloneRecruitmentProcessLoading: true,
      };
    case Types.DELETE_RECRUITMENT_PROCESSES_SUCCESS:
    case Types.DELETE_RECRUITMENT_PROCESSES_FAILED:
    case Types.CLONE_RECRUITMENT_PROCESSES_SUCCESS:
    case Types.CLONE_RECRUITMENT_PROCESSES_FAILED:
      return {
        ...state,
        deleteOrCloneRecruitmentProcessLoading: false,
      };

    case Types.UPDTAE_RECRUITMENT_PROCESSES_STATUS_ATTEMPT:
      return {
        ...state,
        updateRecruitmentProcessStatusLoading: true,
      };
    case Types.UPDTAE_RECRUITMENT_PROCESSES_STATUS_SUCCESS:
    case Types.UPDTAE_RECRUITMENT_PROCESSES_STATUS_FAILED:
      return {
        ...state,
        updateRecruitmentProcessStatusLoading: false,
      };

    case Types.DELETE_INTAKE_FORMS_ATTEMPT:
    case Types.CLONE_INTAKE_FORMS_ATTEMPT:
      return {
        ...state,
        deleteOrCloneIntakeFormLoading: true,
      };
    case Types.DELETE_INTAKE_FORMS_SUCCESS:
    case Types.DELETE_INTAKE_FORMS_FAILED:
    case Types.CLONE_INTAKE_FORMS_SUCCESS:
    case Types.CLONE_INTAKE_FORMS_FAILED:
      return {
        ...state,
        deleteOrCloneIntakeFormLoading: false,
      };

    case Types.UPDTAE_INTAKE_FORMS_STATUS_ATTEMPT:
      return {
        ...state,
        updateIntakeFormStatusLoading: true,
      };
    case Types.UPDTAE_INTAKE_FORMS_STATUS_SUCCESS:
    case Types.UPDTAE_INTAKE_FORMS_STATUS_FAILED:
      return {
        ...state,
        updateIntakeFormStatusLoading: false,
      };

    case Types.DELETE_JOB_POSTS_ATTEMPT:
      return {
        ...state,
        deleteJobPostsLoading: true,
      };
    case Types.DELETE_JOB_POSTS_SUCCESS:
    case Types.DELETE_JOB_POSTS_FAILED:
      return {
        ...state,
        deleteJobPostsLoading: false,
      };

    case Types.UPDTAE_JOB_POSTS_STATUS_ATTEMPT:
      return {
        ...state,
        updateJobPostStatusLoading: true,
      };
    case Types.UPDTAE_JOB_POSTS_STATUS_SUCCESS:
    case Types.UPDTAE_JOB_POSTS_STATUS_FAILED:
      return {
        ...state,
        updateJobPostStatusLoading: false,
      };

    case Types.FETCH_PROCESS_STAGES_FILTER_OPTIONS:
      return {
        ...state,
        stagesOptions: action?.payload,
      };

    // handle Form Builder

    case Types.ADD_FORM_FIELD:
      if (action.formType === "PROCESS_FORM") {
        return {
          ...state,
          [action.payload.formName]: state[action.payload.formName]?.map(
            (stage, i) =>
              i == action.payload.stageIndex
                ? {
                    ...stage,
                    form: {
                      ...stage.form,
                      fields: [
                        ...stage.form.fields,
                        { ...action.payload.newField },
                      ],
                    },
                  }
                : stage
          ),
        };
      } else {
        return {
          ...state,
          [action.payload.formName]: state[action.payload.formName]?.map(
            (stage, i) =>
              i == action.payload.stageIndex
                ? {
                    ...stage,
                    form: {
                      ...stage.form,
                      fields: [
                        ...stage.form.fields,
                        { ...action.payload.newField },
                      ],
                    },
                  }
                : stage
          ),
        };
      }

    case Types.DELETE_STAGE_PROCESS_FIELDS:
      return {
        ...state,
        [action.payload.formName]: state[action.payload.formName]?.map(
          (stage, i) =>
            i == action.payload.stageIndex
              ? {
                  ...stage,
                  form: {
                    ...stage.form,
                    fields: [],
                  },
                }
              : stage
        ),
      };

    case Types.DELETE_FORM_FIELD:
      return {
        ...state,
        [action.payload.formName]: state[action.payload.formName]?.map(
          (stage, i) =>
            i == action.payload.stageIndex
              ? {
                  ...stage,
                  form: {
                    ...stage.form,
                    fields: stage.form?.fields?.filter(
                      (field, index) =>
                        !(
                          field?.parent_field_id == action?.payload?.fieldId ||
                          field.id == action?.payload?.fieldId
                        )
                    ),
                  },
                }
              : stage
        ),
      };

    case Types.ADD_PROCESS_STAGE:
      return {
        ...state,
        recruitmentProcessesForm: {
          ...state.recruitmentProcessesForm,
          stages: [
            ...state.recruitmentProcessesForm.stages.filter(
              (stage) => stage.name !== "hired"
            ),
            {
              ...action?.payload?.stage,
            },
            ...state.recruitmentProcessesForm.stages.filter(
              (stage) => stage.name === "hired"
            ),
          ],
        },
        processFormOpenFields: [],
      };

    case Types.DELETE_PROCESS_STAGE:
      return {
        ...state,
        recruitmentProcessesForm: {
          ...state.recruitmentProcessesForm,
          stages: state.recruitmentProcessesForm?.stages?.filter(
            (stage, index) => stage.id != action.payload?.stageId
          ),
        },
        processFormOpenFields: state.processFormOpenFields.filter(
          (field) => field.stageId !== action.payload?.stageId
        ),
        // recruitmentProcessestSages: state.recruitmentProcessestSages?.filter(
        //   (stage, index) => index != action.payload.stageIndex
        // ),
      };

    case Types.ON_INPUT_FIELD_CHANGE:
      return {
        ...state,
        [action.payload.formName]: state[action.payload.formName]?.map(
          (stage, i) =>
            i == action.payload.stageIndex
              ? {
                  ...stage,
                  form: {
                    ...stage.form,
                    fields: stage.form.fields?.map((field, index) => {
                      if (field.id == action.payload.fieldId) {
                        return {
                          ...field,
                          [action.payload.name]: action.payload.value,
                        };
                      }
                      return field;
                    }),
                  },
                }
              : stage
        ),
      };

    case Types.ON_STAGE_INPUT_FIELD_CHANGE:
      return {
        ...state,
        [action.payload.formName]: state[action.payload.formName]?.map(
          (stage, i) =>
            i == action.payload.stageIndex
              ? {
                  ...stage,
                  [action.payload.name]: action.payload.value,
                }
              : stage
        ),
      };

    case "OPEN_ID":
      return {
        ...state,
        openField: action.payload,
        // processFormOpenFields: [action.payload.fieldId]
      };
    case "OPEN_FIELD":
      
      return {
        ...state,
        openField: action.payload,

        processFormOpenFields: action?.keepOthers
          ? [...state.processFormOpenFields, action.payload]
          : [action.payload],
      };
    case "SUBMITED_FIELD":
      return {
        ...state,
        openField: action.payload,
        processFormSubmittedFields: [
          ...state.processFormSubmittedFields,
          action.payload,
        ],
      };
    case "CLOSE_FIELD":
      return {
        ...state,
        openField: null,
        processFormOpenFields: [],
        processFormOpenFields: state.processFormOpenFields.filter(
          (field) => field.fieldId !== action.payload.fieldId
        ),
      };

    case Types.UPDATE_PROCESS_STAGE_FIELDS_ORDER:
      return {
        ...state,
        recruitmentProcessestSages: state.recruitmentProcessestSages?.map(
          (stage, i) =>
            i == action.payload.stageIndex
              ? {
                  ...stage,
                  form: {
                    ...stage.form,
                    fields: action.payload.fields,
                  },
                }
              : stage
        ),
      };

    case Types.SET_RECRUITMENT_FORM_SUBMITTING:
      return {
        ...state,
        recruitmentProcessFormSubmitting: true,
      };

    case Types.UPSERT_RECRUITMENT_PROCESS_ATTEMPT:
      return {
        ...state,
        recruitmentProcessFormServerValidation: {},
        upsertRecruitmentProcessLoading: true,
      };
    case Types.UPSERT_RECRUITMENT_PROCESS_SUCCESS:
    case Types.RESET_RECRUITMENT_PROCESS_FORM:
      return {
        ...state,
        recruitmentProcessFormSubmitting: false,
        openField: null,
        submittedField: null,
        recruitmentProcessFormServerValidation: {},
        upsertRecruitmentProcessLoading: false,
        recruitmentProcessesForm: {
          ...initState.recruitmentProcessesForm,
        },
        processFormSubmittedFields: [],
        processFormOpenFields: [],
        recruitmentProcessestSages: [
          {
            name: "",
            process_id: null,
            inactivate_application: false,
            form: {
              id: uuid(),
              fields: [
                {
                  id: uuid(),
                  parent_field_id: null,
                  name: "",
                  description: "",
                  // order: 1,
                  grid: "",
                  type: "",
                  selection_type: "",
                  dependent_option_names: [],
                  validation_type_ids: [],
                  options: [],
                },
              ],
            },
          },
        ],
      };
    case Types.UPSERT_RECRUITMENT_PROCESS_FAILED:
      return {
        ...state,
        recruitmentProcessFormServerValidation: action.payload ?? {},
        upsertRecruitmentProcessLoading: false,
      };

    case "SET_PREVIEW_STAGE":
      return {
        ...state,
        previewStageForm: action.payload?.fields,
      };

    case "SUBMIT_FIELD":
      return {
        ...state,
        submittedField: action.payload,
      };

    case Types.INIT_RECRUITMENT_PROCESS_FORM_ATTEMPT:
      return {
        ...state,
        initRecruitmentProcessLoading: true,
      };

    case Types.INIT_RECRUITMENT_PROCESS_FORM_SUCCESS:
      return {
        ...state,
        recruitmentProcessValidationOpts: action.payload,
        initRecruitmentProcessLoading: false,
      };

    case Types.FETCH_RECRUITMENT_PROCESS_FORM_SUCCESS:
      return {
        ...state,
        recruitmentProcessesForm: {
          ...action?.payload?.recruitmentProcessesForm,
        },
        // recruitmentProcessestSages: [
        //   ...action?.payload?.recruitmentProcessestSages?.stages,
        // ],
      };

    case Types.INIT_RECRUITMENT_PROCESS_FORM_FAILED:
      return {
        ...state,
        initRecruitmentProcessLoading: false,
        recruitmentProcessValidationOpts: [],
      };

    case Types.INIT_RECRUITMENT_INTAKE_FORM_ATTEMPT:
      return {
        ...state,
        initRecruitmentIntakeLoading: true,
      };

    case Types.INIT_RECRUITMENT_INTAKE_FORM_SUCCESS:
      return {
        ...state,
        recruitmentProcessValidationOpts: action.payload,
        initRecruitmentIntakeLoading: false,
      };

    case Types.FETCH_RECRUITMENT_INTAKE_FORM_SUCCESS:
      const { fields, ...recruitmentIntakeFormDetails } =
        action?.payload?.recruitmentIntakeForm;
      return {
        ...state,
        recruitmentIntakeForm: {
          ...action?.payload?.recruitmentIntakeForm,
        },
        recruitmentIntakeFormFields: [
          {
            form: {
              fields,
            },
          },
        ],
      };

    case Types.INIT_RECRUITMENT_INTAKE_FORM_FAILED:
      return {
        ...state,
        initRecruitmentIntakeLoading: false,
        recruitmentIntakeForm: {
          id: null,
          name: "",
          email: false,
          attachment: false,
          phone_number: false,
          fields: [
            {
              ...new FormField(null, 1),
              name: "name",
              suggested_field_type: "name",
              type: "text",
            },
          ],
        },
        recruitmentIntakeFormFields: [
          {
            form: {
              fields: [],
            },
          },
        ],
      };

    case Types.UPSERT_RECRUITMENT_INTAKE_ATTEMPT:
      return {
        ...state,
        recruitmentIntakeFormServerValidation: {},
        upsertRecruitmentIntakeLoading: true,
      };

    case Types.UPSERT_RECRUITMENT_INTAKE_SUCCESS:
    case Types.RESET_RECRUITMENT_INTAKE_FORM:
      return {
        ...state,
        recruitmentProcessFormSubmitting: false,
        openField: null,
        submittedField: null,
        recruitmentIntakeFormServerValidation: {},
        upsertRecruitmentIntakeLoading: false,

        recruitmentIntakeForm: {
          id: null,
          name: "",
          email: false,
          attachement: false,
          phone_number: false,
          fields: [
            {
              ...new FormField(null, 1),
              name: "name",
              type: "text",
              suggested_field_type: "name",
            },
          ],
        },
        recruitmentIntakeFormFields: [
          {
            form: {
              fields: [],
            },
          },
        ],
      };
    case Types.UPSERT_RECRUITMENT_INTAKE_FAILED:
      return {
        ...state,
        recruitmentIntakeFormServerValidation: action.payload ?? {},
        upsertRecruitmentIntakeLoading: false,
      };
    case Types.RESET_APPLICATION_LIST:
      return {
        ...state,
        applicationsList: initState.applicationsList,
      };

    case Types.UPDATE_APPLICATION_STAGE_STATUS_ATTEMPT:
      return {
        ...state,
        updateApplicationStageStatusLoading: [
          ...state.updateApplicationStageStatusLoading,
          action?.payload?.applicant_job_post_id,
        ],
      };
    case Types.UPDATE_APPLICATION_STAGE_STATUS_SUCCESS:
    case Types.UPDATE_APPLICATION_STAGE_STATUS_FAILED:
      return {
        ...state,
        updateApplicationStageStatusLoading:
          state.updateApplicationStageStatusLoading.filter(
            (id) => id !== action?.payload?.applicant_job_post_id
          ),
      };

    case Types.DELETE_APPLICANTS_ATTEMPT:
      return {
        ...state,
        deleteApplicantLoading: true,
      };
    case Types.DELETE_APPLICANTS_SUCCESS:
    case Types.DELETE_APPLICANTS_FAILED:
      return {
        ...state,
        deleteApplicantLoading: false,
      };

    case Types.FETCH_JOB_POSTS_FILTER_OPTIONS:
      return {
        ...state,
        jobPostsOptions: action?.payload?.data,
      };

    case Types.SHOW_JOB_POST_MODAL:
    case Types.SHOW_EDIT_APPLICANT_EMAIL_MODAL:
    case Types.SHOW_EDIT_APPLICANT_PHONE_MODAL:
    case Types.SHOW_ADD_APPLICANT_PHONE_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          isVissible: true,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: {
          ...state[action.payload.formName],
          ...action.payload.data,
        },
      };
    case Types.HIDE_JOB_POST_MODAL:
      return {
        ...state,
        jobPostModalServerValidation: {},
        jobPostModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: initState[action.payload.formName],
      };
    case Types.HIDE_EDIT_APPLICANT_EMAIL_MODAL:
      return {
        ...state,
        editApplicantEmailModalServerValidation: {},
        editApplicantEmailModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: initState[action.payload.formName],
      };
    case Types.HIDE_EDIT_APPLICANT_PHONE_MODAL:
      return {
        ...state,
        editApplicantPhoneModalServerValidation: {},
        editApplicantPhoneModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: initState[action.payload.formName],
      };

    case Types.HIDE_ADD_APPLICANT_PHONE_MODAL:
      return {
        ...state,
        addApplicantPhoneModalServerValidation: {},
        addApplicantPhoneModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: initState[action.payload.formName],
      };

    case Types.UPSERT_JOB_POST_ATTEMPT:
      return {
        ...state,
        jobPostModalServerValidation: {},
        jobPostModalActions: {
          ...state.jobPostModalActions,
          isLoading: true,
          modalMessage: "",
        },
      };
    case Types.UPSERT_JOB_POST_SUCCESS:
      return {
        ...state,
        jobPostModalServerValidation: {},
        jobPostModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload?.formName]: initState[action.payload?.formName],
      };

    case Types.UPDATE_APPLICANT_EMAIL_ATTEMPT:
      return {
        ...state,
        editApplicantEmailModalServerValidation: {},
        editApplicantEmailModalActions: {
          ...state.editApplicantEmailModalActions,
          isLoading: true,
          modalMessage: "",
        },
      };
    case Types.UPDATE_APPLICANT_EMAIL_SUCCESS:
      return {
        ...state,
        editApplicantEmailModalServerValidation: {},
        editApplicantEmailModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload?.formName]: initState[action.payload?.formName],
      };

    case Types.CREATE_APPLICANT_PHONE_ATTEMPT:
      return {
        ...state,
        addApplicantPhoneModalServerValidation: {},
        addApplicantPhoneModalActions: {
          ...state.addApplicantPhoneModalActions,
          isLoading: true,
          modalMessage: "",
        },
      };
    case Types.CREATE_APPLICANT_PHONE_SUCCESS:
      return {
        ...state,
        addApplicantPhoneModalServerValidation: {},
        addApplicantPhoneModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload?.formName]: initState[action.payload?.formName],
      };

    case Types.UPDATE_APPLICANT_PHONE_ATTEMPT:
      return {
        ...state,
        editApplicantPhoneModalServerValidation: {},
        editApplicantPhoneModalActions: {
          ...state.editApplicantPhoneModalActions,
          isLoading: true,
          modalMessage: "",
        },
      };
    case Types.UPDATE_APPLICANT_PHONE_SUCCESS:
      return {
        ...state,
        editApplicantPhoneModalServerValidation: {},
        editApplicantPhoneModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload?.formName]: initState[action.payload?.formName],
      };

    case Types.UPSERT_JOB_POST_FAILED:
    case Types.UPDATE_APPLICANT_EMAIL_FAILED:
    case Types.UPDATE_APPLICANT_PHONE_FAILED:
    case Types.CREATE_APPLICANT_PHONE_FAILED:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          modalMessage: action.payload?.modalMessage ?? "",
        },
        [action.payload.serverRef]: action.payload?.errors ?? {},
      };
    case Types.FETCH_JOB_POST_FORM_PROCESSES_AND_INTAKE_FORMS_OPTIONS:
      return {
        ...state,
        fetchJobPostFormOptionsLoading: true,
      };
    case Types.FETCH_JOB_POST_FORM_PROCESSES_AND_INTAKE_FORMS_OPTIONS_SUCCESS:
      return {
        ...state,
        fetchJobPostFormOptionsLoading: false,
        JobPostProcessesOptions: action?.payload?.jobPostFormProcesses,
        JobPostIntakeFormsOptions: action?.payload?.jobPostFormIntakeForms,
      };
    case Types.FETCH_JOB_POST_FORM_PROCESSES_AND_INTAKE_FORMS_OPTIONS_FAILED:
      return {
        ...state,
        fetchJobPostFormOptionsLoading: false,
      };

    case Types.ADD_JOB_POST_PROCESS_STAGES_INPUT:
      return {
        ...state,
        jobPostModal: {
          ...state.jobPostModal,
          stages_map: state?.jobPostModal?.process?.stages?.map((stage) => ({
            old_stage_id: stage?.id,
            new_stage_id: null,
          })),
        },
      };

    // Start Handle Recruitment Process Form Change

    case Types.ON_RECRUITMENT_PROCESS_FORM_FORM_FIELDS_CHANGE:
      return {
        ...state,
        recruitmentProcessesForm: {
          ...state?.recruitmentProcessesForm,
          stages: state?.recruitmentProcessesForm.stages?.map((stage) => {
            if (stage.id === action.payload.stageId) {
              return {
                ...stage,
                form: {
                  ...stage.form,
                  fields: action.payload?.fields,
                },
              };
            }
            return stage;
          }),
        },
      };

    case Types.HANDLE_RECRUITMENT_INTAKE_FORM_CHANGE: {
      return {
        ...state,
        recruitmentIntakeForm: {
          ...state.recruitmentIntakeForm,
          fields: action.payload,
        },
      };
    }

    case "SUBMITED_INTAKE_FIELD": {
      return {
        ...state,
        submittedIntakeFields: [...state.submittedIntakeFields, action.payload],
      };
    }
    case "OPEN_INTAKE_FIELD": {
      return {
        ...state,
        openIntakeFields: [...state.openIntakeFields, action.payload],
      };
    }
    case "CLOSE_INTAKE_FIELD": {
      return {
        ...state,
        openIntakeFields: state.openIntakeFields.filter(
          (fieldId) => fieldId !== action.payload
        ),
      };
    }

    case Types.ON_RECRUITMENT_PROCESS_FORM_CHANGE:
      if (Object.keys(action.payload.field)[0] === "initial_flag") {
        return {
          ...state,
          recruitmentProcessesForm: {
            ...state?.recruitmentProcessesForm,
            stages: state?.recruitmentProcessesForm.stages?.map((stage) => {
              if (stage.id === action.payload.stageId) {
                return {
                  ...stage,
                  initial_flag: true,
                };
              }
              return {
                ...stage,
                initial_flag: false,
              };
            }),
          },
        };
      }

      return {
        ...state,
        recruitmentProcessesForm: {
          ...state?.recruitmentProcessesForm,
          stages: state?.recruitmentProcessesForm.stages?.map((stage) => {
            if (stage.id === action.payload.stageId) {
              return {
                ...stage,
                ...action.payload.field,
                // [action?.payload?.field?.name]: action?.payload?.field?.value,
              };
            }
            return stage;
          }),
        },
      };

    case Types.SHOW_APPLICATION_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          isVissible: true,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: {
          ...state[action.payload.formName],
          ...action.payload.data,
        },
      };

    case Types.HIDE_APPLICATION_MODAL:
      return {
        ...state,
        applicationModalServerValidation: {},
        applicationModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: initState[action.payload.formName],
      };

    case Types.UPSERT_APPLICATION_ATTEMPT:
      return {
        ...state,
        applicationModalServerValidation: {},
        applicationModalActions: {
          ...state.applicationModalActions,
          isLoading: true,
          modalMessage: "",
        },
      };

    case Types.UPSERT_APPLICATION_SUCCESS:
      return {
        ...state,
        applicationModalServerValidation: {},
        applicationModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload?.formName]: initState[action.payload?.formName],
      };

    case Types.UPSERT_APPLICATION_FAILED:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          modalMessage: action.payload?.modalMessage ?? "",
        },
        [action.payload.serverRef]: action.payload?.errors ?? {},
      };

    case Types.FETCH_APPLICANT_PROFILE_ATTEMPT:
      return {
        ...state,
        applicantProfileLoading: true,
      };
    case Types.FETCH_APPLICANT_PROFILE_SUCCESS:
      return {
        ...state,
        selectedApplicantProfile: action.payload.applicantProfile,
        applicantProfileLoading: false,
      };
    case Types.FETCH_APPLICANT_PROFILE_FAILED:
      return {
        ...state,
        selectedApplicantProfile: {},
        applicantProfileLoading: false,
      };

    case Types.DELETE_APPLICANT_PHONE_NUMBER_ATTEMPT:
      return {
        ...state,
        deleteApplicantPhoneNumberLoading: true,
      };
    case Types.DELETE_APPLICANT_PHONE_NUMBER_SUCCESS:
    case Types.DELETE_APPLICANT_PHONE_NUMBER_FAILED:
      return {
        ...state,
        deleteApplicantPhoneNumberLoading: false,
      };

    case Types.LOG_OUT:
    case "SWITCH_ACCOUNT":
      return { ...initState };

    case Types.FETCH_INTAKE_FORM_ATTEMPT:
      return {
        ...state,
        isFetchJobPostIntakeFormLoading: true,
        jobPostIntakeForm: [],
      };

    case Types.FETCH_INTAKE_FORM_SUCCESS:
      return {
        ...state,
        applicationModal: {
          ...state.applicationModal,
          ...action?.payload?.jobPost,
          intake_form_id: action?.payload?.intakeForm?.id,
        },
        isFetchJobPostIntakeFormLoading: false,
        jobPostIntakeFormFields: action.payload?.intakeForm?.fields,
        jobPostIntakeForm: action.payload?.intakeForm?.fields.reduce(
          (acc, field) => {
            return { ...acc, [field.id]: "" };
          },
          {}
        ),
      };

    case "SET_EDITED_STAGE":
      return {
        ...state,
        submittedStageForm: action.payload,
        submitStageServerValidation: {},
      };

    case Types.FETCH_INTAKE_FORM_FAILED:
      return {
        ...state,
        isFetchJobPostIntakeFormLoading: false,
        jobPostIntakeForm: [],
      };

    case Types.FILL_STAGE_DATA_ATTEMPT:
      return {
        ...state,
        isFillStageDataLoading: true,
        submitStageServerValidation: {},
      };

    case Types.FILL_STAGE_DATA_SUCCESS:
      return {
        ...state,
        isFillStageDataLoading: false,
        submitStageServerValidation: {},
      };
    case Types.FILL_STAGE_DATA_FAILED:
      return {
        ...state,
        isFillStageDataLoading: false,
        submitStageServerValidation: action.payload?.errors ?? {},
      };

    default:
      return state;
  }
};
