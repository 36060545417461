import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  AcceptIconButton,
  EditIconButton,
  InfoIconButton,
  RemoveIconButton,
  TimesIconButton,
} from "../../Components/IconButtonWithTooltip";
import Loader from "../../Components/Loader";
import {
  FETCH_MENU_LEAVE_AND_BREAK_SETTINGS,
  FETCH_MEPLOYEE_LEAVE_BREAK_PROFILES,
} from "../../Graphql/query";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { AddButton } from "../../Components/Buttons";
import { useTranslation } from "react-i18next";
import MainModal from "../../Components/MainModal";
import { BSelect } from "../../Builder/Form";
import Constants from "../../Constants";
import {
  DELETE_EMPLOYEE_LEAVE_BREAK_PROFILE,
  UPSERT_EMPLOYEE_LEAVE_BREAK_PROFILE,
} from "../../Graphql/mutation/Employee";
import { useDispatch } from "react-redux";
import { onFormResetAction } from "../../Store/Actions";
import { showToast } from "../../Helpers/HelperFns";
import swal from "sweetalert";
import _ from "lodash";
import moment from "moment";

const EmployeeLeaveAndBreakProfiles = () => {
  const columns = [
    {
      name: "start",
      selector: "start",
      cell: (row) => <span>{row.apply_from}</span>,
    },
    {
      name: "permissionLeavesBreakSettings",
      selector: "permissionLeavesBreakSettings",
      sortable: true,
      cell: (row) =>
        row.permissionLeavesBreakSettings?.currentVersion ? (
          <span>
            {row.permissionLeavesBreakSettings?.currentVersion?.name} (
            {moment(row?.permissionLeavesBreakSettings?.created_at).format(
              "DD/MM/YYYY"
            )}
            {" - "}
            {moment(row?.permissionLeavesBreakSettings?.updated_at).format(
              "DD/MM/YYYY"
            )}
            )
          </span>
        ) : (
          <span>{row.permissionLeavesBreakSettings?.name}</span>
        ),
    },
    {
      name: "Action",
      right: true,
      cell: (row) => (
        <div className="leaves_breaks_card_actions sign-in-logs-button-container">
          {row?.logs?.length > 0 ? (
            <InfoIconButton onClick={() => viewLogs(row)} tooltipText="logs" />
          ) : null}
          {row?.canEdit ? (
            <EditIconButton
              onClick={() => handleEdit(row)}
              tooltipText="Edit"
            />
          ) : null}
          {row?.canDelete ? (
            <RemoveIconButton
              onClick={() => handleDelete(row)}
              tooltipText="Remove"
            />
          ) : null}
        </div>
      ),
      sortable: false,
    },
  ];
  const { userId } = useParams();

  const { loading, error, data, refetch } = useQuery(
    FETCH_MEPLOYEE_LEAVE_BREAK_PROFILES,
    {
      variables: {
        // your variables
        id: userId,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const [employeeLeaveBreakProfileModal, setEmployeeLeaveBreakProfileModal] =
    useState({
      isOpen: false,
      permission_leaves_break_setting_id: null,
      year: null,
      user_id: null,
      isEdit: false,
      // other modal state
    });

  const handleDelete = (row) => {
    swal({
      title: t("are you sure"),
      text: t(""),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("confirm")],
    }).then((confirm) => {
      if (confirm) {
        deleteEmployeeLeaveBreakProfile({
          variables: {
            id: row?.id,
          },
        });
      }
    });
  };

  const handleEdit = (row) => {
    setEmployeeLeaveBreakProfileModal((prevState) => ({
      ...prevState,
      isOpen: true,
      permission_leaves_break_setting_id:
        row?.permissionLeavesBreakSettings?.id,
      main_permission_leaves_break_setting_id:
        row?.permissionLeavesBreakSettings?.currentVersion?.id ||
        row?.permissionLeavesBreakSettings?.id,
      permission_leaves_break_setting: row?.permissionLeavesBreakSettings,
      year: row?.apply_from,
      user_id: userId,
      isEdit: true,
      applicable_type: data?.user?.employee?.__typename,
    }));
  };

  const [deleteEmployeeLeaveBreakProfile, { loading: isDeleteLoading }] =
    useMutation(DELETE_EMPLOYEE_LEAVE_BREAK_PROFILE, {
      onCompleted(res) {
        // do someting
        if (
          res?.delete_user_permission_leave_break_settings?.status === "success"
        ) {
          refetch();
        } else {
          showToast(
            res?.delete_user_permission_leave_break_settings?.status,
            res?.delete_user_permission_leave_break_settings?.message,
            false
          );
        }
      },
      onError(error) {
        // do something
      },
    });

  const addNewLeaveProfile = () => {
    // code for modal open and data setting
    setEmployeeLeaveBreakProfileModal({
      isOpen: true,
      permission_leaves_break_setting_id: null,
      year: null,
      user_id: userId,
      isEdit: false,
      applicable_type: data?.user?.employee?.__typename,
    });
  };

  const { t } = useTranslation();

  const dismissLeaveProfile = () => {
    setEmployeeLeaveBreakProfileModal({
      isOpen: false,
      permission_leaves_break_setting_id: null,
      year: null,
      user_id: null,
      isEdit: false,
      // other modal state
    });
  };

  const [logsModal, setLogsModal] = useState({
    isOpen: false,
    logs: [],
  });
  const viewLogs = (row) => {
    if (row) {
      setLogsModal({
        isOpen: true,
        logs: row?.logs,
      });
    } else {
      setLogsModal({
        isOpen: false,
        logs: [],
      });
    }
  };

  return (
    <div className="work_schedule_tab">
      <HasPrivileges
        reqireMain={[Privilages.ADD_EDIT_DELETE_EMPLOYEE_LEAVE_BREAK_PROFILE]}
        allowBP
      >
        <div className="tab_header mb-0">
          <AddButton onClick={addNewLeaveProfile}>
            {t("Add New Configuration")}
          </AddButton>
        </div>
      </HasPrivileges>
      <DataTable
        noHeader
        noTableHead
        data={data?.user?.employee?.permissionLeavesBreakSettingUser || []}
        columns={columns}
        className="cards_table my-3"
        progressPending={loading || isDeleteLoading}
        progressComponent={<Loader />}
      />

      {employeeLeaveBreakProfileModal?.isOpen ? (
        <EmployeeLeaveAndBreakProfileModal
          refetch={refetch}
          modalData={employeeLeaveBreakProfileModal}
          onDismiss={dismissLeaveProfile}
        />
      ) : null}
      {logsModal?.isOpen ? (
        <>
          <ViewLogsModal modalData={logsModal} toggle={viewLogs} />
        </>
      ) : null}
    </div>
  );
};

export default EmployeeLeaveAndBreakProfiles;

const EmployeeLeaveAndBreakProfileModal = (props) => {
  const { t } = useTranslation();

  const [formInput, setFormInput] = useState({
    permission_leaves_break_setting_id: null,
    main_permission_leaves_break_setting_id: null,
    year: null,
    permission_leaves_break_setting: null,
  });

  const dismiss = () => {
    setFormInput({
      permission_leaves_break_setting_id: null,
      main_permission_leaves_break_setting_id: null,
      year: null,
    });
    props.onDismiss();
  };

  useEffect(() => {
    if (props?.modalData?.isOpen && props?.modalData?.isEdit) {
      setFormInput({
        permission_leaves_break_setting_id:
          props?.modalData?.permission_leaves_break_setting_id,
        main_permission_leaves_break_setting_id:
          props?.modalData?.main_permission_leaves_break_setting_id,
        permission_leaves_break_setting:
          props?.modalData?.permission_leaves_break_setting,
        year: props?.modalData?.year,
      });
    } else {
      setFormInput({
        permission_leaves_break_setting_id: null,
        permission_leaves_break_setting: null,
        year: null,
      });
    }

    return () => {};
  }, [props?.modalData?.isOpen]);

  const dispatch = useDispatch();
  const [upsertEmployeeLeaveProfile, { loading }] = useMutation(
    UPSERT_EMPLOYEE_LEAVE_BREAK_PROFILE,
    {
      onCompleted(res) {
        // do someting
        if (res?.upsert_user_permission_leave_break_settings?.id) {
          props?.refetch();
          dismiss();
        }
      },
      onError(error) {
        // do something
        if (error?.graphQLErrors?.[0]?.extensions?.validation) {
          dispatch(
            onFormResetAction(
              "employeeLeaveProfileServerValidation",
              error?.graphQLErrors?.[0]?.extensions?.validation
            )
          );
          return;
        }
        if (error?.graphQLErrors?.[0]?.extensions?.reason) {
          showToast(
            "error",
            error?.graphQLErrors?.[0]?.extensions?.reason,
            false
          );
          return;
        }
      },
    }
  );

  const {
    loading: loadingOpts,
    error,
    data,
  } = useQuery(FETCH_MENU_LEAVE_AND_BREAK_SETTINGS, {
    notifyOnNetworkStatusChange: true,
    skip: !props?.modalData?.isOpen,
  });

  const handleSubmitEmployeeLeaveProfile = () => {
    upsertEmployeeLeaveProfile({
      variables: {
        input: {
          id: props?.modalData?.id || null,
          permission_leaves_break_setting_id:
            formInput?.permission_leaves_break_setting_id,
          year: formInput?.year,
          employee_id: props?.modalData?.user_id,
        },
      },
    });
  };

  const handleChangeYear = (val, e) => {
    setFormInput((prevState) => ({
      ...prevState,
      year: val?.value || null,
    }));
  };
  const handleChangeLeaveProfile = (val, e) => {
    if (e.name === "main_permission_leaves_break_setting_id") {
      setFormInput((prevState) => ({
        ...prevState,
        main_permission_leaves_break_setting_id: val?.id || null,
        permission_leaves_break_setting_id: val?.id || null,
      }));
    } else {
      setFormInput((prevState) => ({
        ...prevState,
        permission_leaves_break_setting_id: val?.id || null,
      }));
    }
  };

  let selectedMainLeave =
    data?.company_leaves_breaks?.data?.find(
      (lv) => lv.id == formInput?.main_permission_leaves_break_setting_id
    ) || {};

  const versionsLeaveBreakOpts = [
    ...(!!formInput?.main_permission_leaves_break_setting_id
      ? [selectedMainLeave]
      : []),
    ...(selectedMainLeave?.children || []),
  ];

  const workdayType =
    props?.modalData?.applicable_type === "FlexEmployee" ? "flexible" : "fixed";

  return (
    <>
      <MainModal
        isOpen={props?.modalData?.isOpen}
        toggle={dismiss}
        modalTitle={t("leave and break profile")}
        className="work_schedule_modal"
        btnLabel={t("save")}
        btnOnClick={handleSubmitEmployeeLeaveProfile}
        btnSubmitLoading={loading}
      >
        <BSelect
          name="year"
          label="year"
          optionLabel="value"
          optionValue="value"
          options={Constants.YearData}
          keepDefaultStyle
          containerStyle="year-picker my-1"
          icon="calendar"
          bValue={formInput?.year}
          rootStyle="col-md-12 px-0"
          onChange={handleChangeYear}
          validationName="input.year"
          formServerValidation="employeeLeaveProfileServerValidation"
          isDisabled={Boolean(props?.modalData?.id)}
        />
        <div className="row">
          <BSelect
            name="main_permission_leaves_break_setting_id"
            validationName="input.permission_leaves_break_setting_id"
            optionLabel="name"
            optionValue="id"
            options={data?.company_leaves_breaks?.data || []}
            keepDefaultStyle
            containerStyle="year-picker my-1"
            icon="calendar"
            bValue={formInput?.main_permission_leaves_break_setting_id}
            rootStyle="col-md-6"
            onChange={handleChangeLeaveProfile}
            formServerValidation="employeeLeaveProfileServerValidation"
            isLoading={loadingOpts}
            label="main"
            optDependType="equal"
            optDependKey="applicable_type"
            optDependValue={[workdayType]}
          />

          <BSelect
            name="permission_leaves_break_setting_id"
            validationName="input.permission_leaves_break_setting_id"
            optionLabel="name"
            optionValue="id"
            options={versionsLeaveBreakOpts || []}
            keepDefaultStyle
            containerStyle="year-picker my-1"
            icon="calendar"
            bValue={formInput?.permission_leaves_break_setting_id}
            rootStyle="col-md-6"
            onChange={handleChangeLeaveProfile}
            formServerValidation="employeeLeaveProfileServerValidation"
            isLoading={loadingOpts}
            label="version"
            // optDependType="equal"
            // optDependKey="applicable_type"
            // optDependValue={[workdayType]}
          />
        </div>
      </MainModal>
    </>
  );
};

const ViewLogsModal = (props) => {
  const { t } = useTranslation();
  const dismiss = () => {
    props?.toggle();
  };

  const col = [
    {
      name: t("name"),
      selector: "name",
      cell: (row) =>
        row?.permissionLeavesBreakSettings?.currentVersion ? (
          <span>
            {row.permissionLeavesBreakSettings?.currentVersion?.name} (
            {moment(row?.permissionLeavesBreakSettings?.created_at).format(
              "DD/MM/YYYY"
            )}
            {" - "}
            {moment(row?.permissionLeavesBreakSettings?.updated_at).format(
              "DD/MM/YYYY"
            )}
            )
          </span>
        ) : (
          <span>{row.permissionLeavesBreakSettings?.name}</span>
        ),
      grow: 2,
    },
    {
      name: t("start"),
      selector: "start",
      width: "120px",
    },
    {
      name: t("end"),
      selector: "end",
      width: "120px",
    },
    {
      name: t("permissions"),
      selector: "permissions",
      grow: 1.4,
      cell: (row) => {
        return (
          <span>
            {row?.permissionLeavesBreakSettings?.permissionSetting
              ?.allow_permissions ? (
              <AcceptIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            ) : (
              <TimesIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            )}
          </span>
        );
      },
    },
    {
      name: t("annual leave"),
      selector: "annual Leave",
      grow: 1.4,
      cell: (row) => {
        return (
          <span>
            {row?.permissionLeavesBreakSettings?.annualLeaveSetting
              ?.allow_annual_leaves ? (
              <AcceptIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            ) : (
              <TimesIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            )}
          </span>
        );
      },
    },
    {
      name: t("sick leave"),
      selector: "sick Leave",
      grow: 1.4,
      cell: (row) => {
        return (
          <span>
            {row?.permissionLeavesBreakSettings?.sickLeaveSetting
              ?.sick_leaves ? (
              <AcceptIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            ) : (
              <TimesIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            )}
          </span>
        );
      },
    },
    {
      name: t("unpaid leave"),
      selector: "unpaid Leave",
      grow: 1.4,
      cell: (row) => {
        return (
          <span>
            {row?.permissionLeavesBreakSettings?.unpaidLeaveSetting
              ?.unpaid_leaves ? (
              <AcceptIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            ) : (
              <TimesIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            )}
          </span>
        );
      },
    },
    {
      name: t("Bereavement leave"),
      selector: "bereavement leave",
      grow: 1.4,
      cell: (row) => {
        return (
          <span>
            {row?.permissionLeavesBreakSettings?.customLeaveSetting
              ?.allow_custom_leave &&
            row?.permissionLeavesBreakSettings?.customLeaveSetting
              ?.bereavement_leave ? (
              <AcceptIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            ) : (
              <TimesIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            )}
          </span>
        );
      },
    },
    {
      name: t("Maternity/Paternity leave"),
      selector: "maternal paternal leave",
      grow: 1.4,
      cell: (row) => {
        return (
          <span>
            {row?.permissionLeavesBreakSettings?.customLeaveSetting
              ?.allow_custom_leave &&
            row?.permissionLeavesBreakSettings?.customLeaveSetting
              ?.maternal_paternal_leave ? (
              <AcceptIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            ) : (
              <TimesIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            )}
          </span>
        );
      },
    },
    {
      name: t("hajj leave"),
      selector: "hajj leave",
      grow: 1.4,
      cell: (row) => {
        return (
          <span>
            {row?.permissionLeavesBreakSettings?.customLeaveSetting
              ?.allow_custom_leave &&
            row?.permissionLeavesBreakSettings?.customLeaveSetting
              ?.hajj_leave ? (
              <AcceptIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            ) : (
              <TimesIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            )}
          </span>
        );
      },
    },
    {
      name: t("work injury leave"),
      selector: "work injury leave",
      grow: 1.4,
      cell: (row) => {
        return (
          <span>
            {row?.permissionLeavesBreakSettings?.customLeaveSetting
              ?.allow_custom_leave &&
            row?.permissionLeavesBreakSettings?.customLeaveSetting
              ?.work_injury_leave ? (
              <AcceptIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            ) : (
              <TimesIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            )}
          </span>
        );
      },
    },
    {
      name: t("sabbatical leave"),
      selector: "sabbatical leave",
      grow: 1.4,
      cell: (row) => {
        return (
          <span>
            {row?.permissionLeavesBreakSettings?.customLeaveSetting
              ?.allow_custom_leave &&
            row?.permissionLeavesBreakSettings?.customLeaveSetting
              ?.sabbatical_leave ? (
              <AcceptIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            ) : (
              <TimesIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            )}
          </span>
        );
      },
    },
    {
      name: t("study leave"),
      selector: "study leave",
      grow: 1.4,
      cell: (row) => {
        return (
          <span>
            {row?.permissionLeavesBreakSettings?.customLeaveSetting
              ?.allow_custom_leave &&
            row?.permissionLeavesBreakSettings?.customLeaveSetting
              ?.study_leave ? (
              <AcceptIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            ) : (
              <TimesIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            )}
          </span>
        );
      },
    },
    {
      name: t("marriage leave"),
      selector: "marriage leave",
      grow: 1.4,
      cell: (row) => {
        return (
          <span>
            {row?.permissionLeavesBreakSettings?.customLeaveSetting
              ?.allow_custom_leave &&
            row?.permissionLeavesBreakSettings?.customLeaveSetting
              ?.marriage_leave ? (
              <AcceptIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            ) : (
              <TimesIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            )}
          </span>
        );
      },
    },
    {
      name: t("in lieu of work day leave"),
      selector: "in lieu of work day leave",
      grow: 1.4,
      cell: (row) => {
        return (
          <span>
            {row?.permissionLeavesBreakSettings?.customLeaveSetting
              ?.allow_custom_leave &&
            row?.permissionLeavesBreakSettings?.customLeaveSetting
              ?.in_lieu_of_work_day_leave ? (
              <AcceptIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            ) : (
              <TimesIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            )}
          </span>
        );
      },
    },
    {
      name: t("military call leave"),
      selector: "military call leave",
      grow: 1.4,
      cell: (row) => {
        return (
          <span>
            {row?.permissionLeavesBreakSettings?.customLeaveSetting
              ?.allow_custom_leave &&
            row?.permissionLeavesBreakSettings?.customLeaveSetting
              ?.military_call_leave ? (
              <AcceptIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            ) : (
              <TimesIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            )}
          </span>
        );
      },
    },
  ];
  return (
    <MainModal
      isOpen={props?.modalData?.isOpen}
      toggle={dismiss}
      modalTitle={t("leave and break profile logs")}
      className="work_schedule_modal"
      size="xl"
    >
      <DataTable
        noHeader
        data={props?.modalData?.logs || []}
        columns={col}
        className="cards_table my-3"
      />
    </MainModal>
  );
};
