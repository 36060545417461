import React from "react";

import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import LanguageIcon from "@mui/icons-material/Language";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import ListIcon from "@mui/icons-material/List";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import WorkIcon from "@mui/icons-material/Work";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import DnsIcon from "@mui/icons-material/Dns";
import MapOutlinedIcon from "@mui/icons-material/Dns";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PaymentIcon from '@mui/icons-material/Payment';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import {
  faBuilding,
  faCoffee,
  faMailBulk,
} from "@fortawesome/free-solid-svg-icons";

export const renderIcon = (icon) => {
  if (typeof icon === "string") {
    const iconComponents = {
      person: <PersonOutlineIcon />,
      people: <PeopleOutlineIcon />,
      employee: <PersonOutlineIcon />,
      employees: <PeopleOutlineIcon />,
      department: <FontAwesomeIcon icon={faBuilding} />,
      departments: <FontAwesomeIcon icon={faBuilding} />,
      office: <FontAwesomeIcon icon={faMailBulk} />,
      offices: <FontAwesomeIcon icon={faMailBulk} />,
      break: <FontAwesomeIcon icon={faCoffee} />,
      search: <SearchIcon />,
      location: <LocationOnIcon />,
      globe: <LanguageIcon />,
      key: <VpnKeyIcon />,
      money: <LocalAtmIcon />,
      document: <InsertDriveFileIcon />,
      gps: <GpsFixedIcon />,
      calendar: <DateRangeIcon />,
      fingerprint: <FingerprintIcon />,
      holiday: <FlightTakeoffIcon />,
      type: <ListIcon />,
      currency: <AttachMoneyIcon />,
      suitcase: <WorkIcon />,
      mail: <MailOutlineIcon />,
      ip: <DnsIcon />,
      map: <MapOutlinedIcon />,
      phone: <LocalPhoneIcon />,
      city: <LocationCityIcon />,
      user: <FontAwesomeIcon icon="fa-solid fa-user" />,
      time: <AccessTimeIcon />,
      wallet: <AccountBalanceWalletIcon />,
      card: <PaymentIcon />,
      landmark: <FontAwesomeIcon icon="fas fa-landmark" />,
      calculator: <FontAwesomeIcon icon="fas fa-calculator" />,
    };

    return iconComponents[icon.toLowerCase()] ?? null;
  }

  return icon;
};
