import React from "react";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import {
  billingPeriodOptions,
  promotionTypeOptions,
} from "../../Constants/PaymentTracking";
import {
  calcEndDate,
  getPlansOptions,
  formatRatePerPeriod,
  serializeFetchPlanTiers,
} from "../../Helpers/HelperFns/PaymentTracking";
import { EgyptId } from "../../Constants";
import { showToast } from "../../Helpers/HelperFns";
import { onFormResetAction, updateValueAction } from "../../Store/Actions";
import { subscriptionFormQuery } from "../../Graphql/query/PaymentTracking";

import {
  BSelect,
  InputForm,
  RadioboxForm,
  DateTimePickerForm,
  CheckboxBooleanForm,
} from "form-builder";
import { AddButton } from "../Buttons";
import { TimesIconButton } from "../IconButtonWithTooltip";

const reducer = "paymentTracking";
const formName = "subscriptionForm";
const formNameValidation = "paymentTrackingClientValidation";
const formServerValidation = "paymentTrackingServerValidation";
const formatOption = (str) => ({ id: str, name: str });
const getTierPrice = (config, empNum) => {
  const tiers = serializeFetchPlanTiers(config);
  const currTier = tiers.find((t) => t.from <= empNum && t.to >= empNum);
  if (currTier) return currTier.price;
  else return 0;
};

const SubscriptionForm = ({ data }) => {
  const dispatch = useDispatch();

  // Local State
  const [plans, setPlans] = React.useState([]);

  // Reducer State
  const formData = useSelector((state) => state?.[reducer]?.[formName]);
  const options = useSelector(
    (state) => state?.[reducer]?.paymentTrackingOptions
  );

  // Server State
  const { loading } = useQuery(subscriptionFormQuery, {
    variables: {},
    onCompleted: ({ plans, agents, countries, companies }) => {
      // Options
      setPlans(
        getPlansOptions(plans?.data, +data?.companyId || formData?.company)
      );
      dispatch(
        onFormResetAction("paymentTrackingOptions", {
          ...options,
          agents: agents || [],
          plans: plans?.data || [],
          countries: countries || [],
          companies: companies || [],
        })
      );
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  // Constants
  const selectedPlanCurrency = options?.plans.find(
    (plan) => plan?.id === formData?.plan
  )?.currency?.name;
  const FormProps = {
    reducer,
    formName,
    formSubmitting: data?.formSubmitting,
    formNameValidation,
    formServerValidation,
  };

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    if (
      formData.billingPeriod &&
      formData.contractDuration &&
      formData.startDate
    ) {
      handleSelectEndDate();
    }
  }, [formData.billingPeriod, formData.contractDuration, formData.startDate]);

  React.useEffect(() => {
    if (formData.plan && formData.employees) {
      const plan = options.plans.find((o) => o.id === formData.plan);
      const price = getTierPrice(plan?.configuration, +formData?.employees);
      dispatch(
        updateValueAction(
          formName,
          "ratePerPeriod",
          price ? formatRatePerPeriod(plan, price) : "No suitable tier found"
        )
      );
    } else {
      dispatch(updateValueAction(formName, "ratePerPeriod", ""));
    }
  }, [formData.plan, formData.employees]);

  /* ↓ Helpers ↓ */

  const onSelectCompany = (_, company) => {
    setPlans(getPlansOptions(options?.plans, company?.id));
    dispatch(
      onFormResetAction(formName, {
        ...formData,
        plan: null,
        applyFees: 0,
        applyPromotion: 0,
      })
    );
  };

  const onSelectPlan = (_, plan) => {
    dispatch(
      onFormResetAction(formName, {
        ...formData,
        applyFees: 0,
        applyPromotion: 0,
        billingPeriod: plan.billingPeriod,
        contractDuration: plan.contractDuration,
      })
    );
  };

  const handleSelectEndDate = () => {
    const endDate = calcEndDate(
      formData.billingPeriod,
      formData.contractDuration,
      formData.startDate
    );

    dispatch(updateValueAction(formName, "endDate", endDate));
  };

  const generateFees = () => {
    formData?.contractDuration &&
      dispatch(
        updateValueAction(
          formName,
          "fees",
          Array.from(Array(+formData?.contractDuration), (_, i) => i + 1).map(
            () => ({ amount: "0" })
          )
        )
      );
  };

  const generatePromotions = () => {
    formData?.contractDuration &&
      dispatch(
        updateValueAction(
          formName,
          "promotions",
          Array.from(Array(+formData?.contractDuration), (_, i) => i + 1).map(
            () => ({ type: "Fixed", amount: "0" })
          )
        )
      );
  };

  const handlePromotionTypeChange = (promotionIdx, type) => {
    dispatch(
      updateValueAction(
        formName,
        "promotions",
        formData.promotions.map((p, i) =>
          promotionIdx === i ? { ...p, type } : p
        )
      )
    );
  };

  const handlePromotionAmountChange = (promotionIdx, amount) => {
    dispatch(
      updateValueAction(
        formName,
        "promotions",
        formData.promotions.map((p, i) =>
          promotionIdx === i ? { ...p, amount } : p
        )
      )
    );
  };

  const handleAddFee = () => {
    dispatch(
      updateValueAction(formName, "fees", [
        ...formData.fees,
        { amount: "0", from: null },
      ])
    );
  };

  const handleRemoveFee = (feeIdx) => {
    dispatch(
      updateValueAction(
        formName,
        "fees",
        formData.fees.filter((_, i) => i !== feeIdx)
      )
    );
  };

  const handleFeeFromDateChange = (feeIdx, from) => {
    dispatch(
      updateValueAction(
        formName,
        "fees",
        formData.fees.map((f, i) => (feeIdx === i ? { ...f, from } : f))
      )
    );
  };

  const handleFeeAmountChange = (feeIdx, amount) => {
    dispatch(
      updateValueAction(
        formName,
        "fees",
        formData.fees.map((f, i) => (feeIdx === i ? { ...f, amount } : f))
      )
    );
  };

  return (
    <>
      {!data?.isSubscriptionProfile ? (
        <>
          {data?.isCompanyForm ? null : (
            <BSelect
              {...FormProps}
              label="company"
              name="company"
              icon="department"
              rootStyle="w-100"
              validateBy="textRequired"
              options={options.companies}
              isLoading={!options.companies.length}
              onInterceptInputOnChange={onSelectCompany}
              validationName="company_id"
            />
          )}
          <div className="d-flex gap-10">
            <BSelect
              {...FormProps}
              label="country"
              name="country"
              icon="globe"
              validateBy="textRequired"
              rootStyle="flex-1"
              options={options.countries}
              isLoading={!options.countries.length}
              validationName="country_id"
            />
            <BSelect
              {...FormProps}
              label="taxation"
              name="taxation"
              icon="document"
              validateBy="textRequired"
              rootStyle="flex-1"
              options={[
                { id: "Custom", name: "Custom" },
                { id: "Egyptian taxation", name: "Egyptian taxation" },
              ]}
              dependOn="country"
              dependancyType="equal"
              dependancyValue={[EgyptId]}
              validationName="taxation"
            />
          </div>
          <div className="d-flex align-items-end justify-content-between gap-10 my-2">
            <RadioboxForm
              {...FormProps}
              name="applyTax"
              options={[
                { label: "Apply VAT", value: 1 },
                { label: "Don't Apply VAT", value: 0 },
              ]}
              optionInputStyle=" "
              optionItemStyle=" "
              containerStyle=" "
              optionsContainerStyle="d-flex gap-20"
              rootStyle="flex-1"
            />
            {formData.taxation === "Custom" && formData.applyTax ? (
              <InputForm
                {...FormProps}
                name="taxAmount"
                placeholder="taxation percentage"
                labelStyle="mb-2 pt-1"
                containerStyle="w-100"
                rootStyle="flex-1"
                validateBy="textRequired"
                dependOn="taxation"
                dependancyType="equal"
                dependancyValue="Custom"
                validationName="taxation"
              />
            ) : null}
          </div>
        </>
      ) : null}

      <div
        {...(!data?.isSubscriptionProfile && {
          className: "border p-2 mt-4",
          style: { borderRadius: 4 },
        })}
      >
        {data?.isFollowUp ? (
          <div className="my-2 d-flex justify-content-between">
            <div className="">
              <dt>Package:</dt> <dd>{data?.package?.name}</dd>
            </div>
            <div className="">
              <dt>Paid amount:</dt> <dd>{data?.paid}</dd>
            </div>
            <div className="">
              <dt>Currency:</dt> <dd>{data?.currency.name}</dd>
            </div>
          </div>
        ) : null}

        <BSelect
          {...FormProps}
          label="plan"
          name="plan"
          icon="document"
          rootStyle="w-50 d-inline-block pr-2"
          validateBy="textRequired"
          options={plans}
          isLoading={loading}
          onInterceptInputOnChange={onSelectPlan}
          validationName="plan_id"
          isDisabled={formData?.applyPromotion || formData?.applyFees}
          hasTooltip={Boolean(formData?.applyPromotion || formData?.applyFees)}
          tooltipTitle="unselect 'Apply Promotion/Fees' to be able edit"
        />
        <InputForm
          {...FormProps}
          type="number"
          name="suspend_after"
          label="suspense after days"
          labelStyle="mb-2 pt-1"
          containerStyle="w-100"
          rootStyle="w-50 d-inline-block"
          placeholder="suspense after days"
          validationName="duration"
          icon="time"
        />
        <BSelect
          {...FormProps}
          isDisabled
          label="Billing period"
          name="billingPeriod"
          icon="calendar"
          rootStyle="w-50 d-inline-block pr-2"
          options={billingPeriodOptions}
          validateBy="textRequired"
        />
        <InputForm
          {...FormProps}
          name="contractDuration"
          label="Contract Duration"
          labelStyle="mb-2 pt-1"
          containerStyle="w-100"
          rootStyle="w-50 d-inline-block"
          placeholder="No. Of Billing Cycles"
          validateBy="textRequired"
          disabled={
            !formData?.plan || formData?.applyPromotion || formData?.applyFees
          }
          icon="time"
          validationName="duration"
          hasTooltip={Boolean(
            !formData?.plan || formData?.applyPromotion || formData?.applyFees
          )}
          tooltipTitle="select a plan & unselect 'Apply Promotion/Fees' to be able edit"
        />
        <InputForm
          {...FormProps}
          name="employees"
          label="Employees"
          labelStyle="mb-2 pt-1"
          containerStyle="w-100"
          rootStyle="w-50 d-inline-block pr-2"
          placeholder="No. of Employees"
          validateBy="textRequired"
          icon="employees"
          validationName="users"
        />
        <InputForm
          {...FormProps}
          disabled
          name="ratePerPeriod"
          label="Rate Per Period"
          labelStyle="mb-2 pt-1"
          containerStyle="w-100"
          rootStyle="w-50 d-inline-block"
          validateBy="textRequired"
          icon="calculator"
        />
        <DateTimePickerForm
          {...FormProps}
          name="startDate"
          label="Start Date"
          labelStyle="mb-2 pt-1"
          containerStyle="w-100"
          rootStyle="w-50 d-inline-block pr-2"
          validateBy="textRequired"
          hasIcon
          isDisabled={!formData.plan && !formData.contractDuration}
          validationName="from"
        />
        <DateTimePickerForm
          {...FormProps}
          name="endDate"
          label="End Date"
          labelStyle="mb-2 pt-1"
          containerStyle="w-100"
          rootStyle="w-50 d-inline-block"
          validateBy="textRequired"
          disabled
          hasIcon
        />
        <BSelect
          {...FormProps}
          label="agent"
          name="agent"
          icon="employee"
          isClearable
          isLoading={loading}
          options={options?.agents}
        />
        <CheckboxBooleanForm
          {...FormProps}
          name="prorateAmount"
          containerStyle="mt-2"
          options={["Prorate the amount of the first month"]}
          disabled
        />
      </div>

      {!data?.isSubscriptionProfile ? (
        <div style={{ borderRadius: 4 }} className="border px-2 py-3 mt-4">
          <CheckboxBooleanForm
            {...FormProps}
            name="eInvoice"
            options={["E-invoices"]}
          />
        </div>
      ) : null}

      {/* Fees */}
      <div style={{ borderRadius: 4 }} className="border px-2 py-3 mt-4">
        <div className="d-flex gap-20 align-items-center">
          <CheckboxBooleanForm
            {...FormProps}
            name="applyFees"
            options={["Apply Fees"]}
            onInterceptInputOnChange={generateFees}
            disabled={!Boolean(+formData?.contractDuration)}
            hasTooltip={!Boolean(+formData?.contractDuration)}
            tooltipTitle="choose a plan and enter your contract duration to apply fees"
          />
          {formData?.applyFees ? (
            <AddButton isIconOnly onClick={handleAddFee} />
          ) : null}
        </div>
        {formData?.applyFees ? (
          <>
            {formData?.fees?.map((fee, i) => (
              <div key={i} className="d-flex gap-20 align-items-end mb-2">
                <DateTimePickerForm
                  hasIcon
                  value={fee?.from || null}
                  name="from"
                  containerStyle="w-100"
                  rootStyle="flex-1"
                  onChange={(date) => handleFeeFromDateChange(i, date)}
                />
                <InputForm
                  name="amount"
                  placeholder="amount"
                  labelStyle="mb-2 pt-1"
                  containerStyle="w-100"
                  inputStyle="w-100 text-left"
                  inputContainerStyle="w-100"
                  value={fee?.amount}
                  rootStyle="flex-1"
                  onChange={(e) => handleFeeAmountChange(i, e.target.value)}
                />
                <TimesIconButton onClick={() => handleRemoveFee(i)} />
              </div>
            ))}
          </>
        ) : null}
      </div>

      {/* Promotions */}
      <div style={{ borderRadius: 4 }} className="border px-2 py-3 mt-4">
        <CheckboxBooleanForm
          {...FormProps}
          name="applyPromotion"
          options={["Apply Promotion"]}
          disabled={!Boolean(+formData?.contractDuration)}
          onInterceptInputOnChange={generatePromotions}
          hasTooltip={!Boolean(+formData?.contractDuration)}
          tooltipTitle="choose a plan and enter your contract duration to apply promotions"
        />

        {formData?.applyPromotion && formData?.applyPromotionAll ? (
          <>
            <BSelect
              label="Promotion Type"
              icon="document"
              placeholder="Select Promotion Type"
              rootStyle="w-50 d-inline-block pr-2 mt-2"
              options={promotionTypeOptions}
              value={
                formData?.promotions?.[0]?.type
                  ? formatOption(formData?.promotions?.[0]?.type)
                  : null
              }
              onChange={(val) => handlePromotionTypeChange(0, val.id)}
            />
            <InputForm
              name="amount"
              label={
                formData?.promotions?.[0]?.type === "Percentage"
                  ? "Percent"
                  : "Amount"
              }
              labelStyle="mb-2 pt-1"
              containerStyle="w-100"
              inputStyle="w-100 text-left"
              inputContainerStyle="w-100"
              rootStyle="w-50 d-inline-block mt-2"
              placeholder={
                formData?.promotions?.[0]?.type === "Percentage"
                  ? "Enter percentage"
                  : "Enter amount"
              }
              hasSuffix
              suffixTitle={
                formData?.promotions?.[0]?.type === "Percentage"
                  ? "%"
                  : selectedPlanCurrency
              }
              value={formData?.promotions?.[0]?.amount}
              onChange={(e) => handlePromotionAmountChange(0, e.target.value)}
            />
          </>
        ) : null}
        {formData?.promotions?.length > 1 ? (
          <CheckboxBooleanForm
            {...FormProps}
            name="applyPromotionAll"
            options={["Apply Promotion to all invoices"]}
            onInterceptInputOnChange={generatePromotions}
            dependOn="applyPromotion"
            dependancyType="equal"
            dependancyValue={[1]}
          />
        ) : null}

        {formData?.applyPromotionAll ? null : (
          <div className="mt-3">
            {formData?.promotions?.map((prom, idx) => (
              <div key={idx} className="d-flex align-items-center gap-10 mb-1">
                <span style={{ minWidth: 70 }}>Invoice #{idx + 1}</span>
                <BSelect
                  icon="document"
                  placeholder="Select Promotion Type"
                  rootStyle="flex-1"
                  options={promotionTypeOptions}
                  value={prom?.type ? formatOption(prom?.type) : null}
                  onChange={(val) => handlePromotionTypeChange(idx, val.id)}
                />
                <InputForm
                  name="amount"
                  labelStyle="mb-2 pt-1"
                  containerStyle="w-100"
                  inputStyle="w-100 text-left"
                  inputContainerStyle="w-100"
                  rootStyle="flex-1"
                  placeholder={
                    prom?.type === "Percentage"
                      ? "Enter percentage"
                      : "Enter amount"
                  }
                  hasSuffix
                  suffixTitle={
                    prom?.type === "Percentage" ? "%" : selectedPlanCurrency
                  }
                  value={prom?.amount}
                  onChange={(e) =>
                    handlePromotionAmountChange(idx, e.target.value)
                  }
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default SubscriptionForm;
