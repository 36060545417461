import React from "react";

const PrivacyPolicyPage = () => {
  return (
    <div className="container">
      <div class="markdown prose w-full break-words dark:prose-invert dark">
        <p>
          <p style={{ fontSize: "15px" }}>
            <strong>
              <span style={{ fontSize: "26px" }}>
                <span data-custom-class="title">
                  <bdt className="block-component" />
                  PRIVACY POLICY
                  <bdt className="else-block" />
                </span>
              </span>
            </strong>
          </p>
        </p>
        <p>
          <em>Last Updated: March 08, 2024</em>
        </p>
        <p>
          Thank you for choosing to be part of our community at Mawared HR
          (“Company,” “We,” “Us,” or “Our”). Your privacy is important to us,
          and we are committed to protecting your personal information. If you
          have any questions or concerns about our privacy practices, please
          contact us at <a target="_new">info@mawaredhr.com</a>.
        </p>
        <p>
          <strong>1. WHAT INFORMATION DO WE COLLECT?</strong>
        </p>
        <p>
          <strong>Information Automatically Collected:</strong>
          When you visit our mobile application, we automatically collect
          certain information, such as IP address, browser and device
          characteristics, operating system, language preferences, referring
          URLs, device name, country, location, and other technical information.
          This information is primarily used to maintain the security and
          operation of our apps and for internal analytics.
        </p>
        <p>
          <strong>Information Collected Through Our Apps:</strong>
          If you use our apps, we may collect geo-location information, mobile
          device data (such as device ID, model, manufacturer), and may request
          to send you push notifications regarding your account or the mobile
          application.
        </p>
        <p>
          <strong>2. HOW DO WE USE YOUR INFORMATION?</strong>
        </p>
        <p>
          We process your information for purposes based on legitimate business
          interests, the fulfillment of our contract with you, compliance with
          our legal obligations, and/or your consent. We use the information we
          collect for enforcing terms, responding to legal requests, managing
          user accounts, and delivering services to users.
        </p>
        <p>
          <strong>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</strong>
        </p>
        <p>
          We only share information with your consent, to comply with laws, to
          provide you with services, to protect your rights, or to fulfill
          business obligations. This may include sharing data with vendors,
          consultants, third-party service providers, and in the event of
          business transfers.
        </p>
        <p>
          <strong>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong>
        </p>
        <p>
          We may use cookies and similar tracking technologies to collect and
          store your information. For detailed information, refer to our Cookie
          Policy.
        </p>
        <p>
          <strong>5. DO WE USE GOOGLE MAPS?</strong>
        </p>
        <p>
          Yes, we use Google Maps to provide better service. By using our Maps
          API implementation, you agree to be bound by Google’s Terms of
          Service.
        </p>
        <p>
          <strong>6. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
        </p>
        <p>
          We keep your information for as long as necessary to fulfill the
          purposes outlined in this privacy notice, unless otherwise required by
          law.
        </p>
        <p>
          <strong>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
        </p>
        <p>
          We aim to protect your personal information through a system of
          organizational and technical security measures. While we strive to
          protect your data, the transmission of personal information to and
          from our apps is at your own risk.
        </p>
        <p>
          <strong>8. DO WE COLLECT INFORMATION FROM MINORS?</strong>
        </p>
        <p>
          We do not knowingly collect data from or market to children under 18
          years of age.
        </p>
        <p>
          <strong>9. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
        </p>
        <p>
          You may review, change, or terminate your account at any time. If you
          are resident in the European Economic Area, you have the right to
          complain to your local data protection supervisory authority.
        </p>
        <p>
          <strong>10. CONTACT US</strong>
        </p>
        <p>
          If you have questions or comments about this policy, you may email us
          at <a target="_new">info@mawaredhr.com</a> or by post to:
        </p>
        <p>Mawared HR</p>
        <p>
          {" "}
          <bdt className="question">info@mawaredhr.com</bdt>.
        </p>
      </div>

      <div style={{ padding: "16px 0 20px", background: "transparent" }}>
        <h1
          style={{
            fontFamily: "Arial",
            fontSize: "19px",
            color: "#000000",
            textTransform: "uppercase",
          }}
        >
          How can you review, update, or delete the data we collect from you?
        </h1>
        <div
          style={{
            color: "#595959",
            fontSize: "14px",
            fontFamily: "Arial",
            marginTop: "18px",
            lineHeight: "1.2",
          }}
        >
          Based on the laws of some countries, you may have the right to request
          access to the personal information we collect from you, change that
          information, or delete it in some circumstances. To request to review,
          update, or delete your personal information, please submit a request
          form by clicking{" "}
          <a
            style={{ color: "rgb(48, 48, 241) !important" }}
            href="https://app.termly.io/notify/f2117719-e930-49aa-ad2c-2742c52bff4e"
            target="_blank"
          >
            here
          </a>
          . We will respond to your request within 30 days.
        </div>
      </div>
      {/* <div
        style={{
          color: "#595959",
          fontSize: "14px",
          fontFamily: "Arial",
          paddingTop: "16px",
          display: "none",
        }}
      >
        This privacy policy was created using{" "}
        <a
          style={{ color: "rgb(48, 48, 241) !important" }}
          href="https://termly.io/products/privacy-policy-generator/?ftseo"
        >
          Termly’s Privacy Policy Generator
        </a>
        .
      </div> */}
    </div>
  );
};

export default PrivacyPolicyPage;
