import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MainModal from "../../../Components/MainModal";
import { useMutation } from "@apollo/client";
import { DELETE_ITEM } from "../../../Graphql/mutation";
import {
  toggleAssetUnitsModal,
  toggleUnscrappItemModal,
  toggleScrappItemModal,
  toggleAssignRetriveAssetModal,
  toggleExchangeItemModal,
  toggleRetriveAssetModal,
  onFormResetAction,
  toggleScrapNonSerializedAssets,
} from "../../../Store/Actions";
import HelperFns, { showToast } from "../../../Helpers/HelperFns";
import { Tab, Tabs, IconButton } from "@mui/material";
import DataTable from "react-data-table-component";
import ReactShowMoreText from "react-show-more-text";
import IconButtonWithTooltip, {
  RemoveIconButton,
} from "../../../Components/IconButtonWithTooltip";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import RepeatIcon from "@mui/icons-material/Repeat";
import swal from "sweetalert";
import Loader from "../../../Components/Loader";
import _ from "lodash";
import Privilages from "../../../Constants/Privilages";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";

// handle expandable row for assigned serliazed data component
const ExpandedItems = ({ data }) => {
  const dispatch = useDispatch();

  // handle dismiss modal function
  const handleDismissModal = () => {
    dispatch(toggleAssetUnitsModal({ isOpen: false, assetUnits: null }));
  };

  // handle transfer items between employees function
  const handleTransferItemToAnotherEmployee = (item) => {
    // dismiss modal
    handleDismissModal();

    dispatch(toggleExchangeItemModal({ isOpen: true, itemId: item?.id }));
  };

  // handle retrieve asset item function
  const handleUnAssignItem = (item) => {
    // dismiss modal
    handleDismissModal();

    dispatch(toggleRetriveAssetModal({ isOpen: true, itemId: item?.id }));
  };

  return (
    <div className="d-flex align-items-center flex-wrap shadow-sm p-3">
      {data?.items?.map((item) => {
        return (
          <div
            className="w-50 my-1 d-flex align-items-center justify-content-between px-3 text-break"
            key={item?.id}
          >
            <p className="m-0">{item?.serial_number}</p>

            <div className="d-flex align-items-center gap-5">
              <HasPrivileges
                allowBP
                reqireMain={[Privilages.TRANSFER_ASSIGNED_ASSET]}
              >
                <IconButtonWithTooltip
                  label="transfer"
                  icon={<RepeatIcon sx={{ fontSize: 20, color: "#27b40c" }} />}
                  onClick={() => handleTransferItemToAnotherEmployee(item)}
                />
              </HasPrivileges>

              <HasPrivileges
                allowBP
                reqireMain={[Privilages.ASSIGN_RETRIEVE_ASSET_UNITS]}
              >
                <IconButtonWithTooltip
                  label="retrieve"
                  icon={
                    <SettingsBackupRestoreIcon
                      sx={{ fontSize: 20, color: "#009efb" }}
                    />
                  }
                  onClick={() => handleUnAssignItem(item)}
                />
              </HasPrivileges>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const AssetUnitsModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local useStates
  const [tabValue, setTabValue] = useState("assigned");
  const [newAssignedItemsArray, setNewAssignedItemsArray] = useState([]);
  const [newInStorageItemsArray, setNewInStorageItemsArray] = useState([]);
  const [expandedSerialNumbers, setExpandedSerialNumbers] = useState([]);

  useEffect(() => {
    if (
      HelperFns.checkPrivileges({
        allowBP: true,
        privileges: [Privilages.VIEW_ASSIGNED_ASSETS],
      })
    ) {
      setTabValue("assigned");
      return;
    }
    if (
      HelperFns.checkPrivileges({
        allowBP: true,
        privileges: [Privilages.VIEW_STORED_ASSET_UNITE],
      })
    ) {
      setTabValue("inStorage");
      return;
    }
    if (
      HelperFns.checkPrivileges({
        allowBP: true,
        privileges: [Privilages.VIEW_SCRAPPED_ASSETS],
      })
    ) {
      setTabValue("scrapped");
      return;
    }
  }, []);

  // Redux States
  const assetUnitsModal = useSelector(
    (state) => state?.assets?.assetUnitsModal
  );

  // Start of handle delete unit mutation
  const [attemptDeleteItem, { loading: deleteItemLoading }] = useMutation(
    DELETE_ITEM,
    {
      onCompleted: (data) => {
        if (data?.deleteItem?.__typename == "GeneralException") {
          // error toaster
          showToast("error", data?.deleteItem?.message);
          return;
        }
        // success toaster
        showToast("success");

        // dismiss modal
        handleDismissModal();

        // handle refetch data list
        props?.handleRefetch();
      },
      onError(err) {
        // error toaster
        showToast(
          "error",
          err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
        );
      },
    }
  );
  // End of handle delete unit mutation

  // use to remove duplicate elements from the array
  useEffect(() => {
    if (!!assetUnitsModal?.assetUnits) {
      // Start handle of assigned items new array
      let assignedItemsArray = assetUnitsModal?.assetUnits?.items
        ?.filter((obj) => obj?.status === "assigned")
        .map((obj) => ({
          ...obj?.assignOrder?.employee?.user,
          items: assetUnitsModal?.assetUnits?.items?.filter(
            (el) =>
              obj?.assignOrder?.employee?.user?.id ===
              el?.assignOrder?.employee?.user?.id
          ),
          itemsCount: assetUnitsModal?.assetUnits?.items?.filter(
            (el) =>
              obj?.assignOrder?.employee?.user?.id ===
              el?.assignOrder?.employee?.user?.id
          ).length,
        }));

      let uniqueAssignedItemsByEmployee = _.uniqBy(assignedItemsArray, "id");

      setNewAssignedItemsArray(uniqueAssignedItemsByEmployee);
      // End handle of assigned items new array

      if (assetUnitsModal?.assetUnits?.product_type === "non_serialized") {
        // Start handle of in storage items new array
        let inStorageItemsArray = assetUnitsModal?.assetUnits?.items
          ?.filter((obj) => obj?.status === "in_storage")
          .map((obj) => ({
            ...obj?.warehouse,
            items: assetUnitsModal?.assetUnits?.items,
            itemsCount: assetUnitsModal?.assetUnits?.items?.filter(
              (el) => obj?.warehouse?.id === el?.warehouse?.id
            )?.length,
          }));

        let uniqueInStorageItemsByWarehouse = _.uniqBy(
          inStorageItemsArray,
          "id"
        );

        setNewInStorageItemsArray(uniqueInStorageItemsByWarehouse);
        // End handle of in storage items new array
      } else {
        // Start handle of in storage items new array
        setNewInStorageItemsArray(
          assetUnitsModal?.assetUnits?.items?.filter(
            (obj) => obj?.status === "in_storage"
          )
        );
        // End handle of in storage items new array
      }
    }
  }, []);

  // tabs values
  const assetUnitsTabs = [
    {
      label: `Assigned ${assetUnitsModal?.assetUnits?.assigned_items_count}`,
      value: "assigned",
      privilages: [Privilages.VIEW_ASSIGNED_ASSETS],
    },
    {
      label: `In Storage ${assetUnitsModal?.assetUnits?.in_storage_items_count}`,
      value: "inStorage",
      privilages: [Privilages.VIEW_STORED_ASSET_UNITE],
    },
    {
      label: `Scrapped ${assetUnitsModal?.assetUnits?.scrapped_items_count}`,
      value: "scrapped",
      privilages: [Privilages.VIEW_SCRAPPED_ASSETS],
    },
  ];

  // Assigned data table columns
  const assignedUnitsColumns =
    assetUnitsModal?.assetUnits?.product_type === "non_serialized"
      ? [
          {
            name: t("employee"),
            wrap: true,
            grow: 1,
            cell: (row) => <div>{row?.name}</div>,
          },
          {
            name: t("No. of units"),
            wrap: true,
            grow: 1,
            cell: (row) => <div>{row?.itemsCount}</div>,
          },
          {
            width: "100px",
            cell: (row) => (
              <div className="d-flex align-items-center gap-5">
                <HasPrivileges
                  allowBP
                  reqireMain={[Privilages.TRANSFER_ASSIGNED_ASSET]}
                >
                  <IconButtonWithTooltip
                    label="transfer"
                    icon={
                      <RepeatIcon sx={{ fontSize: 20, color: "#27b40c" }} />
                    }
                    onClick={() =>
                      handleTransferItemToAnotherEmployee(row?.items[0])
                    }
                  />
                </HasPrivileges>

                <HasPrivileges
                  allowBP
                  reqireMain={[Privilages.ASSIGN_RETRIEVE_ASSET_UNITS]}
                >
                  <IconButtonWithTooltip
                    label="retrieve"
                    icon={
                      <SettingsBackupRestoreIcon
                        sx={{ fontSize: 20, color: "#009efb" }}
                      />
                    }
                    onClick={() => handleUnAssignItem(row?.items[0])}
                  />
                </HasPrivileges>
              </div>
            ),
          },
        ]
      : [
          {
            name: t("employee"),
            wrap: true,
            grow: 1,
            cell: (row) => <div>{row?.name}</div>,
          },
          {
            name: t("No. of serial numbers"),
            wrap: true,
            grow: 1,
            cell: (row) => <div>{row?.itemsCount}</div>,
          },
          {
            wrap: true,
            grow: 0.5,
            cell: (row) => (
              <div className="d-flex align-items-center">
                <IconButton
                  style={{ fontSize: 14, borderRadius: 4 }}
                  onClick={() => handleViewSerialNmbers(row?.id)}
                >
                  {t("view serials")}
                </IconButton>
              </div>
            ),
          },
        ];

  // in storage units data table columns
  const inStorageUnitsColumns =
    assetUnitsModal?.assetUnits?.product_type === "non_serialized"
      ? [
          {
            name: t("storage location"),
            wrap: true,
            grow: 1,
            cell: (row) => <div>{row?.name}</div>,
          },
          {
            name: t("No. of units"),
            wrap: true,
            grow: 1,
            cell: (row) => <div>{row?.itemsCount}</div>,
          },
          {
            wrap: true,
            grow: 0.8,
            cell: (row) => (
              <div className="d-flex align-items-center gap-5">
                <HasPrivileges
                  allowBP
                  reqireMain={[Privilages.ASSIGN_RETRIEVE_ASSET_UNITS]}
                >
                  <IconButtonWithTooltip
                    label="assign"
                    icon={
                      <PersonAddIcon sx={{ fontSize: 19, color: "#27b40c" }} />
                    }
                    onClick={() => handleAssignAssetItem(row)}
                  />
                </HasPrivileges>

                <HasPrivileges
                  allowBP
                  reqireMain={[Privilages.SCRAP_RESTORE_ASSET_UNITS]}
                >
                  <IconButtonWithTooltip
                    label="deprecate device"
                    icon={
                      <RemoveCircleIcon
                        sx={{ fontSize: 19, color: "#e64942" }}
                      />
                    }
                    onClick={() => handleScrapItem(row?.items[0])}
                  />
                </HasPrivileges>

                <HasPrivileges
                  allowBP
                  reqireMain={[Privilages.DELETE_ASSET_UNITES]}
                >
                  <RemoveIconButton
                    onClick={() => handleDeleteItem(row?.items[0])}
                  />
                </HasPrivileges>
              </div>
            ),
          },
        ]
      : [
          {
            name: t("serial number"),
            wrap: true,
            grow: 1,
            cell: (row) => <div>{row?.serial_number}</div>,
          },
          {
            name: t("storage location"),
            wrap: true,
            grow: 1,
            cell: (row) => <div>{row?.warehouse?.name}</div>,
          },
          {
            wrap: true,
            grow: 0.8,
            cell: (row) => (
              <div className="d-flex align-items-center gap-5">
                <HasPrivileges
                  allowBP
                  reqireMain={[Privilages.ASSIGN_RETRIEVE_ASSET_UNITS]}
                >
                  <IconButtonWithTooltip
                    label="assign"
                    icon={
                      <PersonAddIcon sx={{ fontSize: 19, color: "#27b40c" }} />
                    }
                    onClick={() => handleAssignAssetItem(row)}
                  />
                </HasPrivileges>

                <HasPrivileges
                  allowBP
                  reqireMain={[Privilages.SCRAP_RESTORE_ASSET_UNITS]}
                >
                  <IconButtonWithTooltip
                    label="deprecate device"
                    icon={
                      <RemoveCircleIcon
                        sx={{ fontSize: 19, color: "#e64942" }}
                      />
                    }
                    onClick={() => handleScrapItem(row)}
                  />
                </HasPrivileges>

                <HasPrivileges
                  allowBP
                  reqireMain={[Privilages.DELETE_ASSET_UNITES]}
                >
                  <RemoveIconButton onClick={() => handleDeleteItem(row)} />
                </HasPrivileges>
              </div>
            ),
          },
        ];

  // scrapped units data table columns
  const scrappedUnitsColumns =
    assetUnitsModal?.assetUnits?.product_type === "non_serialized"
      ? [
          {
            name: t("notes"),
            wrap: true,
            grow: 1.8,
            cell: (row) => (
              <ReactShowMoreText lines={1}>
                {row?.note ?? "------------------"}
              </ReactShowMoreText>
            ),
          },
          {
            width: "100px",
            cell: (row) => (
              <div className="d-flex align-items-center gap-5">
                <HasPrivileges
                  allowBP
                  reqireMain={[Privilages.SCRAP_RESTORE_ASSET_UNITS]}
                >
                  <IconButtonWithTooltip
                    label="unscrap device"
                    icon={
                      <CheckCircleIcon
                        sx={{ fontSize: 19, color: "#27b40c" }}
                      />
                    }
                    onClick={() => handleUnscrapp(row)}
                  />
                </HasPrivileges>

                <HasPrivileges
                  allowBP
                  reqireMain={[Privilages.DELETE_ASSET_UNITES]}
                >
                  <RemoveIconButton onClick={() => handleDeleteItem(row)} />
                </HasPrivileges>
              </div>
            ),
          },
        ]
      : [
          {
            name: t("serial number"),
            wrap: true,
            grow: 1,
            cell: (row) => <div>{row?.serial_number}</div>,
          },
          {
            name: t("notes"),
            wrap: true,
            grow: 1.8,
            cell: (row) => (
              <ReactShowMoreText lines={1}>
                {row?.note ?? "------------------"}
              </ReactShowMoreText>
            ),
          },
          {
            width: "100px",
            cell: (row) => (
              <div className="d-flex align-items-center gap-5">
                <HasPrivileges
                  allowBP
                  reqireMain={[Privilages.SCRAP_RESTORE_ASSET_UNITS]}
                >
                  <IconButtonWithTooltip
                    label="unscrap device"
                    icon={
                      <CheckCircleIcon
                        sx={{ fontSize: 19, color: "#27b40c" }}
                      />
                    }
                    onClick={() => handleUnscrapp(row)}
                  />
                </HasPrivileges>

                <HasPrivileges
                  allowBP
                  reqireMain={[Privilages.DELETE_ASSET_UNITES]}
                >
                  <RemoveIconButton onClick={() => handleDeleteItem(row)} />
                </HasPrivileges>
              </div>
            ),
          },
        ];

  /* ↓ Helpers ↓ */

  // handle toggle between tabs function
  const handleToggleTabs = (event, value) => {
    setTabValue(value);
  };

  // handle scrap item function
  const handleScrapItem = (item) => {
    // dismiss modal
    handleDismissModal();

    let modifiedScrappItemData = {
      ...item,
      assetName: assetUnitsModal?.assetUnits?.name,
      assetType: assetUnitsModal?.assetUnits?.assetType?.name,
      assetBrand: assetUnitsModal?.assetUnits?.brand,
      assetModel: assetUnitsModal?.assetUnits?.model,
    };

    dispatch(
      toggleScrappItemModal({ isOpen: true, item: modifiedScrappItemData })
    );
  };

  const handleScrapNonSerializedAssets = (items) => {
    dispatch(
      toggleScrapNonSerializedAssets({
        isOpen: true,
        items,
      })
    );
  };

  // handle unscrap item function
  const handleUnscrapp = (item) => {
    // dismiss modal
    handleDismissModal();

    dispatch(toggleUnscrappItemModal({ isOpen: true, item }));
  };

  // handle assign asset item function
  const handleAssignAssetItem = (item) => {
    // dismiss modal
    handleDismissModal();

    let modifiedAssignItemData = {
      cameFrom: "cameFromAssetAssignUnitsModal",
      warehouseId: item?.warehouse?.id,
      assetName: assetUnitsModal?.assetUnits?.name,
      assetType: assetUnitsModal?.assetUnits?.assetType?.name,
      assetBrand: assetUnitsModal?.assetUnits?.brand,
      assetModel: assetUnitsModal?.assetUnits?.model,
      assetId: assetUnitsModal?.assetUnits?.id,
      items:
        assetUnitsModal?.assetUnits?.product_type === "non_serialized"
          ? [
              {
                asset_id: assetUnitsModal?.assetUnits?.id,
                id: item?.items?.[0]?.id,
                product_type: assetUnitsModal?.assetUnits?.product_type,
                number_of_items: 1,
              },
            ]
          : [
              {
                asset_id: assetUnitsModal?.assetUnits?.id,
                id: [item?.id],
                product_type: assetUnitsModal?.assetUnits?.product_type,
              },
            ],
    };

    dispatch(
      toggleAssignRetriveAssetModal({
        isOpen: true,
        handleRefetch: props?.handleRefetch,
      })
    );
    dispatch(
      onFormResetAction("assignRetriveAssetForm", { ...modifiedAssignItemData })
    );
  };

  // handle view serial numbers for each employee in serliazed case function
  const handleViewSerialNmbers = (itemId) => {
    setExpandedSerialNumbers((itemIds) => {
      if (itemIds?.includes(itemId)) {
        return itemIds?.filter((id) => id != itemId);
      } else {
        return [...itemIds, itemId];
      }
    });
  };

  // handle retrieve asset item function
  const handleUnAssignItem = (item) => {
    // dismiss modal
    handleDismissModal();

    dispatch(toggleRetriveAssetModal({ isOpen: true, itemId: item?.id }));
  };

  // handle delete item function function
  const handleDeleteItem = (item) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        attemptDeleteItem({
          variables: {
            id: item?.id,
          },
        });
      }
    });
  };

  // handle transfer items between employees function
  const handleTransferItemToAnotherEmployee = (item) => {
    // dismiss modal
    handleDismissModal();

    dispatch(toggleExchangeItemModal({ isOpen: true, itemId: item?.id }));
  };

  // handle dismiss modal function
  const handleDismissModal = () => {
    dispatch(toggleAssetUnitsModal({ isOpen: false, assetUnits: null }));
  };

  return (
    <MainModal
      isOpen={assetUnitsModal?.isOpen}
      toggle={handleDismissModal}
      modalTitle={t("Asset Units")}
      className="asset_units_modal_wrapper_style"
    >
      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-baseline">
            <div className="asset_name_style">
              {assetUnitsModal?.assetUnits?.name}
            </div>

            <div className="asset_type_model_brand_style">
              {assetUnitsModal?.assetUnits?.assetType?.name} -{" "}
              {assetUnitsModal?.assetUnits?.brand}{" "}
              {assetUnitsModal?.assetUnits?.model}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="justify-content-start align-items-center">
            <Tabs
              indicatorColor="primary"
              className="tab_border_bottom_style"
              value={tabValue}
              onChange={handleToggleTabs}
              centered
              variant="fullWidth"
            >
              {assetUnitsTabs.map((opt) =>
                HelperFns.checkPrivileges({
                  allowBP: true,
                  privileges: opt?.privilages,
                }) ? (
                  <Tab
                    key={opt?.label}
                    label={t(opt?.label?.toLowerCase())}
                    value={opt?.value}
                  />
                ) : null
              )}
            </Tabs>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          {tabValue === "assigned" && (
            <DataTable
              data={newAssignedItemsArray}
              columns={assignedUnitsColumns}
              className="cards_table my-3"
              noHeader
              persistTableHead
              sortServer
              expandableRows
              expandableRowsComponent={<ExpandedItems />}
              expandableRowDisabled={() => true}
              expandableRowExpanded={(row) =>
                expandedSerialNumbers?.includes(row?.id)
              }
              expandableRowsHideExpander
            />
          )}

          {tabValue === "inStorage" && (
            <DataTable
              data={newInStorageItemsArray}
              columns={inStorageUnitsColumns}
              className="cards_table my-3"
              noHeader
              persistTableHead
              sortServer
              progressPending={deleteItemLoading}
              progressComponent={<Loader />}
            />
          )}

          {tabValue === "scrapped" && (
            <DataTable
              data={
                assetUnitsModal?.assetUnits?.items?.filter(
                  (obj) => obj?.status === "scrapped"
                ) ?? []
              }
              columns={scrappedUnitsColumns}
              className="cards_table my-3"
              noHeader
              persistTableHead
              sortServer
            />
          )}
        </div>
      </div>
    </MainModal>
  );
};

export default AssetUnitsModal;
