import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BSelect, CheckboxBooleanForm, InputForm } from "form-builder";
import Constants from "../../../Constants";

// Icons
import WorkIcon from "@mui/icons-material/Work";

const WorkRemotelySection = ({
  FormProps,
  isEmployeeForm = false,
  selectedAttProfile = {},
  isFlex = false,
}) => {
   
  const { t } = useTranslation();

  const can_work_home = useSelector(
    (state) => state?.[FormProps?.reducer]?.[FormProps?.formName]?.can_work_home
  );
  const attendanceType = useSelector(
    (state) =>
      state?.[FormProps?.reducer]?.[FormProps?.formName]?.attendanceType
  );
  const flexible_home = useSelector(
    (state) => state?.[FormProps?.reducer]?.[FormProps?.formName]?.flexible_home
  );

  const filteredDaysOptions = Constants.WeekendDays?.filter((dayOpt) =>
    selectedAttProfile?.workdays?.includes(dayOpt?.value)
  );

  return (
    <div className={isEmployeeForm ? "boxContainer" : ""}>
      {isEmployeeForm ? (
        <>
          <div className="name_container">
            <WorkIcon />
            <h4
              className={
                isEmployeeForm ? "sub-title-style mb-0" : "secondary_title my-3"
              }
            >
              {t("work from home")}
            </h4>
          </div>
          <hr />
        </>
      ) : null}
      <div className="mb-2">
        <CheckboxBooleanForm
          {...FormProps}
          name="can_work_home"
          options={[t("Allow Employee To Work From Home")]}
          optionLabelStyle={isEmployeeForm ? "content-header-sub-label" : ""}
          validationName="input.user_input.can_work_home"
          type="checkbox"
        />

        <CheckboxBooleanForm
          {...FormProps}
          options={[
            "Employee must submit work remotely request before the start of work day",
          ]}
          name="allow_work_remotely_cutoff"
          validationName={`input.user_input.allow_work_remotely_cutoff`}
          type="checkbox"
          containerStyle="leaves_checkbox_container mb-2"
          dependOn="can_work_home"
          dependancyType="equal"
          dependancyValue={[1]}
          setWithValue
        />

        <InputForm
          {...FormProps}
          validateBy="textRequired"
          label="Employee must submit work remotely request before the start of work day by"
          name="work_remotely_cutoff"
          validationName={`input.user_input.work_remotely_cutoff`}
          type="text"
          hasSuffix
          suffixTitle="Hours"
          containerStyle="leaves_field_container my-2 d-flex justify-content-between"
          inputContainerStyle="d-flex align-items-center"
          dependOn="allow_work_remotely_cutoff"
          dependancyType="equal"
          dependancyValue={[true]}
          labelStyle="text-13px"
        />

        <CheckboxBooleanForm
          {...FormProps}
          name="employee_can_request"
          options={[t("Allow Employee To Request Work From Home")]}
          optionLabelStyle={isEmployeeForm ? "content-header-sub-label" : ""}
          validationName="input.user_input.employee_can_request"
          dependOn="can_work_home"
          dependancyType="equal"
          dependancyValue={[1]}
          setWithValue
        />

        <div className="sub-container-style mt-2">
          <InputForm
            {...FormProps}
            label={t("Max. Number of requests per week")}
            name="max_homeDays_per_week"
            placeholder="Max. Number of requests per week"
            dependOn="can_work_home"
            dependancyType="equal"
            dependancyValue={[1]}
            validateBy="week_day"
            stepInput
            labelStyle=""
            inputContainerStyle="input-container-style-default max-days-style"
            validationName="input.user_input.max_homeDays_per_week"
            type="number"
          />

          {can_work_home == 1 &&
          attendanceType === Constants.attendanceTypes.OFFICE_BASED &&
          !isFlex ? (
            <>
              <CheckboxBooleanForm
                {...FormProps}
                name="flexible_home"
                options={[t("Allow Flexible Work From Home Days")]}
                optionLabelStyle="content-header-sub-label"
                dependOn="can_work_home"
                dependancyType="equal"
                dependancyValue={[1]}
                containerStyle="mt-2"
                validationName="input.user_input.flexible_home"
              />

              <div className={+flexible_home ? "d-none" : ""}>
                <BSelect
                  {...FormProps}
                  label={t("Default Work From Home Days")}
                  name="home_days"
                  keepDefaultStyle
                  placeholder={t("select option")}
                  dependOn="flexible_home"
                  dependancyType="equal"
                  dependancyValue={[0]}
                  isMulti
                  hideSelectedOptions
                  optionLabel="label"
                  optionValue="value"
                  options={filteredDaysOptions ?? []}
                  getOptionLabel={(option) => t(option.label)}
                  validateBy={+flexible_home ? false : "arrayRequired"}
                  containerStyle="row justify-content-between align-items-center my-3"
                  labelStyle="col-12 mb-2"
                  inputContainerStyle="col-12"
                  validationName="input.user_input.home_days"
                  icon={<WorkIcon />}
                />

                <CheckboxBooleanForm
                  {...FormProps}
                  name="can_ex_days"
                  options={[t("Allow Exchanging Work From Home Days")]}
                  optionLabelStyle="content-header-sub-label"
                  dependOn="flexible_home"
                  dependancyType="equal"
                  dependancyValue={[0]}
                  containerStyle=" "
                  validationName="input.user_input.can_ex_days"
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default WorkRemotelySection;
