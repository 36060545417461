import React, { forwardRef, useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import moment from "moment";
import { statusButtons } from "./TimesheetSummary";
import { Button, CircularProgress, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import Loader from "../../Components/Loader";
import { CheckboxBooleanForm } from "form-builder";
import CanViewEmployeeProfile from "../../Helpers/HOC/CanViewEmployeeProfile";
import { Link } from "react-router-dom";
import { TimeSheetUpsertModal } from "../../Components/FlexibleModals";
import {
  GET_CLOCKIN_AND_LOCATIONS,
  GET_TIMESHEET_SUMMARY_FOR_EMPLOYEE,
} from "../../Graphql/query";
import {
  getSumOfDurations,
  normalizedFetchedContainerForEditing,
  openSwalConfirm,
  serializeFetchedClockin,
} from "../../Helpers/HelperFns";
import {
  onFormResetAction,
  showErrorToast,
  showSuccessToast,
  toggleEditClockinsContainer,
} from "../../Store/Actions";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Add,
  ArrowDownward,
  Check,
  Close,
  LocationOn,
} from "@mui/icons-material";
import {
  ACCEPT_OR_REJECT_TIMESHEET_PER_ONE_EMPLOYEE,
  DELETE_CLOCKIN,
} from "../../Graphql/mutation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import EditClockinsContainerModal from "../../Components/FlexibleModals/EditClockinsContainerModal";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import Swal from "sweetalert2";

const formName = "timeSheetUpsertModal";

const LEAVE_TYPES = {
  1: "normal leaves",
  6: "sick leaves",
  7: "unpaid leaves",
};

const TimeSheetSummaryPerEmployee = ({
  modal,
  handleCloseModal = () => {},
  summaryType,
  selectedWeek,
  refetch = () => {},
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isOpen, data } = modal ?? {};

  const { employeeId, employeeName, monthYear } = data;

  const [selectedStatus, setSelectedStatus] = useState("all");

  const [selectedRows, setSelectedRows] = useState([]);

  const [isClockinModalOpen, setIsClockinModalOpen] = useState(false);

  const [clockinsContainerId, setClockinsContainerId] = useState(null);

  const [containerStatus, setContainerStatus] = useState(null);

  const [clockInId, setClockInId] = useState(null);

  const [currentlyLoading, setCurrentlyLoading] = useState(null);

  const [currentlyLoadingDate, setCurrentlyLoadingDate] = useState(null);

  const [expandedDates, setExpandedDates] = useState([]);

  const handleChangeStatus = (value) => {
    setSelectedStatus(value);
    setSelectedRows([]);
  };

  const handleExpandDate = (date) => {
    if (expandedDates?.includes(date)) {
      setExpandedDates((prev) =>
        prev?.filter((expandedDate) => expandedDate != date)
      );
    } else {
      setExpandedDates((prev) => [...prev, date]);
    }
  };

  const [
    fetchTimesheetSummaryPerEmployee,
    {
      loading: timeSheetSummaryPerEmployeeLoading,
      data: timesheetPerEmployeeQueryData,
    },
  ] = useLazyQuery(GET_TIMESHEET_SUMMARY_FOR_EMPLOYEE, {
    variables: {
      input: {
        type: "monthly",
        ...(summaryType == "monthly"
          ? {
              monthYear,
            }
          : {
              date_range: {
                from: selectedWeek?.from,
                to: selectedWeek?.paidAt,
              },
            }),
        emp_id: employeeId,

        approval_status:
          selectedStatus == "pending"
            ? 2
            : selectedStatus == "accepted"
              ? 1
              : selectedStatus == "rejected"
                ? 5
                : undefined,
      },
    },
  });

  const timeSheetPerEmployeeData =
    timesheetPerEmployeeQueryData?.timeSheetSummaryPerEmployee?.map((row) => ({
      ...row,
      id: row?.date,
    })) ?? [];

  const [
    attemptAcceptOrRejectEmployeeDate,
    { loading: acceptOrRejectEmployeesLoading },
  ] = useMutation(ACCEPT_OR_REJECT_TIMESHEET_PER_ONE_EMPLOYEE, {
    onCompleted: (data) => {
      if (data?.acceptSummaryByDatesPerOneEmployee?.status == "fail") {
        dispatch(
          showErrorToast(
            data?.acceptSummaryByDatesPerOneEmployee?.message?.replaceAll(
              ":",
              ""
            )
          )
        );
        setCurrentlyLoadingDate(null);

        return;
      }

      dispatch(
        showSuccessToast(
          data?.acceptSummaryByDatesPerOneEmployee?.message?.replaceAll(":", "")
        )
      );
      setCurrentlyLoadingDate(null);

      setSelectedRows([]);

      fetchTimesheetSummaryPerEmployee();
      refetch();

      if (data?.acceptSummaryByDatesPerOneEmployee?.details?.length > 0) {
        Swal.fire({
          title: t("there are clockins that have no clock out time"),
          html: `
                <div>
                  ${data?.acceptSummaryByDatesPerOneEmployee?.details?.map(
                    (detail) => {
                      return `<div>${detail?.work_day_template_name} - ${detail?.container_date}</div>`;
                    }
                  )}
                </div>
                `,
          icon: "warning",
          className: "swal-warning-style",
          confirmButtonColor: "#ff6a6a",
          confirmButtonText: t("ok"),
          reverseButtons: true,
        });
      }
    },
    onError: (error) => {
      setCurrentlyLoadingDate(null);

      dispatch(
        showErrorToast(
          error?.graphQLErrors?.[0]?.extensions?.reason || error?.message
        )
      );
    },
  });

  const [fetchClockinAndLocations, { data: clockInAndLocationsQueryData }] =
    useLazyQuery(GET_CLOCKIN_AND_LOCATIONS);

  useEffect(() => {
    fetchTimesheetSummaryPerEmployee();
  }, []);

  const handleOpenClockinModal = (
    containerId = null,
    clockInId = null,
    containerStatus = null
  ) => {
    fetchClockinAndLocations({
      variables: {
        clockInId: clockInId,
        includeClockin: !!clockInId,
      },
      onCompleted: (data) => {
        setClockinsContainerId(containerId);
        setContainerStatus(containerStatus);
        setClockInId(clockInId);
        setIsClockinModalOpen(true);
        if (!!data?.clockin) {
          dispatch(
            onFormResetAction(formName, serializeFetchedClockin(data?.clockin))
          );
        }
      },
    });
  };

  const handleCloseClockinModal = () => {
    setIsClockinModalOpen(false);
  };

  const handleAcceptOrRejectEmployeeDates = (acceptOrReject, dates) => {
    attemptAcceptOrRejectEmployeeDate({
      variables: {
        input: {
          type: summaryType,
          empIds: [+employeeId],
          status: acceptOrReject,
          dates,
        },
      },
    });

    setCurrentlyLoading(acceptOrReject);
  };

  const handleAcceptOrRejectedOneDate = (acceptOrReject, date) => {
    handleAcceptOrRejectEmployeeDates(acceptOrReject, [date]);

    setCurrentlyLoadingDate(date);
  };

  const columns = [
    {
      // name: t("employees"),
      wrap: true,
      sortable: false,
      grow: 1.5,
      selector: "date",
      cell: (employeeOneDayData) => {
        return (
          <p className="m-0 font-weight-bold text-dark">
            {moment(employeeOneDayData?.date).format("ddd DD-MM-YYYY")}
          </p>
        );
      },
    },
    {
      // name: t("total clock-ins"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) => {
        const totalClockins = row?.details
          ?.map(
            (container) =>
              container?.calculatedExtraDataPerContainer?.totalClockIns
          )
          ?.reduce((acc, curr) => +acc + +curr, 0);
        return (
          <div className="d-flex align-items-center justify-content-center">
            <p className="m-0 mr-2">{t("total clock-ins")}</p>
            <p className="m-0 timesheet-summary-per-employee-number-of-clockins-style">
              {totalClockins}
            </p>
          </div>
        );
      },
    },
    {
      // name: t("day offs"),
      wrap: true,
      sortable: false,
      grow: 3,
      cell: (row) => {
        const totalContainersActualHoursDurations = row?.details?.map(
          (container) =>
            container?.calculatedExtraDataPerContainer?.actualTotalHours
        );
        return (
          <div className="d-flex align-items-center justify-content-center">
            <p className="m-0 mr-2">{t("total hours")}</p>
            <p className="m-0 text-nowrap text-dark">
              {getSumOfDurations(totalContainersActualHoursDurations)}
            </p>
          </div>
        );
      },
    },
    {
      // name: t("off hours"),
      wrap: true,
      sortable: false,
      grow: 2,
      cell: (row) => {
        const totalContainersOverTimeDurations = row?.details?.map(
          (container) =>
            container?.calculatedExtraDataPerContainer?.actualTotalOverTime
        );
        return (
          <div className="d-flex align-items-center justify-content-center">
            <p className="m-0 mr-2">{t("total overtime")}</p>
            <p className="m-0 text-nowrap text-dark">
              {getSumOfDurations(totalContainersOverTimeDurations)}
            </p>
          </div>
        );
      },
    },
    {
      // name: t("total work hours"),
      wrap: true,
      sortable: false,
      grow: 2.5,
      cell: (row) => {
        const totalContainersLeaveDurations = row?.details?.map(
          (container) => container?.calculatedExtraDataPerContainer?.totalLeave
        );
        return (
          <div className="d-flex align-items-center justify-content-center">
            <p className="m-0 mr-2">{t("leave")}</p>
            <p className="m-0 text-nowrap text-dark">
              {getSumOfDurations(totalContainersLeaveDurations)}
            </p>
          </div>
        );
      },
    },
    {
      name: t("status"),
      cell: (row) => {
        if (
          row?.details?.length &&
          row?.details?.every((container) => container?.accepted_status == 1)
        ) {
          return (
            <span className="request-badge request-approved-status">
              {t("approved")}
            </span>
          );
        } else if (
          row?.details?.length &&
          row?.details?.every((container) => container?.accepted_status == 2)
        ) {
          return (
            <span className="request-badge request-pending-status">
              {t("pending")}
            </span>
          );
        } else if (
          row?.details?.length &&
          row?.details?.every((container) => container?.accepted_status == 5)
        ) {
          return (
            <span className="request-badge request-rejected-status">
              {t("rejected")}
            </span>
          );
        } else if (
          row?.details?.length &&
          row?.details?.some((container) => container?.accepted_status != 2)
        ) {
          return (
            <span className="request-badge timesheet-summary-reviewed-status">
              {t("reviewed")}
            </span>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      // name: t("actions"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) => {
        return acceptOrRejectEmployeesLoading &&
          currentlyLoadingDate == row?.id ? (
          <CircularProgress size={18} />
        ) : (
          <div className="d-flex align-items-center gap-5 justify-content-end w-100">
            {row?.details?.length &&
            row?.details?.every(
              (container) => container?.accepted_status == 2
            ) ? (
              <>
                <IconButton
                  sx={{
                    height: 18,
                    width: 18,
                    borderRadius: 999,
                    backgroundColor: "#27B40C",
                    color: "white",
                    ":hover": {
                      backgroundColor: "#27B40C",
                    },
                  }}
                  onClick={() => handleAcceptOrRejectedOneDate(1, row?.id)}
                  disabled={
                    acceptOrRejectEmployeesLoading &&
                    currentlyLoadingDate == row?.id
                  }
                >
                  <Check style={{ fontSize: 15 }} />
                </IconButton>
                <IconButton
                  sx={{
                    height: 18,
                    width: 18,
                    borderRadius: 999,
                    backgroundColor: "#DC3545",
                    color: "white",
                    ":hover": {
                      backgroundColor: "#DC3545",
                    },
                  }}
                  onClick={() => handleAcceptOrRejectedOneDate(5, row?.id)}
                  disabled={
                    acceptOrRejectEmployeesLoading &&
                    currentlyLoadingDate == row?.id
                  }
                >
                  <Close style={{ fontSize: 15 }} />
                </IconButton>
              </>
            ) : (
              <IconButton
                sx={{
                  height: 18,
                  width: 18,
                  borderRadius: 999,
                  color: "black",
                  ":hover": {},
                }}
                onClick={() => handleAcceptOrRejectedOneDate(2, row?.id)}
                disabled={
                  acceptOrRejectEmployeesLoading &&
                  currentlyLoadingDate == row?.id
                }
              >
                <RotateLeftIcon style={{ fontSize: 20 }} />
              </IconButton>
            )}
          </div>
        );
      },
    },
  ];

  const allRowsSelected =
    timeSheetPerEmployeeData?.length &&
    timeSheetPerEmployeeData?.every((row) => selectedRows?.includes(row?.id));

  const isChecked = (e) => {
    if (e?.name == "select-all-rows") {
      return allRowsSelected;
    } else {
      const id = e?.name?.replace("select-row-", "");

      return selectedRows?.includes(id);
    }
  };

  const handleSelectAll = () => {
    if (allRowsSelected) {
      setSelectedRows([]);
    } else {
      setSelectedRows(timeSheetPerEmployeeData?.map((row) => row?.id) ?? []);
    }
  };

  const handleSelect = (e) => {
    const id = e?.name?.replace("select-row-", "");

    if (selectedRows?.includes(id)) {
      setSelectedRows((prev) => prev?.filter((selectedId) => selectedId != id));
    } else {
      setSelectedRows((prev) => [...prev, id]);
    }
  };

  const [attemptDeleteClockin, { loading: deleteClockinLoading }] =
    useMutation(DELETE_CLOCKIN);

  const handleDeleteClockIn = (clockinId) => {
    openSwalConfirm((isConfirmed) => {
      if (isConfirmed) {
        attemptDeleteClockin({
          variables: {
            id: +clockinId,
          },

          onCompleted: (data) => {
            if (
              data?.deleteClockIn?.__typename === "StatusResponse" &&
              data?.deleteClockIn?.status == "fail"
            ) {
              dispatch(
                showErrorToast(
                  data?.deleteClockIn?.message ?? "Something went wrong"
                )
              );
            }

            if (data?.deleteClockIn?.__typename === "Clockin") {
              fetchTimesheetSummaryPerEmployee();
            }
          },
          onError: (error) => {
            dispatch(
              showErrorToast(
                error?.graphQLErrors?.[0]?.extensions?.reason || error?.message
              )
            );
          },
        });
      }
    });
  };

  const selectedDays = timeSheetPerEmployeeData?.filter((day) =>
    selectedRows?.includes(day?.id)
  );

  const showAccept = () => {
    // if (selectedDays?.every((day) => day?.details?.[0]?.accepted_status == 5)) {
    //   return true;
    // }
    if (selectedDays?.some((day) => day?.details?.[0]?.accepted_status == 2)) {
      return true;
    }
    // if (
    //   selectedDays?.some((day) => day?.details?.[0]?.accepted_status == 1) &&
    //   selectedDays?.some((day) => day?.details?.[0]?.accepted_status == 5)
    // ) {
    //   return true;
    // }

    return false;
  };

  const showReject = () => {
    // if (selectedDays?.every((day) => day?.details?.[0]?.accepted_status == 1)) {
    //   return true;
    // }
    if (selectedDays?.some((day) => day?.details?.[0]?.accepted_status == 2)) {
      return true;
    }
    // if (
    //   selectedDays?.some((day) => day?.details?.[0]?.accepted_status == 1) &&
    //   selectedDays?.some((day) => day?.details?.[0]?.accepted_status == 5)
    // ) {
    //   return true;
    // }

    return false;
  };

  const showReset = () => {
    if (selectedDays?.some((day) => day?.details?.[0]?.accepted_status != 2)) {
      return true;
    }

    return false;
  };

  const Containers = ({ data }) => {
    const containers = data?.details;

    const [expandedContainers, setExpandedContainers] = useState([
      ...containers?.map((container) => container?.id),
    ]);

    const handleExpandContainer = (containerId) => {
      if (expandedContainers?.includes(containerId)) {
        setExpandedContainers((prev) =>
          prev?.filter(
            (expandedContainerId) => expandedContainerId != containerId
          )
        );
      } else {
        setExpandedContainers((prev) => [...prev, containerId]);
      }
    };

    const handleEditContainer = (container) => {
      dispatch(
        toggleEditClockinsContainer({
          modal: {
            isOpen: true,
          },
          form: normalizedFetchedContainerForEditing(container),
        })
      );
    };

    const containerColumns = [
      {
        // name: t("employees"),
        wrap: true,
        sortable: false,
        grow: 1.5,
        cell: (container) => {
          if (
            container?.status?.toLowerCase() == "day off" ||
            container?.status?.toLowerCase() == "holiday"
          ) {
            return <></>;
          }
          return (
            <p className="m-0 text-dark">
              {container?.flexWorkDayTemplate?.name}
            </p>
          );
        },
      },
      {
        // name: t("total clock-ins"),
        wrap: true,
        sortable: false,
        grow: 1.2,
        cell: (container) => {
          if (
            container?.status?.toLowerCase() == "day off" ||
            container?.status?.toLowerCase() == "holiday"
          ) {
            return <></>;
          }

          const totalClockins = container?.clockins?.length ?? 0;

          return (
            <div className="d-flex align-items-center justify-content-center">
              <p className="m-0 mr-2">{t("total clock-ins")}</p>
              <p className="m-0 timesheet-summary-per-employee-number-of-clockins-style">
                {totalClockins}
              </p>
            </div>
          );
        },
      },
      {
        // name: t("day offs"),
        wrap: true,
        sortable: false,
        grow: 3,
        cell: (container) => {
          if (
            container?.status?.toLowerCase() == "day off" ||
            container?.status?.toLowerCase() == "holiday"
          ) {
            return (
              <p className="m-0 font-weight-bold ml-auto">
                {t(container?.status)}
              </p>
            );
          }
          const totalContainerActualHours =
            container?.calculatedExtraDataPerContainer?.actualTotalHours;

          return (
            <div className="d-flex align-items-center justify-content-center">
              <p className="m-0 mr-2">{t("total hours")}</p>
              <p className="m-0 text-nowrap">{totalContainerActualHours}</p>
            </div>
          );
        },
      },
      {
        // name: t("off hours"),
        wrap: true,
        sortable: false,
        grow: 2,
        cell: (container) => {
          if (
            container?.status?.toLowerCase() == "day off" ||
            container?.status?.toLowerCase() == "holiday"
          ) {
            return <></>;
          }
          const totalContainerActualOverTime =
            container?.calculatedExtraDataPerContainer?.actualTotalOverTime;

          return (
            <div className="d-flex align-items-center justify-content-center">
              <p className="m-0 mr-2">{t("total overtime")}</p>
              <p className="m-0 text-nowrap">{totalContainerActualOverTime}</p>
            </div>
          );
        },
      },
      {
        // name: t("total work hours"),
        wrap: true,
        sortable: false,
        grow: 2.5,
        cell: (container) => {
          if (
            container?.status?.toLowerCase() == "day off" ||
            container?.status?.toLowerCase() == "holiday"
          ) {
            return <></>;
          }
          const totalContainerLeave =
            container?.calculatedExtraDataPerContainer?.totalLeave;

          return (
            <div className="d-flex align-items-center justify-content-center">
              <p className="m-0 mr-2">{t("leave")}</p>
              <p className="m-0 text-nowrap text-dark">{totalContainerLeave}</p>
            </div>
          );
        },
      },

      {
        // name: t("actions"),
        wrap: true,
        sortable: false,
        grow: 2.8,
        cell: (container) => {
          const isDayoffOrHoliday =
            container?.status?.toLowerCase() == "day off" ||
            container?.status?.toLowerCase() == "holiday";

          return (
            <>
              {!isDayoffOrHoliday ? (
                <IconButton
                  style={{
                    fontSize: 12,
                    borderRadius: 4,
                    textTransform: "capitalize",
                    backgroundColor: "#009EFB",
                    color: "white",
                    fontWeight: "bold",
                  }}
                  onClick={() =>
                    handleOpenClockinModal(
                      container?.id,
                      null,
                      container?.status
                    )
                  }
                >
                  <Add fontSize="small" />
                  {t("add clockins")}
                </IconButton>
              ) : null}

              <EditIconButton
                className="ml-auto"
                onClick={() => handleEditContainer(container)}
              />
            </>
          );
        },
      },
    ];

    return (
      <DataTable
        className="timesheet-summary-per-employee"
        columns={containerColumns}
        data={containers}
        customStyles={{
          rows: {
            style: {
              marginTop: "0px !important",
              backgroundColor: "#E7EFF5",
              cursor: "pointer",
            },
          },
        }}
        onRowClicked={(container) => handleExpandContainer(container?.id)}
        noHeader
        noTableHead
        selectableRows
        selectableRowDisabled={() => true}
        selectableRowsComponent={() => <></>}
        expandableRows
        expandableRowExpanded={(container) =>
          expandedContainers?.includes(container?.id) &&
          !["day off", "holiday"]?.includes(container?.status?.toLowerCase())
        }
        expandableRowsComponent={<ClockIns />}
        expandableIcon={{
          expanded: <FontAwesomeIcon icon={faAngleUp} />,
          collapsed: <FontAwesomeIcon icon={faAngleDown} />,
        }}
        expandableRowDisabled={(container) => container?.clockins?.length == 0}
      />
    );
  };

  const ClockIns = ({ data }) => {
    const clockins = data?.clockins ?? [];

    const clockInColumns = [
      {
        wrap: true,
        sortable: false,
        grow: 1.5,
      },
      {
        wrap: true,
        sortable: false,
        grow: 1.2,
        cell: (clockIn) => {
          if (!!clockIn?.leave_type) {
            return <></>;
          }
          const clockInOrder =
            clockins?.findIndex((clockin) => clockin?.id == clockIn?.id) + 1;

          return (
            <p className="m-0">
              {t("clock-in-index", {
                index: clockInOrder,
                suffix:
                  clockInOrder == 1
                    ? "st"
                    : clockInOrder == 2
                      ? "nd"
                      : clockInOrder == 3
                        ? "rd"
                        : "th",
              })}
            </p>
          );
        },
      },
      {
        wrap: true,
        sortable: false,
        grow: 5,
        cell: (clockIn) => {
          if (!!clockIn?.leave_type) {
            return (
              <p className="timesheet-summary-leave-record text-nowrap">
                {`${t("leave")} ${clockIn?.leave_hours} (${t(`${LEAVE_TYPES?.[clockIn?.leave_type] ?? "custom leaves"}`)})`}
              </p>
            );
          }
          return (
            <div className="d-flex align-items-center justify-content-between gap-10">
              <div>
                <div className="d-flex align-items-center">
                  <ArrowDownward className="clock-in-icon" />
                  <p className="m-0">
                    {!!clockIn?.clock_in_time
                      ? moment(clockIn?.clock_in_time).format("hh:mm A")
                      : "--------"}
                  </p>
                </div>

                <div className="d-flex align-items-center">
                  <LocationOn
                    fontSize="small"
                    className="clock-in-out-location-icon"
                  />
                  <div
                    title={clockIn?.clockInOffice?.name}
                    className="timesheet-clock-in-out-location-name"
                  >
                    <p className="text-ellipsis m-0">
                      {clockIn?.clockInOffice?.name}
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div className="d-flex align-items-center">
                  <ArrowDownward className="clock-out-icon" />
                  <p className="m-0">
                    {!!clockIn?.clock_out_time
                      ? moment(clockIn?.clock_out_time).format("hh:mm A")
                      : "--------"}
                  </p>
                </div>

                <div className="d-flex align-items-center">
                  <LocationOn
                    fontSize="small"
                    className="clock-in-out-location-icon"
                  />
                  <div
                    title={clockIn?.clockOutOffice?.name}
                    className="timesheet-clock-in-out-location-name"
                  >
                    <p className="text-ellipsis m-0">
                      {clockIn?.clockOutOffice?.name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        // name: t("off hours"),
        wrap: true,
        sortable: false,
        grow: 2,
        cell: (clockIn) => {
          if (!!clockIn?.leave_type) {
            return <></>;
          }
          return (
            <p className="clock-in-out-interval">
              <AccessTimeIcon className="time-icon" />
              <p className="m-0">{clockIn?.working_time}</p>
            </p>
          );
        },
      },
      {
        // name: t("total work hours"),
        wrap: true,
        sortable: false,
        grow: 2.5,
        cell: (clockIn) => {
          if (!!clockIn?.leave_type) {
            return <></>;
          }
          return <></>;
        },
      },

      {
        // name: t("actions"),
        wrap: true,
        sortable: false,
        grow: 1.2,
        cell: (clockIn) => (
          <div className="d-flex align-items-center">
            <EditIconButton
              onClick={() => {
                const containerId = data?.id;
                handleOpenClockinModal(containerId, clockIn?.id);
              }}
            />
            <RemoveIconButton
              // disabled={deleteClockinLoading}
              onClick={() => handleDeleteClockIn(clockIn?.id)}
            />
          </div>
        ),
      },
    ];

    return (
      <DataTable
        className="timesheet-summary-per-employee"
        columns={clockInColumns}
        data={clockins}
        customStyles={{
          rows: {
            style: {
              marginTop: "0px !important",
            },
          },
        }}
        noHeader
        noTableHead
        selectableRows
        selectableRowDisabled={() => true}
        selectableRowsComponent={() => <></>}
        expandableRows
        expandableIcon={<></>}
        expandableRowDisabled={() => true}
      />
    );
  };

  const isEditContainerModalOpen = useSelector(
    (state) => state?.flex?.editClockinsContainerModal?.isOpen
  );

  const handleRefetchAfterChangingContainer = () => {
    fetchTimesheetSummaryPerEmployee(); // timesheet summary per emp
    refetch(); // timesheet summary
  };

  return (
    <MainModal
      isOpen={isOpen}
      toggle={handleCloseModal}
      hasModalFooter={false}
      modalTitle={moment(monthYear, "MM-YYYY").format("MMMM, YYYY")}
      // size="lg"
      className="timesheet-summary-modal"
    >
      <h4 className="text-dark">{employeeName}</h4>

      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-5 my-4">
          {statusButtons?.map((button) => (
            <Button
              onClick={() => handleChangeStatus(button?.value)}
              key={button?.value}
              sx={{
                textTransform: "unset",

                ...(selectedStatus == button?.value
                  ? {
                      border: "2px solid #009EFB",
                      color: "#009EFB",
                    }
                  : {
                      border: "2px solid transparent",
                      color: "gray",
                      ":hover": {
                        border: "2px solid #009EFB",
                        color: "#009EFB",
                        opacity: 0.5,
                      },
                    }),
              }}
            >
              {t(button?.label)}
            </Button>
          ))}
        </div>
        <div className="d-flex align-items-center gap-5">
          {showAccept() ? (
            <Button
              onClick={() => handleAcceptOrRejectEmployeeDates(1, selectedRows)}
              sx={{
                backgroundColor: "#27B40C",
                color: "white",
                height: 35,
                width: 100,
                textTransform: "unset",
                ":hover": {
                  backgroundColor: "#188C01",
                },
                ":disabled": {
                  color: "white",
                  opacity: 0.7,
                },
              }}
              disabled={
                !!!selectedRows?.length || acceptOrRejectEmployeesLoading
              }
            >
              {acceptOrRejectEmployeesLoading &&
              currentlyLoading == 1 &&
              !!!currentlyLoadingDate ? (
                <CircularProgress size={20} style={{ color: "white" }} />
              ) : (
                t("accept")
              )}
            </Button>
          ) : null}
          {showReject() ? (
            <Button
              onClick={() => handleAcceptOrRejectEmployeeDates(5, selectedRows)}
              sx={{
                backgroundColor: "#DE4243",
                color: "white",
                height: 35,
                width: 100,
                textTransform: "unset",
                ":hover": {
                  backgroundColor: "#C63031",
                },
                ":disabled": {
                  color: "white",
                  opacity: 0.7,
                },
              }}
              disabled={
                !!!selectedRows?.length || acceptOrRejectEmployeesLoading
              }
            >
              {acceptOrRejectEmployeesLoading &&
              currentlyLoading == 0 &&
              !!!currentlyLoadingDate ? (
                <CircularProgress size={20} style={{ color: "white" }} />
              ) : (
                t("reject")
              )}
            </Button>
          ) : null}

          {showReset() ? (
            <Button
              onClick={() => handleAcceptOrRejectEmployeeDates(2, selectedRows)}
              sx={{
                backgroundColor: "#555",
                color: "white",
                height: 35,
                width: 100,
                textTransform: "unset",
                ":hover": {
                  backgroundColor: "#555",
                },
                ":disabled": {
                  color: "white",
                  opacity: 0.7,
                },
              }}
              disabled={
                !!!selectedRows?.length || acceptOrRejectEmployeesLoading
              }
            >
              {acceptOrRejectEmployeesLoading &&
              currentlyLoading == 2 &&
              !!!currentlyLoadingDate ? (
                <CircularProgress size={20} style={{ color: "white" }} />
              ) : (
                t("reset")
              )}{" "}
            </Button>
          ) : null}
        </div>
      </div>

      {timeSheetPerEmployeeData.length ? (
        <CheckboxBooleanForm
          options={["select all"]}
          name="select-all-rows"
          type="checkbox"
          labelStyle="checkbox text-nowrap"
          optionItemStyle="d-flex justify-content-start"
          optionInputStyle={`timesheet-summary-per-employee-select-all-button ${
            allRowsSelected ? "timesheet-active-button" : ""
          }`}
          checked={allRowsSelected}
          onChange={() => handleSelectAll()}
        />
      ) : null}

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="timesheet-summary-per-employee"
        columns={columns}
        data={timeSheetPerEmployeeData ?? []}
        noHeader
        noTableHead
        customStyles={{
          rows: {
            style: {
              cursor: "pointer",
            },
          },
        }}
        persistTableHead
        progressPending={timeSheetSummaryPerEmployeeLoading}
        progressComponent={
          <div className="my-5">
            <CircularProgress />
          </div>
        }
        selectableRows
        // selectableRowDisabled={(row) => row?.pending == 0} // this is commented for testing purposes and should be uncommented when deploying
        clearSelectedRows={false}
        selectableRowsComponentProps={(row) => row}
        selectableRowsComponent={forwardRef((e, d) => {
          if (!e.disabled) {
            return (
              <div className="mr-2">
                <CheckboxBooleanForm
                  options={[""]}
                  type="checkbox"
                  labelStyle="checkbox"
                  containerStyle="containerStyle"
                  optionsContainerStyle="d-flex flex-column"
                  optionItemStyle="optionItemStyle mr-0"
                  optionInputStyle="optionInputStyle containerStyle checkbox fix-checkbox-payroll-datable-responsive"
                  optionLabelStyle="optionLabelStyle ml-4 my-1 w-100"
                  optionLabelActiveStyle="optionLabelActiveStyle"
                  {...e}
                  checked={isChecked(e)}
                  onChange={() => handleSelect(e)}
                />
              </div>
            );
          } else {
            return (
              <div className="fix-checkbox-payroll-datable-responsive"></div>
            );
          }
        })}
        expandableRows
        expandableRowExpanded={(row) => expandedDates?.includes(row?.date)}
        expandableRowsComponent={<Containers />}
        onRowClicked={(row) => handleExpandDate(row?.date)}
        expandableIcon={{
          expanded: <FontAwesomeIcon icon={faAngleUp} />,
          collapsed: <FontAwesomeIcon icon={faAngleDown} />,
        }}
      />

      <TimeSheetUpsertModal
        isOpen={isClockinModalOpen}
        onClose={handleCloseClockinModal}
        clockinsContainerId={clockinsContainerId}
        containerStatus={containerStatus}
        clockInId={clockInId}
        workPlaces={clockInAndLocationsQueryData?.locations_menu?.data ?? []}
        workdayTemplates={
          clockInAndLocationsQueryData?.flex_Work_Day_Template_Menu ?? []
        }
        refetch={fetchTimesheetSummaryPerEmployee}
      />

      {isEditContainerModalOpen ? (
        <EditClockinsContainerModal
          refetch={handleRefetchAfterChangingContainer}
        />
      ) : null}
    </MainModal>
  );
};

export default TimeSheetSummaryPerEmployee;
