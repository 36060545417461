import gql from "graphql-tag";

/*
  Offices
*/

/*
  Departments
*/

/*
  Positions
*/

/*
  Work Timings
*/
/*
  time ranges profile settings for overtime 
*/
export const UPSERT_TIME_RANGE_PRESET = gql`
  mutation UpsertTimeRangeProfile($input: TimeRangeProfilePresetInput) {
    upsertTimeRangeProfilePreset(input: $input) {
      id
    }
  }
`;

export const DELETE_OVERTIME_GRACE_MINUTES_PRESET = gql`
  mutation deleteOvertimeGraceMinutePreset($id: ID) {
    deleteTimeRangeProfilePreset(id: $id) {
      id
    }
  }
`;

export const DELETE_EMPLOYEE_GRACE_MINUTES_PROFILE = gql`
  mutation deleteEmployeeGraceMinuteProfile($id: ID) {
    deleteGraceMinute(id: $id) {
      id
    }
  }
`;

export const UPSERT_EMPLOYEE_GRACE_MINUTES_PROFILE = gql`
  mutation UpsertEmployeeGraceMinutesProfile($input: EmployeeGraceMinuteInput) {
    upsertGraceMinute(input: $input) {
      id
    }
  }
`;

/*
  Leave & Breaks
*/

/*
  Attendance Profiles
*/

/*
  Salary Configurations Presets
*/

export const upsertSalaryConfigurationPresetsMutation = gql`
  mutation upsertSalaryConfigurationPresets(
    $input: UpdateOrCreateSalaryConfigurationPresetInput
  ) {
    updateOrCreateSalaryConfigurationPreset(input: $input) {
      id
    }
  }
`;
export const removeSalaryConfigurationPresetsMutation = gql`
  mutation removeSalaryConfigurationPresets($id: ID!) {
    deleteSalaryConfigurationPreset(id: $id) {
      status
      message
    }
  }
`;

/*
  Allowances & Deductibles
*/

export const upsertAllowanceMutation = gql`
  mutation upsertAllowance($input: UpsertAllowanceInput!) {
    upsertAllowance(input: $input) {
      ... on Allowance {
        id
      }
      ... on GeneralException {
        message
      }
    }
  }
`;
export const removeAllowanceMutation = gql`
  mutation removeAllowance($id: ID!) {
    remove: deleteAllowance(id: $id) {
      ... on Allowance {
        id
      }
      ... on GeneralException {
        message
      }
    }
  }
`;

export const upsertDeductibleMutation = gql`
  mutation upsertDeductible($input: UpsertDeductibleInput!) {
    upsertDeductible(input: $input) {
      id
    }
  }
`;
export const removeDeductibleMutation = gql`
  mutation removeDeductibles($id: ID!) {
    remove: deleteDeductible(id: $id) {
      ... on Deductible {
        id
      }
      ... on GeneralException {
        message
      }
    }
  }
`;

/*
  Holidays
*/

/*
  Documents
*/

/*
  Expense Categories
*/

/*
  Access levels
*/

/*
  Announcements
*/

export const storeAnnouncementMutation = gql`
  mutation storeAnnouncement(
    $type: String
    $body: String
    $publish: Int
    $title: String
    $send_to: JSON
    $file: Face
  ) {
    store_announcement(
      body: $body
      type: $type
      title: $title
      publish: $publish
      send_to: $send_to
      file: $file
    ) {
      __typename
    }
  }
`;
export const editAnnouncementMutation = gql`
  mutation editAnnouncement(
    $id: ID
    $type: String
    $body: String
    $publish: Int
    $title: String
    $send_to: JSON
  ) {
    change_announcement(
      id: $id
      body: $body
      type: $type
      title: $title
      publish: $publish
      send_to: $send_to
    ) {
      __typename
    }
  }
`;
export const publishAnnouncementMutation = gql`
  mutation publishAnnouncement($id: ID!) {
    publish_announcement(id: $id)
  }
`;
export const removeAnnouncementMutation = gql`
  mutation publishAnnouncement($id: ID!) {
    remove_announcement(id: $id)
  }
`;

// handle manual phone verification mutation
export const manualPhoneVerificationMutation = gql`
  mutation manualPhoneVerification($id: ID!, $password: String) {
    admin_verify_phone_number(id: $id, password: $password) {
      status
      message
    }
  }
`;
