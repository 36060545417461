import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import HelperFns from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";
import { ViewWorkTeams } from "../ViewWorkTeams";
import ViewFlexWorkTeams from "../ViewWorkTeams/ViewFlexWorkTeams";
import { useDispatch, useSelector } from "react-redux";
import { onFormResetAction } from "../../Store/Actions";
import useCompanyAttType from "../../Helpers/Hooks/useCompanyAttType";

const ShiftsAndFlexWorkTeams = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const value = useSelector((state) => state?.super?.selectedWorkTeamsTab);

  const { hasFlex, hasFixed } = useCompanyAttType();

  const tabs = [
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_WORK_TEAMS],
      allowBP: true,
    }) && hasFixed
      ? [
          {
            label: "shifts",
            Panel: ViewWorkTeams,
          },
        ]
      : []),

    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_WORK_TEAMS],
      allowBP: true,
    }) && hasFlex
      ? [
          {
            label: "flex",
            Panel: ViewFlexWorkTeams,
          },
        ]
      : []),
  ];

  /* ↓ Helpers ↓ */

  const handleChange = (_, newValue) => {
    dispatch(onFormResetAction("selectedWorkTeamsTab", newValue));
  };

  return (
    <div className="content payroll_wrapper_style no-padding-first-child">
      <TabContext value={value}>
        {/* Tabs */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            {tabs.map(({ label }, i) => (
              <Tab key={label} label={t(label)} value={String(i)} />
            ))}
          </TabList>
        </Box>

        {/* Panels */}
        {tabs.map(({ Panel, props }, i) => (
          <TabPanel key={i} value={String(i)}>
            <Panel {...props} />
          </TabPanel>
        ))}
      </TabContext>
    </div>
  );
};

export default ShiftsAndFlexWorkTeams;
