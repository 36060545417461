import React from "react";

import HelperFns from "../../../Helpers/HelperFns";
import Privileges from "../../../Constants/Privilages";

import Churn from "./Churn";
import Payment from "./Payment";
import Revenue from "./Revenue";
import Overview from "./Overview";
import Subscription from "./Subscription";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

const checkPrivileges = ({ privileges }) =>
  !privileges?.length || HelperFns.checkPrivileges({ privileges });
const reports = [
  {
    value: "1",
    label: "Overview",
    Panel: Overview,
    privileges: [],
  },
  {
    value: "2",
    label: "Revenue",
    Panel: Revenue,
    privileges: [],
  },
  {
    value: "3",
    label: "Subscription",
    Panel: Subscription,
    privileges: [],
  },
  {
    value: "4",
    label: "Churn",
    Panel: Churn,
    privileges: [],
  },
  {
    value: "5",
    label: "Payment",
    Panel: Payment,
    privileges: [],
  },
];

const Reports = () => {
  // Local State
  const [value, setValue] = React.useState(getInitialValue());

  /* ↓ Helpers ↓ */

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  function getInitialValue() {
    for (let i = 0; i < reports.length; i++) {
      const report = reports[i];
      if (checkPrivileges({ privileges: report.privileges })) {
        return report.value;
      }
    }
  }

  return (
    <TabContext value={value}>
      {/* Tabs */}
      <Box sx={{ mb: 1, borderBottom: 1, borderColor: "divider" }}>
        <TabList variant="scrollable" onChange={handleChange}>
          {reports.map((report) =>
            checkPrivileges({ privileges: report.privileges }) ? (
              <Tab
                key={report.value}
                label={report.label}
                value={report.value}
              />
            ) : null
          )}
        </TabList>
      </Box>

      {/* Panels */}
      {reports.map((report) =>
        checkPrivileges({ privileges: report.privileges }) ? (
          <TabPanel key={report.value} value={report.value} sx={{ p: 1 }}>
            <report.Panel />
          </TabPanel>
        ) : (
          []
        )
      )}
    </TabContext>
  );
};

export default Reports;
