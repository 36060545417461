import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Select, { components as selectComponent } from "react-select";
import { useFormValidation } from "../../Helpers/Hooks/useFormValidation";
import { withDependency } from "../../Helpers/HOC/withDependency";
import { ErrorOutline } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import { onInputChangeAction, updateValueAction } from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import HelperFns from "../../Helpers/HelperFns";
import { renderIcon } from "./formIcons";
import AsyncSelect from "react-select/async";

const InputTooltip = ({ title = "", tooltipStyle }) => {
  return (
    <span className={`${tooltipStyle ? tooltipStyle : "pl-2"}`}>
      <Tooltip placement="top" title={title}>
        <ErrorOutline fontSize="inherit" color="inherit" />
      </Tooltip>
    </span>
  );
};

const styless = new Proxy(
  {},
  {
    get: (target, propKey) => () => {},
  }
);

const BSelect = ({
  async,
  isDisabled,
  isClearable,
  isRtl,
  isSearchable,
  isMulti,
  name,
  dependOn,
  label,
  labelStyle,
  keepDefaultLabel,
  formName,
  validateBy,
  formSubmitting,
  formNameValidation,
  containerStyle,
  keepDefaultContainer,
  inputContainerStyle,
  keepDefaultInputContainer,
  hasTooltip,
  tooltipTitle,
  tooltipStyle,
  options,
  SSR,
  endUrl,
  queryKey,
  minQuery = 3,
  optionLabel = "name",
  optionValue = "id",
  getOptionValue,
  keepDefaultStyle = true,
  classNamePrefix,
  icon,
  iconStyle,
  defaultStyle,
  errorStyle,
  keepDefaultError,
  optDependType,
  optDependKey,
  optDependValue,
  data,
  validateContainerStyle,
  validateMessage,
  rootStyle = "",
  formServerValidation,
  validationName = undefined,
  skipLocalization = false,
  preSelectValue,
  bValue,
  customNewStyles = false,
  customComponents,
  components,
  isValueObject = false,
  selectAll = false,
  onInterceptInputOnChange = () => undefined,
  ...props
}) => {
  const inputval = props?.value ?? bValue ?? data?.[formName]?.[name];
  const validate = useFormValidation(
    inputval,
    validationName ?? name,
    formNameValidation,
    formSubmitting,
    validateBy,
    isDisabled
  );

  useEffect(() => {
    if (preSelectValue) {
      if (preSelectValue && !(inputval > 0)) {
        let e = {
          name,
        };

        dispatch(onInputChangeAction(formName, e, "select", preSelectValue));
      }
    }
    return () => {};
  }, [preSelectValue, options]);

  const defStyles = {
    control: customNewStyles
      ? (styles) => ({
          ...styles,
          border: "none",
          borderBottom: "2px solid #eaeef0",
          borderRadius: "0px !important",
          padding: "5px 0px",
        })
      : (styles) => ({ ...styles }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) =>
      !skipLocalization
        ? { ...styles }
        : {
            ...styles,
            direction: HelperFns.isStringRTL(data?.[optionLabel])
              ? "rtl"
              : "ltr",
          },
    multiValueLabel: (styles, { data }) =>
      !skipLocalization
        ? data.isFixed
          ? { ...styles, color: "white !important" }
          : styles
        : {
            ...(data.isFixed
              ? { ...styles, color: "white !important" }
              : styles),
            direction: HelperFns.isStringRTL(data?.[optionLabel])
              ? "rtl"
              : "ltr",
          },

    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles, { data }) =>
      !skipLocalization
        ? { ...styles }
        : {
            ...styles,
            direction: HelperFns.isStringRTL(data?.[optionLabel])
              ? "rtl"
              : "ltr",
          },
    multiValue: (styles, { data }) =>
      !skipLocalization
        ? data.isFixed
          ? {
              ...styles,
              backgroundColor: "gray !important",
              border: "1px solid grey !important",
            }
          : styles
        : {
            ...(data.isFixed
              ? {
                  ...styles,
                  backgroundColor: "gray !important",
                  border: "1px solid grey !important",
                }
              : styles),
            direction: HelperFns.isStringRTL(data?.[optionLabel])
              ? "rtl"
              : "ltr",
          },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: "none" } : base;
    },
  };

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedOptions, setFetchedOptions] = useState([]);
  const isServerValid = !Boolean(data[formServerValidation])
    ? false
    : Boolean(data[formServerValidation][validationName ?? name])
      ? true
      : false;

  const handleChange = (value, event) => {
    if (onInterceptInputOnChange(event, value)) {
      return;
    }
    if (isMulti) {
      dispatch(
        onInputChangeAction(
          formName,
          event,
          "select",
          value ? value.map((val) => val[optionValue]) : []
        )
      );
      // onInputChangeAction(
      //   formName,
      //   event,
      //   "select",
      //   value ? value.map(val => val[optionValue]) : []
      // );
      setSelected(value);
    } else {
      dispatch(
        onInputChangeAction(
          formName,
          event,
          "select",
          ...(isValueObject ? [value] : value ? [value[optionValue]] : "")
        )
      );
      // onInputChangeAction(
      //   formName,
      //   event,
      //   "select",
      //   value ? value[optionValue] : ""
      // );
      setSelected(value ? [value] : "");
    }
  };

  const handleSelectAll = () => {
    dispatch(
      updateValueAction(
        formName,
        name,
        isValueObject ? options : options.map((c) => c?.[optionValue])
      )
    );
  };

  // const loadOptions = (inputValue, event) => {
  //   if (event.action == "input-change" && inputValue.length > minQuery) {
  //     setIsLoading(true);
  //     userApiClient
  //       .get(endUrl, {
  //         params: {
  //           [queryKey]: inputValue,
  //         },
  //       })
  //       .then((res) => {
  //         setFetchedOptions(res.data);
  //         setIsLoading(false);
  //       })
  //       .catch((err) => {
  
  //         setIsLoading(false);
  //       });
  //   }
  // };

  const filterOptions = (option, inputValue) => {
    return true;
  };

  const dependOptions = () => {
    switch (optDependType) {
      case "equal":
        return options?.filter((opt) =>
          optDependValue.includes(opt[optDependKey])
        );

      case "notEqual":
        return options?.filter(
          (opt) => !optDependValue.includes(opt[optDependKey])
        );

      case "greater":
        return options?.filter((opt) => opt[optDependKey] > optDependValue);
      case "less":
        return options?.filter((opt) => opt[optDependKey] < optDependValue);

      default:
        return options;
    }
  };

  const vaildContainer = () => {
    if (!validate.validity || isServerValid) {
      return validateContainerStyle ?? "invalid-container-style";
    }
    return " ";
  };

  const Control = ({ children, ...props }) => (
    <selectComponent.Control {...props}>
      {icon ? (
        <div className={`bselect_icon ${iconStyle ?? ""}`}>
          {renderIcon(icon)}
        </div>
      ) : null}
      {children}
    </selectComponent.Control>
  );

  const SelectComp = async ? AsyncSelect : Select;

  return (
    <div className={`${rootStyle} ${vaildContainer()}`}>
      <div
        className={`${keepDefaultContainer ? "select-def-container" : ""} ${
          containerStyle ? containerStyle : "my-1"
        }`}
      >
        {label && (
          <label
            className={`${keepDefaultLabel ? "select-def-label" : ""} ${
              labelStyle ? labelStyle : "mb-2"
            } validity-label-style`}
          >
            {t(label)}
            {hasTooltip && (
              <InputTooltip title={tooltipTitle} tooltipStyle={tooltipStyle} />
            )}
          </label>
        )}

        {selectAll ? (
          <div className="d-flex gap-10 align-items-end">
            <Select
              className={`${
                keepDefaultInputContainer ? "select-def-input-container" : ""
              } ${
                inputContainerStyle
                  ? inputContainerStyle
                  : "flex-1 b-select-style"
              }`}
              classNamePrefix={
                classNamePrefix ? classNamePrefix : "b-select-style"
              }
              getOptionLabel={(option) =>
                skipLocalization ? option[optionLabel] : t(option[optionLabel])
              }
              getOptionValue={(option) => option[optionValue]}
              isDisabled={isDisabled}
              isLoading={isLoading}
              isClearable={isClearable}
              isRtl={isRtl}
              // menuIsOpen
              {...(SSR
                ? {
                    // onInputChange: loadOptions,
                    filterOption: filterOptions,
                    options:
                      selected && isMulti
                        ? [
                            ...fetchedOptions.filter(
                              (opt) => !inputval?.includes(opt[optionValue])
                            ),
                            ...selected,
                          ]
                        : fetchedOptions,
                    value:
                      isMulti && SSR
                        ? selected
                        : fetchedOptions?.filter(
                            (opt) => inputval == opt[optionValue]
                          ),
                  }
                : {
                    options: dependOptions(),
                    value: isMulti
                      ? options?.filter((opt) =>
                          inputval?.includes(opt[optionValue])
                        )
                      : isValueObject
                        ? inputval?.[optionValue]
                        : options?.filter(
                            (opt) => inputval == opt[optionValue]
                          ),
                  })}
              isMulti={isMulti}
              styles={keepDefaultStyle ? defStyles : styless}
              name={name}
              onChange={handleChange}
              placeholder={t("select option")}
              components={{ Control, ...components }}
              {...props}
            />
            <button
              disabled={isLoading}
              className="link-btn"
              onClick={handleSelectAll}
            >
              {t("select all")}
            </button>
          </div>
        ) : (
          <SelectComp
            className={`${
              keepDefaultInputContainer ? "select-def-input-container" : ""
            } ${inputContainerStyle ? inputContainerStyle : "b-select-style"}`}
            classNamePrefix={
              classNamePrefix ? classNamePrefix : "b-select-style"
            }
            getOptionLabel={(option) =>
              skipLocalization ? option[optionLabel] : t(option[optionLabel])
            }
            getOptionValue={(option) => option[optionValue]}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            // menuIsOpen
            {...(SSR
              ? {
                  // onInputChange: loadOptions,
                  filterOption: filterOptions,
                  options:
                    selected && isMulti
                      ? [
                          ...fetchedOptions.filter(
                            (opt) => !inputval?.includes(opt[optionValue])
                          ),
                          ...selected,
                        ]
                      : fetchedOptions,
                  value:
                    isMulti && SSR
                      ? selected
                      : fetchedOptions?.filter(
                          (opt) => inputval == opt[optionValue]
                        ),
                }
              : {
                  options: dependOptions(),
                  value: isMulti
                    ? options?.filter((opt) =>
                        inputval?.includes(opt?.[optionValue])
                      )
                    : isValueObject
                      ? inputval
                      : options?.filter(
                          (opt) => inputval == opt?.[optionValue]
                        ),
                })}
            isMulti={isMulti}
            styles={keepDefaultStyle ? defStyles : styless}
            name={name}
            onChange={handleChange}
            placeholder={t("select option")}
            components={{ Control, ...components }}
            {...props}
          />
        )}
      </div>
      <div className="validity-msg-style text-left">
        {validateMessage ? validateMessage : validate.validityMessage}
        {validate.validityMessage && <br />}
        {Boolean(data[formServerValidation])
          ? (data[formServerValidation][validationName ?? name] ?? "")
          : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state, { reducer = "super" }) => {
  return {
    data: state[reducer],
  };
};

export default connect(mapStateToProps)(withDependency(BSelect));
