import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CostCentersList from "./CostCentersList";
import CostCentersReport from "./CostCentersReport";
import Privilages from "../../Constants/Privilages";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import HelperFns from "../../Helpers/HelperFns";

const tabs = [
  {
    label: "cost centers",
    Panel: CostCentersList,
    privileges: [Privilages.VIEW_COST_CENTERS],
  },
  {
    label: "Cost centers report",
    Panel: CostCentersReport,
    privileges: [Privilages.VIEW_COST_CENTERS_REPORT],
  },
];

const CostCenters = () => {
  const { t } = useTranslation();

  // Local State
  const [value, setValue] = React.useState("0");

  /* ↓ Helpers ↓ */

  const handleChange = (_, newValue) => {
    
    setValue(newValue);
  };

  

  const renderfirstTab = () => {
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_COST_CENTERS],
        allowBP: true,
      })
    ) {
      setValue("0");
      return;
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_COST_CENTERS_REPORT],
        allowBP: true,
      })
    ) {
      setValue("1");
      return;
    }
    return null;
  };

  useEffect(() => {
    renderfirstTab();
  }, []);

  return (
    <div className="content payroll_wrapper_style no-padding-first-child">
      <TabContext value={value}>
        {/* Tabs */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            {tabs.map(({ privileges, label }, i) =>
              HelperFns.checkPrivileges({
                privileges: privileges,
                allowBP: true,
              }) ? (
                <Tab key={label} label={t(label)} value={String(i)} />
              ) : null
            )}
          </TabList>
        </Box>

        {/* Panels */}
        {tabs.map(({ privileges, Panel }, i) =>
          HelperFns.checkPrivileges({
            privileges: privileges,
            allowBP: true,
          }) ? (
            <TabPanel key={i} value={String(i)}>
              <Panel />
            </TabPanel>
          ) : null
        )}
      </TabContext>
    </div>
  );
};

export default CostCenters;
