import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import { showToast } from "../../Helpers/HelperFns";
import { onFormResetAction } from "../../Store/Actions";
import { getCrmAgentsMenu } from "../../Graphql/query";
import { editSubscriptionAgentMutation } from "../../Graphql/mutation/PaymentTracking";

import MainModal from "../MainModal";
import { BSelect } from "form-builder";

const reducer = "paymentTracking";
const formName = "editAgentSubscriptionModal";
const formNameValidation = "paymentTrackingClientValidation";
const formServerValidation = "paymentTrackingServerValidation";

const EditAgentSubscriptionModal = ({ data, onClose, refetchList }) => {
  const dispatch = useDispatch();

  // Local State
  const [errMsg, setErrMsg] = React.useState("");
  const [formSubmitting, setFormSubmitting] = React.useState(false);

  // Reducer State
  const formData = useSelector((state) => state?.[reducer]?.[formName]);
  const formClientValidation = useSelector(
    (state) => state?.[reducer]?.[formNameValidation]
  );

  // Server State
  const { data: options, loading: getAgentsLoading } = useQuery(
    getCrmAgentsMenu,
    {
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    }
  );
  const [editSubscriptionAgent, { loading: editSubscriptionAgentLoading }] =
    useMutation(editSubscriptionAgentMutation);

  // Constants
  const FormProps = {
    reducer,
    formName,
    formSubmitting,
    formNameValidation,
    formServerValidation,
  };

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    data?.agent &&
      dispatch(
        onFormResetAction(formName, { ...formData, agent: data?.agent })
      );
  }, []);

  /* ↓ Helpers ↓ */

  const handleCloseModal = () => {
    onClose();
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formNameValidation));
    dispatch(onFormResetAction(formServerValidation));
  };

  const handleSubmit = () => {
    setFormSubmitting(true);
    if (formClientValidation?.length) return;

    editSubscriptionAgent({
      variables: {
        from: data?.from,
        company_id: +data?.company_id,
        user_id: formData?.agent ? +formData?.agent : null,
      },
      onCompleted: () => {
        refetchList();
        handleCloseModal();
        showToast("success");
      },
      onError: (err) => {
        const validation = err?.graphQLErrors?.[0]?.extensions?.validation;
        dispatch(onFormResetAction(formServerValidation, validation || {}));

        const msg =
          validation?.json?.[0] ||
          err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message;
        setErrMsg(msg || "");
      },
    });
  };

  return (
    <MainModal
      isOpen
      modalTitle="Change Agent"
      toggle={handleCloseModal}
      btnOnClick={handleSubmit}
      btnSubmitLoading={editSubscriptionAgentLoading}
    >
      <BSelect
        {...FormProps}
        isClearable
        name="agent"
        label="agent"
        icon="employee"
        options={options?.agents || []}
        isLoading={getAgentsLoading}
      />

      {errMsg ? (
        <p role="alert" className="red-color mt-2 mb-0">
          {errMsg}
        </p>
      ) : null}
    </MainModal>
  );
};

export default EditAgentSubscriptionModal;
