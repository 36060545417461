import React, { useEffect } from "react";
import { BSelect, RadioboxForm } from "form-builder";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import Constants from "../../Helpers/Constants";
import { faCoins, faPlus } from "@fortawesome/free-solid-svg-icons";
import { onFormResetAction, showBalanceModal } from "../../Store/Actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../Components/Loader";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import ShowMoreText from "react-show-more-text";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import EventIcon from "@mui/icons-material/Event";

import * as REQUESTS from "../../Constants/Requests";
import gql from "graphql-tag";
import MainModal from "../../Components/MainModal";
import { CheckboxBooleanForm } from "form-builder";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import { useLazyQuery } from "@apollo/client";
import { ExportButton } from "../../Components/Buttons";

const balanceFilterOptionsWithSick = [
  { label: "annual leaves", value: REQUESTS.NORMAL_LEAVES },
  { label: "sick leaves", value: REQUESTS.SICK_LEAVES },
  { label: "salary", value: REQUESTS.UNPAID_LEAVES },
];
const balanceFilterOptions = [
  { label: "annual leaves", value: REQUESTS.NORMAL_LEAVES },
  { label: "salary", value: REQUESTS.UNPAID_LEAVES },
];

const exportModalInitState = { isOpen: false, data: null };
const EXPORT_LEAVE_BALANCE = gql`
  query exportLeaveBalanceQuery(
    $input: ExportLeaveBalanceReportColumns
    $isFlexUser: Boolean! =false
  ) {
    export_leave_balance_report(input: $input) @skip(if: $isFlexUser) {
      file
      status
      message
    }
    export_flex_balance_report(input: $input) @include(if: $isFlexUser) {
      file
      status
      message
    }
  }
`;

const MyBalance = ({
  employeeBalanceData,
  isLoading,
  pagination,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [exportModalState, setExportModalState] =
    React.useState(exportModalInitState);

  // to approximate the numbers of remaning balance
  function remaningBalanceCalcualtion(num) {
    let roundNumToSeven = (+num)?.toFixed(7);
    let roundNumToTwo = (+roundNumToSeven)?.toFixed(2);
    return Math.abs(roundNumToTwo) == 0 ? 0 : roundNumToTwo;
  }

  useEffect(() => {
    return () => {
      dispatch(onFormResetAction(props.dataTableRef));
    };
  }, []);

  const columns = [
    {
      name: t("date"),
      wrap: true,
      selector: "date_time",
      sortable: true,
      grow: 1,
      cell: (row) => moment(row.date_time).format("ddd DD, MMM yyyy"),
    },
    {
      name: t("value"),
      selector: "quantity",
      wrap: true,
      sortable: false,
      grow: 0.75,
      cell: (row) => {
        return (
          <>
            <span className="panlties-balance-list-value">
              {row?.quantity + " "}
            </span>
            {row?.quantity_unit?.toLowerCase() === "fixed_amount" ? (
              <FontAwesomeIcon icon={faCoins} />
            ) : (
              t(row?.quantity_unit ?? "days")
            )}
          </>
        );
      },
    },
    {
      name: t("reason"),
      selector: "reason",
      wrap: true,
      sortable: false,
      grow: 1.25,
      cell: (row) => (
        <ShowMoreText
          lines={1}
          more="Show more"
          less="Show less"
          expanded={false}
          width={280}
        >
          {t(row?.reason)}
        </ShowMoreText>
      ),
    },
    {
      name: t("remaining balance"),
      selector: "point_of_time_balance",
      wrap: true,
      sortable: false,
      grow: 1.25,
      ignoreRowClick: true,
      cell: (row) => (
        <>{remaningBalanceCalcualtion(row?.point_of_time_balance)}</>
      ),
      omit: props?.data?.type === REQUESTS.UNPAID_LEAVES,
    },
  ];
  const handleAddBalance = () => {
    props.showBalanceModal({
      ref: "balanceModalActions",
      formName: "balanceModal",
      data: { type: "add", emp_id: props.employeeId },
    });
  };

  const openExportModal = () => {
    const ReportFilterInput = {
      type: props.data?.type,
      year: props.data?.yearFilter,
      employee_id: props.employeeId,
      orderBy: [
        {
          order: props.data?.sorting?.dir.toUpperCase(),
          column: props.data?.sorting?.key,
        },
      ],
    };

    setExportModalState({ isOpen: true, data: { ReportFilterInput } });
  };

  return (
    <div className="col-md-12 col-lg-6 my-2 mb-xl-2 my-requests balance_wrapper_style">
      <div className="d-flex justify-content-between align-items-end custom-leave-balance-style mb-2">
        <div className="d-flex align-items-center gap-10">
          <div className="side-title mb-0">{t("leaves balance")}</div>
          <RadioboxForm
            formName={props.dataTableRef}
            reducer={props.reducer}
            name="type"
            optionLabel="label"
            optionValue="value"
            options={
              props.user?.employee?.permission_leaves_break_setting
                ?.sickLeaveSetting?.sick_leaves
                ? balanceFilterOptionsWithSick
                : balanceFilterOptions
            }
            getOptionLabel={(option) => t(option.label)}
            optionsContainerStyle="d-flex gap-10 align-items-center"
            optionItemStyle="d-flex text-nowrap"
            containerStyle="mb-0"
            optionInputStyle=" "
          />
        </div>

        <div className="d-flex align-items-center custom-leave-balance-select-plus-container">
          <BSelect
            name="yearFilter"
            optionLabel="value"
            optionValue="value"
            options={Constants.YearData}
            formName={props.dataTableRef}
            reducer={props.reducer}
            keepDefaultStyle
            containerStyle="year-picker"
            placeholder={t("year")}
            icon={<EventIcon />}
          />

          {props?.employeeId ? (
            <HasPrivileges
              reqireMain={[Privilages.ADD_DEDUCT_LEAVE_BALANCE]}
              allowBP
              avalibleOnExpire={false}
              altExpireChildren={
                <button
                  className="add-btn float-right btn mt-0 pt-1 ml-2"
                  disabled
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              }
            >
              <button
                className="add-btn float-right btn mt-0 pt-1 ml-2"
                onClick={handleAddBalance}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </HasPrivileges>
          ) : null}

          <ExportButton isIconOnly className="ml-2" onClick={openExportModal} />
        </div>
      </div>

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="cards_table"
        columns={columns}
        data={employeeBalanceData}
        noHeader
        persistTableHead
        sortServer
        onSort={props.onSorting}
        defaultSortAsc={props.data.sorting.dir == "asc"}
        defaultSortField={props.data.sorting.key}
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={props.onPaginate}
            customPaginator={pagination}
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={isLoading}
        progressComponent={<Loader />}
      />

      {exportModalState?.isOpen ? (
        <ExportModal
          data={exportModalState.data}
          onClose={() => setExportModalState(exportModalInitState)}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state, { reducer, dataTableRef }) => {
  return {
    data: state[reducer][dataTableRef],
  };
};

export default connect(mapStateToProps, { showBalanceModal })(MyBalance);

const ExportModal = ({ data, onClose }) => {
  const { t } = useTranslation();

  // Local State
  const [input, setInput] = React.useState({
    date_time: true,
    reason: true,
    quantity: true,
    quantity_unit: true,
    remaining_leaves: true,
  });

  const isFlexUser =
    useSelector(
      (state) => state.super.selectedEmployeeProfile?.employee?.__typename
    ) === "FlexEmployee";

  // Server State
  const [exportPenalties, { loading: exportLoading }] =
    useLazyQuery(EXPORT_LEAVE_BALANCE);

  /* ↓ Helpers ↓ */

  const handleChange = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const handleExport = () => {
    exportPenalties({
      variables: {
        input: {
          ...input,
          ReportFilterInput: data?.ReportFilterInput,
        },
        isFlexUser,
      },
      onCompleted: ({
        export_leave_balance_report = {},
        export_flex_balance_report = {},
      }) => {
        onClose();
        let leave_balance_report = isFlexUser
          ? export_flex_balance_report
          : export_leave_balance_report;
        HelperFns.downloadFile(leave_balance_report?.file);
        showToast("success", leave_balance_report?.message);
      },
      onError: () => {
        showToast("error");
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={onClose}
      btnOnClick={handleExport}
      btnSubmitLoading={exportLoading}
      modalTitle={t("export leave balance")}
    >
      {Object.keys(input).map((key) => (
        <CheckboxBooleanForm
          key={key}
          name={key}
          options={[t(key.replaceAll("_", " "))]}
          checked={input[key]}
          onChange={handleChange}
          setWithValue
        />
      ))}
    </MainModal>
  );
};
