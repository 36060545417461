import React, { useEffect, useState } from "react";
import { BSelect, InputForm } from "form-builder";
import moment from "moment";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import Loader from "../../Components/Loader";
import { useTranslation } from "react-i18next";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import {
  EditIconButton,
  TimesIconButton,
} from "../../Components/IconButtonWithTooltip";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  requestApprovalRule,
  requestApprovalRuleManagers,
  requestsApprovalLayersListQuery,
  requestsApprovalsLayersListFilters,
} from "../../Graphql/query";
import ShowMoreText from "react-show-more-text";
import RequestsApprovalLayersModal from "./RequestsApprovalLayersModal";
import { useDispatch, useSelector } from "react-redux";
import {
  onFormResetAction,
  showErrorToast,
  showSuccessToast,
} from "../../Store/Actions";
import Constants from "../../Constants";
import { deleteRequestApprovalRule } from "../../Graphql/mutation";
import Swal from "sweetalert2";
import { Spinner } from "reactstrap";
import {
  EXPENSE_CLAIM,
  InboundRequestTypeOptions,
  REQUEST_LOAN,
} from "../../Constants/Requests";
import HelperFns from "../../Helpers/HelperFns";

const formNameValidation = "requestApprovalFormValidations";

const paginationInitState = {
  total: 1,
  perPage: 10,
  lastPage: 1,
  lastItem: 1,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const formInitialState = {
  id: null,
  requestsTypes: null,
  applicableValues: null,
  appliesOnAllEmployees: true,
  excludedEmployees: null,
  approvalLayers: [{ acceptors: null }],
};

const RequestsApprovalLayersList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loadingIds, setLoadingIds] = useState([]);

  const [filters, setFilters] = useState({
    offices_ids: null,
    departments_ids: null,
    employee_ids: null,
    employee_search: null,
  });

  const auth = useSelector((state) => state?.auth?.userProfile);

  const authOfficeId = auth?.office?.id;

  const authDepartmentId = auth?.department?.id;

  const [isModalVissible, setIsModalVissible] = useState(false);
  const [formData, setFormData] = useState({
    ...formInitialState,
    applicableType: HelperFns.checkPrivileges({
      privileges: [
        Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_MY_OFFICE,
        Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_ALL_OFFICE,
      ],
      allowBP: true,
    })
      ? Constants.requestApprovalsApplicableTypes.Offices
      : HelperFns.checkPrivileges({
            privileges: [
              Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_MY_DEPARTMENT,
              Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_ALL_DEPARTMENT,
            ],
            allowBP: true,
          })
        ? Constants.requestApprovalsApplicableTypes.Departments
        : Constants.requestApprovalsApplicableTypes.Employees,
  });

  useEffect(() => {
    if (isModalVissible) {
      setFormData((prev) => ({
        ...prev,
        applicableValues: formInitialState.applicableValues,
        appliesOnAllEmployees: formInitialState.appliesOnAllEmployees,
        excludedEmployees: formInitialState.excludedEmployees,
      }));
    }
  }, [formData.applicableType]);

  useEffect(() => {
    if (isModalVissible) {
      setFormData((prev) => ({
        ...prev,
        excludedEmployees: formInitialState.excludedEmployees,
      }));
    }
  }, [formData.applicableValues]);

  const handleFiltersChange = (value, target) => {
    setFilters((prev) => ({ ...prev, [target?.name]: value }));
  };

  // handle employee search input on change function
  const handleEmployeeSearchInputOnChange = (e) => {
    setFilters((prevState) => {
      return { ...prevState, employee_search: e?.target?.value };
    });
  };

  const handleInputChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddLayer = () => {
    setFormData((prev) => ({
      ...prev,
      approvalLayers: [...prev?.approvalLayers, { acceptors: [] }],
    }));
  };

  const handleDeleteLayer = (index) => {
    setFormData((prev) => ({
      ...prev,
      approvalLayers: prev.approvalLayers.filter((_, i) => i !== index),
    }));
  };

  const handleLayerChange = (index, value) => {
    setFormData((prev) => ({
      ...prev,
      approvalLayers: formData.approvalLayers?.map((approvalLayer, i) => {
        if (i === index) {
          return { ...approvalLayer, acceptors: value };
        } else {
          return approvalLayer;
        }
      }),
    }));
  };

  const [pagination, setPagination] = useState(paginationInitState);

  const { data: filtersData, loading: fetchFiltersLoading } = useQuery(
    requestsApprovalsLayersListFilters
  );

  const getEmployeeOptions = () => {
    if (
      HelperFns.checkPrivileges({
        privileges: [
          Privilages.VIEW_APPROVAL_LAYERS_APPLIED_ON_EMPLOYEES,
          Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_EMPLOYEES,
        ],
        allowBP: true,
        scope: "all",
      })
    ) {
      return filtersData?.company_users?.data ?? [];
    } else if (
      HelperFns.checkPrivileges({
        privileges: [
          Privilages.VIEW_APPROVAL_LAYERS_APPLIED_ON_EMPLOYEES,
          Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_EMPLOYEES,
        ],
        allowBP: true,
        scope: "managed",
      })
    ) {
      return filtersData?.company_users?.data?.filter((user) => {
        return HelperFns.isManagedByAuth(
          user?.manager?.id,
          user?.copied_managers?.map((copiedManager) => copiedManager?.id)
        );
      });
    } else {
      return [];
    }
  };

  const requestsListQueryVariables = {
    offices: filters?.offices_ids?.map((office) => office?.id),
    departments: filters?.departments_ids?.map((department) => department?.id),
    employees: filters?.employee_ids?.map((employee) => employee?.id),
    ...(filters?.employee_search
      ? {
          employee_search: filters?.employee_search,
        }
      : {}),
    page: pagination?.currentPage,
  };

  const { data: listData, loading: listLoading } = useQuery(
    requestsApprovalLayersListQuery,
    {
      variables: requestsListQueryVariables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-first",
      onCompleted: (data) => {
        setPagination(data?.request_multi_layer_rules?.paginatorInfo ?? {});
      },
    }
  );

  const [
    attemptFetchRequestApprovalRule,
    { data: editRuleData, loading: editLoading },
  ] = useLazyQuery(requestApprovalRule);

  const refetchQueries = [
    {
      query: requestsApprovalLayersListQuery,
      variables: requestsListQueryVariables,
    },
  ];

  const [attemptDeleteRequestRole, { loading: deleteLoading }] = useMutation(
    deleteRequestApprovalRule,
    {
      refetchQueries,
    }
  );

  const handleDeleteRequestRule = (id) => {
    Swal.fire({
      icon: "warning",
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      showConfirmButton: true,
      confirmButtonText: t("Yes"),
      confirmButtonColor: "#7cd1f9",
      showDenyButton: true,
      denyButtonText: t("No"),
      showCancelButton: false,
      customClass: {
        popup: "swal-warning-style",
        container: "work_team_details_swal",
        icon: "swal-icon",
        denyButton: "order-2",
        confirmButton: "order-3",
      },
    }).then((values) => {
      if (values.isConfirmed) {
        setLoadingIds((prev) => [...prev, id]);
        attemptDeleteRequestRole({
          variables: {
            id,
          },
          onCompleted: (data) => {
            if (
              data?.delete_request_multi_layer_rule?.__typename ===
              "GeneralException"
            ) {
              dispatch(
                showErrorToast(
                  data?.delete_request_multi_layer_rule?.message ??
                    "Something went wrong"
                )
              );
              return;
            }
            dispatch(showSuccessToast("success"));
            setLoadingIds((prev) => prev?.filter((id) => id !== id));
          },
          onError: (error) => {
            dispatch(
              showErrorToast(
                error?.graphQLErrors?.[0]?.message ?? "Something went wrong"
              )
            );
            setLoadingIds((prev) => prev?.filter((id) => id !== id));
          },
        });
      }
    });
  };

  const handlePaginate = (page = pagination.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const [fetchManagers, { loading: fetchManagersLoading, data: managers }] =
    useLazyQuery(requestApprovalRuleManagers, {
      onCompleted: (data) => {
        setIsModalVissible(true);
      },
    });

  const handleNewRequestApprovalRule = () => {
    fetchManagers();
  };

  const getApplicableValueObjects = (applicableType, applicables) => {
    const applicableIds = applicables?.map(
      (applicableValue) => applicableValue?.type?.id
    );

    switch (applicableType) {
      case Constants.requestApprovalsApplicableTypes.Offices:
        return filtersData?.company_offices?.data?.filter((office) =>
          applicableIds?.includes(office?.id)
        );
      case Constants.requestApprovalsApplicableTypes.Departments:
        return filtersData?.company_departments?.data?.filter((department) =>
          applicableIds?.includes(department?.id)
        );
    }
  };

  const handleEditRequestRule = (ruleId) => {
    setLoadingIds((prev) => [...prev, ruleId]);
    attemptFetchRequestApprovalRule({
      variables: { id: ruleId },
      onCompleted: (data) => {
        const {
          id,
          layers = [],
          request_types = [],
          applicable = [],
          employees = [],
        } = data?.request_multi_layer_rule ?? {};

        setLoadingIds((prev) => prev?.filter((id) => id !== id));

        setFormData((prev) => ({
          ...prev,
          id,
          requestsTypes: [
            ...InboundRequestTypeOptions,
            { label: "Loan Request", value: REQUEST_LOAN },
            { label: "claim Request", value: EXPENSE_CLAIM },
          ]?.filter((request) =>
            request_types?.find(
              (requestType) => requestType?.id == request?.value
            )
          ),

          applicableType:
            applicable?.[0]?.type?.__typename ??
            Constants.requestApprovalsApplicableTypes.Employees,

          applicableValues: !applicable?.[0]?.type?.__typename
            ? employees
            : getApplicableValueObjects(
                applicable?.[0]?.type?.__typename,
                applicable
              ),

          appliesOnAllEmployees: applicable?.[0]?.applies_on_all,

          approvalLayers: layers,

          excludedEmployees: applicable?.[0]?.applies_on_all ? null : employees,
        }));

        setIsModalVissible(true);
      },
      onError: (error) => {
        dispatch(
          showErrorToast(
            error?.graphQLErrors?.[0]?.message ?? "Something went wrong"
          )
        );
        setLoadingIds((prev) => prev?.filter((id) => id !== id));
      },
    });
  };

  const canEditDeleteRequestApprovalRule = (rule) => {
    const applicableType = rule?.applicable?.[0]?.type?.__typename;

    switch (applicableType) {
      case Constants.requestApprovalsApplicableTypes.Offices:
        if (
          HelperFns.checkPrivileges({
            privileges: [
              Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_ALL_OFFICE,
            ],
            allowBP: true,
          })
        ) {
          return true;
        } else if (
          HelperFns.checkPrivileges({
            privileges: [
              Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_MY_OFFICE,
            ],
            allowBP: true,
          }) &&
          rule?.applicable?.find(
            (applicable) => applicable?.type?.id == authOfficeId
          )
        ) {
          return true;
        } else {
          return false;
        }

      case Constants.requestApprovalsApplicableTypes.Departments:
        if (
          HelperFns.checkPrivileges({
            privileges: [
              Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_ALL_DEPARTMENT,
            ],
            allowBP: true,
          })
        ) {
          return true;
        } else if (
          HelperFns.checkPrivileges({
            privileges: [
              Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_MY_DEPARTMENT,
            ],
            allowBP: true,
          }) &&
          rule?.applicable?.find(
            (applicable) => applicable?.type?.id == authDepartmentId
          )
        ) {
          return true;
        } else {
          return false;
        }

      // applied on employees case
      case undefined:
        return HelperFns.checkPrivileges({
          privileges: [Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_EMPLOYEES],
          allowBP: true,
        });
    }
  };

  const columns = [
    {
      name: t("request types"),
      wrap: true,
      selector: "request_types",
      width: "300px",
      cell: (row) => {
        const requestTypesNames = row?.request_types
          ?.map((requestType) => t(requestType?.name))
          ?.join(" , ");
        return (
          <div className="py-2">
            {row?.request_types?.length > 1 ? (
              <ShowMoreText
                lines={1}
                more={t("Show more")}
                less={t("Show less")}
                expanded={true}
                width={200}
              >
                {requestTypesNames}
              </ShowMoreText>
            ) : (
              requestTypesNames
            )}
          </div>
        );
      },
      grow: 1,
    },
    {
      name: t("applicable on"),
      selector: "applicable_on",
      wrap: true,
      grow: 1.25,
      width: "200px",
      cell: (row) =>
        !row?.applicable?.[0]?.type?.__typename ? (
          row?.employees?.length > 1 ? (
            <ShowMoreText
              lines={1}
              more={t("Show more")}
              less={t("Show less")}
              expanded={true}
              width={140}
            >
              {row?.employees?.map((emp) => emp?.name)?.join(" , ")}
            </ShowMoreText>
          ) : (
            row?.employees?.map((emp) => emp?.name)?.join(" , ")
          )
        ) : row?.applicable?.length > 1 ? (
          <ShowMoreText
            lines={1}
            more={t("Show more")}
            less={t("Show less")}
            expanded={true}
            width={140}
          >
            {row?.applicable
              ?.map(
                (applicable) =>
                  `${applicable?.type?.name} ${t(applicable?.type?.__typename)}`
              )
              ?.join(" , ")}
          </ShowMoreText>
        ) : (
          row?.applicable
            ?.map(
              (applicable) =>
                `${applicable?.type?.name} ${t(applicable?.type?.__typename)}`
            )
            ?.join(" , ")
        ),
    },
    {
      name: t("no. of layers"),
      selector: "no_of_layerse",
      wrap: true,
      grow: 1,
      cell: (row) => row?.layers?.length,
    },
    {
      name: t("created by"),
      selector: "created_By",
      wrap: true,
      grow: 2,
      cell: (row) => row?.created_by?.name,
    },

    {
      name: t("applied from"),
      selector: "applied_from",
      wrap: true,
      grow: 1,
      cell: (row) =>
        moment(row?.created_at, "YYYY-MM-DD hh:mm:ss").format("YYYY-MM-DD"),
    },
    {
      name: "",
      wrap: true,
      grow: 1,
      ignoreRowClick: true,
      cell: (row) => {
        if (canEditDeleteRequestApprovalRule(row)) {
          return (
            <div className="cards_table_actions">
              {(deleteLoading || editLoading) &&
              loadingIds?.includes(row?.id) ? (
                <Spinner style={{ color: "#23aaeb", fontSize: 14 }} />
              ) : (
                <>
                  <EditIconButton
                    onClick={() => handleEditRequestRule(row?.id)}
                  />
                  <TimesIconButton
                    label={t("delete")}
                    onClick={() => handleDeleteRequestRule(row?.id)}
                  />
                </>
              )}
            </div>
          );
        } else {
          return <></>;
        }
      },

      // </HasPrivileges>
    },
  ];

  const handleCloseModal = () => {
    setIsModalVissible(false);
    setFormData({
      ...formInitialState,
      applicableType: HelperFns.checkPrivileges({
        privileges: [
          Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_MY_OFFICE,
          Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_ALL_OFFICE,
        ],
        allowBP: true,
      })
        ? Constants.requestApprovalsApplicableTypes.Offices
        : HelperFns.checkPrivileges({
              privileges: [
                Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_MY_DEPARTMENT,
                Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_ALL_DEPARTMENT,
              ],
              allowBP: true,
            })
          ? Constants.requestApprovalsApplicableTypes.Departments
          : Constants.requestApprovalsApplicableTypes.Employees,
    });
    dispatch(onFormResetAction(formNameValidation));
  };

  return (
    <div className="request_approval_layers pt-4">
      {false ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="mb-2 pl-0 d-flex flex-column flex-md-row align-items-md-center justify-content-between pr-0 m-0">
        <InputForm
          name="employee_search"
          icon="search"
          type="search"
          placeholder={t("employee search")}
          value={filters?.employee_search?.trimStart()}
          onChange={(e) => handleEmployeeSearchInputOnChange(e)}
          inputContainerStyle="w-100 mx-2"
          rootStyle="w-100"
        />

        <HasPrivileges
          reqireMain={[Privilages.VIEW_APPROVAL_LAYERS_APPLIED_ON_ALL_OFFICE]}
          allowBP
        >
          <BSelect
            name="offices_ids"
            options={filtersData?.company_offices?.data ?? []}
            keepDefaultStyle
            isClearable
            placeholder={t("offices")}
            rootStyle="mr-0 mr-md-2 w-100"
            icon="type"
            value={filters.offices_ids}
            onChange={handleFiltersChange}
            isLoading={fetchFiltersLoading}
            isMulti
            skipLocalization
          />
        </HasPrivileges>

        <HasPrivileges
          reqireMain={[
            Privilages.VIEW_APPROVAL_LAYERS_APPLIED_ON_ALL_DEPARTMENT,
          ]}
          allowBP
        >
          <BSelect
            name="departments_ids"
            options={filtersData?.company_departments?.data ?? []}
            keepDefaultStyle
            isClearable
            placeholder={t("departments")}
            rootStyle="mr-0 mr-md-2 w-100"
            icon="type"
            value={filters.departments_ids}
            onChange={handleFiltersChange}
            isLoading={fetchFiltersLoading}
            isMulti
          />
        </HasPrivileges>

        <HasPrivileges
          reqireMain={[Privilages.VIEW_APPROVAL_LAYERS_APPLIED_ON_EMPLOYEES]}
          allowBP
        >
          <BSelect
            name="employee_ids"
            options={getEmployeeOptions()}
            keepDefaultStyle
            isClearable
            placeholder={t("employees")}
            rootStyle="mr-0 mr-md-2 w-100"
            icon="type"
            value={filters.employee_ids}
            onChange={handleFiltersChange}
            isLoading={fetchFiltersLoading}
            isMulti
          />
        </HasPrivileges>

        <HasPrivileges
          allowBP
          reqireMain={[
            Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_MY_OFFICE,
            Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_ALL_OFFICE,
            Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_MY_DEPARTMENT,
            Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_ALL_DEPARTMENT,
            Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_EMPLOYEES,
          ]}
        >
          <div className="align-items-center d-flex col justify-content-end p-0">
            <button
              type="button"
              className={`btn py-1 px-3 add_new_btn_style text-nowrap `}
              onClick={handleNewRequestApprovalRule}
              disabled={fetchManagersLoading}
            >
              {fetchManagersLoading ? (
                <Spinner style={{ width: 20, height: 20 }} />
              ) : (
                t("new rule")
              )}
            </button>
          </div>
        </HasPrivileges>
      </div>

      <DataTable
        className="cards_table"
        columns={columns}
        data={listData?.request_multi_layer_rules?.data ?? []}
        noHeader
        persistTableHead
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
        pagination={true} // consider changing these
        paginationServer={true} // consider changing these
        progressPending={listLoading} // consider changing these
        progressComponent={<Loader />}
      />

      {isModalVissible ? (
        <RequestsApprovalLayersModal
          isModalVissible={isModalVissible}
          handleCloseModal={handleCloseModal}
          formData={formData}
          handleInputChange={handleInputChange}
          handleAddLayer={handleAddLayer}
          handleLayerChange={handleLayerChange}
          officesOptions={filtersData?.company_offices?.data ?? []}
          departmentsOptoins={filtersData?.company_departments?.data ?? []}
          managersOptions={
            managers?.company_users?.data ??
            editRuleData?.company_users?.data ??
            []
          }
          employeesOptions={getEmployeeOptions()}
          handleDeleteLayer={handleDeleteLayer}
          refetchQueries={refetchQueries}
        />
      ) : null}
    </div>
  );
};

export default RequestsApprovalLayersList;
