import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import ViewHrEmployees from "../ViewHrEmployees";
import EmployeesTabs from "./EmployeesTabs";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import HelperFns from "../../Helpers/HelperFns";
import { ViewWorkGroups } from "../../Containers/WorkGroupsList";
import { ViewWorkTeams } from "../ViewWorkTeams";
import { useSelector } from "react-redux";
import ViewEmployeeActions from "../ViewEmployeeActions";
import ViewDocuments from "../ViewDocuments";
import ShiftsAndFlexWorkGroups from "./ShiftsAndFlexWorkGroups";
import ShiftsAndFlexWorkTeams from "./ShiftsAndFlexWorkTeams";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const EmployeesPage = (props) => {
  const history = useHistory();
  const query = useQuery();
  const activeValue = query.get("tab");

  useEffect(() => {
    if (!activeValue) {
      renderfirstTab();
    }
  }, [activeValue]);

  const renderfirstTab = () => {
    if (
      HelperFns.checkPrivileges({
        privileges: [
          Privilages.VIEW_EMPLOYEES,
          Privilages.VIEW_EMPLOYEE_SALARY_CONFIGURATION,
          Privilages.VIEW_EMPLOYEE_ATTENDANCE_TYPE_CONFIGURATIONS,
          Privilages.VIEW_EMPLOYEE_PETTY_CASH_BALANCE_IN_EMPLOYEE_PROFILE,
          Privilages.VIEW_EMPLOYEE_DOCUMENTS,
          Privilages.VIEW_EMPLOYEE_SCHEDULES,
        ],
        allowBP: true,
      })
    ) {
      return history.push(`/employees?tab=employees`);
    }

    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_EMPLOYEE_WORK_GROUPS],
        allowBP: true,
      })
    ) {
      return history.push(`/employees?tab=work-groups`);
    }

    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_WORK_TEAMS],
        allowBP: true,
      })
    ) {
      return history.push(`/employees?tab=work-teams`);
    }

    if (
      HelperFns.checkPrivileges({
        privileges: [
          Privilages.VIEW_EMPLOYEES,
          Privilages.EDIT_USER_PRIVILEGE,
          Privilages.ADD_EDIT_DELETE_EMPLOYEES,
        ],
        allowBP: true,
      })
    ) {
      return history.push(`/employees?tab=employee-actions`);
    }

    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_MISSING_EXPIRED_DOCUMENT],
        allowBP: true,
      })
    ) {
      return history.push(`/employees?tab=employee-documents`);
    }

    return null;
  };

  const renderSelectedTab = () => {
    switch (activeValue?.toLowerCase()) {
      case "employees":
        return (
          <HasPrivileges
            reqireMain={[
              Privilages.VIEW_EMPLOYEES,
              Privilages.VIEW_EMPLOYEE_SALARY_CONFIGURATION,
              Privilages.VIEW_EMPLOYEE_ATTENDANCE_TYPE_CONFIGURATIONS,
              Privilages.VIEW_EMPLOYEE_PETTY_CASH_BALANCE_IN_EMPLOYEE_PROFILE,
              Privilages.VIEW_EMPLOYEE_DOCUMENTS,
              Privilages.VIEW_EMPLOYEE_SCHEDULES,
            ]}
            allowBP
          >
            <ViewHrEmployees />
          </HasPrivileges>
        );

      case "work-groups":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_EMPLOYEE_WORK_GROUPS]}
            allowBP={true}
          >
            <ShiftsAndFlexWorkGroups />
          </HasPrivileges>
        );

      case "work-teams":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_WORK_TEAMS]}
            allowBP={true}
          >
            <ShiftsAndFlexWorkTeams />
          </HasPrivileges>
        );

      case "employee-actions":
        return (
          <HasPrivileges
            reqireMain={[
              Privilages.VIEW_EMPLOYEES,
              Privilages.EDIT_USER_PRIVILEGE,
              Privilages.ADD_EDIT_DELETE_EMPLOYEES,
            ]}
            allowBP={true}
          >
            <ViewEmployeeActions />
          </HasPrivileges>
        );

      case "employee-documents":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_MISSING_EXPIRED_DOCUMENT]}
            allowBP={true}
          >
            <ViewDocuments />
          </HasPrivileges>
        );

      default:
        break;
    }
  };
  return (
    <div className="content pt-3">
      <EmployeesTabs />
      {renderSelectedTab()}
    </div>
  );
};

export default EmployeesPage;
