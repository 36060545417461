import React, { useEffect, useState } from "react";
import Chart from "chart.js";
import { DateTimePickerForm } from "form-builder";

import moment from "moment";
import _ from "lodash";
import { faker } from "@faker-js/faker";
import { Config } from "../../Config";
import axios from "axios";

import { Box, Tab, Tabs } from "@mui/material";

const generateTimeRange = (filter) => {
  let startTime, numHours;

  if (filter) {
    startTime = moment(filter).format("HH:mm");
    numHours = 1;
  } else {
    startTime = "00:00";
    numHours = 24;
  }
  let timeRange = [];
  let currentTime = moment(startTime, "HH:mm");

  for (let i = 0; i < numHours * 60; i++) {
    timeRange.push(currentTime.format("HH:mm"));
    currentTime.add(1, "minutes");
  }

  return timeRange;
};

const getDataSets = (data, tab = 1, filter) => {
  let date = moment(filter?.date).format("YYYY-MM-DD");
  let time = filter?.hour ? moment(filter?.hour).format("HH:mm") : "00:00";
  let startHour = moment(`${date} ${time}`, "YYYY-MM-DD HH:mm");
  let endHour = moment(`${date} ${time}`, "YYYY-MM-DD HH:mm").add(1, "hour");

  const filteredDates = filter?.hour
    ? _.filter(data?.data, ({ start_time }) => {
        return moment(start_time).isBetween(startHour, endHour, true);
      })
    : data?.data;

  const groupedByName = _.groupBy(filteredDates, "name");

  // Reformat the data to group by minute and calculate average processing time for each minute
  const reformattedData = _.mapValues(groupedByName, (logs) => {
    // Group by minute
    const groupedByMinute = _.groupBy(logs, (log) =>
      moment(log.start_time).format("HH:mm")
    );

    // Calculate average processing time for each minute
    const averagedData = _.map(groupedByMinute, (logsInMinute) => {
      const totalProcessingTime = _.sumBy(logsInMinute, (log) => +log.interval);

      const processingTimeOverSecondCount = _.filter(
        logsInMinute,
        (obj) => +obj.interval >= 1
      ).length;

      const totalProcessingTimeOverSecond = _.sumBy(
        _.filter(logsInMinute, (obj) => +obj.interval >= 1),
        "interval"
      );

      const maxProcessingTime = _.maxBy(logsInMinute, "interval")?.interval;
      const minProcessingTime = _.minBy(logsInMinute, "interval")?.interval;
      const averageProcessingTime = (
        +totalProcessingTime / +logsInMinute?.length
      )?.toFixed(2);
      const averageProcessingTimeOverSecond = (
        totalProcessingTimeOverSecond / processingTimeOverSecondCount
      ).toFixed(2);
      return {
        start_time: moment(logsInMinute[0].start_time).format("HH:mm"),
        average_processing_time: averageProcessingTime,
        count: logsInMinute.length,
        processingTimeOverSecondCount,
        y:
          tab == 1
            ? logsInMinute.length
            : tab == 2
              ? averageProcessingTime
              : processingTimeOverSecondCount,
        maxProcessingTime,
        averageProcessingTimeOverSecond,
        minProcessingTime,
        x: moment(logsInMinute[0].start_time).format("HH:mm"),
      };
    });
    return averagedData;
  });

  return Object.entries(reformattedData)?.map(([key, val], index) => {
    const color = faker.color.rgb({ format: "hex", casing: "lower" });
    return {
      label: key,
      data: val,
      borderColor: color,
      backgroundColor: color,
      fill: false,
      // pointHoverRadius: index + 2,
    };
  });
};

let Line;
let Line2;
let Line3;

const ProcessedQueries = () => {
  const [filter, setFilter] = useState({
    date: moment(),
    hour: null,
  });
  const [type, setType] = useState("query");

  const [jobsData, setJobsData] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchJobs = async () => {
    setLoading(true);
    let date = moment(filter.date).format("YYYY-MM-DD");

    try {
      const response = await axios.get(
        `${Config.API_URL}/requests-with-name/${date}/${type}`
      );
      setJobsData(response?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    fetchJobs();

    return () => {};
  }, [filter.date, type]);

  const [refreshChart, setRefreshChart] = useState(false);

  useEffect(() => {
    if (!loading) {
      // apply space between legends and chart

      const ctx = document?.getElementById("LineChart")?.getContext("2d");
      if (typeof Line !== "undefined") Line?.destroy();
      if (ctx) {
        ctx.height = 1200;

        Line = new Chart(ctx, {
          type: "line",
          PositionType: "chartArea",
          data: {
            labels: generateTimeRange(filter?.hour),
            datasets: getDataSets(jobsData, 1, filter),
          },
          aspectRatio: 1,
          options: {
            elements: {
              line: {
                tension: 0, // disables bezier curves
              },
            },
            animation: {
              duration: 0, // general animation time
            },
            responsiveAnimationDuration: 0, // animation duration after a resize
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              position: "top",
              align: "start",
              fullWidth: true,
              labels: {
                usePointStyle: true,
              },
            },

            tooltips: {
              mode: "single",
              footerFontStyle: "normal",
              footerFontSize: 10,
              callbacks: {
                // label: function () {
                //     return null;
                // },
                title: function () {
                  return null;
                },
                // title:
                footer: function (tooltipItem, data) {
                  let pointValue =
                    data?.datasets?.[tooltipItem[0]?.datasetIndex]?.data?.[
                      tooltipItem[0]?.index
                    ] ?? null;
                  let content = "";
                  if (pointValue) {
                    content +=
                      "average processing time: " +
                      pointValue?.average_processing_time +
                      ",\n";
                    content +=
                      "average processing time: " +
                      pointValue?.average_processing_time +
                      ",\n";
                    content +=
                      "max processing time: " +
                      pointValue?.maxProcessingTime +
                      ",\n";
                    content +=
                      "min processing time: " +
                      pointValue?.minProcessingTime +
                      ",\n";
                    content += "count: " + pointValue?.count + ", \n";
                    content +=
                      "count over one second: " +
                      pointValue?.processingTimeOverSecondCount +
                      ", \n";
                    content += "start time: " + pointValue?.x + ",";
                  }

                  return content;
                },
              },
            },
            hover: {
              mode: "point",
              intersect: true,
              animationDuration: 0,
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    fontColor: "#5c6974",
                    source: "labels",
                    maxTicksLimit: filter?.hour ? 60 : 24,
                  },
                  gridLines: {
                    display: true,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: false,
                    fontColor: "#313030",
                  },
                  gridLines: {
                    display: true,
                  },
                },
              ],
            },
          },
        });
      }
      const ctx2 = document?.getElementById("LineChart2")?.getContext("2d");
      if (typeof Line2 !== "undefined") Line2?.destroy();
      if (ctx2) {
        ctx2.height = 1200;
        // ctx.width = 10;
        // intiliazing Line Chart and its options
        Line2 = new Chart(ctx2, {
          type: "line",
          PositionType: "chartArea",
          data: {
            labels: generateTimeRange(filter?.hour),
            datasets: getDataSets(jobsData, 2, filter),
          },
          aspectRatio: 1,
          options: {
            elements: {
              line: {
                tension: 0, // disables bezier curves
              },
            },
            animation: {
              duration: 0, // general animation time
            },
            responsiveAnimationDuration: 0, // animation duration after a resize
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              position: "top",
              align: "start",
              fullWidth: true,
              labels: {
                usePointStyle: true,
              },
            },

            tooltips: {
              mode: "single",
              footerFontStyle: "normal",
              footerFontSize: 10,
              callbacks: {
                // label: function () {
                //     return null;
                // },
                title: function () {
                  return null;
                },
                // title:
                footer: function (tooltipItem, data) {
                  let pointValue =
                    data?.datasets?.[tooltipItem[0]?.datasetIndex]?.data?.[
                      tooltipItem[0]?.index
                    ] ?? null;
                  let content = "";
                  if (pointValue) {
                    content +=
                      "average processing time: " +
                      pointValue?.average_processing_time +
                      ",\n";
                    content +=
                      "max processing time: " +
                      pointValue?.maxProcessingTime +
                      ",\n";
                    content +=
                      "min processing time: " +
                      pointValue?.minProcessingTime +
                      ",\n";
                    content += "count: " + pointValue?.count + ", \n";
                    content +=
                      "count over one second: " +
                      pointValue?.processingTimeOverSecondCount +
                      ", \n";
                    content += "start time: " + pointValue?.x + ",";
                  }

                  return content;
                },
              },
            },
            hover: {
              mode: "point",
              intersect: true,
              animationDuration: 0,
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    fontColor: "#5c6974",
                    source: "labels",
                    maxTicksLimit: filter?.hour ? 60 : 24,
                    // major: 23,
                    // minor: 0
                  },
                  gridLines: {
                    display: true,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    fontColor: "#313030",
                  },
                  gridLines: {
                    display: true,
                  },
                },
              ],
            },
          },
        });
      }
      const ctx3 = document?.getElementById("LineChart3")?.getContext("2d");
      if (typeof Line3 !== "undefined") Line3?.destroy();
      if (ctx3) {
        ctx3.height = 1200;
        // ctx.width = 10;
        // intiliazing Line Chart and its options
        Line3 = new Chart(ctx3, {
          type: "line",
          PositionType: "chartArea",
          data: {
            labels: generateTimeRange(filter?.hour),
            datasets: getDataSets(jobsData, 3, filter),
          },
          aspectRatio: 1,
          options: {
            elements: {
              line: {
                tension: 0, // disables bezier curves
              },
            },
            animation: {
              duration: 0, // general animation time
            },
            responsiveAnimationDuration: 0, // animation duration after a resize
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              position: "top",
              align: "start",
              fullWidth: true,
              labels: {
                usePointStyle: true,
              },
            },

            tooltips: {
              mode: "single",
              footerFontStyle: "normal",
              footerFontSize: 10,
              callbacks: {
                // label: function () {
                //     return null;
                // },
                title: function () {
                  return null;
                },
                // title:
                footer: function (tooltipItem, data) {
                  let pointValue =
                    data?.datasets?.[tooltipItem[0]?.datasetIndex]?.data?.[
                      tooltipItem[0]?.index
                    ] ?? null;
                  let content = "";
                  if (pointValue) {
                    content +=
                      "average processing time: " +
                      pointValue?.average_processing_time +
                      ",\n";
                    content +=
                      "max processing time: " +
                      pointValue?.maxProcessingTime +
                      ",\n";
                    content +=
                      "min processing time: " +
                      pointValue?.minProcessingTime +
                      ",\n";
                    content += "count: " + pointValue?.count + ", \n";
                    content +=
                      "count over one second: " +
                      pointValue?.processingTimeOverSecondCount +
                      ", \n";
                    content += "start time: " + pointValue?.x + ",";
                  }

                  return content;
                },
              },
            },
            hover: {
              mode: "point",
              intersect: true,
              animationDuration: 0,
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    fontColor: "#5c6974",
                    source: "labels",
                    maxTicksLimit: filter?.hour ? 60 : 24,
                    // major: 23,
                    // minor: 0
                  },
                  gridLines: {
                    display: true,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    fontColor: "#313030",
                  },
                  gridLines: {
                    display: true,
                  },
                },
              ],
            },
          },
        });
      }
    }
  }, [loading, refreshChart]);

  const handleChange = (e, newValue) => {
    setActiveTab(newValue);
  };

  const toggle = () => {
    if (activeTab == 1) {
      Line.data.datasets.forEach((dataset) => {
        Object.keys(dataset._meta).forEach((key) => {
          const current = !dataset._meta[key].hidden;
          dataset._meta[key].hidden = current || null;
        });
      });
      Line.update();
    }
    if (activeTab == 2) {
      Line2.data.datasets.forEach((dataset) => {
        Object.keys(dataset._meta).forEach((key) => {
          const current = !dataset._meta[key].hidden;
          dataset._meta[key].hidden = current || null;
        });
      });
      Line2.update();
    } else {
      Line3.data.datasets.forEach((dataset) => {
        Object.keys(dataset._meta).forEach((key) => {
          const current = !dataset._meta[key].hidden;
          dataset._meta[key].hidden = current || null;
        });
      });
      Line3.update();
    }
  };

  return (
    <div
      style={{
        overflowX: "scroll",
      }}
      className="p-5"
    >
      <DateTimePickerForm
        label="select date"
        name="date"
        placeholder="select date"
        value={filter?.date}
        onChange={(date) => setFilter((prevState) => ({ ...prevState, date }))}
        onSelect={(date) => setFilter((prevState) => ({ ...prevState, date }))}
        rootStyle="ml-3 w-100"
        containerStyle="w-100"
        hasIcon
        // isClearable
      />
      <div className="row align-items-center gap-10 mt-2 mx-0">
        <fieldset className="gap-5 align-items-center d-flex">
          <input
            type="radio"
            name="type"
            value="query"
            onChange={(e) => setType(e.target.value)}
            checked={type == "query"}
          />
          <label>query</label>
        </fieldset>
        <fieldset className="gap-5 align-items-center d-flex">
          <input
            type="radio"
            name="type"
            value="mutation"
            onChange={(e) => setType(e.target.value)}
            checked={type == "mutation"}
          />
          <label>mutation</label>
        </fieldset>
      </div>

      <Box
        className="notification-drawer-main-container"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tabs onChange={handleChange} value={activeTab}>
          <Tab label={"counts"} value={1} />
          <Tab label={"avg processed time"} value={2} />
          <Tab label={"counts over second"} value={3} />
        </Tabs>
      </Box>

      {loading ? (
        <h1>loading </h1>
      ) : (
        <>
          <div className="row gap-20 align-items-end mt-3 mb-1 mx-0">
            <DateTimePickerForm
              label="select hour"
              name="hour"
              placeholder="select hour"
              value={filter?.hour}
              onChange={(hour) =>
                setFilter((prevState) => ({ ...prevState, hour }))
              }
              onSelect={(hour) =>
                setFilter((prevState) => ({ ...prevState, hour }))
              }
              rootStyle="w-250"
              containerStyle="w-100"
              hasIcon
              timeOnly
              isClearable
            />
            <button
              className="btn-dark"
              onClick={() => setRefreshChart(!refreshChart)}
            >
              update chart
            </button>
          </div>

          <button className="my-2" onClick={toggle}>
            toggle
          </button>
          <div
            style={{
              width: "2400px",
              minHeight: "1200px",
            }}
          >
            <canvas
              className={`${activeTab == 1 ? "d-block" : "d-none"}`}
              id="LineChart"
            ></canvas>
            <canvas
              className={`${activeTab == 2 ? "d-block" : "d-none"}`}
              id="LineChart2"
            ></canvas>
            <canvas
              className={`${activeTab == 3 ? "d-block" : "d-none"}`}
              id="LineChart3"
            ></canvas>
          </div>
        </>
      )}
    </div>
  );
};

export default ProcessedQueries;
