import moment from "moment";
import {
  EDIT_FLEX_WORK_GROUP,
  TOGGLE_EDIT_CLOCKINS_CONTAINER,
  TOGGLE_FLEX_ATTENDANCE_ACTION_MODAL,
} from "../Actions/flexActions/types";
import Types from "../Actions/types";
import { normalizeFetchedMonthlyProfile } from "../../Helpers/HelperFns";
import _ from "lodash";

export const initState = {
  upsertFlexWorkGroupForm: {
    id: null,
    name: "",
    managersIds: [],
    employeesIds: [],
    workDayTemplatesIds: [],
    locationsIds: [],
  },
  upsertFlexWorkGroupFormValidation: [],
  upsertFlexWorkGroupFormServerValidation: {},

  upsertFlexWorkTeamForm: {
    id: null,
    name: "",
    workGroup: null,
    employeesIds: [],
    start: null,
  },
  upsertFlexWorkTeamFormValidation: [],
  upsertFlexWorkTeamFormServerValidation: {},

  // Flexible
  workHoursFilters: {
    search: "",
    type: "Active",
  },
  MonthlyProfileForm: {
    id: null,
    name: "",
    setRestrictions: 0,
    applyRestrictionsPer: "monthly",
    setMinimumHrs: 0,
    minimumHrs: 0,
    penaltyFactor: 0,
    deductFrom: "Leaves",
    calculateOvertime: 0,
    hoursPerMonth: 0,
    calculateOvertimeAfterWorkHoursBy: 0,
    setOvertimeMonthLimit: 0,
    overtimeMonthLimit: 0,
    setMaxHrsPerMonth: 0,
    maxHrsPerMonth: 0,
    treatExtraHoursAs: "normal",
    overtimeHourRate: 0,
    overtimeFactor: 0,
  },
  workDaysFilters: {
    search: "",
    type: "All",
  },
  WorkDaysUpsertModal: {
    name: "",
    hoursPerOneLeaveDay: 0,
    setRestrictions: 0,
    setMinDailyHrs: 0,
    minDailyHrs: 0,
    calcPenalty: 0,
    deductFrom: "Leaves",
    isCalcOvertime: 0,
    hoursPerDay: 0,
    overtimeAfter: 0,
    setOvertimeDayLimit: 0,
    overtimeDayLimit: 0,
    setMaxHrsPerDay: 0,
    maxHrsPerDay: 0,
    treatExtraHoursAs: "normal",
  },

  timeSheetListFilters: {
    to: "",
    from: "",
    office: "",
    employee: "",
    department: "",
    onlyNoSignOut: 0,
  },
  timeSheetUpsertModal: {
    status: "attended",
    signInTime: "",
    signOutTime: "",
    signInWorkPlace: "",
    signOutWorkPlace: "",
    leaveType: "",
    leaveHours: "",

    // assignment (dayoff or holiday to default) inputs
    apply_compensation: 0,
    weight: 1,
    compensation_type: null,
    compensation_quantity: null,
    treat_as_normal: false,
    allow_permission: false,
    allow_overtime: false,
    workplace_setting: "DEFAULT",
    workplace_setting_signout: "DEFAULT",
    additional_work_places: [],
    additional_work_places_signout: [],
    workplace_setting_identical: 1,

    holiday_workplace_setting: "DEFAULT",
    holiday_workplace_setting_signout: "DEFAULT",
    holiday_additional_work_places: [],
    holiday_additional_work_places_signout: [],
    holiday_workplace_setting_identical: 1,
  },

  flexibleClientValidation: [],
  flexibleServerValidation: {},

  addWorkTeamMembersForm: {
    start: "",
    employeesIds: [],
  },
  addWorkTeamMembersFormValidation: [],
  addWorkTeamMembersFormServerValidation: {},

  editWorkTeamMembersForm: {
    name: "",
  },
  editWorkTeamMembersFormValidation: [],
  editWorkTeamMembersFormServerValidation: {},

  moveWorkTeamMembersForm: {
    toId: [],
    start: "",
  },
  moveWorkTeamMembersFormValidation: [],
  moveWorkTeamMembersFormServerValidation: {},

  workScheduleForm: {},
  workScheduleFormValidation: [],
  workScheduleFormServerValidation: {},

  employeeWorkScheduleForm: {
    id: null,
    workdayType: "fixed",
    attendanceType: "office based",
    start: "",
    workGroupId: null,
    workTeamId: null,
    permissionLeavesBreakSettingId: null,
    joiningFrom: null,
    allowMultipleCheckIns: 0,
    allow_customize_check_ins: 0,
    check_in_form_id: null,
    check_out_form_id: null,
    attendanceProfileId: null,
    can_work_home: 0, // work remotely
    max_homeDays_per_week: 0, // work remotely
    flexible_home: 1, // work remotely
    home_days: [], // work remotely
    can_ex_days: 0, // work remotely
    first_day_of_the_week: null, // work remotely (in case of shifts based only)

    allow_work_on_day_off: false,
    day_off_exception_id: null,
    apply_compensation: 0,
    weight: 1,
    compensation_type: null,
    compensation_quantity: null,
    treat_as_normal: false,
    allow_permission: false,
    allow_overtime: false,
    workplace_setting: "DEFAULT",
    workplace_setting_signout: "DEFAULT",
    additional_work_places: [],
    additional_work_places_signout: [],
    workplace_setting_identical: 1,

    check_in_setting_id: null,
    check_in_settings_as_work_timing_sign_in_settings: 1,
    reuiqre_facial_recognition: 0,
    employee_can_check_in_from: "ANY_PLACE",
    check_in_specific_work_laces: [],
    apply_same_setting_when_checking_out: 1,
    employee_can_check_out_from: "ANY_PLACE",
    check_out_specific_work_laces: [],
    metres: "",
  },
  prevTemployeeWorkScheduleForm: {
    start: "",
    attendanceProfileId: "",
  },
  employeeWorkScheduleFormValidation: [],
  employeeWorkScheduleFormServerValidation: {},

  workCalendarForm: {
    filter: null,
    work_group_ids: [],
    work_places_ids: [],
    employees_ids: [],
    teams_ids: [],
  },
  workCalendarFormValidation: [],
  workCalendarFormServerValidation: {},
  workTeamFilters: {
    search: "",
    workGroupId: null,
  },

  timeSheetListFilters: {
    officeId: null,
    departmentId: null,
    employeeId: null,
    from: null,
    to: null,
    onlyShowNoSignOutTime: false,
  },

  flexAttendanceModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  flexAttendanceActionModal: {
    action_type: "",
    from: "",
    to: "",
    deducted_from_emergency: false,
    include_weekends_and_holidays_in_custom_requests: 0,
    payment_flag: "paid",
    time_from: "",
    time_to: "",
    half_day_will_attend: "",
    home_day: "",
    id: null,
    incurred_at: "",
    amount: "",
    reason: "",
    assignment_id: null,
    leaveHours: "00:00",
    from_home_field: "home",
  },
  flexAttendanceActionModalValidation: [],
  flexRequestFormServerValidation: {},

  timesheetSummaryFilters: {
    offices: [],
    departments: [],
    positions: [],
    employees: [],
    year: moment().format("YYYY"),
    month: moment().format("MM"),
    week: null,
  },

  flexMonthlyProfileConfig: {
    id: null,
    start: null,
    monthlyProfileId: null,
  },

  flexMonthlyProfileConfigFormValidation: [],

  // edit clockins container start
  editClockinsContainerModal: {
    isOpen: false,
  },

  editClcokinsContainerForm: {
    containerId: null,
    status: "default",
    workdayTemplateId: null,
    apply_compensation: 0,
    weight: 1,
    compensation_type: null,
    compensation_quantity: null,
    treat_as_normal: false,
    allow_permission: false,
    allow_overtime: false,
    workplace_setting: "DEFAULT",
    workplace_setting_signout: "DEFAULT",
    additional_work_places: [],
    additional_work_places_signout: [],
    workplace_setting_identical: 1,
    cost_center_id: null,
  },

  editClockinContainerFormClientValidation: [],
  editClockinContainerFormServerValidation: {},
  // edit clockins container end

  miscellanousForm: {
    id: null,

    // probation

    probation_value: null,
    probation_unit: null,

    // requests limit

    has_request_dayoff_limit: 0,
    dayoff_request_limits: 0,
    dayoff_period_type: "month",
    submit_dayoff_cutoff_time: 0,
    dayoff_cutoff_time: null,

    has_request_change_shift_limit: 0,
    change_shift_request_limits: 0,
    change_shift_period_type: "month",
    submit_change_shift_cutoff_time: 0,
    change_shift_cutoff_time: null,

    has_request_additional_shift_limit: 0,
    additional_shift_request_limits: 0,
    additional_shift_period_type: "month",
    submit_additional_shift_cutoff_time: 0,
    additional_shift_cutoff_time: null,

    // end of day behavious

    allow_linked_shifts: 0,
    set_time_limit: 0,
    time_limit: null,
  },
};

export const FlexReducer = (state = initState, action) => {
  switch (action.type) {
    case EDIT_FLEX_WORK_GROUP:
      return {
        ...state,
        upsertFlexWorkGroupForm: {
          ...state.upsertFlexWorkGroupForm,
          ...action.payload,
        },
      };

    case TOGGLE_FLEX_ATTENDANCE_ACTION_MODAL:
      return {
        ...state,
        flexRequestFormServerValidation: {},
        flexAttendanceModalActions: {
          ...state.flexAttendanceModalActions,
          isVissible: !state.flexAttendanceModalActions.isVissible,
        },
      };

    case Types.SHOW_EDIT_UPSERT_SALARY_CONFIG_FORM_SUCCESS:
      const flexSettings = action?.payload?.flexSettings ?? {};
      return {
        ...state,
        MonthlyProfileForm: normalizeFetchedMonthlyProfile({ ...flexSettings }),
      };

    case Types.CREATE_NEW_EMPLOYEE_SALARY_FAILED:
      return {
        ...state,
        flexibleServerValidation: action.payload ?? {},
      };

    case Types.UPDATE_EMPLOYEE_SUCCESS:
    case Types.CREATE_NEW_EMPLOYEE_SALARY_SUCCESS:
    case Types.ACTIVATE_USER_SUCCESS:
    case Types.TOGGLE_ACTIVATION_MODAL:
    // case Types.SHOW_NEW_UPSERT_SALARY_CONFIG_FORM_SUCCESS:
    case Types.DISMISS_UPSERT_SALARY_CONFIG_FORM:
      return {
        ...state,
        MonthlyProfileForm: _.cloneDeep(initState.MonthlyProfileForm),
      };

    case TOGGLE_EDIT_CLOCKINS_CONTAINER:
      return {
        ...state,
        editClockinsContainerModal: {
          ...state?.editClockinsContainerModal,
          ...action?.payload?.modal,
        },
        editClcokinsContainerForm: {
          ...state?.editClcokinsContainerForm,
          ...action?.payload?.form,
        },
      };

    default:
      return state;
  }
};
