import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BSelect, InputForm } from "form-builder";
import { useTranslation } from "react-i18next";
import { handleActionMutation } from "../../Helpers/HelperFns";

// Apollo
import { useMutation, useQuery } from "@apollo/client";
import { UPSERT_FLEX_WORK_GROUP } from "../../Graphql/mutation";

// Actions
import { onFormResetAction, onInputChangeAction } from "../../Store/Actions";
import { editFlexWorkGroupAction } from "../../Store/Actions/flexActions/actions";

// Ui
import MainModal from "../MainModal";
import BlockUi from "react-block-ui";
import Swal from "sweetalert2";
import { FLEX_WORK_GROUP_MODAL_QUERY } from "../../Graphql/query";

//   form props
const reducerName = "flex";
const formName = "upsertFlexWorkGroupForm";
const formNameValidation = "upsertFlexWorkGroupFormValidation";
const formServerValidation = "upsertFlexWorkGroupFormServerValidation";

const FlexWorkGroupModal = ({
  isModalOpen,
  setIsModalOpen,
  fetchFlexWorkGroups,
  workGroupId,
  setWorkGroupId,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const formProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
    reducer: reducerName,
  };

  // //   Redux hooks
  const dispatch = useDispatch();
  const upsertFlexWorkGroupForm = useSelector(
    (state) => state?.[reducerName]?.[formName]
  );
  const formClientValidation = useSelector(
    (state) => state?.[reducerName]?.[formNameValidation]
  );

  useEffect(() => {
    return () => {
      setWorkGroupId("");
      dispatch(onFormResetAction(formName));
      dispatch(onFormResetAction(formNameValidation));
      dispatch(onFormResetAction(formServerValidation));
    };
  }, []);

  // // Apollo hooks
  const {
    data: flexWorkGroupOptions,
    loading: fetchFlexWorkGroupModalLoading,
  } = useQuery(FLEX_WORK_GROUP_MODAL_QUERY, {
    variables: {
      id: workGroupId,
      isEditing: workGroupId ? true : false,
    },

    onCompleted: (data) => {
      if (data?.flexWorkGroup) {
        dispatch(
          editFlexWorkGroupAction({
            id: data?.flexWorkGroup?.id,
            name: data?.flexWorkGroup?.name,
            managersIds: data?.flexWorkGroup?.managers?.map(
              (manager) => manager?.id
            ),
            employeesIds: data?.flexWorkGroup?.employees?.map(
              (employee) => employee?.user?.id
            ),
            workDayTemplatesIds: data?.flexWorkGroup?.workDayTemplates?.map(
              (workDayTemplate) => workDayTemplate?.id
            ),
            locationsIds: data?.flexWorkGroup?.offices?.map(
              (location) => location?.id
            ),
          })
        );
      }
    },

    onError: (error) => {
      Swal.fire({
        title: t("error"),
        text: error.message ? error.message : t("something went wrong"),
        icon: "error",
        className: "swal-error-style",
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      });
    },
  });

  const [upserFlextWorkGroup, { loading: upsertWorkGroupLoading }] =
    useMutation(UPSERT_FLEX_WORK_GROUP, {
      variables: {
        input: upsertFlexWorkGroupForm,
      },
    });

  const handleSubmit = async () => {
    setIsSubmitting(true);

    if (formClientValidation?.length === 0) {
      setIsSubmitting(false);
      const { response } = await handleActionMutation(upserFlextWorkGroup, {
        isReturnUnion: true,
        refetchQuery: fetchFlexWorkGroups,
      });

      if (!response?.errors) {
        setIsModalOpen(false);
      }
    }
  };

  const handleSelectAll = (e) => {
    const name = e.target?.getAttribute("name");
    let value = [];

    if (name === "workDayTemplatesIds") {
      value = flexWorkGroupOptions?.workDayTemplates?.map(
        (timing) => timing?.id
      );
    } else {
      value = flexWorkGroupOptions?.offices?.data?.map(
        (location) => location?.id
      );
    }

    const formEvent = {
      action: "select-option",
      name,
    };

    dispatch(onInputChangeAction(formName, formEvent, "select", value));
  };

  // uncomment this if you don't want to allow selected employees to be managers
  // const filterManagersOptions = flexWorkGroupOptions?.managers?.data?.filter(
  //   (emp) => !upsertFlexWorkGroupForm?.employeesIds?.includes(emp?.id)
  // );
  const filterManagersOptions = flexWorkGroupOptions?.managers?.data
    ?.concat(
      flexWorkGroupOptions?.profile?.company?.businessPartners?.map((bp) => ({
        id: bp?.user?.id,
        name: bp?.user?.name,
      }))
    )
    .filter((el) => el != undefined);

  const filterEmployeeOptions = flexWorkGroupOptions?.employees?.map((emp) => {
    return { id: emp?.user?.id, name: emp?.user?.name };
  });
  // uncomment this if you don't want to allow selected managers to be employees
  // ?.filter((emp) => !upsertFlexWorkGroupForm?.managersIds?.includes(emp?.id));

  const renderEmployeeOptions = filterEmployeeOptions?.concat(
    flexWorkGroupOptions?.flexWorkGroup?.employees?.map((emp) => {
      return {
        id: emp?.user?.id,
        name: emp?.user?.name,
        isFixed: true,
      };
    })
  );

  const handleEmployeesChange = (value, event) => {
    switch (event.action) {
      case "remove-value":
      case "pop-value":
        if (event.removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        value = renderEmployeeOptions.filter((v) => v.isFixed);
        break;
    }

    dispatch(
      onInputChangeAction(
        formName,
        event,
        "select",
        value ? value?.map((val) => val["id"]) : []
      )
    );
  };

  return (
    <>
      <MainModal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(false)}
        modalTitle={workGroupId ? t("edit work group") : t("new work group")}
        btnLabel={t("save")}
        btnOnClick={handleSubmit}
        btnSubmitLoading={upsertWorkGroupLoading}
        className="work_group_modal"
      >
        <BlockUi tag="div" blocking={fetchFlexWorkGroupModalLoading}>
          <InputForm
            {...formProps}
            validateBy="textRequired"
            label={t("name")}
            name="name"
            validationName="input.name"
            type="text"
            placeholder={t("group name")}
            containerStyle="name_field_container"
            inputContainerStyle="name_field_input"
            labelStyle="name_field_label"
            icon="people"
          />

          <BSelect
            {...formProps}
            name="managersIds"
            validationName="input.managersIds"
            label={"managers"}
            keepDefaultStyle
            options={filterManagersOptions}
            optionLabel="name"
            optionValue="id"
            labelStyle="my-2"
            containerStyle="containerStyle flex-column align-items-start "
            inputContainerStyle="flex-grow-1 mr-0 w-100"
            skipLocalization
            isMulti
            icon="people"
          />

          {/* <BSelect
            {...formProps}
            name="employeesIds"
            validationName="input.employeesIds"
            label={"employees"}
            keepDefaultStyle
            options={renderEmployeeOptions
              ?.filter((opt) => opt?.isFixed)
              .concat(renderEmployeeOptions?.filter((opt) => !opt?.isFixed))
              ?.filter((opt) => opt != undefined)}
            isMulti={true}
            optionLabel="name"
            optionValue="id"
            labelStyle="my-2"
            containerStyle={`containerStyle flex-column align-items-start ${
              workGroupId && "workGroup_employees_select"
            }`}
            inputContainerStyle="flex-grow-1 mr-0 w-100"
            skipLocalization
            icon="people"
            onChange={handleEmployeesChange}
          /> */}

          <div className="selectall_dropdown">
            <span onClick={handleSelectAll} name="workDayTemplatesIds">
              Select all
            </span>
            <BSelect
              {...formProps}
              name="workDayTemplatesIds"
              validateBy="textRequired"
              validationName="input.workDayTemplatesIds"
              label={"Work Day Templates"}
              keepDefaultStyle
              options={flexWorkGroupOptions?.workDayTemplates}
              isMulti={true}
              optionLabel="name"
              optionValue="id"
              labelStyle="my-2"
              containerStyle="containerStyle flex-column align-items-start"
              inputContainerStyle="flex-grow-1 mr-0 w-100"
              skipLocalization
              icon="calendar"
            />
          </div>

          <div className="selectall_dropdown">
            <span onClick={handleSelectAll} name="locationsIds">
              Select all
            </span>
            <BSelect
              {...formProps}
              name="locationsIds"
              validateBy="textRequired"
              validationName="input.locationsIds"
              label={"locations"}
              keepDefaultStyle
              options={flexWorkGroupOptions?.offices?.data}
              isMulti={true}
              optionLabel="name"
              optionValue="id"
              labelStyle="my-2"
              containerStyle="containerStyle flex-column align-items-start"
              inputContainerStyle="flex-grow-1 mr-0 w-100"
              skipLocalization
              icon="location"
            />
          </div>
        </BlockUi>
      </MainModal>
    </>
  );
};

export default FlexWorkGroupModal;
