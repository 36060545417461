import React from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";

import { showErrorToast, showSuccessToast } from "../../Store/Actions";

const useCustomMutation = (mutation, hookOptions = {}) => {
  const dispatch = useDispatch();
  const [mutate, options] = useMutation(mutation, hookOptions);

  const handleMutate = async (mutateOptions = {}) => {
    const options = { ...hookOptions, ...mutateOptions };
    const {
      onError,
      onCompleted,
      refetchList,
      onCloseModal,
      isShowErrorModal = false,
      isShowSuccessModal = true,
      ...otherOptions
    } = options;

    const handleError = (err) => {
      if (isShowErrorModal) {
        const msg = Array.isArray(err)
          ? err
              .map(({ message = "" }) => message)
              .join()
              .replaceAll(",", "\n")
          : err?.message;

        dispatch(showErrorToast(msg));
      }

      onError && onError(err);
    };

    mutate(otherOptions)
      .then((res) => {
        if (!Boolean(res?.errors)) {
          isShowSuccessModal && dispatch(showSuccessToast());
          refetchList && refetchList();
          onCloseModal && onCloseModal();
          onCompleted && onCompleted(res?.data);
        } else {
          handleError(res?.errors);
        }
      })
      .catch(handleError);

    // mutate({
    //   ...otherOptions,
    //   errorPolicy: 'all',
    //   onError: handleError,
    //   onCompleted: (res) => {
    //     if (Boolean(options.error)) {
    //       handleError(options.error);
    //     } else {
    //       isShowSuccessModal && dispatch(showSuccessToast());
    //       refetchList && refetchList();
    //       onCloseModal && onCloseModal();
    //       onCompleted && onCompleted(res);
    //     }
    //   },
    // });
  };

  return [handleMutate, options];
};

export default useCustomMutation;
