import React from "react";
import { useParams, useLocation } from "react-router-dom";

// import Privileges from "../../Constants/Privilages";

import {
  CompanyRemindersList,
  CompanyActivitiesList,
} from "../../Components/AdminPanel";
import BlockUi from "react-block-ui";
import { Box, Tab } from "@mui/material";
import { AdminPanelTabs } from "./AdminPanelPage";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { MeetingsList } from "../../Containers/CRMLists";
import { CompaniesList } from "../../Containers/AdminPanelLists";

let loading = false;
const tabsPanels = [
  {
    value: "1",
    label: "Primary",
    Panel: CompaniesList,
    props: { isPrimary: true },
    // privileges: [Privileges.SUPER_PRIVILEGE, Privileges.VIEW_COMPANIES],
  },
  {
    value: "2",
    label: "Secondary",
    Panel: CompaniesList,
    props: { isSecondary: true },
    // privileges: [Privileges.SUPER_PRIVILEGE, Privileges.VIEW_COMPANIES],
  },
  {
    value: "3",
    label: "Activities",
    Panel: CompanyActivitiesList,
    props: { isAccountManagerProfile: true },
    // privileges: [Privileges.SUPER_PRIVILEGE, Privileges.VIEW_COMPANY_ACTIVITIES],
  },
  {
    value: "4",
    label: "Reminders",
    Panel: CompanyRemindersList,
    props: { isAccountManagerProfile: true },
    // privileges: [Privileges.SUPER_PRIVILEGE, Privileges.VIEW_COMPANY_REMINDERS],
  },
  {
    value: "5",
    label: "Meetings",
    Panel: MeetingsList,
    props: { isAccountManagerProfile: true },
    // privileges: [Privileges.SUPER_PRIVILEGE, Privileges.VIEW_COMPANY_MEETING],
  },
];

const AccountManagerProfile = () => {
  const { id } = useParams();
  const location = useLocation();
  const [value, setValue] = React.useState("1");

  // Constants
  const { name } = location.state || {};

  /* Helpers */

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <BlockUi tag="div" className="content pt-3" blocking={loading}>
      <AdminPanelTabs />

      <div className="mt-4">
        <h1>{name}</h1>
      </div>

      <TabContext value={value}>
        {/* Tabs */}
        <Box sx={{ mt: 3, borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            {tabsPanels.map((panel) => (
              <Tab key={panel.value} label={panel.label} value={panel.value} />
            ))}
          </TabList>
        </Box>

        {/* Panels */}
        {tabsPanels.map((panel) => (
          <TabPanel key={panel.value} value={panel.value} sx={{ p: 1 }}>
            <panel.Panel accountManagerID={id} {...panel.props} />
          </TabPanel>
        ))}
      </TabContext>
    </BlockUi>
  );
};

export default AccountManagerProfile;
