import { IconButton, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import EmployeeAssignedAssets from "./EmployeeAssignedAssets";
import EmployeeAssetsRequests from "./EmployeeAssetsRequests";
import { useDispatch } from "react-redux";
import {
  toggleAssignRetriveAssetModal,
  toggleEmployeeAssetRequestModal,
} from "../../../Store/Actions";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import Privilages from "../../../Constants/Privilages";
import HelperFns from "../../../Helpers/HelperFns";

const EmployeeAssets = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { userId } = useParams();

  const [currentList, setCurrentList] = useState("assigned");

  const handleSwitchChange = () => {
    setCurrentList((prev) => (prev == "assigned" ? "requests" : "assigned"));
  };

  useEffect(() => {
    if (userId) {
      if (
        HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_ASSIGNED_ASSETS],
        })
      ) {
        setCurrentList("assigned");
        return;
      }
      if (
        HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_ASSET_REQUEST],
        })
      ) {
        setCurrentList("requests");
        return;
      }
    } else {
      if (
        HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_MY_ASSETS],
        })
      ) {
        setCurrentList("assigned");
        return;
      }
      if (
        HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_MY_ASSET_REQUEST],
        })
      ) {
        setCurrentList("requests");
        return;
      }
    }
  }, []);

  const handleAssignAssets = () => {
    dispatch(
      toggleAssignRetriveAssetModal({
        isOpen: true,
        preSelectedEmployee: userId,
      })
    );
  };

  const handleRequestAssets = () => {
    dispatch(toggleEmployeeAssetRequestModal({ isOpen: true }));
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-5">
          <p className="m-0 font-weight-bold">{t("assigned assets")}</p>
          <Switch
            checked={currentList == "requests"}
            onChange={handleSwitchChange}
            classes={{ switchBase: "switchBase" }}
            {...((userId &&
              !HelperFns.checkPrivileges({
                allowBP: true,
                privileges: [
                  Privilages.VIEW_ASSIGNED_ASSETS,
                  Privilages.MANAGE_ASSET_REQUEST,
                ],
              })) ||
            (!userId &&
              !HelperFns.checkPrivileges({
                privileges: [
                  Privilages.VIEW_MY_ASSETS,
                  Privilages.VIEW_MY_ASSET_REQUEST,
                ],
              }))
              ? { disabled: true }
              : {})}
          />
          <p className="m-0 font-weight-bold">{t("asset requests")}</p>
        </div>

        <div className="d-flex align-items-center gap-5">
          {userId ? (
            <HasPrivileges
              allowBP
              reqireMain={[Privilages.ASSIGN_RETRIEVE_ASSET_UNITS]}
            >
              <button
                className="btn add_new_btn_style text-nowrap"
                onClick={handleAssignAssets}
              >
                {t("assign assets")}
              </button>
            </HasPrivileges>
          ) : (
            <HasPrivileges
              allowBP
              reqireMain={[Privilages.SUBMIT_ASSET_REQUEST]}
            >
              <button
                className="btn add_new_btn_style text-nowrap"
                onClick={handleRequestAssets}
              >
                {t("request assets")}
              </button>
            </HasPrivileges>
          )}
        </div>
      </div>

      {currentList == "assigned" ? (
        <HasPrivileges
          {...(!!userId
            ? { allowBP: true, reqireMain: [Privilages.VIEW_ASSIGNED_ASSETS] }
            : { reqireMain: [Privilages.VIEW_MY_ASSETS] })}
        >
          <EmployeeAssignedAssets employeeId={userId} />
        </HasPrivileges>
      ) : (
        <HasPrivileges
          {...(!!userId
            ? {
                allowBP: true,
                reqireMain: [
                  Privilages.VIEW_ASSET_REQUEST,
                  Privilages.MANAGE_ASSET_REQUEST,
                ],
              }
            : {
                reqireMain: [
                  Privilages.VIEW_MY_ASSET_REQUEST,
                  Privilages.SUBMIT_ASSET_REQUEST,
                ],
              })}
        >
          <EmployeeAssetsRequests employeeId={userId} />
        </HasPrivileges>
      )}
    </div>
  );
};

export default EmployeeAssets;
