import React from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  storePaymentMethodMutation,
  changePaymentMethodMutation,
} from "../../Graphql/mutation/PaymentTracking";
import { showToast } from "../../Helpers/HelperFns";
import { onFormResetAction } from "../../Store/Actions";
import { serializeUpsertPaymentMethod } from "../../Helpers/HelperFns/PaymentTracking";

import MainModal from "../MainModal";
import { BSelect, InputForm } from "form-builder";

const reducer = "paymentTracking";
const formName = "upsertPaymentMethodModal";
const formNameValidation = "paymentTrackingClientValidation";
const formServerValidation = "paymentTrackingServerValidation";
const paymentMethods = [
  { id: "Cash", name: "Cash" },
  { id: "Bank", name: "Bank Transfer" },
  { id: "Wallet", name: "Wallet" },
  { id: "Prepaid", name: "Prepaid Card" },
];

const UpsertPaymentMethodModal = ({ data, onClose, refetchList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [errMsg, setErrMsg] = React.useState("");
  const [formSubmitting, setFormSubmitting] = React.useState(false);

  // Reducer State
  const formData = useSelector((state) => state?.[reducer]?.[formName]);
  const formClientValidation = useSelector(
    (state) => state?.[reducer]?.[formNameValidation]
  );

  // Server State
  const [store, { loading: storeLoading }] = useMutation(
    storePaymentMethodMutation
  );
  const [change, { loading: changeLoading }] = useMutation(
    changePaymentMethodMutation
  );

  // Constants
  const isEdit = Boolean(data?.id);
  const FormProps = {
    reducer,
    formName,
    formSubmitting,
    formNameValidation,
    formServerValidation,
  };

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    if (isEdit) {
      dispatch(onFormResetAction(formName, data));
    }
  }, [isEdit]);

  /* ↓ Helpers ↓ */

  const handleCloseModal = () => {
    onClose();
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formNameValidation));
    dispatch(onFormResetAction(formServerValidation));
  };

  const handleSubmit = () => {
    setFormSubmitting(true);
    if (formClientValidation?.length) return;

    const upsert = isEdit ? change : store;
    upsert({
      variables: serializeUpsertPaymentMethod(formData),
      onCompleted: () => {
        refetchList();
        showToast("success");
        handleCloseModal();
      },
      onError: (err) => {
        const validation = err?.graphQLErrors?.[0]?.extensions?.validation;

        const msg =
          validation?.json?.[0] ||
          err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message;
        setErrMsg(msg || "");
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={handleCloseModal}
      btnOnClick={handleSubmit}
      btnSubmitLoading={storeLoading || changeLoading}
      modalTitle={isEdit ? "Edit Payment Method" : "Add Payment Method"}
    >
      <InputForm
        {...FormProps}
        name="name"
        label="name"
        icon="key"
        containerStyle=" "
        rootStyle="mb-2"
        validateBy="textRequired"
      />
      <BSelect
        {...FormProps}
        name="paymentMethod"
        label="Payment Method"
        icon="money"
        rootStyle="mb-2"
        options={paymentMethods}
        validateBy="textRequired"
      />

      {/* Bank Transfer */}
      <>
        <InputForm
          {...FormProps}
          icon="landmark"
          name="bankName"
          label="bank name"
          containerStyle=" "
          rootStyle="mb-2"
          validateBy="textRequired"
          dependOn="paymentMethod"
          dependancyType="equal"
          dependancyValue={["Bank"]}
        />
        <InputForm
          {...FormProps}
          icon="type"
          name="accountNumber"
          label="account number"
          containerStyle=" "
          rootStyle="mb-2"
          validateBy="textRequired"
          dependOn="paymentMethod"
          dependancyType="equal"
          dependancyValue={["Bank"]}
        />
        <InputForm
          {...FormProps}
          icon="type"
          name="IBAN"
          label="IBAN"
          containerStyle=" "
          validateBy="textRequired"
          dependOn="paymentMethod"
          dependancyType="equal"
          dependancyValue={["Bank"]}
        />
      </>

      {/* Wallet */}
      <>
        <InputForm
          {...FormProps}
          name="wallet"
          label="wallet name"
          icon="wallet"
          containerStyle=" "
          rootStyle="mb-2"
          validateBy="textRequired"
          dependOn="paymentMethod"
          dependancyType="equal"
          dependancyValue={["Wallet"]}
        />
        <InputForm
          {...FormProps}
          icon="phone"
          name="phoneNumber"
          label="phone Number"
          containerStyle=" "
          rootStyle="mb-2"
          validateBy="textRequired"
          dependOn="paymentMethod"
          dependancyType="equal"
          dependancyValue={["Wallet"]}
        />
      </>

      {/* Prepaid Card */}
      <>
        <InputForm
          {...FormProps}
          name="card"
          label="card name"
          icon="card"
          containerStyle=" "
          rootStyle="mb-2"
          validateBy="textRequired"
          dependOn="paymentMethod"
          dependancyType="equal"
          dependancyValue={["Prepaid"]}
        />
        <InputForm
          {...FormProps}
          name="cardNumber"
          label="card number"
          icon="type"
          containerStyle=" "
          rootStyle="mb-2"
          validateBy="textRequired"
          dependOn="paymentMethod"
          dependancyType="equal"
          dependancyValue={["Prepaid"]}
        />
      </>

      {errMsg ? (
        <p role="alert" className="red-color mt-2 mb-0">
          {t(errMsg)}
        </p>
      ) : null}
    </MainModal>
  );
};

export default UpsertPaymentMethodModal;
