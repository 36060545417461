import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { joinMeetingQuery } from "../../Graphql/query";

import Loader from "../../Components/Loader";
import { Alert, Divider, Container, Typography } from "@mui/material";

const CRMClientMeeting = () => {
  const { uuid } = useParams();
  const { t } = useTranslation();

  // Server State
  const { error, loading: joinMeetingLoading } = useQuery(joinMeetingQuery, {
    variables: { uuid },
    onCompleted: ({ join_meeting_url }) => {
      window.location.href = join_meeting_url;
    },
  });

  return (
    <Container className="pb-4">
      <header>
        <div className="mt-5 mb-4 text-center">
          <img
            src="assets/img/logo.svg"
            width="230"
            height="230"
            className="img-fluid mb-3"
            alt="Mawared Logo"
          />
        </div>

        <h1 className="mb-3 side-title" style={{ fontSize: 20 }}>
          {t("Generating Meeting")}
        </h1>

        <Typography sx={{ color: "#898A8D", textTransform: "initial" }}>
          {t("Please wait a few seconds...")}
        </Typography>

        <Divider sx={{ mt: 3, mb: 2 }} />
      </header>
      <main>
        {joinMeetingLoading ? (
          <Loader inner />
        ) : error ? (
          <Alert severity="error">
            {error?.graphQLErrors?.[0]?.extensions?.reason ||
              error?.graphQLErrors?.[0]?.message ||
              error?.message}
          </Alert>
        ) : null}
      </main>
    </Container>
  );
};

export default CRMClientMeeting;
