import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CanViewEmployeeProfile from "../../Helpers/HOC/CanViewEmployeeProfile";
import { useTranslation } from "react-i18next";
import ClockinsContainer from "./ClockinsContainer";
import { InfoOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { getSumOfDurations } from "../../Helpers/HelperFns";

const WorkDayTemplatesContainer = ({
  workDayTemplatesContainer,
  handleOpenClockinModal = () => {},
  refetch,
}) => {
  const { t } = useTranslation();
  const [isWorkDayTemplatesOpen, setIsWorkDayTemplatesOpen] = useState(false);

  const handleOpenWorkdayTemplates = () => {
    setIsWorkDayTemplatesOpen((prev) => !prev);
  };

  const user = {
    id: workDayTemplatesContainer?.details?.[0]?.user?.id,
    name: workDayTemplatesContainer?.name,
  };

  const date = moment(workDayTemplatesContainer?.date).format("ddd YYYY-MM-DD");

  const totalSignIns = workDayTemplatesContainer?.details
    ?.map(
      (container) => container?.calculatedExtraDataPerContainer?.totalClockIns
    )
    ?.flat(Infinity)
    ?.reduce((acc, curr) => +acc + +curr, 0);

  const totalHours = getSumOfDurations(
    workDayTemplatesContainer?.details
      ?.map(
        (container) => container?.calculatedExtraDataPerContainer?.totalHours
      )
      ?.flat(Infinity)
  );

  const actualTotalHours = getSumOfDurations(
    workDayTemplatesContainer?.details
      ?.map(
        (container) =>
          container?.calculatedExtraDataPerContainer?.actualTotalHours
      )
      ?.flat(Infinity)
  );

  const overTime = getSumOfDurations(
    workDayTemplatesContainer?.details
      ?.map(
        (container) => container?.calculatedExtraDataPerContainer?.totalOverTime
      )
      ?.flat(Infinity)
  );

  const actualOverTime = getSumOfDurations(
    workDayTemplatesContainer?.details
      ?.map(
        (container) =>
          container?.calculatedExtraDataPerContainer?.actualTotalOverTime
      )
      ?.flat(Infinity)
  );

  const Leave = getSumOfDurations(
    workDayTemplatesContainer?.details
      ?.map(
        (container) => container?.calculatedExtraDataPerContainer?.totalLeave
      )
      ?.flat(Infinity)
  );

  const containers = workDayTemplatesContainer?.details;

  return (
    <div className="clockins-main-container">
      <div onClick={handleOpenWorkdayTemplates} className="clockins-container">
        <div className="clockins-container-name">
          <CanViewEmployeeProfile
            allowBP
            directManger={user?.manager?.id}
            copiedManagers={user?.copied_managers?.map((cp) => cp?.id)}
            altChildren={
              <p className="clockins-container-name">{user?.name}</p>
            }
          >
            <Link to={`/employees/employee-profile/${user?.id}`}>
              {user?.name}
            </Link>
          </CanViewEmployeeProfile>
        </div>
        <p className="clockins-container-date">{date}</p>
        <div className="clockins-container-total-sign-ins d-flex align-items-center">
          <p className="mr-2">total sign-ins</p>
          <p>{totalSignIns}</p>
        </div>
        <div className="clockins-container-total-hours d-flex align-items-center">
          <p className="mr-2">total hours</p>
          <p>
            {totalHours == "00:00:00" ? "" : totalHours}({actualTotalHours})
          </p>
        </div>

        <div className="clockins-container-overtime d-flex align-items-center">
          <p className="mr-2">overtime</p>
          <p>
            {overTime == "00:00:00" ? "" : overTime}({actualOverTime})
          </p>
        </div>

        <div className="clockins-container-leave d-flex align-items-center">
          <p className="mr-2">leave</p>
          <p>{Leave}</p>
        </div>
      </div>

      {isWorkDayTemplatesOpen ? (
        <div>
          {containers.map((container, index) => (
            <>
              <ClockinsContainer
                id={container?.id}
                handleOpenClockinModal={handleOpenClockinModal}
                container={container}
                refetchTimeSheet={refetch}
              />
              {index !== containers?.length - 1 && containers?.length > 1 ? (
                <hr />
              ) : null}
            </>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default WorkDayTemplatesContainer;
