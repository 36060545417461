import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DateTimePickerForm, BSelect } from "form-builder";
import { useTranslation } from "react-i18next";
import { fetchHistoryListAttempt } from "../../Store/Actions";
import HistoryList from "../../Containers/HistoryList";
import Pagination from "../../Components/Pagination";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import Const from "../../Constants";
import { KeyboardBackspace } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";

const AttendanceHistory = (props) => {
  const dataTableRef = `historyList`;

  const { t } = useTranslation();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    props.refetchFromSagaToHook && fetchData();
  }, [props.refetchFromSagaToHook]);

  const fetchData = () => {
    props.fetchHistoryListAttempt({
      ref: dataTableRef,
      rows: props.data.pagination.perPage,
      status: props.data.status,
      from: props.data.from,
      to: props.data.to,
      page: props.data.pagination.currentPage,
    });
  };

  const handleFilter = () => {
    props.fetchHistoryListAttempt({
      ref: dataTableRef,
      rows: props.data.pagination.perPage,
      status: props.data.status,
      from: props.data.from,
      to: props.data.to,
      page: (props.data.pagination.currentPage = 1),
    });
  };
  const didUpdtate = useDidUpdateEffect(handleFilter, [
    props.data.status,
    props.data.from,
    props.data.to,
  ]);

  const formNameValidation = `employeeHistoryValidation`;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlePaginate = (page = props.data.pagination.currentPage) => {
    
    props.fetchHistoryListAttempt({
      ref: dataTableRef,
      page: page,
      rows: props.data.pagination.perPage,
      status: props.data.status,
      from: props.data.from,
      to: props.data.to,
    });
  };

  return (
    <div className="content">
      <div className="row">
        <div className="col-sm-12">
          <Link to="/employee-profile">
            <div className="back-btn d-flex align-items-center">
              <KeyboardBackspace className="back-icon" />
              <span>Back</span>
            </div>
          </Link>
          <h4 className="page-title w-100">{t("attendance history")}</h4>
        </div>
      </div>

      {/* (Start) History Employee Header */}
      <BSelect
        reducer="user"
        name="status"
        formName={dataTableRef}
        formSubmitting={isSubmitting}
        options={Const.signInStatusOptions}
        keepDefaultStyle
        optionLabel="value"
        optionValue="value"
        isMulti={true}
      />

      <div className="row align-items-center my-2 att-history-header">
        <div className="col-12 d-flex flex-wrap">
          <DateTimePickerForm
            name="from"
            formName={dataTableRef}
            formSubmitting={isSubmitting}
            label={t("from")}
            hasIcon
            reducer="user"
            labelStyle="label-style mb-0"
            containerStyle="container-style-default mr-sm-4 flex-nowrap"
            rootStyle="att-datepicker-style"
          />
          <DateTimePickerForm
            name="to"
            formName={dataTableRef}
            formSubmitting={isSubmitting}
            label="To"
            hasIcon
            reducer="user"
            labelStyle="label-style mb-0"
            containerStyle="container-style-default flex-nowrap"
            rootStyle="att-datepicker-style"
          />
        </div>
      </div>
      {/* (End) History Employee Header */}

      {props.data.isLoading ? (
        <Loader />
      ) : (
        <>
          <HistoryList isMyProfileHistory />
          {props.data.pagination.lastPage > 1 && (
            <Pagination
              tableRef={dataTableRef}
              styleWraper="mt-5"
              onPaginate={handlePaginate}
              reducer="user"
            />
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.user.historyList,
  refetchFromSagaToHook: state.super.refetchFromSagaToHook,
});

export default connect(mapStateToProps, { fetchHistoryListAttempt })(
  AttendanceHistory
);
