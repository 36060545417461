import React from "react";
import { connect } from "react-redux";
import { CheckboxBooleanForm, InputForm } from "form-builder";
import { useTranslation } from "react-i18next";
import RadioboxForm from "../../Builder/Form/RadioboxForm";

const EditAttOvertime = (props) => {
  const { formProps } = props;

  const { t } = useTranslation();

  return (
    <div className={+props?.sign_in_req ? " " : "d-none"}>
      <h5 className="my-2 sub-title-style">{t("overtime")}</h5>
      <div className="">
        <CheckboxBooleanForm
          dependOn="sign_in_req"
          dependancyType="equal"
          dependancyValue={[1]}
          {...formProps}
          options={["Allow Overtime"]}
          name="overtime_permissible"
          validationName="input.overtime_permissible"
          type="checkbox"
        />
        <div className="sub-container-style">
          <InputForm
            {...formProps}
            dependOn="overtime_permissible"
            dependancyType="equal"
            dependancyValue={[1]}
            hasSuffix
            suffixTitle="Minutes"
            validateBy="textRequired"
            label="Calculate Overtime After calculated sign out time by"
            name="min_overtime_cuttoff"
            validationName="input.min_overtime_cuttoff"
            type="text"
            inputContainerStyle="m-start-auto"
          />

          {/* <InputForm
            {...formProps}
            dependOn="overtime_permissible"
            dependancyType="equal"
            dependancyValue={[1]}
            stepInput
            validateBy="textRequired"
            label="Overtime Hour Factor"
            name="overtime_payment_factor"
            validationName="input.overtime_payment_factor"
            type="text"
          /> */}

          <CheckboxBooleanForm
            dependOn="overtime_permissible"
            dependancyType="equal"
            dependancyValue={[1]}
            {...formProps}
            options={["Apply Overtime Limits"]}
            optionLabelStyle="content-header-sub-label"
            name="overtime_limit"
            validationName="input.overtime_limit"
            type="checkbox"
          />
          <InputForm
            {...formProps}
            dependOn="overtime_limit"
            dependancyType="equal"
            dependancyValue={[1]}
            stepInput
            validateBy="textRequired"
            label="Max. Overtime Hours Per Day"
            name="max_overtime_per_day"
            validationName="input.max_overtime_per_day"
            type="text"
            inputContainerStyle="m-start-auto"
          />

          <CheckboxBooleanForm
            dependOn="overtime_permissible"
            dependancyType="equal"
            dependancyValue={[1]}
            {...formProps}
            options={["record the actual overtime duration"]}
            optionLabelStyle="content-header-sub-label"
            name="record_actual"
            type="checkbox"
            setWithValue
          />

          <RadioboxForm
            dependOn="record_actual"
            dependancyType="equal"
            dependancyValue={[false]}
            name="overtime_type"
            {...formProps}
            options={[
              { label: "Rounded Up", value: "up" },
              { label: "Rounded Down", value: "down" },
            ]}
            containerStyle="mt-2"
            labelStyle="d-flex mb-3"
            optionsContainerStyle="penalty-days-style fit-width flex-md-row flex-column w-100 "
            optionItemStyle="d-flex alignbaseline-center"
            optionLabelStyle="mb-0 mr-3"
            optionInputStyle=" "
            label="Display payable overtime hours as"
            validateBy="textRequired"
          />

          <RadioboxForm
            dependOn="record_actual"
            dependancyType="equal"
            dependancyValue={[false]}
            name="overtime_value"
            {...formProps}
            options={[
              { label: "5 minutes", value: "5" },
              { label: "10 minutes", value: "10" },
              { label: "15 minutes", value: "15" },
              { label: "20 minutes", value: "20" },
              { label: "25 minutes", value: "25" },
              { label: "30 minutes", value: "30" },
            ]}
            containerStyle="mt-2"
            labelStyle="d-flex mb-3"
            optionsContainerStyle="penalty-days-style w-100 flex-md-row flex-column "
            optionItemStyle="d-flex alignbaseline-center"
            optionLabelStyle="mb-0 mr-3"
            optionInputStyle=" "
            label="Rounded to the nearest"
            validationName={"input.overtime_value"}
          />

          {/* <InputForm
            {...formProps}
            dependOn="overtime_limit"
            dependancyType="equal"
            dependancyValue={[1]}
            stepInput
            validateBy="textRequired"
            label="Max. Overtime Hours Per Month"
            name="max_overtime_per_month"
            validationName="input.max_overtime_per_month"
            type="text"
          /> */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, { formProps = {} }) => {
  return {
    sign_in_req: state.super[formProps?.formName]?.sign_in_req,
  };
};

export default connect(mapStateToProps)(EditAttOvertime);
