import moment from "moment";
import { assignmentTypesConstants } from "../../Constants";
import HelperFns from ".";
import {
  ADDITIONAL_SHIFT,
  CHANGE_SHIFT,
  DAY_OFF,
} from "../../Constants/Requests";

export const serializeUpsertingFlexAssignment = ({
  id,
  name,
  from,
  to,
  apply_on,
  employee_applicable_on_ids,
  department_applicable_on_ids,
  office_applicable_on_ids,
  department_employee_excludes,
  exclude_department_employees,
  exclude_office_employees,
  office_employee_excludes,
  penalise,
  no_compensation,
  // year,
  // month,
  payable_at,
  retain_default_work_timing,
  award_bonus_days_quantity,
  award_additional_leaves_quantity,
  compensation,
  allow_permission,
  allow_overtime,
  allow_home,
  workplace_setting,
  additional_work_places,
  workplace_setting_signout,
  additional_work_places_signout,
  workplace_setting_identical,
  weight,
  assignmentType,
  allow_employees_to_request_half_days,
  inLieuOfWorkdayDate,
  inLieuOfWorkdayRequestableObject,
  flex_work_day_template_id,
  payment_factor_id,
}) => {
  let compensationConfig = [
    compensation === "bonus"
      ? {
          type: "bonus",
          payment_factor_id,
          quantity: parseFloat(award_bonus_days_quantity),
          month_year_payroll: moment(payable_at).format("DD-MM-YYYY"),
        }
      : null,
    compensation === "leaves"
      ? {
          type: "leaves",
          quantity: parseFloat(award_additional_leaves_quantity),
          month_year_payroll: null,
        }
      : compensation === "request"
        ? {
            type: "request",
            request: {
              date: moment(inLieuOfWorkdayDate).format("DD-MM-YYYY"),
              clockable: {
                clockableId: inLieuOfWorkdayRequestableObject?.id,
                clockableType: inLieuOfWorkdayRequestableObject?.typename,
              },
            },
          }
        : null,
  ];
  let submitData = {
    id,
    name,
    // weight:
    //   assignmentType == assignmentTypesConstants.NORMAL_WORK_DAY
    //     ? parseFloat(weight)
    //     : 0,
    weight: 0,
    from: from?.replaceAll("/", "-"),
    to: to?.replaceAll("/", "-"),
    apply_on: apply_on,
    applicable_on_ids:
      apply_on?.toLowerCase() === "employee"
        ? employee_applicable_on_ids?.map((applicable_id) => +applicable_id)
        : apply_on?.toLowerCase() === "department"
          ? department_applicable_on_ids?.map((applicable_id) => +applicable_id)
          : office_applicable_on_ids?.map((applicable_id) => +applicable_id),

    assignment_flex_employee_excludes: exclude_department_employees
      ? department_employee_excludes?.map(
          (excluded_employee) => +excluded_employee
        )
      : exclude_office_employees
        ? office_employee_excludes?.map(
            (excluded_employee) => +excluded_employee
          )
        : [],

    ...(assignmentType != assignmentTypesConstants.DAY_OFF &&
    assignmentType != assignmentTypesConstants.HOLIDAY
      ? {
          flex_work_day_template_id: +flex_work_day_template_id,

          retain_work_day_template:
            assignmentType == assignmentTypesConstants.NORMAL_WORK_DAY
              ? retain_default_work_timing
              : 1, // if the assingment is an exception it should always retain

          treat_as_normal:
            assignmentType == assignmentTypesConstants.NORMAL_WORK_DAY,
          workplace_setting_identical: workplace_setting_identical,
          workplace_setting,
          additional_work_places,
          workplace_setting_sign_out: workplace_setting_identical
            ? workplace_setting
            : workplace_setting_signout,
          additional_work_places_sign_out: !workplace_setting_identical
            ? additional_work_places_signout
            : additional_work_places,

          ...(assignmentType == assignmentTypesConstants.EXCEPTIONAL_SHIFT
            ? {
                allow_overtime,
                allow_home,
                flex_assignment_compensation_configurations:
                  assignmentType ==
                    assignmentTypesConstants.EXCEPTIONAL_SHIFT && !!compensation
                    ? compensationConfig?.filter((conf) => conf != null)
                    : [],
                flex_assignment_absence_action: {
                  penalise: penalise ? true : false,
                  compensate: no_compensation ? false : true,
                },
              }
            : {}),
        }
      : {
          retain_work_day_template: 0,
          treat_as_normal: true,
          ...(assignmentType == assignmentTypesConstants.HOLIDAY
            ? {
                is_holiday: true,
              }
            : {}),
        }),
  };

  return submitData;
};

export const serializeFetchingFlexAssignment = ({
  id,
  name,
  from,
  to,
  apply_on,
  flexWorkDayTemplate,
  retain_work_day_template,
  flexAssignmentApplicants,
  employeeExcludes,
  offices,
  flexAssignmentCompensationConfigurations,
  canEditFrom,
  canEditTo,
  canEdit,
  canEditRetain,
  createdBy,
  created_at,
  ignoredEmployees,
  treat_as_normal,
  allow_overtime,
  allow_home,
  workplace_setting,
  additional_work_places,
  workplace_setting_signout,
  additional_work_places_signout,
  workplace_setting_identical,
  weight,
  is_holiday = false,
  penalise,
  compensate,
  change_shift,
  __typename,
}) => {
  let award_bonus_index = flexAssignmentCompensationConfigurations?.find(
    (compensation) => compensation?.type?.toLowerCase() === "bonus"
  );
  let additional_leaves_index = flexAssignmentCompensationConfigurations?.find(
    (compensation) => compensation?.type?.toLowerCase() === "leaves"
  );
  let in_lieu_of_workday = flexAssignmentCompensationConfigurations?.find(
    (compensation) => compensation?.type?.toLowerCase() === "request"
  );

  let fetchedData = {
    id,
    name,
    from,
    to,
    assignment_work_day_type: "flexible",
    apply_on,
    treat_as_normal,
    payment_factor_id: award_bonus_index?.payment_factor_id,
    flex_work_day_template_id: flexWorkDayTemplate?.id,
    penalise,
    no_compensation: compensate ? 0 : 1,
    retain_default_work_timing: +retain_work_day_template,
    exclude_department_employees:
      apply_on.toLowerCase() == "department" && employeeExcludes.length ? 1 : 0,
    exclude_office_employees:
      apply_on.toLowerCase() == "office" && employeeExcludes.length ? 1 : 0,
    department_employee_excludes:
      apply_on.toLowerCase() == "department"
        ? employeeExcludes?.map((emp) => emp?.user?.id)
        : [],
    office_employee_excludes:
      apply_on.toLowerCase() == "office"
        ? employeeExcludes?.map((emp) => emp?.user?.id)
        : [],
    employee_applicable_on_ids:
      apply_on == "employee"
        ? flexAssignmentApplicants.map(
            (applicant) => applicant?.applicable?.user?.id
          )
        : [],
    department_applicable_on_ids:
      apply_on == "department"
        ? flexAssignmentApplicants.map((applicant) => applicant?.applicable?.id)
        : [],
    office_applicable_on_ids:
      apply_on == "office"
        ? flexAssignmentApplicants.map((applicant) => applicant?.applicable?.id)
        : [],
    compensate_for_extra_time_worked:
      flexAssignmentCompensationConfigurations?.length ? 1 : 0,
    award_bonus_days_quantity: award_bonus_index
      ? award_bonus_index.quantity
      : null,
    compensation: additional_leaves_index
      ? "leaves"
      : award_bonus_index
        ? "bonus"
        : in_lieu_of_workday
          ? "request"
          : "",
    inLieuOfWorkdayDate:
      flexAssignmentCompensationConfigurations?.[0]?.request?.time_from,
    inLieuOfWorkdayRequestable:
      flexAssignmentCompensationConfigurations?.[0]?.request?.requestables?.[0]
        ?.id,
    award_additional_leaves_quantity: additional_leaves_index
      ? additional_leaves_index.quantity
      : null,

    payable_at: award_bonus_index
      ? moment(award_bonus_index.month_year_payroll, "YYYY-MM-DD").format(
          "YYYY/MM/DD"
        )
      : "",
    // month: award_bonus_index
    //   ? moment(award_bonus_index.month_year_payroll, "DD-MM-YYYY").format("YYYY/MM/DD")
    //   : new Date().getMonth() + 1,
    // year: award_bonus_index
    //   ? moment(award_bonus_index.month_year_payroll, "MM-YYYY").format("YYYY")
    //   : new Date().getFullYear(),
    canEditFrom,
    canEditTo,
    canEdit,
    canEditRetain,
    assignmentType: is_holiday
      ? assignmentTypesConstants.HOLIDAY
      : !!flexWorkDayTemplate?.id
        ? treat_as_normal
          ? assignmentTypesConstants.NORMAL_WORK_DAY
          : assignmentTypesConstants.EXCEPTIONAL_SHIFT
        : assignmentTypesConstants.DAY_OFF,
    applied_on_names:
      apply_on.toLowerCase() == "employee"
        ? flexAssignmentApplicants.map(
            (applicant) => applicant?.applicable?.user?.name
          )
        : flexAssignmentApplicants.map(
            (applicant) => applicant?.applicable?.name
          ),
    excluded_employees_names: employeeExcludes?.map((emp) => emp.user?.name),
    assignment_location_names: offices?.map((offices) => offices?.name),
    createdBy,
    created_at,
    ignoredEmployees,
    allow_overtime,
    allow_home,
    workplace_setting_identical,
    workplace_setting,
    workplace_setting_signout,
    additional_work_places,
    additional_work_places_signout,
    weight,
    change_shift,
    __typename,
    flexWorkDayTemplate,
  };
  return fetchedData;
};

export const getSumOfDurations = (
  durations = [] // example: ["05:00:00", "08:00:00"]
) => {
  if (durations?.length === 0) {
    return "00:00:00";
  }

  let totalMilliseconds = 0;

  durations.forEach((time) => {
    totalMilliseconds += moment.duration(time).asMilliseconds();
  });

  const totalSeconds = Math.floor(totalMilliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
};

export const serializeFetchedClockin = (data) => {
  return {
    status: data?.status,
    signInTime: data?.clock_in_time
      ? moment(data?.clock_in_time).format("HH:mm")
      : null,
    signOutTime: data?.clock_out_time
      ? moment(data?.clock_out_time).format("HH:mm")
      : null,
    signInWorkPlace: data?.clockInOffice?.id,
    signOutWorkPlace: data?.clockOutOffice?.id,
    leaveType: data?.leave_type,
    leaveHours: moment(data?.leave_hours, "hh:mm:ss").format("h"),
  };
};

export const normalizeUpsertFlexMonthlyProfile = ({
  id,
  // name,
  setRestrictions,
  setMinimumHrs,
  minimumHrs,
  penaltyFactor,
  deductFrom,
  calculateOvertime,
  hoursPerMonth,
  calculateOvertimeAfterWorkHoursBy,
  setOvertimeMonthLimit,
  overtimeMonthLimit,
  setMaxHrsPerMonth,
  maxHrsPerMonth,
  applyRestrictionsPer,
  treatExtraHoursAs,
  overtimeHourRate,
  overtimeFactor,
}) => {
  return {
    id,
    // name,
    restrict_work_hours: !!setRestrictions,
    ...(setRestrictions
      ? {
          apply_restrict_per: applyRestrictionsPer,
          set_minimum_work_hours: !!setMinimumHrs,
          ...(setMinimumHrs
            ? {
                minimum_work_hours: HelperFns.hoursToTime(minimumHrs),
                penalty_factor: parseFloat(penaltyFactor),
                deduct_from: deductFrom,
              }
            : {}),
          allow_overtime: !!calculateOvertime,
          ...(calculateOvertime
            ? {
                total_hours: HelperFns.hoursToTime(hoursPerMonth),
                calculate_overtime_after: HelperFns.minutesToTime(
                  calculateOvertimeAfterWorkHoursBy
                ),
                payment_factor: parseFloat(overtimeFactor),
                overtime_hour_rate: parseFloat(overtimeHourRate),
                allow_overtime_monthly_limit: !!setOvertimeMonthLimit,
                ...(setOvertimeMonthLimit
                  ? {
                      overtime_monthly_limit:
                        HelperFns.hoursToTime(overtimeMonthLimit),
                    }
                  : {}),
                allow_maximum_work_hours: !!setMaxHrsPerMonth,
                ...(setMaxHrsPerMonth
                  ? {
                      maximum_work_hours: HelperFns.hoursToTime(maxHrsPerMonth),
                    }
                  : {}),
                ...(calculateOvertime &&
                setOvertimeMonthLimit &&
                setMaxHrsPerMonth
                  ? {
                      treat_extra_overtime_hours_as: treatExtraHoursAs,
                    }
                  : {}),
              }
            : {}),
        }
      : {}),
  };
};

export const normalizeFetchedMonthlyProfile = ({
  id,
  restrict_work_hours,
  set_minimum_work_hours,
  minimum_work_hours,
  penalty_factor,
  deduct_from,
  allow_overtime,
  total_hours,
  calculate_overtime_after,
  allow_overtime_monthly_limit,
  overtime_monthly_limit,
  allow_maximum_work_hours,
  maximum_work_hours,
  apply_restrict_per,
  treat_extra_overtime_hours_as,
  payment_factor,
  overtime_hour_rate,
}) => {
  return {
    id,
    setRestrictions: restrict_work_hours ? 1 : 0,
    applyRestrictionsPer: apply_restrict_per,
    setMinimumHrs: set_minimum_work_hours ? 1 : 0,
    minimumHrs: HelperFns.timeToHours(minimum_work_hours),
    penaltyFactor: penalty_factor,
    deductFrom: deduct_from,
    calculateOvertime: allow_overtime ? 1 : 0,
    hoursPerMonth: HelperFns.timeToHours(total_hours),
    calculateOvertimeAfterWorkHoursBy: HelperFns.timeToMinutes(
      calculate_overtime_after
    ),
    setOvertimeMonthLimit: allow_overtime_monthly_limit ? 1 : 0,
    overtimeMonthLimit: HelperFns.timeToHours(overtime_monthly_limit),
    setMaxHrsPerMonth: allow_maximum_work_hours ? 1 : 0,
    maxHrsPerMonth: HelperFns.timeToHours(maximum_work_hours),
    treatExtraHoursAs: treat_extra_overtime_hours_as,
    overtimeFactor: payment_factor?.toString(),
    overtimeHourRate: overtime_hour_rate?.toString(),
  };
};

export const normalizedFetchedContainerForEditing = (container) => {
  const status = !!container?.status
    ? container?.status?.toLowerCase() == "day off"
      ? "dayOff"
      : "holiday"
    : "default";

  return {
    containerId: container?.id,
    workdayTemplateId: container?.flexWorkDayTemplate?.id,
    status,
    originalStatus: status,
    cost_center_id: container?.cost_center_id,
  };
};

export const isTotal24Hours = (ranges) => {
  const format = "HH:mm:ss";
  let totalDuration = moment.duration(0);

  ranges.forEach(({ from, to }) => {
    const start = moment(from, format);
    const end = moment(to, format);

    let duration = moment.duration(end.diff(start));

    if (duration.asMilliseconds() < 0) {
      duration = moment
        .duration(moment("24:00:00", format).diff(start))
        .add(moment.duration(end.diff(moment("00:00:00", format))));
    }

    totalDuration.add(duration);
  });

  return (
    Math.ceil(totalDuration.asHours()) === 24 ||
    !!ranges?.find(
      (range) =>
        moment(range?.from).format("HH:mm:ss") ==
        moment(range?.to).format("HH:mm:ss")
    )
  );
};

export const normalizeCompanySettingsPresetSubmittedData = ({
  id,

  // probation

  probation_value,
  probation_unit,

  // requests limit

  has_request_dayoff_limit,
  dayoff_request_limits,
  dayoff_period_type,
  dayoff_cutoff_time,
  submit_dayoff_cutoff_time,

  has_request_change_shift_limit,
  change_shift_request_limits,
  change_shift_period_type,
  change_shift_cutoff_time,
  submit_change_shift_cutoff_time,

  has_request_additional_shift_limit,
  additional_shift_request_limits,
  additional_shift_period_type,
  additional_shift_cutoff_time,
  submit_additional_shift_cutoff_time,

  // end of day behavious

  allow_linked_shifts,
  set_time_limit,
  time_limit,
}) => {
  return {
    id,
    probation_value: +probation_value,
    probation_unit: probation_value ? probation_unit : null,
    flex_company_settings: {
      allow_linked_shifts: !!allow_linked_shifts,
      ...(!!allow_linked_shifts && !!set_time_limit && !!time_limit
        ? {
            time_limit: HelperFns.minutesToTime(time_limit),
          }
        : {}),
    },
    request_configuration_settings: [
      {
        request_type_id: +DAY_OFF,
        period_type: dayoff_period_type,
        ...(!!has_request_dayoff_limit
          ? {
              request_limits: +dayoff_request_limits,
            }
          : {}),
        cutoff_time: !!submit_dayoff_cutoff_time
          ? HelperFns.hoursToTime(dayoff_cutoff_time)
          : null,
      },

      {
        request_type_id: +CHANGE_SHIFT,
        period_type: change_shift_period_type,
        ...(!!has_request_change_shift_limit
          ? {
              request_limits: +change_shift_request_limits,
            }
          : {}),
        cutoff_time: !!submit_change_shift_cutoff_time
          ? HelperFns.hoursToTime(change_shift_cutoff_time)
          : null,
      },

      {
        request_type_id: +ADDITIONAL_SHIFT,
        period_type: additional_shift_period_type,
        ...(!!has_request_additional_shift_limit
          ? {
              request_limits: +additional_shift_request_limits,
            }
          : {}),
        cutoff_time: !!submit_additional_shift_cutoff_time
          ? HelperFns.hoursToTime(additional_shift_cutoff_time)
          : null,
      },
    ],
  };
};

export const normalizeCompanySettingsPresetFetchedData = ({
  id = null,
  probation_value,
  probation_unit,
  allow_linked_shifts,
  time_limit,
  request_configuration_settings = [],
}) => {
  const dayOffConfig =
    request_configuration_settings?.find(
      (config) => config?.request_type_id == DAY_OFF
    ) ?? {};
  const changeShiftConfig =
    request_configuration_settings?.find(
      (config) => config?.request_type_id == CHANGE_SHIFT
    ) ?? {};
  const additionalShiftConfig =
    request_configuration_settings?.find(
      (config) => config?.request_type_id == ADDITIONAL_SHIFT
    ) ?? {};
  return {
    id,

    // probation

    probation_value,
    probation_unit,

    // requests limit

    has_request_dayoff_limit: !!dayOffConfig?.request_limits ? 1 : 0,
    dayoff_request_limits: dayOffConfig?.request_limits,
    dayoff_period_type: dayOffConfig?.period_type,
    submit_dayoff_cutoff_time: !!dayOffConfig?.cutoff_time ? 1 : 0,
    dayoff_cutoff_time: HelperFns.timeToHours(dayOffConfig?.cutoff_time),

    has_request_change_shift_limit: !!changeShiftConfig?.request_limits ? 1 : 0,
    change_shift_request_limits: changeShiftConfig?.request_limits,
    change_shift_period_type: changeShiftConfig?.period_type,
    change_shift_cutoff_time: HelperFns.timeToHours(
      changeShiftConfig?.cutoff_time
    ),
    submit_change_shift_cutoff_time: !!changeShiftConfig?.cutoff_time ? 1 : 0,

    has_request_additional_shift_limit: !!additionalShiftConfig?.request_limits
      ? 1
      : 0,
    additional_shift_request_limits: additionalShiftConfig?.request_limits,
    additional_shift_period_type: additionalShiftConfig?.period_type,
    submit_additional_shift_cutoff_time: !!additionalShiftConfig?.cutoff_time
      ? 1
      : 0,
    additional_shift_cutoff_time: HelperFns.timeToHours(
      additionalShiftConfig?.cutoff_time
    ),

    // end of day behavious

    allow_linked_shifts: +allow_linked_shifts,
    set_time_limit: !!allow_linked_shifts && !!time_limit ? 1 : 0,
    time_limit:
      !!allow_linked_shifts && !!time_limit
        ? HelperFns.timeToMinutes(time_limit)
        : null,
  };
};
