import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  cancleLoanRequestMutation,
  deleteLoanMutation,
} from "../../Graphql/mutation";
import { useMutation } from "@apollo/client";
import swal from "sweetalert";
import InstallmentsSection from "./InstallmentsSection";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  showErrorToast,
  showLoansModalAction,
  showSuccessToast,
} from "../../Store/Actions";
import Loader from "../../Components/Loader";
import CanViewEmployeeProfile from "../../Helpers/HOC/CanViewEmployeeProfile";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import loanStatus from "../../Constants/LoanStatusConstants";
import { Link } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import {
  EditIconButton,
  RemoveIconButton,
  TimesIconButton,
} from "../IconButtonWithTooltip";
import { Spinner } from "reactstrap";
import { showToast } from "../../Helpers/HelperFns";

const LoanCard = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // handle toggle installments section
  const [showInstallmentsSection, setShowInstallmentsSection] = useState(false);

  // handleing edit loan modal
  const handleEditLoan = (loanObj, e) => {
    // to pervent card from toggling
    e.stopPropagation();

    let data = {
      id: loanObj?.id,
      user_id: props?.isInEmployeeProfile ? props?.userId : loanObj?.user?.id,
      currency_id: loanObj?.currency?.id,
      name: loanObj?.name,
      amount: loanObj?.amount,
      granted_on: loanObj?.granted_on,
      notes: loanObj?.notes,
      installment_number: loanObj?.installment_number,
      include_payroll: loanObj?.include_payroll,
      status: loanObj?.status?.id,
      installments: loanObj?.installments.map((install) => ({
        id: install?.id,
        amount: install?.amount,
        payment_date: moment(install?.payment_date),
        status: install?.status?.id,
      })),
    };
    dispatch(
      showLoansModalAction({
        data,
        ref: "loansModalActions",
        formName: "loansModal",
      })
    );
  };

  // handleing delete swal
  const handleDeleteSwal = (loanID, e) => {
    // to pervent card from toggling
    e.stopPropagation();

    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        handleDeleteLoan(loanID);
      }
    });
  };

  const [cancleLoanRequest, { loading: cancleLoanRequestLoading }] =
    useMutation(cancleLoanRequestMutation, {
      onCompleted: (res) => {
        if (res?.cancel_loan_request?.status === "success") props.refetch();
        showToast(
          res?.cancel_loan_request?.status,
          res?.cancel_loan_request?.message
        );
      },
      onError: (error) => {
        showToast(
          "fail",
          error?.graphQLErrors[0]?.extensions?.reason ||
            error?.graphQLErrors[0]?.message ||
            error?.message
        );
      },
    });

  const handleCancelRequest = () => {
    swal({
      title: t("are you sure"),
      text: t(""),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("confirm")],
    }).then((confirm) => {
      if (confirm) {
        cancleLoanRequest({
          variables: {
            id: props?.id,
          },
        });
      }
    });
  };

  // handle delete loan mutation
  const handleDeleteLoan = (loanID) => {
    deleteLoan({
      variables: {
        loan_id: loanID,
      },
    })
      .then((response) => {
        if (
          response?.errors ||
          response?.data?.delete_loan?.status === "error"
        ) {
          dispatch(
            showErrorToast(
              response.data?.delete_loan?.message ||
                response.errors[0]?.extensions?.reason
            )
          );
        }
      })
      .catch((error) => {
        dispatch(showErrorToast(error?.message));
      });
  };

  const [
    deleteLoan,
    {
      data: deleteLoanResponse,
      loading: isDeleteLoanLoading,
      error: deleteLoanError,
    },
  ] = useMutation(deleteLoanMutation, {
    onCompleted: (data) => {
      if (data?.delete_loan?.status === "success") {
        dispatch(showSuccessToast(data?.delete_loan?.message));

        // handle fetch loans list
        props?.handleFetchLoansData();
      }
    },
  });

  // handle stop propgation in see more and see less btn
  const handleSeeMoreAndSeeLess = (e) => {
    // to pervent checkin section from toggling
    e.stopPropagation();
  };

  return (
    <>
      {isDeleteLoanLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div
        className={
          +props?.status?.id === loanStatus.SETTLED_LOAN
            ? "settled_status_style loan_card"
            : "ongoing_status_style loan_card"
        }
        onClick={() => setShowInstallmentsSection(!showInstallmentsSection)}
      >
        {props?.isInEmployeeProfile || props?.isInMyProfile ? null : (
          <CanViewEmployeeProfile
            allowBP
            directManger={props?.user?.manager?.id}
            copiedManagers={props?.user?.copied_managers?.map((cp) => cp?.id)}
            altChildren={
              <span className="loan_card_item">
                {props?.user?.name?.toLowerCase()}
              </span>
            }
          >
            <div className="link_name_style">
              <Link to={`/employees/employee-profile/${props?.user?.id}`}>
                <span className="loan_card_item">
                  {props?.user?.name?.toLowerCase()}
                </span>
              </Link>
            </div>
          </CanViewEmployeeProfile>
        )}
        <span className="loan_card_item">{props?.name}</span>
        <span className="loan_card_item">
          {props?.amount} {t(props?.currency?.name)}
        </span>
        <span className="loan_card_item">
          {props?.remainingToLoan} {t(props?.currency?.name)}
        </span>
        <span className="loan_card_item">{props?.installment_number}</span>
        <span className="loan_card_item">{props?.createdBy?.name}</span>
        <span
          className="loan_card_item"
          onClick={(e) => handleSeeMoreAndSeeLess(e)}
        >
          <ShowMoreText
            lines={1}
            more="Show more"
            less="Show less"
            expanded={false}
            width={130}
          >
            {props?.notes}
          </ShowMoreText>
        </span>

        <span className="loan_card_item">
          {props?.include_payroll ? (
            <>
              {t("Payroll")} {" - "}
              {props?.granted_on
                ? moment(props?.granted_on).format("MMMM, YYYY")
                : ""}
            </>
          ) : (
            <>
              {" "}
              {props?.granted_on
                ? moment(props?.granted_on).format("DD - MM -  YYYY")
                : ""}
            </>
          )}
        </span>
        <span className="loan_card_item">
          {+props?.status?.id == loanStatus.SETTLED_LOAN ? (
            <span className="settled_status_style">{t("Settled")}</span>
          ) : props?.status?.id == loanStatus.ONGOING_LOAN ? (
            <span className="ongoing_status_style">{t("Ongoing")}</span>
          ) : props?.status == 1 ? (
            <span className="accepted_status_style">{t("accepted")}</span>
          ) : props?.status == 2 ? (
            <span className="ongoing_status_style">{t("pending")}</span>
          ) : (
            <span className="rejected_status_style">{t("rejected")}</span>
          )}
        </span>
        {props?.isInMyProfile ? null : (
          <div className="loan_card_actions">
            <HasPrivileges
              reqireMain={[Privilages.ADD_EDIT_DELETE_LOAN]}
              allowBP
              avalibleOnExpire={false}
              altExpireChildren={<EditIconButton />}
            >
              <EditIconButton onClick={(e) => handleEditLoan(props, e)} />
            </HasPrivileges>

            <HasPrivileges
              reqireMain={[Privilages.ADD_EDIT_DELETE_LOAN]}
              allowBP
              avalibleOnExpire={false}
              altExpireChildren={<RemoveIconButton />}
            >
              <RemoveIconButton
                onClick={(e) => handleDeleteSwal(props?.id, e)}
              />
            </HasPrivileges>
          </div>
        )}

        {props?.isInMyProfile && props?.status == 2 && (
          <HasPrivileges
            reqireMain={[Privilages.REQUEST_CANCEL_LOAN]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={
              <div className="cards_table_actions">
                <TimesIconButton />
              </div>
            }
          >
            <div className="cards_table_actions">
              {cancleLoanRequestLoading ? (
                <Spinner />
              ) : (
                <>
                  <TimesIconButton
                    label="cancel"
                    onClick={handleCancelRequest}
                  />
                </>
              )}
            </div>
          </HasPrivileges>
        )}
      </div>

      {showInstallmentsSection && props?.installments?.length > 0 ? (
        <InstallmentsSection
          installments={props?.installments}
          handleFetchLoansData={props?.handleFetchLoansData}
          statusId={props?.status?.id}
          isInEmployeeProfile={props?.isInEmployeeProfile}
          userId={props?.userId}
          isInMyProfile={props?.isInMyProfile}
        />
      ) : null}
    </>
  );
};

export default LoanCard;
