import gql from "graphql-tag";

/*
  Attendance History
*/

/*
  Penalties
*/

/*
  Assignments
*/

export const assignmentFragment = gql`
  fragment assignment on Assignment {
    id
    space_id
    name
    to
    from
    canEdit
    canEditTo
    canEditFrom
    change_shift
    workplace_setting
    workplace_setting_signout
    workplace_setting_identical
    additional_work_places: workPlacesIn
    additional_work_places_signout: workPlacesOut
    normalWorkTiming {
      id
      name
    }
    offices {
      id
      name
    }
    createdBy {
      id
      name
    }
    is_holiday
  }
`;

export const flexAssignmentFragment = gql`
  fragment flexAssignment on FlexAssignment {
    id
    name
    to
    from
    canEdit
    canEditTo
    canEditFrom
    change_shift
    workplace_setting
    workplace_setting_signout: workplace_setting_sign_out
    workplace_setting_identical
    additional_work_places: workPlacesIn
    additional_work_places_signout: workPlacesOut
    flexWorkDayTemplate {
      id
      name
    }
    offices {
      id
      name
    }
    createdBy {
      id
      name
    }
    is_holiday
  }
`;

/*
  Monthly Report
*/

/*
  Yearly Report
*/

/*
  Facial Report
*/
