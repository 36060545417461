import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { connect } from "react-redux";
import {
  acceptInboundRequestAction,
  rejectInboundRequestAction,
  hideViewAttRequestModal,
  cancelRequestAction,
} from "../../Store/Actions";
import swal from "sweetalert";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Loader from "../../Components/Loader";
import Tooltip from "@mui/material/Tooltip";
import HelperFns from "../../Helpers/HelperFns";
import Privileges from "../../Constants/Privilages";
import { useLazyQuery } from "@apollo/client";
import { GET_COST_CENTERS_MENU } from "../../Graphql/query";
import { BSelect } from "form-builder";
import useAllowCostCenter from "../../Helpers/Hooks/useAllowCostCenter";

const AttRequestInfoModal = ({ refetchQueries = [], ...props }) => {
  const {
    data,
    isModalVissible,
    isNotificationVissible,
    pageFlag,
    employeeId,
  } = props;

  const handleHideModal = () => {
    props?.hideViewAttRequestModal();
  };
  const { t } = useTranslation();

  const { allowCostCenter } = useAllowCostCenter();

  const [costCenter, setCostCenter] = useState(null);

  const handleChangeCostCenter = (value) => {
    setCostCenter(value);
  };

  const handleAcceptBtn = () => {
    // if (!!!costCenter) {
    //   return;
    // }

    swal({
      title: t("default_warning_accept_message"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.acceptInboundRequestAction(
          data?.id,
          employeeId,
          pageFlag,
          true,
          refetchQueries,
          costCenter?.id
        );
      }
    });
  };

  const handleRejectBtn = () => {
    swal({
      title: t("default_warning_reject_message"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.rejectInboundRequestAction(
          data?.id,
          employeeId,
          pageFlag,
          true,
          refetchQueries
        );
      }
    });
  };

  const handleCancelRequest = () => {
    swal({
      title: t("default_warning_cancel_message"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.cancelRequestAction(
          data?.id,
          pageFlag,
          null,
          null,
          refetchQueries
        );
      }
    });
  };

  const [getCostCenterMenu, { data: costCentersMenuQueryData }] = useLazyQuery(
    GET_COST_CENTERS_MENU
  );

  const canCancel = data?.canCancel;
  const canAcceptReject =
    !["my_requests_history", "my_requests_general"].includes(props?.pageFlag) &&
    HelperFns.checkPrivileges({
      allowBP: true,
      privileges: [Privileges.MANAGE_EMPLOYEE_REQUESTS],
    });

  useEffect(() => {
    if (isModalVissible && canAcceptReject) {
      getCostCenterMenu();
    }
  }, [isModalVissible, canAcceptReject]);

  const modalActions =
    data?.status?.toLowerCase() === "pending"
      ? {
          hasModalFooter: canCancel || canAcceptReject,
          // Accept
          btnLabel: canAcceptReject && t("accept"),
          btnOnClick: canAcceptReject && handleAcceptBtn,
          // Cancel & Reject
          btnLabelCancel: canCancel
            ? t("Cancel")
            : canAcceptReject
              ? t("reject")
              : undefined,
          btnOnCancelClick: canCancel
            ? handleCancelRequest
            : canAcceptReject
              ? handleRejectBtn
              : undefined,
        }
      : { hasModalFooter: false };

  if (props?.isLoading) {
    return (
      <div className="loader_wrapper_style">
        <Loader />
      </div>
    );
  }

  return (
    <MainModal
      toggle={handleHideModal}
      isOpen={
        pageFlag.toLowerCase() === "notifications"
          ? isNotificationVissible
          : isModalVissible
      }
      {...modalActions}
      modalTitle={t("view requests details")}
      btnCancelLoading={
        props?.isLoading ||
        props?.requstsSuperLoader?.includes(data?.id) ||
        props?.requstsUserLoader?.includes(data?.id)
      }
      btnSubmitLoading={
        props?.isLoading ||
        props?.requstsSuperLoader?.includes(data?.id) ||
        props?.requstsUserLoader?.includes(data?.id)
      }
      modalClassName="unset-min-width"
    >
      <div>
        <div>
          <label className="d-block">{data?.employee?.user?.name}</label>
          <label className="d-block gray-color mt-1">{data?.req_day}</label>
        </div>

        <div className="row mt-3">
          <div className="col-6">
            <label className="d-block">{t("status")}</label>
            <label className="d-block gray-color mt-1">
              {t(`${data?.sign_in_status?.toLowerCase()}`) || "------"}
            </label>
          </div>
          {!!data?.from_home ? (
            <div className="col-6">
              <label className="d-block">&nbsp;</label>
              <label className="d-block mt-1">
                {t("from home")} (
                {!!data?.from_field ? t("from field") : t("from_home")})
              </label>
            </div>
          ) : null}
        </div>

        <div className="row mt-3">
          <div className="col-6">
            <label className="d-block">{t("sign in")}</label>

            <Tooltip
              classes={{
                tooltip: "edit_attendance_tooltip_style custom_tooltip_style",
                arrow: "edit_attendance_arrow_style custom_arrow_tooltip_style",
              }}
              arrow={true}
              title={
                <React.Fragment>
                  <div className="d-flex justify-content-center pb-0">
                    <div>
                      <label>{t("previous sign in time")} :</label>
                    </div>

                    <div className="mx-2">
                      <p className="other_employee_info_style mb-2">
                        {data?.actual_sign_in_out?.actualSignIn
                          ? moment(
                              data?.actual_sign_in_out?.actualSignIn
                            ).format("dddd (DD/MM) hh:mm A")
                          : " -- : --"}
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              }
            >
              <label className="d-block gray-color mt-1 fit-width cursor-pointer">
                {data?.sign_in_time
                  ? moment(data?.sign_in_time).format("dddd (DD/MM) hh:mm A")
                  : " -- : --"}
              </label>
            </Tooltip>
          </div>

          <div className="col-6">
            <label className="d-block">{t("sign out")}</label>

            <Tooltip
              classes={{
                tooltip: "edit_attendance_tooltip_style custom_tooltip_style",
                arrow: "edit_attendance_arrow_style custom_arrow_tooltip_style",
              }}
              arrow={true}
              title={
                <React.Fragment>
                  <div className="d-flex justify-content-center pb-0">
                    <div>
                      <label>{t("previous sign out time")} :</label>
                    </div>

                    <div className="mx-2">
                      <p className="other_employee_info_style mb-2">
                        {data?.actual_sign_in_out?.actualSignOut
                          ? moment(
                              data?.actual_sign_in_out?.actualSignOut
                            ).format("dddd (DD/MM) hh:mm A")
                          : " -- : --"}
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              }
            >
              <label className="d-block gray-color mt-1 fit-width cursor-pointer">
                {data?.sign_out_time
                  ? moment(data?.sign_out_time).format("dddd (DD/MM) hh:mm A")
                  : " -- : --"}
              </label>
            </Tooltip>
          </div>
        </div>

        <div className="mt-3">
          <label className="d-block">{t("day type")}</label>
          <label className="d-block gray-color mt-1">
            {t(`${data?.day_type?.toLowerCase()}`) || "------"}
          </label>
        </div>

        {allowCostCenter ? (
          <BSelect
            value={costCenter}
            onChange={handleChangeCostCenter}
            validationName={"input.cost_center_id"}
            containerStyle="mt-3"
            name="cost_center_id"
            label="cost center"
            keepDefaultStyle
            placeholder={t("select cost center")}
            options={costCentersMenuQueryData?.cost_centers?.data ?? []}
            labelStyle="mb-2"
            inputContainerStyle="b-select-style attendance_work_timing_select"
            icon="money"
          />
        ) : null}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => {
  return {
    requstsSuperLoader: state.super.requstsLoader,
    requstsUserLoader: state.user.requstsLoader,
    data: state.user.viewAttRequestModalData,
    isModalVissible: state.user.viewAttRequestModal.isVissible,
    isNotificationVissible:
      state.user.viewAttRequestModal.isNotificationVissible,
    isLoading: state.user.viewAttRequestModal.isLoading,
  };
};

export default connect(mapStateToProps, {
  acceptInboundRequestAction,
  rejectInboundRequestAction,
  hideViewAttRequestModal,
  cancelRequestAction,
})(AttRequestInfoModal);
