import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InputForm } from "form-builder";

// Ui
import MainModal from "../MainModal";

// Helpers
import {
  EDIT_FLEX_WORK_TEAM_NAME,
  upsertWorkTeamMutation,
} from "../../Graphql/mutation";
import { onFormResetAction } from "../../Store/Actions";
import { editWorkTeamAction } from "../../Store/Actions/shiftsActions/actions";
import { handleActionMutation, showToast } from "../../Helpers/HelperFns";

//   form props
const formName = "editWorkTeamMembersForm";
const formNameValidation = "editWorkTeamMembersFormValidation";
const formServerValidation = "editWorkTeamMembersFormServerValidation";
const reducer = "shifts";

const EditFlexWorkTeamModal = ({
  isModalOpen,
  setIsModalOpen,
  fetchFlexWorkTeam,
  workTeam,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation();
  const { flexWorkTeamId } = useParams();

  const formProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
    reducer,
  };

  //   Redux hooks
  const dispatch = useDispatch();
  const editWorkTeamMembersForm = useSelector(
    (state) => state?.shifts?.[formName]
  );
  const formClientValidation = useSelector(
    (state) => state?.shifts?.[formNameValidation]
  );

  const [upsertFlexWorkTeam, { loading: upsertFlexWorkTeamLoading }] =
    useMutation(EDIT_FLEX_WORK_TEAM_NAME);

  useEffect(() => {
    dispatch(editWorkTeamAction(workTeam?.name));

    return () => {
      dispatch(onFormResetAction(formName));
      dispatch(onFormResetAction(formNameValidation));
      dispatch(onFormResetAction(formServerValidation));
    };
  }, []);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    if (formClientValidation?.length === 0) {
      setIsSubmitting(false);

      upsertFlexWorkTeam({
        variables: {
          input: {
            id: flexWorkTeamId,
            name: editWorkTeamMembersForm?.name ?? "",
          },
        },
        onCompleted: (data) => {
          if (
            data?.updateOrCreateFlexWorkTeam?.__typename == "GeneralException"
          ) {
            showToast(
              "error",
              data?.updateOrCreateFlexWorkTeam?.message ??
                "Something went wrong"
            );
            return;
          }
          if (data?.updateOrCreateFlexWorkTeam?.id) {
            fetchFlexWorkTeam({
              variables: {
                id: flexWorkTeamId,
              },
            });
          }
          setIsModalOpen(false);
        },
        onError: (err) => {
          showToast(
            "error",
            err?.graphQLErrors?.[0]?.extensions?.reason ||
              err?.graphQLErrors?.[0]?.message ||
              err?.message
          );
        },
      });
    }
  };

  return (
    <MainModal
      isOpen={isModalOpen}
      toggle={() => setIsModalOpen(false)}
      modalTitle={t("edit flex work team")}
      btnLabel={t("save")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={upsertFlexWorkTeamLoading}
    >
      <InputForm
        {...formProps}
        validateBy="textRequired"
        label={t("team name")}
        name="name"
        validationName="input.name"
        type="text"
        placeholder={t("Team name")}
        containerStyle="name_field_container"
        inputContainerStyle="name_field_input"
        labelStyle="name_field_label"
        icon="people"
      />
    </MainModal>
  );
};

export default EditFlexWorkTeamModal;
